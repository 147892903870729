import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SingleErrorMessage = _resolveComponent("SingleErrorMessage")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card_item = _resolveComponent("v-card-item")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.errorMessage != '')
      ? (_openBlock(), _createBlock(_component_SingleErrorMessage, {
          key: 0,
          errorMessage: _ctx.errorMessage
        }, null, 8, ["errorMessage"]))
      : _createCommentVNode("", true),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_v_container, {
          key: 1,
          fluid: "",
          style: {"display":"inline-grid","justify-content":"center"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_progress_circular, {
              size: 70,
              width: 7,
              color: "loading",
              indeterminate: ""
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!_ctx.canViewRolePermission)
      ? (_openBlock(), _createBlock(_component_v_container, {
          key: 2,
          fluid: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, { flat: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_title, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_img, {
                          cover: "",
                          height: "250px",
                          src: "https://upload.wikimedia.org/wikipedia/commons/b/bd/Wyoming_Supreme_Court_Building%2C_Cheyenne%2C_Southwest_view_20110823_1.jpg",
                          class: "text-white"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_toolbar, {
                              theme: "dark",
                              color: "rgba(0, 0, 0, 0.39)",
                              style: {"margin-top":"185px"}
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_toolbar_title, { class: "text-h6" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" Wyoming CSTMS ")
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : (!_ctx.isLoading && _ctx.canViewRolePermission)
        ? (_openBlock(), _createBlock(_component_v_container, { key: 3 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_sheet, {
                elevation: "8",
                height: "100%",
                width: "100%"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, { flat: "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_title, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_img, {
                                cover: "",
                                height: "250px",
                                src: "https://upload.wikimedia.org/wikipedia/commons/b/bd/Wyoming_Supreme_Court_Building%2C_Cheyenne%2C_Southwest_view_20110823_1.jpg",
                                class: "text-white"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_toolbar, {
                                    theme: "dark",
                                    color: "rgba(0, 0, 0, 0.39)",
                                    style: {"margin-top":"185px"}
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_toolbar_title, { class: "text-h6" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString("Tasklist Items For " + _ctx.currentUser?.firstName + " " + _ctx.currentUser?.lastName), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_text_field, {
                                        color: "white",
                                        class: "custom-label-color",
                                        modelValue: _ctx.searchTaskListItems,
                                        "onUpdate:modelValue": [
                                          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTaskListItems) = $event)),
                                          _ctx.filterData
                                        ],
                                        label: "Search For Any Tasklist Item",
                                        "single-line": "",
                                        "hide-details": ""
                                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchOptions, (option) => {
                            return (_openBlock(), _createBlock(_component_v_col, {
                              cols: "12",
                              md: "4",
                              lg: "3",
                              key: option.key
                            }, {
                              default: _withCtx(() => [
                                (option.type === 'select')
                                  ? (_openBlock(), _createBlock(_component_v_select, {
                                      key: 0,
                                      modelValue: (_ctx.taskSearch as ITaskSearch)[option.key as keyof ITaskSearch],
                                      "onUpdate:modelValue": [($event: any) => (((_ctx.taskSearch as ITaskSearch)[option.key as keyof ITaskSearch]) = $event), _ctx.filterData],
                                      clearable: "",
                                      items: _ctx.lookups.lookupLists[option.lookupKey || 0] as IBaseLookup[] ?? [],
                                      "item-value": "id",
                                      "item-title": "description",
                                      label: option.name,
                                      style: {"margin-left":"15px","margin-right":"15px"}
                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "items", "label"]))
                                  : (_openBlock(), _createBlock(_component_v_text_field, {
                                      key: 1,
                                      modelValue: (_ctx.taskSearch as ITaskSearch)[option.key as keyof ITaskSearch],
                                      "onUpdate:modelValue": ($event: any) => (((_ctx.taskSearch as ITaskSearch)[option.key as keyof ITaskSearch]) = $event),
                                      onInput: _ctx.filterData,
                                      label: option.name,
                                      "hide-details": "",
                                      color: "white",
                                      style: {"margin-left":"15px","margin-right":"15px"}
                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput", "label"]))
                              ]),
                              _: 2
                            }, 1024))
                          }), 128)),
                          _createVNode(_component_v_col, {
                            cols: "12",
                            md: "8",
                            lg: "6"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_select, {
                                modelValue: _ctx.taskSearch.treatmentProgram,
                                "onUpdate:modelValue": [
                                  _cache[1] || (_cache[1] = ($event: any) => ((_ctx.taskSearch.treatmentProgram) = $event)),
                                  _ctx.filterData
                                ],
                                clearable: "",
                                items: _ctx.currentUser.attachedPrograms,
                                "item-value": "id",
                                "item-title": "description",
                                label: "Treatement Program",
                                style: {"margin-left":"15px","margin-right":"15px"}
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "items"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_data_table, {
                            density: "compact",
                            "sort-by": _ctx.sortBy,
                            "onUpdate:sortBy": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sortBy) = $event)),
                            headers: _ctx.taskListHeaders,
                            items: _ctx.taskListItems,
                            "custom-filter": _ctx.filterTaskList,
                            class: "elevation-1"
                          }, {
                            [`item.modifyDate`]: _withCtx(({ item }) => [
                              _createElementVNode("tr", null, [
                                _createElementVNode("td", null, _toDisplayString(_ctx.formatDateForDisplay(item.raw.modifyDate)), 1)
                              ])
                            ]),
                            [`item.actions`]: _withCtx(({ item }) => [
                              _createVNode(_component_v_btn, {
                                onClick: ($event: any) => (_ctx.showWorkflowSection(item.raw)),
                                density: "comfortable",
                                size: "small",
                                icon: "mdi-account-arrow-right",
                                color: "info"
                              }, null, 8, ["onClick"])
                            ]),
                            _: 2
                          }, 1032, ["sort-by", "headers", "items", "custom-filter"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
  ], 64))
}