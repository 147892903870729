import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IncorrectPermissions = _resolveComponent("IncorrectPermissions")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_SingleErrorMessage = _resolveComponent("SingleErrorMessage")!
  const _component_ValidationAlert = _resolveComponent("ValidationAlert")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_DocumentGenerator = _resolveComponent("DocumentGenerator")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_BulkEditor = _resolveComponent("BulkEditor")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!

  return (_ctx.isPermissionDenied)
    ? (_openBlock(), _createBlock(_component_v_container, {
        key: 0,
        fluid: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IncorrectPermissions)
        ]),
        _: 1
      }))
    : (_ctx.errorMessage != '')
      ? (_openBlock(), _createBlock(_component_v_container, {
          key: 1,
          fluid: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_SingleErrorMessage, { errorMessage: _ctx.errorMessage }, null, 8, ["errorMessage"])
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_v_container, {
          key: 2,
          fluid: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ValidationAlert, { validationResults: _ctx.validationResults }, null, 8, ["validationResults"]),
            (_ctx.isLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_v_progress_circular, {
                    size: 70,
                    width: 7,
                    color: "loading",
                    indeterminate: ""
                  })
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_v_card, { style: {"margin-bottom":"20px"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, { class: "text-h5" }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Select Court to Edit Associated Templates ")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_select, _mergeProps({
                            modelValue: _ctx.selectedProgram,
                            "onUpdate:modelValue": [
                              _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedProgram) = $event)),
                              _ctx.updateSelectedProgram
                            ],
                            clearable: "",
                            items: _ctx.availablePrograms,
                            "item-title": "description"
                          }, {'return-object':true}), null, 16, ["modelValue", "items", "onUpdate:modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (!_ctx.isLoading)
                    ? (_openBlock(), _createBlock(_component_v_expansion_panels, {
                        key: 0,
                        multiple: "",
                        modelValue: _ctx.openPanels,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.openPanels) = $event)),
                        disabled: _ctx.selectedProgram === null,
                        variant: "accordion"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_expansion_panel, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_expansion_panel_title, { class: "text-h5" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Generate Documents ")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_expansion_panel_text, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_DocumentGenerator, {
                                    documentParentId: -1,
                                    documentParentTypeId: _ctx.DocumentParentType.Generated,
                                    infillProp: _ctx.infillVariables,
                                    refreshProp: _ctx.templateRefreshProp,
                                    treatmentProgramId: _ctx.selectedProgram!.treatmentProgramId,
                                    onDidTemplateRefresh: _ctx.didTemplateRefresh
                                  }, null, 8, ["documentParentTypeId", "infillProp", "refreshProp", "treatmentProgramId", "onDidTemplateRefresh"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_expansion_panel, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_expansion_panel_title, { class: "text-h5" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Court Header Templates ")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_expansion_panel_text, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_BulkEditor, {
                                    availableTemplates: _ctx.headerTemplates,
                                    activeProgram: _ctx.selectedProgram,
                                    templateTypeId: _ctx.TemplateType.Header,
                                    treatmentProgramId: _ctx.selectedProgram?.treatmentProgramId ?? 0,
                                    onTemplateCreated: _ctx.doTemplateRefresh,
                                    onTemplateDeleted: _ctx.didTemplateDelete,
                                    onTemplateSaved: _ctx.doTemplateRefresh
                                  }, null, 8, ["availableTemplates", "activeProgram", "templateTypeId", "treatmentProgramId", "onTemplateCreated", "onTemplateDeleted", "onTemplateSaved"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_expansion_panel, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_expansion_panel_title, { class: "text-h5" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Letter Templates ")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_expansion_panel_text, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_BulkEditor, {
                                    availableTemplates: _ctx.letterTemplates,
                                    activeProgram: _ctx.selectedProgram,
                                    templateTypeId: _ctx.TemplateType.Letter,
                                    treatmentProgramId: _ctx.selectedProgram?.treatmentProgramId ?? 0,
                                    onTemplateCreated: _ctx.doTemplateRefresh,
                                    onTemplateDeleted: _ctx.didTemplateDelete,
                                    onTemplateSaved: _ctx.doTemplateRefresh
                                  }, null, 8, ["availableTemplates", "activeProgram", "templateTypeId", "treatmentProgramId", "onTemplateCreated", "onTemplateDeleted", "onTemplateSaved"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue", "disabled"]))
                    : _createCommentVNode("", true)
                ]))
          ]),
          _: 1
        }))
}