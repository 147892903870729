import { ComponentType } from '@/Enums/ComponentType'
import { Role } from '@/Enums/Role'

export const ComponentPermissionsRegistry: Record<ComponentType, Record<string, Record<Role, boolean>>> = {
    [ComponentType.TaskList]: {
        CanEdit: {
            [Role.StateAdministrator]: true,
            [Role.CaseManager]: true,
            [Role.Coordinator]: true,
            [Role.Judge]: true,
            [Role.StateUser]: false,
            [Role.Provider]: false,
			[Role.ProbationSupervisor]: false,
			[Role.PeerSupportSpecialist]: false,
		},
        CanView: {
            [Role.StateAdministrator]: true,
            [Role.CaseManager]: true,
            [Role.Coordinator]: true,
            [Role.Judge]: true,
            [Role.StateUser]: true,
            [Role.Provider]: false,
			[Role.ProbationSupervisor]: true,
			[Role.PeerSupportSpecialist]: true,
		}
    },
    [ComponentType.ParticipantSearch]: {
        CanEdit: {
            [Role.StateAdministrator]: true,
            [Role.CaseManager]: true,
            [Role.Coordinator]: true,
            [Role.Judge]: true,
            [Role.StateUser]: false,
            [Role.Provider]: false,
			[Role.ProbationSupervisor]: false,
			[Role.PeerSupportSpecialist]: false,
        },
    },
    [ComponentType.ParticipantInfo]: {
        CanEdit: {
            [Role.StateAdministrator]: true,
            [Role.CaseManager]: true,
            [Role.Coordinator]: true,
            [Role.Judge]: true,
            [Role.StateUser]: false,
            [Role.Provider]: false,
			[Role.ProbationSupervisor]: false,
			[Role.PeerSupportSpecialist]: false,
        },
    },
    [ComponentType.ParticipantNotes]: {
        CanEdit: {
            [Role.StateAdministrator]: true,
            [Role.CaseManager]: true,
            [Role.Coordinator]: true,
            [Role.Judge]: true,
            [Role.StateUser]: false,
            [Role.Provider]: false,
			[Role.ProbationSupervisor]: false,
			[Role.PeerSupportSpecialist]: false,
        }
    },
    [ComponentType.ContactInfo]: {
        CanEdit: {
            [Role.StateAdministrator]: true,
            [Role.CaseManager]: true,
            [Role.Coordinator]: true,
            [Role.Judge]: true,
            [Role.StateUser]: false,
            [Role.Provider]: false,
			[Role.ProbationSupervisor]: false,
			[Role.PeerSupportSpecialist]: false,
        },
    },
    [ComponentType.CaseNotes]: {
        CanEdit: {
            [Role.StateAdministrator]: true,
            [Role.CaseManager]: true,
            [Role.Coordinator]: true,
            [Role.Judge]: true,
            [Role.StateUser]: false,
            [Role.Provider]: false,
			[Role.ProbationSupervisor]: false,
			[Role.PeerSupportSpecialist]: false,
        },
        CanView: {
            [Role.StateAdministrator]: true,
            [Role.CaseManager]: true,
            [Role.Coordinator]: true,
            [Role.Judge]: true,
            [Role.StateUser]: true,
            [Role.Provider]: true,
			[Role.ProbationSupervisor]: true,
			[Role.PeerSupportSpecialist]: true,
        },
        CanViewConditional: {
            [Role.StateAdministrator]: false,
            [Role.CaseManager]: false,
            [Role.Coordinator]: false,
            [Role.Judge]: false,
            [Role.StateUser]: false,
            [Role.Provider]: true,  // Can view only notes marked as viewable by provider
			[Role.ProbationSupervisor]: true,
			[Role.PeerSupportSpecialist]: true,
       }
    },
    [ComponentType.CaseLedger]: {
        CanEdit: {
            [Role.StateAdministrator]: true,
            [Role.CaseManager]: true,
            [Role.Coordinator]: true,
            [Role.Judge]: true,
            [Role.StateUser]: false,
            [Role.Provider]: false,
			[Role.ProbationSupervisor]: false,
			[Role.PeerSupportSpecialist]: false,
        },
        CanView: {
            [Role.StateAdministrator]: true,
            [Role.CaseManager]: true,
            [Role.Coordinator]: true,
            [Role.Judge]: true,
            [Role.StateUser]: true,
            [Role.Provider]: false,
			[Role.ProbationSupervisor]: true,
			[Role.PeerSupportSpecialist]: true,
       }
    },
    [ComponentType.ParticipantEligibility]: {
        CanEdit: {
            [Role.StateAdministrator]: true,
            [Role.CaseManager]: true,
            [Role.Coordinator]: true,
            [Role.Judge]: true,
            [Role.StateUser]: false,
            [Role.Provider]: false,
			[Role.ProbationSupervisor]: false,
			[Role.PeerSupportSpecialist]: false,
        },
    },
    [ComponentType.PhaseDashboard]: {
        CanEdit: {
            [Role.StateAdministrator]: true,
            [Role.CaseManager]: true,
            [Role.Coordinator]: true,
            [Role.Judge]: true,
            [Role.StateUser]: false,
            [Role.Provider]: false,
			[Role.ProbationSupervisor]: false,
			[Role.PeerSupportSpecialist]: false,
        },
        CanDelete: {
            [Role.StateAdministrator]: true,
            [Role.CaseManager]: true,
            [Role.Coordinator]: true,
            [Role.Judge]: false,
            [Role.StateUser]: false,
            [Role.Provider]: false,
			[Role.ProbationSupervisor]: false,
			[Role.PeerSupportSpecialist]: false,
       },
    },
    [ComponentType.DrugTest]: {
        CanEdit: {
            [Role.StateAdministrator]: true,
            [Role.CaseManager]: true,
            [Role.Coordinator]: true,
            [Role.Judge]: true,
            [Role.StateUser]: false,
            [Role.Provider]: false,
			[Role.ProbationSupervisor]: false,
			[Role.PeerSupportSpecialist]: false,
        },
    },
    [ComponentType.DrugTestDashboard]: {
        CanEdit: {
            [Role.StateAdministrator]: true,
            [Role.CaseManager]: true,
            [Role.Coordinator]: true,
            [Role.Judge]: true,
            [Role.StateUser]: false,
            [Role.Provider]: false,
			[Role.ProbationSupervisor]: false,
			[Role.PeerSupportSpecialist]: false,
        },
    },
    [ComponentType.CaseClosure]: {
        CanEdit: {
            [Role.StateAdministrator]: true,
            [Role.CaseManager]: true,
            [Role.Coordinator]: true,
            [Role.Judge]: true,
            [Role.StateUser]: false,
            [Role.Provider]: false,
			[Role.ProbationSupervisor]: false,
			[Role.PeerSupportSpecialist]: false,
        },
    // ... add more permissions for the same component type if needed
    },
    [ComponentType.CriminalHistory]: {
        CanEdit: {
            [Role.StateAdministrator]: true,
            [Role.CaseManager]: true,
            [Role.Coordinator]: true,
            [Role.Judge]: true,
            [Role.StateUser]: false,
            [Role.Provider]: false,
			[Role.ProbationSupervisor]: false,
			[Role.PeerSupportSpecialist]: false,
        },
    },
	[ComponentType.OutcomeSummary]: {
        CanEdit: {
            [Role.StateAdministrator]: true,
            [Role.CaseManager]: true,
            [Role.Coordinator]: true,
            [Role.Judge]: true,
            [Role.StateUser]: false,
            [Role.Provider]: false,
			[Role.ProbationSupervisor]: false,
			[Role.PeerSupportSpecialist]: false,
        },
        CanView: {
            [Role.StateAdministrator]: true,
            [Role.CaseManager]: true,
            [Role.Coordinator]: true,
            [Role.Judge]: true,
            [Role.StateUser]: true,
            [Role.Provider]: true,
			[Role.ProbationSupervisor]: false,
			[Role.PeerSupportSpecialist]: false,
        },
	},
	[ComponentType.CaseDocuments]: {
        CanEdit: {
            [Role.StateAdministrator]: true,
            [Role.CaseManager]: true,
            [Role.Coordinator]: true,
            [Role.Judge]: true,
            [Role.StateUser]: false,
            [Role.Provider]: false,
			[Role.ProbationSupervisor]: false,
			[Role.PeerSupportSpecialist]: false,
        },
        CanView: {
            [Role.StateAdministrator]: true,
            [Role.CaseManager]: true,
            [Role.Coordinator]: true,
            [Role.Judge]: true,
            [Role.StateUser]: true,
            [Role.Provider]: true,
			[Role.ProbationSupervisor]: true,
			[Role.PeerSupportSpecialist]: true,
        },
	}
  // ... add more components and their corresponding permissions
}
