import { BaseDto, IBaseDto } from "./IBaseDto";
import { DateHelper } from '@/Services/Helper/date-helper';

export interface ICaseNote extends IBaseDto {
    caseNoteId: number;
	caseId: number;
	noteTypeId: number;
	noteTypeOther: string;
	note: string;
	noteDate: Date;
	createdByName: string;
	noteType: string;
	canProviderView: boolean;
}

export class CaseNoteForEdit extends BaseDto implements ICaseNote {
	caseNoteId: number;
	caseId: number;
	noteTypeId: number;
	noteTypeOther: string;
	note: string;
	noteDate: Date;
	createdByName: string;
	noteType: string;
	noteDateEditable: string;
	canProviderView: boolean;

	constructor(src: ICaseNote) {
        super(src);
		this.caseNoteId = src.caseNoteId;
		this.caseId = src.caseId;
		this.noteTypeId = src.noteTypeId;
		this.noteTypeOther = src.noteTypeOther;
		this.note = src.note;
		this.noteDate = src.noteDate;
		this.createdByName = src.createdByName;
		this.noteType = src.noteType;
		this.noteDateEditable = DateHelper.formatDateUtc(src.noteDate, "yyyy-MM-dd");
		this.canProviderView = src.canProviderView;
	}
}
