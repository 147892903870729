
	import { defineComponent, PropType, Ref, ref } from 'vue';

	import { WorkflowService } from '@/Services/workflow-service';
	import { ValidationHelper } from '@/Services/Helper/validation-helper';

	import { IWorkflowStatus } from '@/Models/Workflow/IWorkflowStatus';
	import { IWorkflowSubmit } from '@/Models/IWorkflowSubmit';

	export default defineComponent({
		name: 'workflow-submission',
		setup(): { workflowForm: Ref } {
			const workflowForm = ref(null);
			return {
				workflowForm
			};
		},
		data() {
			return {
				nextWorkflowStatusSelectItems: [] as IWorkflowStatus[],
				statusFieldRules: ValidationHelper.requiredRules,
				noteFieldRules: ValidationHelper.requiredRules,
				selectedNextStatusId: null as number | null,
				notes: "" as string,
				saveButtonsDisabled: false as boolean,

			}
		},
		props: {
			suppliedWorkflowInstanceId: {
				type: Number as PropType<number>,
				required: true
			},
			showPartialSaveOptions: {
				type: Boolean as PropType<boolean>,
				required: true
			},
            defaultNextWorkflowStatusId: {
                type: Number,
                required: false
            },
            isParentLoading: {
                type: Boolean,
                required: false
            },
            title: {
                type: String,
                required: false
            },
            variant: {
                type: String,
                default: "elevated"
            },
            showSubmitAndDoMore: {
                type: Boolean,
                required: false
            },
            submitAndDoMoreText: {
                type: String,
                default: "Submit And More"
            },
		},
		watch: {
			selectedNextStatusId() {
				const selectedStatus = this.nextWorkflowStatusSelectItems.find(x => x.workflowStatusId == this.selectedNextStatusId);
				this.noteFieldRules = selectedStatus != undefined && selectedStatus.isNoteRequired ? ValidationHelper.requiredRules : [];
			},
			suppliedWorkflowInstanceId: {
				handler(newValue: number): void{
					if (newValue > 1) {
						this.fetchApprovedStatusIds();
						return;
					}
					else {
						return;
					}
				},
				immediate: true
			},
            isParentLoading() {
                this.saveButtonsDisabled = !!this.isParentLoading
            }
		},
		methods: {  
			fetchApprovedStatusIds(): void {
				WorkflowService.listNextWorkflowStatuses(this.suppliedWorkflowInstanceId).then((response) => {
					this.nextWorkflowStatusSelectItems = response;
                    if (this.defaultNextWorkflowStatusId && this.nextWorkflowStatusSelectItems.some(x => x.workflowStatusId == this.defaultNextWorkflowStatusId)) {
                        this.selectedNextStatusId = this.defaultNextWorkflowStatusId;
                    }
				});
			},
			savePartial(): void {
				this.saveButtonsDisabled = true;
				this.$emit('partial-save', true);
			},
			async submitWorkflowStep(doMore: boolean): Promise<void> {
				const {valid} = await this.workflowForm.validate();
				if (valid) {
					this.saveButtonsDisabled = true;
					const submitDto = {
						workflowInstanceId: this.suppliedWorkflowInstanceId,
						note: this.notes,
						newWorkflowStatusId: this.selectedNextStatusId,
						assignedUserId: null
					} as IWorkflowSubmit;
					WorkflowService.submit(submitDto).then(() => {
                        if (doMore) {
                            this.$emit('submit-do-more', this.selectedNextStatusId);
                        } else {
                            this.$emit('submit-step', this.selectedNextStatusId);
                        }

					})
				}
			},
		}
	}); 
