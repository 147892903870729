import { IOutcome, IOutcomeEvent } from "@/Models/IOutcomes";
import { BaseApiService } from "./base-api-service";
import { ITypedValidationResult } from "@/Models/IValidationResult";

class outcomeService extends BaseApiService {
    public constructor() {
        super('outcome/');
    }

    // getClosureOutcomesByCaseId will return only the closing outcome (or create it if it doesn't
    // exist yet) rather than including quarterly outcomes
    public async getClosureOutcomeByCaseId(caseId: number): Promise<IOutcome[]> {
        return this.httpClient.get<IOutcome[]>(`GetClosuresByCaseId/${caseId}`)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public async getClosureOutcomeByPhaseId(phaseId: number): Promise<IOutcome[]> {
        return this.httpClient.get<IOutcome[]>(`GetClosuresByPhaseId/${phaseId}`)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    // getOutcomesByCaseId will return all of the outcomes (both quarterly and closing currently)
    // associated with the caseId
    public async getOutcomesByCaseId(caseId: number): Promise<IOutcome[]> {
        return this.httpClient.get<IOutcome[]>(`GetByCaseId/${caseId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public async getOutcomesByPhaseId(phaseId: number): Promise<IOutcome[]> {
        return this.httpClient.get<IOutcome[]>(`GetByPhaseId/${phaseId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    // getByOutcomeId will return only the outcome with the associated outcomeId. If the outcomeId
    // is undefined, then this will return undefined
    public async getByOutcomeId(outcomeId: number | undefined): Promise<IOutcome | void> {
        if (outcomeId === undefined) {
            return new Promise((res) => { res(); });
        }

        return this.httpClient.get<IOutcome>(`GetOutcomeById/${outcomeId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public async save(dto: IOutcome): Promise<ITypedValidationResult<IOutcome>> {
        return this.httpClient.post<ITypedValidationResult<IOutcome>>("SaveClosureOutcome", dto).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public async delete(id: number): Promise<void> {
        return new Promise<void>((resolve) =>{ resolve(); });
        // currently no functionality is present to delete an outcome, but the method is sketched
        // for completeness. if the functionality is implemented to manually create and delete outcome
        // objects this is where it will be placed.
        // return this.httpClient.delete<void>(`delete/${id}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

}

class outcomeEventService extends BaseApiService {
    public constructor() {
        super('outcome/event/')
    }

    public async getOpenEventsByCaseId(caseId: number): Promise<IOutcomeEvent[]> {
        return this.httpClient.get<IOutcomeEvent[]>(`GetOpenByCaseId/${caseId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public async getOpenEventsByPhaseId(phaseId: number): Promise<IOutcomeEvent[]> {
        return this.httpClient.get<IOutcomeEvent[]>(`GetOpenByPhaseId/${phaseId}`)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public async getAllEventsByCaseId(caseId: number): Promise<IOutcomeEvent[]> {
        return this.httpClient.get<IOutcomeEvent[]>(`GetAllByCaseId/${caseId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public async getAllEventsByPhaseId(phaseId: number): Promise<IOutcomeEvent[]> {
        return this.httpClient.get<IOutcomeEvent[]>(`GetAllByPhaseId/${phaseId}`)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public async save(dto: IOutcomeEvent): Promise<ITypedValidationResult<IOutcomeEvent>> {
        return this.httpClient.post<ITypedValidationResult<IOutcomeEvent>>("SaveEvent", dto).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public async delete(eventId: number): Promise<ITypedValidationResult<IOutcomeEvent>> {
        return this.httpClient.delete<ITypedValidationResult<IOutcomeEvent>>(`DeleteEvent/${eventId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
}

export const OutcomeService: outcomeService = new outcomeService();
export const OutcomeEventService: outcomeEventService = new outcomeEventService();