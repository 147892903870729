import { ICaseNote } from '@/Models/ICaseNote';
import { ITypedValidationResult } from '@/Models/IValidationResult';
import { BaseApiService } from './base-api-service';

class caseNoteService extends BaseApiService{
    
    public constructor() {
		super('CaseNote/');
    }

	public async getByCaseId(caseId: number): Promise<ICaseNote[]> {
		return this.httpClient.get<ICaseNote[]>(`GetByCaseId/${caseId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
	public async save(note: ICaseNote): Promise<ITypedValidationResult<ICaseNote>> {
		return this.httpClient.post<ITypedValidationResult<ICaseNote>>("save", note).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
	public async delete(noteId: number): Promise<void> {
		return this.httpClient.delete<void>(`delete/${noteId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
}

export const CaseNoteService: caseNoteService = new caseNoteService();