import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ParticipantHeader = _resolveComponent("ParticipantHeader")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_SingleErrorMessage = _resolveComponent("SingleErrorMessage")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.headerData && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_ParticipantHeader, {
          key: 0,
          data: _ctx.headerData
        }, null, 8, ["data"]))
      : _createCommentVNode("", true),
    _createVNode(_component_PageHeader, { headerText: "Participant Workflows" }),
    (_ctx.isLoading && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_SingleErrorMessage, { errorMessage: _ctx.errorMessage }, null, 8, ["errorMessage"]),
    (!_ctx.isLoading && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_v_card, { key: 2 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_data_table, {
                  headers: _ctx.workflowHeaders,
                  items: _ctx.workflowList,
                  density: "compact",
                  class: "elevation-1"
                }, {
                  "item.startDate": _withCtx(({ item }) => [
                    _createTextVNode(_toDisplayString(_ctx.formatDateUtc(item.raw.startDate)), 1)
                  ]),
                  "item.endDate": _withCtx(({ item }) => [
                    _createTextVNode(_toDisplayString(_ctx.formatDateUtc(item.raw.endDate)), 1)
                  ]),
                  "item.createDate": _withCtx(({ item }) => [
                    _createTextVNode(_toDisplayString(_ctx.formatDate(item.raw.createDate)), 1)
                  ]),
                  "item.actions": _withCtx(({ item }) => [
                    _createVNode(_component_v_btn, {
                      onClick: ($event: any) => (_ctx.openWorkflow(item.raw)),
                      density: "comfortable",
                      size: "small",
                      icon: "mdi-magnify-plus-outline",
                      color: "info"
                    }, null, 8, ["onClick"])
                  ]),
                  _: 1
                }, 8, ["headers", "items"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}