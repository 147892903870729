import { Role } from '@/Enums/Role';

export interface IInternalUserRole {
    roleId: number;
    description: string;
}

export const mapUserRolesToEnums = (userRoles: IInternalUserRole[]): Role[] => {
    const roleMappings: Record<number, Role> = {
        1: Role.StateAdministrator,
        2: Role.StateUser,
        3: Role.Provider,
        4: Role.Judge,
        5: Role.Coordinator,
        6: Role.CaseManager,
		7: Role.ProbationSupervisor,
		8: Role.PeerSupportSpecialist,
    }

    // Extract the Role enum for each user role
    const mappedRoles = userRoles.map((userRole) => roleMappings[userRole.roleId]);

    return mappedRoles.filter(Boolean); // Remove any undefined values (unmapped roles)
}