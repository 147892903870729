
import { defineComponent } from 'vue';
import  IncorrectPermissions from '@/ChildrenComponents/IncorrectPermissions.vue'
import UserDrilldownDialog from '@/ChildrenComponents/UserDrilldownDialog.vue';
import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';

import type { IInternalUser } from '@/Models/IInternalUser';
import type { IInternalUserRole } from '@/Models/IInternalUserRole';
import type { IDataTableHeader} from "@/Models/IDataTableHeader";

import { useUserStore } from '@/Services/Store/user-store';
import { InternalUserService } from '@/Services/internal-user-service';
import { IUserSearch } from '@/Models/SearchParams/IUserSearch';
import { TreatmentProgramService } from '@/Services/treatment-program-service';
import { ITreatmentProgram } from '@/Models/ITreatmentProgram';
import ErrorHandlingHelper from '@/Services/Helper/error-handling-helper';
import { RoleService } from '@/Services/role-service';
import ReportExporter from '@/ChildrenComponents/ReportExporter.vue';

export default defineComponent({
    name: 'user-management',
    components: { 
        IncorrectPermissions, 
        UserDrilldownDialog, 
        ValidationAlert,
        ReportExporter,
    },

    setup(): {currentUser: IInternalUser | null } {
        const userStoreInstance = useUserStore();
        const currentUser = userStoreInstance.$state.cstInternalUser;
        return {
            currentUser        
        };
    },
    beforeCreate() {
    
    },
    created() {
        if (!this.currentUser || !this.currentUser.attachedRoles.some((e: IInternalUserRole) => e.roleId === 1)) {
            this.isPermissionDenied = true;
            return;
        }
        else {
            Promise.all([
                this.getActiveUsers(),
                TreatmentProgramService.getAllTreatmentPrograms(),
                RoleService.getAllRoles(),
            ])
            // eslint-disable-next-line no-unused-vars
            .then(([_, progs, roles]: [void, ITreatmentProgram[], IInternalUserRole[]]) => {
                this.treatmentPrograms = progs;
                this.availableRoles = roles
            })
            .catch(ErrorHandlingHelper.genericErrorHandler(
                (err => this.errorMessage = err)
            ))
            .finally(() => this.isLoading = false)

        }
    },
    data () {
        return {
            allUsers: [] as IInternalUser[],
            filteredUsers: [] as IInternalUser[],
            activeUsersHeaders: [
                { title: "First Name", key: "firstName", sortable: true, align: "start" },
                {title: "Last Name", key: "lastName", sortable: true, align: "start"},
                {title: "Username", key: "userName", sortable: true, align: "start"},
                {title:" Email", key: "email", sortable: true, align: "start"},
                {title: "Phone", key: "phone", sortable: true, align: "start"},
                {title: "Roles", key: "attachedRoles", sortable: true, align: "start", width: '200px'},
                {title: "Program", key: "attachedPrograms", sortable: true, align: "start", width: '400px'},
                {title: "Actions", key: "actions", sortable: false},
            ]  as IDataTableHeader[],
            validationResults: [] as string[],
            
            focusedUser: {} as IInternalUser,

            searchOptions: {} as IUserSearch,
            treatmentPrograms: [] as ITreatmentProgram[],
            availableRoles: [] as IInternalUserRole[],
            reportFooter: "" as string,

            errorMessage: "" as string,
            isPermissionDenied: false as boolean,
            isUserDialogActive: false as boolean,
            isFormValid: false as boolean,
            isLoading: true as boolean,

            tabs: 0 as number,
            searchUsers: "" as string,
        }
    },
    watch: {
        
    },
    methods: {
        getActiveUsers(): Promise<void> {
            return new Promise((resolve) => {
                InternalUserService.getAllActiveUsers().then((response) => {
                    this.allUsers = response;
                    this.filterUsers()
                    resolve();
                });
            });
        },
        drilldownUser(user: IInternalUser): void {
            this.isUserDialogActive = true;
            this.focusedUser = user;
        },
        filterUsers(): void {
            this.filteredUsers = this.allUsers;

            if (!this.searchOptions) {
                return;
            }

            const hasSimilarText = (itemText: string, filterText: string) => ((itemText.indexOf(filterText) != -1) || (filterText.indexOf(itemText) != -1))

            this.filteredUsers = this.filteredUsers.filter((item: IInternalUser) => {
                let shouldShowItem = true;

                if (this.searchOptions.firstName && this.searchOptions.firstName != "") {
                    shouldShowItem = shouldShowItem && hasSimilarText(item.firstName.toLowerCase(), this.searchOptions.firstName.toLowerCase());
                }

                if (this.searchOptions.lastName && this.searchOptions.lastName != "") {
                    shouldShowItem = shouldShowItem && hasSimilarText(item.lastName.toLowerCase(), this.searchOptions.lastName.toLowerCase());
                }

                if (this.searchOptions.email && this.searchOptions.email != "") {
                    shouldShowItem = shouldShowItem && hasSimilarText(item.email.toLowerCase(), this.searchOptions.email.toLowerCase());
                }

                if (this.searchOptions?.treatmentProgram) {
                    shouldShowItem = shouldShowItem && null != item.attachedPrograms.find(
                        (prog: ITreatmentProgram) => {
                            return hasSimilarText(prog.description, this.searchOptions!.treatmentProgram!);
                        }
                    );
                }

                if (this.searchOptions?.role) {
                    shouldShowItem = shouldShowItem && null != item.attachedRoles.find(
                        (role: IInternalUserRole) => {
                            return hasSimilarText(role.description, this.searchOptions!.role!);
                        }
                    )
                }

                return shouldShowItem;
            })
        },
        presentAttachedRoles(roles: IInternalUserRole[]): string {
            return this.presentList(roles);
        },
        presentAttachedPrograms(progs: ITreatmentProgram[]): string {
            return this.presentList(progs);
        },
        presentList(ll: {description: string}[]): string {
            if (!ll || ll.length === 0) {
                return "";
            }
            
            return ll.reduce((acc, curr) => {
                return `${curr.description}; ${acc}`
            }, "").slice(0, -2);
        },
        spliceInEditedUser(editedUser: IInternalUser): void {
            InternalUserService.updateInternalUser(editedUser).then((response) => {
                if (response.messages.length > 0) {
                    this.validationResults = response.messages;
                }
                else {
                    if (editedUser.isInactive) {
                        this.allUsers.splice(this.allUsers.findIndex((e: IInternalUser) => e.internalUserId === this.focusedUser.internalUserId), 1);
                    }
                    else {
                        this.allUsers.splice(this.allUsers.findIndex((e: IInternalUser) => e.internalUserId === this.focusedUser.internalUserId), 1, editedUser );
                    }
                }
            })
            .then(() => {
                this.filterUsers();
            })
            .catch(ErrorHandlingHelper.genericErrorHandler(
                (err => this.errorMessage = err)
            ));
        },
    },
    computed: {
        reportData() : any {
            return this.filteredUsers.map((u: IInternalUser) => {
                return {
                    ...u,
                    attachedPrograms: this.presentAttachedPrograms(u.attachedPrograms),
                    attachedRoles: this.presentAttachedRoles(u.attachedRoles),
                }
            })
        },
        reportJsonFields() : any {
            var json = {} as any;
            this.activeUsersHeaders.forEach(
                h => { json[h.title] = h.key; }
            );
            if (json.actions) {
                delete json.actions;
            }
            return json;
        },
        reportPdfFields() : {header: string, dataKey: string}[] {
            return this.activeUsersHeaders.map(h => 
                ({header: h.title, dataKey: h.key})
            );
        },
    }
});
