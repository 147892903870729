import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { useUserStore } from "../Store/user-store";

export const userStoreAuthenticationGuard = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
): void => {
    const store = useUserStore();
    store.getInternalUser().then(() => {
        if (store.$state.cstInternalUser?.attachedRoles && store.$state.cstInternalUser?.attachedRoles.length > 0) {
            return next();
        }
        else {
            return next('/access-request');
        }
    }).catch(() => {
        //For when an exception may be thrown for a user ID not yet existing in claims.
        return next('/access-request');
    }); 
};