import { ITypedValidationResult } from '@/Models/IValidationResult';
import { BaseApiService } from './base-api-service';
import { IParticipantEmergencyContactInformation } from '@/Models/IParticipantEmergencyContactInformation';

class emergencyContactService extends BaseApiService {
    public constructor() {
		super('emergencycontact/');
    }
    
    public async saveWithChildren(dto: IParticipantEmergencyContactInformation): Promise<ITypedValidationResult<IParticipantEmergencyContactInformation>> {
        return this.httpClient.post<ITypedValidationResult<IParticipantEmergencyContactInformation>>("SaveWithChildren", dto)
            .then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}

    public async getEmergencyContactsByCaseId(caseId: number): Promise<IParticipantEmergencyContactInformation[]> {
        return this.httpClient.get<IParticipantEmergencyContactInformation[]>(`GetEmergencyContactsByCaseId/${caseId}`)
            .then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
}

export const EmergencyContactService: emergencyContactService = new emergencyContactService();