import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_form, { ref: "workflowForm" }, {
      default: _withCtx(() => [
        (_ctx.statusList.length > 0)
          ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_select, {
                      items: _ctx.statusList,
                      "item-value": "workflowStatusId",
                      "item-title": "submitDescription",
                      label: "Select Next Applicable Status",
                      modelValue: _ctx.newStatus.newWorkflowStatusId,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newStatus.newWorkflowStatusId) = $event)),
                      required: "",
                      rules: _ctx.dropdownRules
                    }, null, 8, ["items", "modelValue", "rules"]),
                    (_ctx.showCompleteDate)
                      ? (_openBlock(), _createBlock(_component_v_text_field, {
                          key: 0,
                          type: "date",
                          label: _ctx.completeDateText,
                          modelValue: _ctx.newStatus.completeDateForEdit,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newStatus.completeDateForEdit) = $event)),
                          rules: _ctx.completeDateRules
                        }, null, 8, ["label", "modelValue", "rules"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_textarea, {
                      label: "Notes",
                      modelValue: _ctx.newStatus.note,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newStatus.note) = $event)),
                      required: "",
                      rules: _ctx.noteRules
                    }, null, 8, ["modelValue", "rules"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 512),
    (_ctx.statusList.length > 0)
      ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { align: "center" }, {
              default: _withCtx(() => [
                _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                  class: "mx-2 mb-4",
                  color: "success",
                  onClick: _ctx.submit,
                  disabled: _ctx.isSaving
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
                  ]),
                  _: 1
                }, 8, ["onClick", "disabled"])), [
                  [_directive_ripple]
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}