import { ITemplate } from "@/Models/Templating/ITemplate";
import { BaseApiService } from "../base-api-service";
import { ITypedValidationResult } from "@/Models/IValidationResult";
import { RenderableTemplate } from "@/Models/Templating/TemplatingEngine";

// TemplateService provides the API connections for the template DTOs.
// it's distinguished from the TemplateEngine by the fact that it primarily focusses
// on the synchronization and saving/deleting/fetching of template data rather than
// dealing with template objects and documents themselves
class templateService extends BaseApiService{
    
    public constructor() {
        super('template/');
    }

    // given a program id, it will return all of the templates associated with that program
    public async getByProgramId(programId: number): Promise<ITemplate[]> {
        return this.httpClient.get<ITemplate[]>(`ByProgramId/${programId}`)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    // given a list of program IDs, fetch all of the templates for all of the program ids.
    // this resulting list will need to be filtered based on treatmentProgramIds if they should
    // be separated by program
    public async getByProgramIds(programIdList: number[]): Promise<ITemplate[]> {
        const encodedPrograms = programIdList.map(pid => `ids=${pid}`).join("&");
        return this.httpClient.get<ITemplate[]>(`ByProgramIds?${encodedPrograms}`)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public async saveTemplate(templ: ITemplate): Promise<ITypedValidationResult<ITemplate>> {
        return this.httpClient.post<ITypedValidationResult<ITemplate>>(`Save`, templ)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public async deleteTemplate(templateId: number): Promise<void> {
        return this.httpClient.delete<void>(`Delete/${templateId}`)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public async registerNewTemplate(treatmentProgramId: number, typeId: number): Promise<ITemplate> {
        return this.httpClient.put<ITemplate>(`New/${treatmentProgramId}/${typeId}`)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    // invoke the generation of a pdf and use SSR to create it. this returns the blob object
    // to automatically save the contents of the file, you can make use of the generatePdfResponseHandler
    // callback declared in the TemplateEngine object
    public async generatePdf(template: RenderableTemplate): Promise<Blob> {
        return this.httpClient.post<Blob>(`ConvertToPdf`, template,  { responseType: 'blob' })
            .then(response => { return response.data })
            .catch(this.handleError.bind(this));
    }
}

export const TemplateService : templateService = new templateService();