
	import { defineComponent, PropType, Ref, ref } from 'vue';

	import { BehaviorResponseService } from '@/Services/behavior-response-service';
	import { DateHelper } from '@/Services/Helper/date-helper';
	import { ValidationHelper } from '@/Services/Helper/validation-helper';

	import { LookupEnum } from '@/Enums/LookupEnum';

	import { IBehaviorResponse, IBehaviorResponseForEdit } from '@/Models/IBehaviorResponse';
	import { IBehaviorType } from '@/Models/Lookup/IBehaviorType';
	import { IBehaviorResponseType } from '@/Models/Lookup/IBehaviorResponseType';
	import { BehaviorResponseLookupWrapper } from '@/Models/Lookup/BehaviorResponseLookupWrapper';

	import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';

	export default defineComponent({
		name: "behavior-response-dialog",
		components: { ValidationAlert },
		emits: ["close", "saved"],
		props: {
			lookups: {
				type: Object as PropType<BehaviorResponseLookupWrapper>,
				required: true
			},
			source: {
				type: Object as PropType<IBehaviorResponse>,
				required: true
			},
		},
		setup(): { form: Ref } {
			const form = ref(null);
			return { form };
		},
		created() {
			this.setDto();
		},
		data () {
			return {
				singleSelectRules: ValidationHelper.requiredRules,
				multiSelectRules: ValidationHelper.atLeastOneRules,
				dto: {} as IBehaviorResponseForEdit,
				selectedBehaviorTypeIds: [] as number[],
				selectedResponseTypeIds: [] as number[],
				errors: [] as string[],
				isSaving: false,
				typeFilter: "both",
			}
		},
		methods: {
			setDto(): void {
				this.dto = JSON.parse(JSON.stringify(this.source)) as IBehaviorResponseForEdit;
				if (this.dto.behaviorResponseId != undefined) {
					this.selectedBehaviorTypeIds = this.dto.behaviors.map(x => x.behaviorTypeId);
					this.selectedResponseTypeIds = this.dto.responses.map(x => x.behaviorResponseTypeId);
					this.dto.startDateForEdit = DateHelper.formatDateUtc(this.dto.startDate, "yyyy-MM-dd");
					this.dto.endDateForEdit = DateHelper.formatDateUtc(this.dto.endDate, "yyyy-MM-dd");
				}
				if (this.form != null) {
					//adding a brief delay to make sure the changes to startDateForEdit have fully registered before resetting validation
					setTimeout(() => { this.form.resetValidation(); }, 100);
				}
			},
			prepForSave(): void {
				if (!this.selectedBehaviorTypeIds.includes(LookupEnum.Other)) {
					this.dto.behaviorTypeOther = "";
				}
				if (!this.selectedResponseTypeIds.includes(LookupEnum.Other)) {
					this.dto.behaviorResponseTypeOther = "";
				}

				this.dto.behaviors = this.lookups.behaviorTypes.filter(x => this.selectedBehaviorTypeIds.includes(x.id));
				const behaviorIndex = this.dto.behaviors.findIndex(x => x.behaviorTypeId == LookupEnum.Other);
				if (behaviorIndex > -1) {
					this.dto.behaviors[behaviorIndex].description = this.dto.behaviorTypeOther;
				}
				this.dto.responses = this.lookups.responseTypes.filter(x => this.selectedResponseTypeIds.includes(x.id));
				const responseIndex = this.dto.responses.findIndex(x => x.behaviorResponseTypeId == LookupEnum.Other);
				if (responseIndex > -1) {
					this.dto.responses[responseIndex].description = this.dto.behaviorResponseTypeOther;
				}

				this.dto.startDate = DateHelper.standardizeDate(this.dto.startDateForEdit);
				this.dto.endDate = DateHelper.standardizeDate(this.dto.endDateForEdit);
                if (!this.dto.endDate) this.dto.endDate = this.dto.startDate;
				this.dto.statusName = this.lookups.statuses.find(x => x.id == this.dto.behaviorResponseStatusId)?.description ?? "";
			},
			closeDialog(): void {
				this.$emit("close");
			},
			async save(startNew: boolean) {
                if (!this.dto.endDateForEdit) this.dto.endDateForEdit = this.dto.startDateForEdit;
				const { valid } = await this.form.validate();
				if (valid) {
					this.prepForSave();
					this.isSaving = true;
					BehaviorResponseService.save(this.dto)
						.then(result => {
							this.errors = result.messages;
							if (result.isValid && result.resultObject != null) {
								this.$emit("saved", result.resultObject, startNew);
							}
						})
						.catch(error => this.errors.push(error))
						.finally(() => this.isSaving = false);
				}
			},
		},
		computed: {
			buttonText1(): string {
				return this.isSaving ? "Saving..." : "Save and Close";
			},
			buttonText2(): string {
				return this.isSaving ? "Saving..." : "Save and Start New";
			},
			showOtherType(): boolean {
				return this.selectedBehaviorTypeIds.includes(LookupEnum.Other);
			},
			showOtherResponseType(): boolean {
				return this.selectedResponseTypeIds.includes(LookupEnum.Other);
			},
			behaviorTypeList(): IBehaviorType[] {
				if (this.typeFilter == "positive") {
					return this.lookups.behaviorTypes.filter(x => x.isPositive || x.isPositive === null || this.selectedBehaviorTypeIds.includes(x.id));
				}
				if (this.typeFilter == "negative") {
					return this.lookups.behaviorTypes.filter(x => !x.isPositive || x.isPositive === null || this.selectedBehaviorTypeIds.includes(x.id));
				}
				return this.lookups.behaviorTypes;
			},
			responseTypeList(): IBehaviorResponseType[] {
				if (this.typeFilter == "positive") {
					return this.lookups.responseTypes.filter(x => x.showForPositive || this.selectedResponseTypeIds.includes(x.id));
				}
				if (this.typeFilter == "negative") {
					return this.lookups.responseTypes.filter(x => x.showForNegative || this.selectedResponseTypeIds.includes(x.id));
				}
				return this.lookups.responseTypes;
			},
			endDateRules() {
				return [
					(v : string) => {
						if (this.dto) {
							const startDate = new Date(this.dto.startDateForEdit);
							const endDate = new Date(v);
							return !startDate || endDate >= startDate || 'End Date cannot be before Start Date';
						}
						return true; // Return true if this.dto is null
					}
				];
			},
		},
		watch: {
			source() {
				this.setDto();
			},
			// 'dto.startDateForEdit': {
			// 	handler(value) {
			// 		if (this.dto && !this.dto?.endDateForEdit) {
			// 			this.dto.endDateForEdit = value;
			// 		}
			// 	},
			// 	deep: true
			// },
		},
	});
