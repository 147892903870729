export enum Substances {
    SYNTHETIC_CANNABINOID = 18862,
    SYNTHETIC_CANNABIS = 18863,
    SYNTHETIC_NARCOTIC = 18866,
    Other_Not_Listed = 999999
}

export const OtherSubstanceList = [
    Substances.SYNTHETIC_CANNABINOID,
    Substances.SYNTHETIC_CANNABIS,
    Substances.SYNTHETIC_NARCOTIC,
    Substances.Other_Not_Listed
]