
    import { 
        defineComponent, 
        PropType,
    } from 'vue';

    import { useUserStore } from '@/Services/Store/user-store';
    import { TemplateService } from '@/Services/Templating/template-service';

    import { IInternalUser } from '@/Models/IInternalUser';
    import { IInternalUserRole } from '@/Models/IInternalUserRole';
    import { MasterLookupWrapper } from '@/Models/Lookup/MasterLookupWrapper';
    import { ITreatmentProgram } from '@/Models/ITreatmentProgram';
    import { ITemplate } from '@/Models/Templating/ITemplate';

    import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';
    import SingleErrorMessage from '@/ChildrenComponents/Participant/Common/SingleErrorMessage.vue';
    import TemplateEditor from './Common/TemplateEditor.vue';
    import SuccessAlert from '../SuccessAlert.vue';
    import LoadingSpinner from '../LoadingSpinner.vue';


    export default defineComponent({
        name: "bulk-editor",
        emits: [
            "templateSaved",    // emitted when the template is successfully saved
            "templateCreated",  // emitted when a new template is registered successfully
            "templateDeleted",  // emitted when a template object is successfully deleted
        ],
        components: {
            ValidationAlert,
            SingleErrorMessage,
            TemplateEditor,
            SuccessAlert,
            LoadingSpinner,
        },
        props: {
            // the list of all templates which can be edited. this prop is watched
            availableTemplates: {
                type: Array as PropType<ITemplate[]>,
                required: true,
            },
            // activeProgram is the treatment program associated with the templates
            activeProgram: {
                type: Object as PropType<ITreatmentProgram | null>,
                required: true,
            },
            // the templateTypeId is used to register new templates created by the instance
            templateTypeId: {
                type: Number,
                required: true,
            },
        },
        setup(): { currentUser: IInternalUser | null } {
            const userStoreInstance = useUserStore();
            const currentUser = userStoreInstance.$state.cstInternalUser;
            return {
                currentUser
            };
        },
        created() {
            if (!this.currentUser || !this.currentUser.attachedRoles.some((e: IInternalUserRole) => e.roleId === 1)) {
                this.isPermissionDenied = true;
                return;
            } else {
                this.loadData();
            }
        },
        data() {
            return {
                // status
                validationResults: [] as string[],
                errorMessage: "" as string,
                successMessages: [] as string[],

                // state
                openPanels: [] as number[],
                lookups: {} as MasterLookupWrapper,

                // dto
                templates: [] as ITemplate[],
                selectedTemplate: null as ITemplate | null,

                // perms
                isPermissionDenied: false as boolean,
                isLoading: true as boolean,
                isSaving: false as boolean,
                isDeleting: false as boolean,
            }
        },
        computed: {
            templateBody(): string {
                return this.selectedTemplate?.contents || "";
            },
            templateLabel(): string {
                return this.selectedTemplate?.label || "";
            }
        },
        methods: {
            loadData() {
                this.isLoading = true;
                this.templates = this.availableTemplates;
                this.isLoading = false;
            },
            newTemplate() {
                this.isLoading = true
                TemplateService.registerNewTemplate(this.activeProgram!.treatmentProgramId, this.templateTypeId)
                    .then((registered: ITemplate) => {
                        this.selectedTemplate = registered;
                        this.templates.push(registered);
                    })
                    .then(() => {
                        this.$emit("templateCreated");
                    })
                    .catch(async (err) => {
                        const errMessage = await err.response.data.text();
                        this.errorMessage = (errMessage && errMessage != "") ? errMessage : err.toString();
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
            onChange(updatedTemplate: ITemplate) {
                this.selectedTemplate = updatedTemplate;
            },
            // eslint-disable-next-line no-unused-vars
            submit(_event: any) {
                this.isSaving = true;

                if (this.selectedTemplate == null) {
                    this.errorMessage = "Please select a template before saving.";
                    return;
                }

                TemplateService.saveTemplate(this.selectedTemplate)
                    .then((res) => {
                        if (res.isValid) {
                            // Array.protoype.push doesn't trigger a reactive re-rendering for the success component
                            this.successMessages = ["Saved Template."]
                        } else if (res.messages.length != 0) {
                            this.validationResults = res.messages;
                        } else {
                            throw new Error("An unkown error occured. Please try again or contact an administrator if the error persists.");
                        }
                    })
                    .then(() => {
                        this.$emit("templateSaved", this.selectedTemplate);
                    })
                    .catch(async (err) => {
                        const errMessage = await err.response.data.text();
                        this.errorMessage = (errMessage && errMessage != "") ? errMessage : err.toString();
                    })
                    .finally(() => {
                        this.isSaving = false;
                    });
            },
            // eslint-disable-next-line no-unused-vars
            deleteTemplate(_event: any) {
                if (confirm("Are you sure you want to delete this template? It cannot be recovered.")) {
                    this.isDeleting = true;
                    if (this.selectedTemplate == null) {
                        this.errorMessage = "You must select a template to delete first.";
                        return;
                    }
                    
                    const deletedId = this.selectedTemplate.templateId;
                    TemplateService.deleteTemplate(deletedId)
                        .then(() => {
                            this.templates = this.templates.filter(t => t.templateId !== deletedId);
                            this.selectedTemplate = null;
                            this.isDeleting = false;
                        })
                        .then(() => {
                            this.$emit("templateDeleted", deletedId);
                        })
                        .catch(async (err) => {
                            const errMessage = await err.response.data.text();
                            this.errorMessage = (errMessage && errMessage != "") ? errMessage : err.toString();
                        })
                        .finally(() => {
                            this.loadData();
                        });
                }
            }
        },
        watch: {
            // eslint-disable-next-line no-unused-vars
            availableTemplates(newTemplates, _oldTemplates) {
                // listents for new templates to be provided by an outer component
                this.templates = newTemplates;
            }
        }
    });
