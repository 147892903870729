
	import { defineComponent } from 'vue';

	import LoadingSpinner from '@/ChildrenComponents/LoadingSpinner.vue';
	import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';
	import AddAddressDialog from '@/ChildrenComponents/Participant/AddAddressDialog.vue';
	import AddEmailDialog from '@/ChildrenComponents/Participant/AddEmailDialog.vue';
	import AddPhoneDialog from '@/ChildrenComponents/Participant/AddPhoneDialog.vue';
	import PageHeader from '@/ChildrenComponents/Participant/Common/PageHeader.vue';
	import ParticipantHeader from '@/ChildrenComponents/Participant/Common/ParticipantHeader.vue';
	import SingleErrorMessage from '@/ChildrenComponents/Participant/Common/SingleErrorMessage.vue';

	import { ComponentType } from '@/Enums/ComponentType'
	import { IInternalUser } from '@/Models/IInternalUser';
	import { IDataTableHeader } from '@/Models/IDataTableHeader';
	import { Address, IAddress } from '@/Models/IAddress';
	import { IEmail } from '@/Models/IEmail';
	import { IPhone } from '@/Models/IPhone';
	import HeaderData from '@/Models/HeaderData';

	import { doesUserRoleHaveComponentEditPermissions } from '@/Services/Helper/component-permissions-helper'
	import { AddressService } from '@/Services/address-service';
	import { EmailService } from '@/Services/email-service';
	import { PhoneService } from '@/Services/phone-service';
	import FormatHelper from '@/Services/Helper/format-helper';
	import { loadParticipantDataRequireCase } from '@/Services/Helper/loader-helper';

	import { useUserStore } from '@/Services/Store/user-store';
    import { LookupService } from '@/Services/lookup-service';
    import { LookupKeys } from '@/Enums/LookupKeys';
    import { IContactType } from '@/Models/Lookup/IContactType';
    import { EmergencyContactService } from '@/Services/emergency-contact-service';
    import { IParticipantEmergencyContactInformation } from '@/Models/IParticipantEmergencyContactInformation';

	export default defineComponent({
		name: "contact-info",
		components: { AddAddressDialog, LoadingSpinner, AddEmailDialog, AddPhoneDialog, ParticipantHeader, ValidationAlert, PageHeader, SingleErrorMessage },
		setup(): { currentUser: IInternalUser | null } {
			const userStoreInstance = useUserStore();
			return { currentUser: userStoreInstance.$state.cstInternalUser };
		},
		created() {
			loadParticipantDataRequireCase(this.pageType, this.id).then((data) => {
				this.caseId = data.case?.caseId ?? null;
				this.headerData = new HeaderData(data.participant, data.case?.program ?? null);

				this.setBlankAddress();
				this.setBlankEmail();
				this.setBlankPhone();
			})
				.then(() => {
					if (this.caseId != null) {
						Promise.all([
							AddressService.getByCaseId(this.caseId),
							EmailService.getByCaseId(this.caseId),
							PhoneService.getByCaseId(this.caseId),
                            EmergencyContactService.getEmergencyContactsByCaseId(this.caseId),
                            LookupService.getLookupByKey(LookupKeys.ContactType)
						]).then(([addressList, emailList, phoneList, emerContactResults, contactTypeResult]) => {
							this.addresses = addressList;
							this.emails = emailList;
							this.phones = phoneList;
                            this.emergencyContacts = emerContactResults;
                            this.contactTypes = contactTypeResult as IContactType[];
						}).then(() => this.isLoading = false);
					} else {
                        this.errorMessage = "Cannot load participant's case."
                    }
				})
				.catch(error => this.errorMessage = error);
		},
		data() {
			return {
				isLoading: true,
				errorMessage: "",
				validationMessages: [] as string[],
				pageType: this.$route.params.type?.toString() ?? "",
				id: this.$route.params.id == undefined ? null : parseInt(this.$route.params.id.toString()),
				caseId: null as number | null,
				headerData: null as HeaderData | null,

				addressHeaders: [
                    { title: "Type", key: "contactType", sortable: true, align: "start" },
					{ title: "Active?", key: "isActive", sortable: true, align: "start" },
					{ title: "Address", key: "address", sortable: true, align: "start", minWidth: "50%" },
					{ title: "Edit", key: "actions", sortable: false },
				] as IDataTableHeader[],
				emailHeaders: [
                    { title: "Type", key: "contactType", sortable: true, align: "start" },
					{ title: "Active?", key: "isActive", sortable: true, align: "start" },
					{ title: "Email Address", key: "email", sortable: true, align: "start" },
					{ title: "Edit", key: "actions", sortable: false },
				] as IDataTableHeader[],
				phoneHeaders: [
                    { title: "Type", key: "contactType", sortable: true, align: "start" },
					{ title: "Active?", key: "isActive", sortable: true, align: "start" },
					{ title: "Phone Number", key: "phoneNumber", sortable: true, align: "start" },
					{ title: "Edit", key: "actions", sortable: false },
				] as IDataTableHeader[],

				addresses: [] as IAddress[],
				emails: [] as IEmail[],
				phones: [] as IPhone[],
                emergencyContacts: [] as IParticipantEmergencyContactInformation[],

				selectedAddress: null as Address | null,
				selectedEmail: null as IEmail | null,
				selectedPhone: null as IPhone | null,
				selectedEmailActive: false,
				selectedPhoneActive: false,

				addressHeader: "",
				emailHeader: "",
				phoneHeader: "",

				showAddressDialog: false,
				showEmailDialog: false,
				showPhoneDialog: false,
                emergencyEditIndex: null as number | null,
                isEmergencyContactSaving: false,
                contactTypes: [] as IContactType[],
			}
		},
		methods: {
            loadEmergencyContacts() {
                if (this.caseId) {
                    EmergencyContactService.getEmergencyContactsByCaseId(this.caseId).then(result => {
                        this.emergencyContacts = result;
                    })
                }
            },
			setBlankAddress() {
				const blankAddress = this.createNewAddressObj();
				this.selectedAddress = new Address(blankAddress);
			},
            createNewAddressObj() {
                return {
					addressId: 0,
					caseId: this.caseId,
					isActive: true,
					countryId: 1, //USA
				} as IAddress;
            },
			newAddress() {
				this.setBlankAddress();
				this.addressHeader = "Add New Address";
				this.showAddressDialog = true;
			},
			editAddress(address: IAddress, editIndex: number | null = null) {
				this.selectedAddress = new Address(address);
				this.addressHeader = "Edit Address";
				this.showAddressDialog = true;
                this.emergencyEditIndex = editIndex;
			},
			cancelAddressEdit() {
				this.setBlankAddress();
				this.showAddressDialog = false;
                this.emergencyEditIndex = null;
			},
			saveAddress(address: Address) {
                if (this.emergencyEditIndex !== null) {
                    this.emergencyContacts[this.emergencyEditIndex].associatedAddress = address;
                    this.saveEmergencyContact(this.emergencyEditIndex);
                } else {
                    AddressService.save(address).then(result => {
                        this.validationMessages = result.messages;
                        if (result.isValid && this.caseId != null) {
                            AddressService.getByCaseId(this.caseId).then(results => this.addresses = results);
                            this.loadEmergencyContacts();
                        }
                    }).catch(error => this.errorMessage = error).finally(() => this.showAddressDialog = false);
                }
			},
			setBlankEmail() {
				this.selectedEmail = this.createNewEmailObj();
			},
            createNewEmailObj() {
                return {
					emailId: 0,
					caseId: this.caseId,
					email: "",
					isActive: true,
				} as IEmail;
            },
			newEmail() {
				this.setBlankEmail();
				this.emailHeader = "Add Email";
				this.showEmailDialog = true;
			},
			editEmail(email: IEmail, editIndex: number | null = null) {
				this.selectedEmail = email;
				this.emailHeader = "Edit Email";
				this.showEmailDialog = true;
                this.emergencyEditIndex = editIndex;
			},
			cancelEmailEdit() {
				this.setBlankEmail();
				this.showEmailDialog = false;
                this.emergencyEditIndex = null;
			},
			saveEmail(email: IEmail) {
				if (this.selectedEmail != null) {
					this.selectedEmail = email;
                    if (this.emergencyEditIndex !== null) {
                        this.emergencyContacts[this.emergencyEditIndex].associatedEmail = email;
                        this.saveEmergencyContact(this.emergencyEditIndex);
                    } else {
                        EmailService.save(this.selectedEmail).then(result => {
                            this.validationMessages = result.messages;
                            if (result.isValid && this.caseId != null) {
                                EmailService.getByCaseId(this.caseId).then(results => this.emails = results);
                            }
                        }).catch(error => this.errorMessage = error).finally(() => this.showEmailDialog = false);
                    }
				}
			},
			setBlankPhone() {
				this.selectedPhone = this.createNewPhoneObj();
			},
            createNewPhoneObj() {
                return {
					phoneId: 0,
					caseId: this.caseId,
					phoneNumber: "",
					isActive: true,
				} as IPhone;
            },
			newPhone() {
				this.setBlankPhone();
				this.phoneHeader = "Add Phone";
				this.showPhoneDialog = true;
			},
			editPhone(phone: IPhone, editIndex: number | null = null) {
				this.selectedPhone = phone;
				this.phoneHeader = "Edit Phone";
				this.showPhoneDialog = true;
                this.emergencyEditIndex = editIndex;
			},
			cancelPhoneEdit() {
				this.setBlankPhone();
				this.showPhoneDialog = false;
                this.emergencyEditIndex = null;
			},
			savePhone(phone: IPhone) {
				if (this.selectedPhone != null) {
                    this.selectedPhone = phone;
					this.selectedPhone.phoneNumber = this.selectedPhone.phoneNumber.replace(/\D+/g, "");
                    if (this.emergencyEditIndex !== null) {
                        this.emergencyContacts[this.emergencyEditIndex].associatedPhone = phone;
                        this.saveEmergencyContact(this.emergencyEditIndex);
                    } else {
                        PhoneService.save(this.selectedPhone).then(result => {
                            this.validationMessages = result.messages;
                            if (result.isValid && this.caseId != null) {
                                PhoneService.getByCaseId(this.caseId).then(results => this.phones = results);
                            }
                        }).catch(error => this.errorMessage = error).finally(() => this.showPhoneDialog = false);
                    }
					
				}
			},
			formatPhone(phone: string): string {
				return FormatHelper.formatPhone(phone);
			},
			getActiveText(isActive: boolean): string {
				return isActive ? "Yes" : "No";
			},
            canAddAddress(index: number): boolean {
				const item = this.emergencyContacts[index].associatedAddress;
				return this.canEditRolePermission && item?.id == undefined;
			},
            canAddEmail(index: number): boolean {
				const item = this.emergencyContacts[index].associatedEmail;
				return this.canEditRolePermission && item?.id == undefined;
			},
			canAddPhone(index: number): boolean {
				const item = this.emergencyContacts[index].associatedPhone;
				return this.canEditRolePermission && item?.id == undefined;
			},
            removeEmergencyContactObject(index: number) {
				this.emergencyContacts.splice(index, 1);
			},
            addEmergencyContactObject() {
				this.emergencyContacts.push(
                    { 
                        caseId: this.caseId,
                        associatedAddress: this.createNewAddressObj(),
                        associatedEmail: this.createNewEmailObj(),
                        associatedPhone: this.createNewPhoneObj()
                    } as IParticipantEmergencyContactInformation
                );
			},
            saveEmergencyContact(index: number) {
                this.isEmergencyContactSaving = true;
                let contact = this.emergencyContacts[index];
                EmergencyContactService.saveWithChildren(contact).then(result => {
                    this.validationMessages = result.messages;
                    if (result.isValid && this.caseId != null) {
                        this.loadEmergencyContacts();
                    }
                }).catch(error => this.errorMessage = error).finally(() => {
                    this.showPhoneDialog = this.showAddressDialog = this.showEmailDialog = false;
                    this.emergencyEditIndex = null;
                    this.isEmergencyContactSaving = false;
                });
            }
		},
		computed: {
			componentType(): ComponentType {
				return ComponentType.ContactInfo
			},
			canEditRolePermission() : boolean {
				return doesUserRoleHaveComponentEditPermissions(this.currentUser?.attachedRoles ?? [], this.componentType)
			},
            isEmergencyEdit(): boolean {
                return this.emergencyEditIndex !== null;
            }
		},
	});
