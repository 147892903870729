
	import { defineComponent } from 'vue';

	import LoadingSpinner from '@/ChildrenComponents/LoadingSpinner.vue';
	import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';
	import ProgramSelectDialog from '@/ChildrenComponents/Participant/ProgramSelectDialog.vue';
	import PageHeader from '@/ChildrenComponents/Participant/Common/PageHeader.vue';
	import ParticipantHeader from '@/ChildrenComponents/Participant/Common/ParticipantHeader.vue';
	import SingleErrorMessage from '@/ChildrenComponents/Participant/Common/SingleErrorMessage.vue';

	import { MenuItemTypes } from "@/Enums/MenuItemTypes";

	import HeaderData from '@/Models/HeaderData';
	import { ICase } from '@/Models/ICase';
	import { IInternalUser } from '@/Models/IInternalUser';
	import { IDataTableHeader } from '@/Models/IDataTableHeader';

	import { CaseService } from '@/Services/case-service';
	import { DateHelper } from '@/Services/Helper/date-helper';
	import { loadParticipantDataRequireParticipant } from '@/Services/Helper/loader-helper';
	import { useUserStore } from '@/Services/Store/user-store';

	export default defineComponent({
		name: "case-list",
		components: { ParticipantHeader, LoadingSpinner, ProgramSelectDialog, ValidationAlert, PageHeader, SingleErrorMessage },
		setup(): { currentUser: IInternalUser | null } {
			const userStoreInstance = useUserStore();
			const currentUser = userStoreInstance.$state.cstInternalUser;
			return { currentUser };
		},
		created() {
			loadParticipantDataRequireParticipant(this.pageType, this.id).then(data => {
				this.participantId = data.participant?.participantId ?? null;
				this.headerData = new HeaderData(data.participant, data.case?.program ?? null);
			})
				.then(() => {
					if (this.participantId != null) {
						CaseService.getCaseHistoryByParticipant(this.participantId).then((result => {
							this.caseList = result;
							this.allowNewCase = this.caseList.find(x => x.isActive) == undefined && (this.currentUser?.attachedPrograms ?? []).length > 0;
						})).then(() => this.isLoading = false);
					}
				})
				.catch(error => this.errorMessage = error);
		},
		data() {
			return {
				isLoading: true,
				errorMessage: "",
				validationMessages: [] as string[],
				pageType: this.$route.params.type?.toString() ?? MenuItemTypes.Participant,
				id: this.$route.params.id == undefined ? null : parseInt(this.$route.params.id.toString()),
				participantId: null as number | null,
				headerData: null as HeaderData | null,

				caseList: [] as ICase[],
				caseHeaders: [
					{ title: "Program Case Number", key: "caseId", sortable: true, align: "start" },
					{ title: "Program", key: "program.description", sortable: true, align: "start" },
					{ title: "Court", key: "court.description", sortable: true, align: "start" },
					{ title: "Program Start Date", key: "createDate", sortable: true, align: "start" },
					{ title: "Active?", key: "isActiveText", sortable: true, align: "start" },
					{ title: "Actions", key: "actions", sortable: false },
				] as IDataTableHeader[],
				allowNewCase: false,
				showProgramSelectDialog: false,
			}
		},
		methods: {
			openCase(caseId: number): void {
				this.$router.push(`/case/${caseId}`);
			},
			formatDate(date: Date): string {
				return DateHelper.formatDateLocal(date, "MM/dd/yyyy");
			},
			getActiveText(isActive: boolean): string {
				return isActive ? "Yes" : "No";
			},
			newCase() {
				this.showProgramSelectDialog = true;
			},
			newCaseCancel() {
				this.showProgramSelectDialog = false;
			},
			newCaseCreate(programId: number) {
				if (this.participantId != null) {
					CaseService.createCase(this.participantId, programId).then(result => {
						this.validationMessages = result.messages;
						if (result.isValid && result.resultObject) {
							this.openCase(result.resultObject.caseId);
						}
					}).catch(error => this.errorMessage = error).finally(() => this.showProgramSelectDialog = false);
				}
			},
		},
	});
