
    import { ChartableCollection } from '@/Models/Charts/ChartableCollection';
import IChartRenderOptions from '@/Models/Charts/IChartRenderOptions';
import { VisualizerOptions } from '@/Services/Report/visualization-service';
import { defineComponent, PropType } from 'vue';
import VueApexCharts from 'vue3-apexcharts';

    export default defineComponent({
        name: "stacked-percentage-chart",
        components: {
            ApexCharts: VueApexCharts,
        },
        props: {
            data: {
                type: Object as PropType<ChartableCollection>,
                required: true,
            },
            chartOptions: {
                type: Object as PropType<IChartRenderOptions>,
                required: false,
                default: VisualizerOptions.getHundredStackedBarOptions(),
            }
        },
    })
