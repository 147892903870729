import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_alert = _resolveComponent("v-alert")!

  return (_ctx.validationResults.length > 0)
    ? (_openBlock(), _createBlock(_component_v_alert, {
        key: 0,
        prominent: "",
        closable: "",
        "close-label": "Close Alert",
        type: "error",
        title: "Validation Failed",
        icon: "mdi-cloud-alert"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_divider),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.validationResults, (error, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              _createElementVNode("ul", null, [
                _createElementVNode("li", null, _toDisplayString(error), 1)
              ])
            ]))
          }), 128))
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}