import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!

  return (_openBlock(), _createBlock(_Transition, null, {
    default: _withCtx(() => [
      (_ctx.successMessages.length > 0)
        ? (_openBlock(), _createBlock(_component_v_alert, {
            key: 0,
            type: "success",
            title: "Action Succeeded",
            icon: "mdi-check-circle"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.successMessages, (msg, idx) => {
                return (_openBlock(), _createElementBlock("div", { key: idx }, [
                  _createElementVNode("ul", null, [
                    _createElementVNode("li", null, _toDisplayString(msg), 1)
                  ])
                ]))
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}