
	import { defineComponent, PropType } from 'vue';
	import VueApexCharts from 'vue3-apexcharts';

	import { ChartableCollection } from '@/Models/Charts/ChartableCollection';
	import IChartRenderOptions from '@/Models/Charts/IChartRenderOptions';
	
	import { VisualizerOptions } from '@/Services/Report/visualization-service';

	export default defineComponent({
		name: 'base-chart',
		components: {
			apexcharts: VueApexCharts,
		},
		props: {
			chartData: {
				type: Object as PropType<ChartableCollection>,
				required: true,
			},
			// complete list of options are here: https://apexcharts.com/docs/options/
			// also, a set of reusable defaults are exported in the visualization service
			chartOptions: {
				type: Object as PropType<IChartRenderOptions>,
				required: false,
				default: VisualizerOptions.getGenericOptions(),
			}
		},
	});
