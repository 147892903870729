
import { defineComponent, Ref, ref } from 'vue';

import { ValidationHelper } from '@/Services/Helper/validation-helper';

export default defineComponent({
	name: "add-participant-note-dialog",
	emits: ["close", "save"],
	props: {
		sourceText: String,
		headerText: String,
	},
	setup(): { noteForm: Ref } {
		const noteForm = ref(null);
		return { noteForm };
	},
	created() {
		this.text = this.sourceText != undefined ? this.sourceText : "";
	},
	data () {
		return {
			requiredFieldRules: ValidationHelper.requiredRules,
			text: "",
			buttonText: "Save Note",
			isSaving: false,
		}
	},
	methods: {
		closeDialog(): void {
			this.$emit("close");
		},
		async saveNote() {
			const { valid } = await this.noteForm.validate();
			if (valid) {
				this.isSaving = true;
				this.buttonText = "Saving..."
				this.$emit("save", this.text);
			}
		},
	},
	watch: {
		sourceText(newText: string) {
			this.text = newText;
			this.isSaving = false;
			this.buttonText = "Save Note";
		},
	},
});
