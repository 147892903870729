import { IEmail } from '@/Models/IEmail';
import { ITypedValidationResult } from '@/Models/IValidationResult';
import { BaseApiService } from './base-api-service';

class emailService extends BaseApiService{
    
    public constructor() {
		super('email/');
    }

	public async getByCaseId(caseId: number): Promise<IEmail[]> {
		return this.httpClient.get<IEmail[]>(`GetByCaseId/${caseId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
	public async save(email: IEmail): Promise<ITypedValidationResult<IEmail>> {
		return this.httpClient.post<ITypedValidationResult<IEmail>>("save", email).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}

	public async delete(emailId: number): Promise<void> {
		return this.httpClient.delete<void>(`delete/${emailId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}
}

export const EmailService: emailService = new emailService();