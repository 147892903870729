import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Editor = _resolveComponent("Editor")!

  return (_openBlock(), _createBlock(_component_Editor, {
    "api-key": _ctx.apiKey,
    init: _ctx.options,
    modelValue: _ctx.text,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.text) = $event)),
    disabled: _ctx.disabled,
    onChange: _ctx.onChange
  }, null, 8, ["api-key", "init", "modelValue", "disabled", "onChange"]))
}