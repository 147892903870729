<template>
    <v-menu top close-on-content-click>
        <template v-slot:activator="{ props }">
            <v-btn class="white--text my-2" color="primary" depressed v-bind="props">export<v-icon right>mdi-export</v-icon></v-btn>
        </template>

        <v-list v-model="selectedExportType" color="primary">
            <v-list-item>
                <JsonExcel
                    :data="items"
                    :fields="json_fields"
                    :name="filenameExcel"
                    :header="header"
                    :footer="footer"
                >
                    <v-list-item-title>Excel</v-list-item-title>
                </JsonExcel>
            </v-list-item>
            <v-list-item @click="exportPdf">
                <v-list-item-title>PDF</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
    import JsonExcel from "vue-json-excel3"
    import jsPDF from 'jspdf'
    import autoTable from 'jspdf-autotable'

    export default {
        components: {
            JsonExcel
        },
        props: {
            items: Array,
            header: String,
            footer: String,
            filename: String,
            json_fields: Object,
            pdf_fields: Array
        },
        data: () => ({
            reportRan: '',
            exportTypeList: ['Excel', 'PDF'],
            selectedExportType: null,
            loading: false,
        }),
        computed: {
            filenameExcel() { return this.filename + '.xls' }
        },
        created() {
        },
        methods: {
            exportPdf() {
                const doc = new jsPDF({
                    orientation: "landscape"
                });
                doc.text(this.header,10,15);

                autoTable(doc, {
                    startY: 25,
                    columns: this.pdf_fields,
                    // body: [
                    //     ['David', 'david@example.com', 'Sweden'],
                    //     ['Castille', 'castille@example.com', 'Spain'],
                    // ],
                    body: this.items
                });
                doc.text(this.footer,1,1);
                doc.save(`${this.filename}.pdf`)
            }
        },
    }
</script>