export enum LookupKeys {
	ADAStatus = 0,
	AdjudicationType = 1,
	AnnualIncome = 2,
	ArrestHistory = 3,
	AssessmentType = 4,
	CaseClosureReason = 5,
	CaseManagementStatus = 6,
	CaseManagementType = 7,
	ChargeType = 8,
	ChildCustody = 9,
	ContactType = 10,
	Country = 11,
	County = 12,
	Court = 13,
	CourtAttendanceStatus = 14,
	CourtAttendanceType = 15,
	Diagnosis = 16,
	DiagnosisType = 17,
	DocumentType = 18,
	DrugOrAlcoholRelated = 19,
	Education = 20,
	Employed = 21,
	EmploymentStatus = 22,
	EmploymentType = 23,
	DemographicEmploymentType = 24,
	Ethnicity = 25,
	Gender = 26,
	GoalStatus = 27,
	GoalType = 28,
	HousingStatus = 29,
	SchoolStatus = 30,
	SchoolType = 31,
	JailTime = 32,
	MaritalStatus = 33,
	MilitaryStatus = 34,
	MonitoringStatus = 35,
	MonitoringType = 36,
	NeedLevel = 37,
	NoteType = 38,
	PhaseType = 39,
	Race = 40,
	Residence = 41,
	RiskLevel = 42,
	DocumentParentType = 43,
	SupervisionStatus = 44,
	SupervisionType = 45,
	TreatmentStatus = 46,
	TreatmentType = 47,
	YesNo = 48,
	DrugTestSessionType = 49,
	DrugTestLocation = 50,
	DrugTestMethod = 51,
	DrugTestType = 52,
	DrugTestResult = 53,
	BehaviorResponseStatus = 54,
	BehaviorResponseType = 55,
	BehaviorType = 56,
	SchoolAttendanceStatus = 57,
	AncillaryMeetingStatus = 58,
	AncillaryMeetingType = 59,
	CaseLedgerType = 60,
	LedgerFeeType = 61,
	LedgerPaymentType = 62,
	DrugType = 63,
	Substance = 64,
	Degree = 65,
    CaseLedgerCategory = 66,
	CriminalJusticeStatus = 67,
	OutcomeEventType = 68,
	OutcomeJuvenileStatusType = 69,
	OutcomeRecidivismType = 70,
	OutcomeTreatmentType = 71,
	OutcomeType = 72,
	WorkflowType = 73,
	WorkflowTypeStatus = 74,
	TemplateType = 75,
}