
import type { IInternalUserRole } from '@/Models/IInternalUserRole';
import { BaseApiService } from './base-api-service';

class roleService extends BaseApiService{
    
    public constructor() {
        super('role/');
    }

    public async getAllRoles(): Promise<Array<IInternalUserRole>> {
        return this.httpClient.get<IInternalUserRole[]>(`get-all-roles`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public async getRolesByRoleIds(ids: number[]): Promise<Array<IInternalUserRole>> {
        return this.httpClient.get<IInternalUserRole[]>(`get-roles-by-ids/${ids.toString()}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
}

export const RoleService : roleService = new roleService();