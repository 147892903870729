import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, vShow as _vShow, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_SingleErrorMessage = _resolveComponent("SingleErrorMessage")!
  const _component_OutcomeEventDialog = _resolveComponent("OutcomeEventDialog")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_ValidationAlert = _resolveComponent("ValidationAlert")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.cardTitle), 1)
          ]),
          _: 1
        }),
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.errorMessage != '')
          ? (_openBlock(), _createBlock(_component_SingleErrorMessage, {
              key: 1,
              errorMessage: _ctx.errorMessage
            }, null, 8, ["errorMessage"]))
          : _createCommentVNode("", true),
        _createVNode(_component_OutcomeEventDialog, _mergeProps(_ctx.eventPopupProps, {
          onCloseOutcomeEventDialog: _ctx.closeNewEventDialog,
          onOutcomeEventSubmit: _ctx.submitEvent
        }), null, 16, ["onCloseOutcomeEventDialog", "onOutcomeEventSubmit"]),
        (!_ctx.isLoading && _ctx.outcomeEvents.length > 0)
          ? (_openBlock(), _createBlock(_component_v_data_table, {
              key: 2,
              headers: _ctx.headers,
              items: _ctx.outcomeEvents,
              "sort-by": _ctx.sortBy,
              density: "compact",
              "item-value": "outcomeEventId",
              class: "elevation-1 pre-wrap"
            }, {
              [`item.eventType`]: _withCtx(({ item }) => [
                _createElementVNode("span", null, _toDisplayString(_ctx.eventTypes.find((e) => e.id == item.raw.outcomeEventTypeId)?.description || "Other"), 1)
              ]),
              [`item.eventDescription`]: _withCtx(({ item }) => [
                _createElementVNode("span", null, _toDisplayString(_ctx.formatEventDescription(item.raw)), 1)
              ]),
              [`item.startDate`]: _withCtx(({ item }) => [
                _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(item.raw.startDate)), 1)
              ]),
              [`item.endDate`]: _withCtx(({ item }) => [
                _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(item.raw.endDate)), 1)
              ]),
              [`item.actions`]: _withCtx(({ item }) => [
                (_ctx.maySave)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      onClick: ($event: any) => (_ctx.editEvent(item.raw.outcomeEventId)),
                      class: "ma-1",
                      density: "comfortable",
                      size: "small",
                      icon: "mdi-pencil-outline",
                      color: "info"
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (_ctx.mayDelete)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 1,
                      onClick: ($event: any) => (_ctx.deleteEvent(item.raw.outcomeEventId)),
                      class: "ma-1",
                      density: "comfortable",
                      size: "small",
                      icon: "mdi-trash-can-outline",
                      color: "error"
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["headers", "items", "sort-by"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (!_ctx.isLoading && _ctx.outcomeEvents.length === 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(" There are currently no "),
                  _createElementVNode("span", null, _toDisplayString(_ctx.includeClosedEvents ? "" : "open"), 1),
                  _createTextVNode(" events associated with this case. You may add one below. To view closed events, see the Outcome Summary page. ")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_row, {
      align: "center",
      style: {"padding-top":"10px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_spacer),
        _createVNode(_component_v_col, {
          cols: "12",
          md: "2"
        }, {
          default: _withCtx(() => [
            _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
              onClick: _ctx.addEvent,
              class: "mx-2 mb-4",
              color: "secondary",
              disabled: _ctx.isSaving
            }, {
              default: _withCtx(() => [
                _createTextVNode("Add Event")
              ]),
              _: 1
            }, 8, ["onClick", "disabled"])), [
              [_vShow, _ctx.canEditPermission],
              [_directive_ripple]
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_row, { align: "center" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { cols: "1" }),
        _createVNode(_component_v_col, { cols: "10" }, {
          default: _withCtx(() => [
            _createVNode(_component_ValidationAlert, { validationResults: _ctx.validationMessages }, null, 8, ["validationResults"]),
            _createVNode(_component_v_dialog, {
              modelValue: _ctx.isValidationError,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isValidationError) = $event)),
              width: "auto"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createTextVNode(" An unexpected error occurred when adding a new outcome event. Please try again. ")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_card_actions, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          color: "primary",
                          block: "",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isValidationError = false))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Close")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}