import { ICaseManagement } from '@/Models/ICaseManagement';
import { ITypedValidationResult } from '@/Models/IValidationResult';
import { BaseApiService } from './base-api-service';

class caseManagementService extends BaseApiService {
    public constructor() {
		super('caseManagement/');
    }

	public async getByPhaseId(phaseId: number): Promise<ICaseManagement[]> {
		return this.httpClient.get<ICaseManagement[]>(`GetByPhaseId/${phaseId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
	public async save(dto: ICaseManagement): Promise<ITypedValidationResult<ICaseManagement>> {
		return this.httpClient.post<ITypedValidationResult<ICaseManagement>>("save", dto).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}

	public async delete(id: number): Promise<void> {
		return this.httpClient.delete<void>(`delete/${id}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}
}

export const CaseManagementService: caseManagementService = new caseManagementService();