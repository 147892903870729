
import { defineComponent, PropType } from 'vue';
import { RoleService } from '@/Services/role-service';
import { TreatmentProgramService } from '@/Services/treatment-program-service';

import { IAccessRequest } from '@/Models/IAccessRequest';
import { IInternalUserRole } from '@/Models/IInternalUserRole';
import type { ITreatmentProgram } from '@/Models/ITreatmentProgram';


export default defineComponent({
  name: 'access-request-drilldown-dialog',
  components: { },

  setup(): { } {
    return {      
    };
  },
  beforeCreate() {
    
  },
  created() {
    RoleService.getAllRoles().then((response) => {
        this.selectableRoles = response;
    });
    TreatmentProgramService.getAllTreatmentPrograms().then((response) => {
        this.selectableTreatmentPrograms = response;
    });
  },
  data () {
    return {
        selectableTreatmentPrograms: [] as ITreatmentProgram[],
        selectableRoles: [] as IInternalUserRole[],

        requestedProgramIds: [] as number[],
        requestedRoleIds: [] as number[],
        
        mutableAccessRequest: {} as IAccessRequest,

        mutableDialogSwitch: undefined as boolean | undefined,
        isReadOnlyState: true as boolean,
        isFocused: false as boolean,
        isFormValid: false as boolean,

        atLeastOneRules: [
            (value: any) => value.length > 0 || "At least ONE option must be selected."
        ],
        requiredFieldRules: [
            (value: any) => !!value || 'This field is REQUIRED.',
        ],
    }
  },
  props: {
    suppliedAccessRequest: {
        type: Object as PropType<IAccessRequest>,
        required: true
    },
    dialog: {
        type: Boolean,
        required: true
    },
  },
  watch: {
    dialog(newValue: boolean): void {
        this.mutableDialogSwitch = newValue;
        this.isReadOnlyState = true;
        this.isFocused = false;
    },
    suppliedAccessRequest(newValue: IAccessRequest): void {
        this.mutableAccessRequest = JSON.parse(JSON.stringify(newValue));
        this.requestedRoleIds = newValue.requestedRoleIds.split(',').map(Number);
        this.requestedProgramIds = newValue.requestedTreatmentProgramIds.split(',').map(Number);
    }
  },
  methods: {
    closeDialog(): void {
        this.$emit("close-access-request-dialog");
    },
    modifyRequest(): void {
        this.isReadOnlyState = false;
        this.isFocused = true;
    },
    approveRequest(): void {
        if (this.isFormValid !== false) {
            this.mutableAccessRequest.requestedRoleIds = this.requestedRoleIds.toString();
            this.mutableAccessRequest.requestedTreatmentProgramIds = this.requestedProgramIds.toString();
            this.$emit("refresh-pending-requests", this.mutableAccessRequest, true);
            this.$emit("close-access-request-dialog");
        }
        else {
            return;
        }
    },
    rejectRequest(): void {
        if (this.isFormValid !== false) {
            this.$emit("refresh-pending-requests", this.mutableAccessRequest, false);
            this.$emit("close-access-request-dialog");
        }
        else {
            return;
        }
    },
  }
});
