import { IGoal } from '@/Models/IGoal';
import { ITypedValidationResult } from '@/Models/IValidationResult';
import { BaseApiService } from './base-api-service';

class goalService extends BaseApiService {
    public constructor() {
		super('goal/');
    }

	public async getByPhaseId(phaseId: number): Promise<IGoal[]> {
		return this.httpClient.get<IGoal[]>(`GetByPhaseId/${phaseId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
	public async save(dto: IGoal): Promise<ITypedValidationResult<IGoal>> {
		return this.httpClient.post<ITypedValidationResult<IGoal>>("save", dto).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}

	public async delete(id: number): Promise<void> {
		return this.httpClient.delete<void>(`delete/${id}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}
}

export const GoalService: goalService = new goalService();