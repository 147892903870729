import { IAddress } from '@/Models/IAddress';
import { ITypedValidationResult } from '@/Models/IValidationResult';
import { BaseApiService } from './base-api-service';

class addressService extends BaseApiService{
    
    public constructor() {
		super('address/');
    }

	public async getByCaseId(caseId: number): Promise<IAddress[]> {
		return this.httpClient.get<IAddress[]>(`GetByCaseId/${caseId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
	public async save(address: IAddress): Promise<ITypedValidationResult<IAddress>> {
		return this.httpClient.post<ITypedValidationResult<IAddress>>("save", address).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
	public async delete(addressId: number): Promise<void> {
		return this.httpClient.delete<void>(`delete/${addressId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
}

export const AddressService: addressService = new addressService();