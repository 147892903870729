
	import { defineComponent } from 'vue';

	import LoadingSpinner from '@/ChildrenComponents/LoadingSpinner.vue';
	import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';
	import PageHeader from '@/ChildrenComponents/Participant/Common/PageHeader.vue';
	import ParticipantHeader from '@/ChildrenComponents/Participant/Common/ParticipantHeader.vue';
	import SingleErrorMessage from '@/ChildrenComponents/Participant/Common/SingleErrorMessage.vue';

	import { ComponentType } from '@/Enums/ComponentType'
	import { IInternalUser } from '@/Models/IInternalUser';
	import { IDataTableHeader } from '@/Models/IDataTableHeader';
	import HeaderData from '@/Models/HeaderData';

	import { doesUserRoleHaveComponentEditPermissions } from '@/Services/Helper/component-permissions-helper'
	import { CriminalHistoryService } from '@/Services/criminal-history-service';
	import { DateHelper } from '@/Services/Helper/date-helper';
	import { loadParticipantDataRequireCase } from '@/Services/Helper/loader-helper';

	import { useUserStore } from '@/Services/Store/user-store';
    import { IParticipantCriminalHistory, ICriminalHistoryPayload } from '@/Models/IParticipantCriminalHistory';
    import { LookupService } from '@/Services/lookup-service';
    import { LookupKeys } from '@/Enums/LookupKeys';
import { IBaseLookup } from '@/Models/Lookup/BaseLookup';
import { MasterLookupWrapper } from '@/Models/Lookup/MasterLookupWrapper';

	export default defineComponent({
		name: "criminal-history",
		components: { LoadingSpinner, ParticipantHeader, ValidationAlert, PageHeader, SingleErrorMessage },
		setup(): { currentUser: IInternalUser | null } {
			const userStoreInstance = useUserStore();
			return { currentUser: userStoreInstance.$state.cstInternalUser };
		},
		created() {
			loadParticipantDataRequireCase(this.pageType, this.id).then((data) => {
				this.caseId = data.case?.caseId ?? null;
				this.headerData = new HeaderData(data.participant, data.case?.program ?? null);
			}).then(() => {
                LookupService.getLookupsByKey([LookupKeys.ChargeType, LookupKeys.DrugOrAlcoholRelated, LookupKeys.Degree]).then((response: MasterLookupWrapper) => {
                    this.chargeTypeSelectItems = response.lookupLists[LookupKeys.ChargeType];
                    this.drugOrAlcoholSelectItems = response.lookupLists[LookupKeys.DrugOrAlcoholRelated];
                    this.degreeSelectItems = response.lookupLists[LookupKeys.Degree]
                    this.loadData();
                });
            })
            .catch(error => this.errorMessage = error);
		},
		data() {
			return {
				isLoading: true,
				errorMessage: "",
				validationMessages: [] as string[],
				pageType: this.$route.params.type?.toString() ?? "",
				id: this.$route.params.id == undefined ? null : parseInt(this.$route.params.id.toString()),
				caseId: null as number | null,
				headerData: null as HeaderData | null,
                chargeTypeSelectItems: [] as IBaseLookup[],
                drugOrAlcoholSelectItems: [] as IBaseLookup[],
                degreeSelectItems: [] as IBaseLookup[],
                criminalHistoryOptionsHeaders: [
					{ title: "Case Number", key: "caseNumber", sortable: false, align: "start" },
					{ title: "Arrest/Citation Date", key: "arrestDate", width: "15%", sortable: false, align: "start" },
					{ title: "Charge Type", key: "chargeType", width: "20%", sortable: false, align: "start" },
					{ title: "Arrest Charge", key: "arrestCharge", width: "20%", sortable: false, align: "start" },
                    { title: "Citation", key: "citation", width: "20%", sortable: false, align: "start" },
					{ title: "Drug Or Alcohol Related", key: "drugOrAlcoholRelated", width: "13%", sortable: false, align: "start" },
					{ title: "Blood Alcohol Content", key: "bac", width: "10%", sortable: false, align: "start" },
                    { title: "Degree", key: "degree", sortable: false, align: "start" },
					{ title: "History Actions", key: "actions", width: "5%", sortable: false, align: "end" }
				] as IDataTableHeader[],

				criminalHistories: [] as IParticipantCriminalHistory[],
			}
		},
		methods: {
            loadData() {
                this.isLoading = true;
                if (this.caseId) {
                    CriminalHistoryService.getByCaseId(this.caseId).then(results => {
                        this.criminalHistories = results
                        this.criminalHistories.forEach(e => {
							e.arrestDate = e.arrestDate ? DateHelper.formatForEdit(e.arrestDate) : null;
						});
                        this.isLoading = false;
                    });
                }
            },
			saveCriminalHistories() {
                if (!this.caseId) return;
                this.isLoading = true
                let payload = {
                    caseId: this.caseId,
                    criminalHistories: this.criminalHistories
                } as ICriminalHistoryPayload
				CriminalHistoryService.saveList(payload).then(result => {
					this.validationMessages = result.messages;
					if (result.isValid) {
						this.loadData();
					}
				}).catch(error => {
                    this.errorMessage = error
                    this.isLoading = false
                });
			},
			getActiveText(isActive: boolean): string {
				return isActive ? "Yes" : "No";
			},
            addCriminalHistory() {
				this.criminalHistories.push({ eligibilityId: null, caseId: this.caseId, caseNumber: null, arrestDate: null, chargeTypeId: null, arrestHistoryId: null, drugOrAlcoholRelatedId: null, bac: null } as IParticipantCriminalHistory);
			},
            removeCriminalHistory(id: number, index: number) {

                if (id) {
                    CriminalHistoryService.delete(id).then(() => {
                        this.loadData();
                    })
                } else {
                    this.criminalHistories.splice(index, 1);
                }

            },
            getAbbreviatedText(text: string): string {
                if (text.includes("Citation:")) return text
                else return text?.split(':')[0] ?? ""
            }
		},
		computed: {
			componentType(): ComponentType {
				return ComponentType.CriminalHistory
			},
			canEditRolePermission() : boolean {
				return doesUserRoleHaveComponentEditPermissions(this.currentUser?.attachedRoles ?? [], this.componentType)
			},
		},
	});
