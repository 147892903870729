
import { defineComponent } from "vue";

import ConfirmDialog from "@/ChildrenComponents/ConfirmDialog.vue";
import LoadingSpinner from "@/ChildrenComponents/LoadingSpinner.vue";
import ValidationAlert from "@/ChildrenComponents/ValidationAlert.vue";
import AddCaseLedgerLineDialog from "@/ChildrenComponents/Participant/AddCaseLedgerLineDialog.vue";
import PageHeader from "@/ChildrenComponents/Participant/Common/PageHeader.vue";
import ParticipantHeader from "@/ChildrenComponents/Participant/Common/ParticipantHeader.vue";
import SingleErrorMessage from "@/ChildrenComponents/Participant/Common/SingleErrorMessage.vue";
import IncorrectPermissions from "@/ChildrenComponents/IncorrectPermissions.vue";
import ReportExporter from '@/ChildrenComponents/ReportExporter.vue'

import { ComponentType } from "@/Enums/ComponentType";
import { IInternalUser } from "@/Models/IInternalUser";
import { IDataTableHeader } from "@/Models/IDataTableHeader";
import { ILedgerLine, LedgerLineForEdit } from "@/Models/ILedgerLine";
import HeaderData from "@/Models/HeaderData";
import { LookupKeys } from "@/Enums/LookupKeys";
import { MasterLookupWrapper } from "@/Models/Lookup/MasterLookupWrapper";

import { LookupService } from "@/Services/lookup-service";
import { CaseLedgerService } from "@/Services/case-ledger-service";
import { DateHelper } from "@/Services/Helper/date-helper";
import { loadParticipantDataRequireCase } from "@/Services/Helper/loader-helper";

import {
	doesUserRoleHaveComponentEditPermissions,
	doesUserRoleHaveComponentViewPermissions,
} from "@/Services/Helper/component-permissions-helper";
import { useUserStore } from "@/Services/Store/user-store";
import { CaseLedgerType } from "@/Enums/CaseLedgerType";
import FormatHelper from "@/Services/Helper/format-helper";

export default defineComponent({
	name: "case-ledger",
	components: {
		AddCaseLedgerLineDialog,
		LoadingSpinner,
		ParticipantHeader,
		ConfirmDialog,
		ValidationAlert,
		PageHeader,
		SingleErrorMessage,
		IncorrectPermissions,
        ReportExporter
	},
	setup(): { currentUser: IInternalUser | null } {
		const userStoreInstance = useUserStore();
		return { currentUser: userStoreInstance.$state.cstInternalUser };
	},
	created() {
		const selectedLookups = [
			LookupKeys.CaseLedgerType,
			LookupKeys.LedgerFeeType,
			LookupKeys.LedgerPaymentType,
            LookupKeys.CaseLedgerCategory
		] as LookupKeys[];
		Promise.all([
			loadParticipantDataRequireCase(this.pageType, this.id),
			LookupService.getLookupsByKey(selectedLookups),
		])
			.then(([data, wrapper]) => {
				this.caseId = data.case?.caseId ?? null;
				this.headerData = new HeaderData(
					data.participant,
					data.case?.program ?? null
				);
				this.setBlankLine();
				this.lookups = wrapper;
			})
			.then(() => {
				if (this.caseId != null) {
					CaseLedgerService.getByCaseId(this.caseId)
						.then((result) => (this.ledgerLines = result))
						.then(() => {
							this.isLoading = false;
						});
				}
			})
			.catch((error) => (this.errorMessage = error));
	},
	data() {
		return {
			isLoading: true,
			errorMessage: "",
			validationMessages: [] as string[],
			pageType: this.$route.params.type?.toString() ?? "",
			id:
				this.$route.params.id == undefined
					? null
					: parseInt(this.$route.params.id.toString()),
			caseId: null as number | null,
			headerData: null as HeaderData | null,
			lookups: {} as MasterLookupWrapper,

			ledgerLines: [] as ILedgerLine[],
			selectedLedgerLine: {} as LedgerLineForEdit | null,
			ledgerLineHeaders: [
				{ title: "Date", key: "date", sortable: true, align: "start" },
				{ title: "Type", key: "ledgerTypeDisplay", sortable: true, align: "start" },
				{ title: "SubType", key: "ledgerSubTypeDisplay", sortable: true, align: "start" },
                { title: "Category", key: "categoryDisplay", sortable: true, align: "start" },
				{ title: "Amount", key: "amount", sortable: true, align: "start" },
				{ title: "Edit", key: "actions", sortable: false },
			] as IDataTableHeader[],
            reportJsonfields: {
                "Date": "dateEditable",
                "Type": "ledgerTypeDisplay",
                "SubType": "ledgerSubTypeDisplay",
                "Category": "categoryDisplay",
                "Amount": "amountFormattedToCurrency"
            },
            reportPdfFields: [
				{ header: "Date", dataKey: "dateEditable" },
				{ header: "Type", dataKey: "ledgerTypeDisplay" },
				{ header: "SubType", dataKey: "ledgerSubTypeDisplay" },
                { header: "Category", dataKey: "categoryDisplay" },
				{ header: "Amount", dataKey: "amountFormattedToCurrency" },
			],
            reportFooter: "",
			showDeleteWarning: false,
			deleteWarningText:
				"Are you sure you want to delete this line? This cannot be undone!",
			idToDelete: null as number | null,

			showLedgerLineDialog: false,
			ledgerLineHeader: "",
		};
	},
	methods: {
		formatDate(date: Date): string {
			return DateHelper.formatDateUtc(date, "MM/dd/yyyy");
		},
		setBlankLine() {
			const blankLine = {
				caseLedgerId: 0,
				caseId: this.caseId,
				metaData: "",
				note: "",
			} as ILedgerLine;
			this.selectedLedgerLine = new LedgerLineForEdit(blankLine);
		},
		newLedgerLine() {
			this.setBlankLine();
			this.ledgerLineHeader = "Add Ledger Line";
			this.showLedgerLineDialog = true;
		},
		editLine(line: ILedgerLine) {
			this.selectedLedgerLine = new LedgerLineForEdit(line);
			this.ledgerLineHeader = "Edit Ledger Line";
			this.showLedgerLineDialog = true;
		},
		cancelLineEdit() {
			this.setBlankLine();
			this.showLedgerLineDialog = false;
		},
		saveLine(newLine: LedgerLineForEdit) {
			CaseLedgerService.save(newLine)
				.then((result) => {
					this.validationMessages = result.messages;

					if (result.isValid && result.resultObject != null) {
						this.setBlankLine();
						this.showLedgerLineDialog = false;
						if (this.caseId != null) {
							CaseLedgerService.getByCaseId(this.caseId).then(
								(lines) => (this.ledgerLines = lines)
							);
						}
					}
				})
				.catch((error) => (this.errorMessage = error));
		},
		deleteLine(lineId: number) {
			this.idToDelete = lineId;
			this.showDeleteWarning = true;
		},
		cancelDelete() {
			this.showDeleteWarning = false;
			this.idToDelete = null;
		},
		confirmDelete() {
			this.showDeleteWarning = false;
			if (this.idToDelete != null) {
				CaseLedgerService.delete(this.idToDelete).then(
					() =>
						(this.ledgerLines = this.ledgerLines.filter(
							(x) => x.caseLedgerId != this.idToDelete
						))
				);
			}
		},
		getTextColor(ledgerTypeId: number) {
            switch (ledgerTypeId) {
                case CaseLedgerType.Fee:
                    return 'red';
                case CaseLedgerType.Payment:
                    return 'green';
                default:
                    return 'black';
            }
		},
		formatCurrency(amount: number): string {
			// const roundedAmount = Math.round(amount * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
			// const amountString = roundedAmount.toFixed(decimalPlaces);
			// const [integerPart, decimalPart] = amountString.split('.');
			// const integerFormatted = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
			// const formattedCurrency = `${currencySymbol}${integerFormatted}.${decimalPart}`;

			// return formattedCurrency;
            return FormatHelper.formatCurrency(amount);
		}
	},
	computed: {
		componentType(): ComponentType {
			return ComponentType.CaseLedger;
		},
		canEditRolePermission(): boolean {
			return doesUserRoleHaveComponentEditPermissions(
				this.currentUser?.attachedRoles ?? [],
				this.componentType
			);
		},
		canViewRolePermission(): boolean {
			return doesUserRoleHaveComponentViewPermissions(
				this.currentUser?.attachedRoles ?? [],
				this.componentType
			);
		},
		totalFees(): number {
			const feeItems = this.ledgerLines.filter(item => item.ledgerTypeId === CaseLedgerType.Fee);

			const totalAmount = feeItems.reduce((accumulator, currentItem) => {
				return accumulator + currentItem.amount;
			}, 0);

			return totalAmount;
		},
		totalPayments(): number {
			const paymentItems = this.ledgerLines.filter(item => item.ledgerTypeId === CaseLedgerType.Payment);

			const totalAmount = paymentItems.reduce((accumulator, currentItem) => {
				return accumulator + currentItem.amount;
			}, 0);

			return totalAmount;
		},
		balance(): number {
			return this.totalFees - this.totalPayments;
		},
        reportHeader(): string {
            return `Ledger Receipt for: ${this.headerData?.fullName}        Print Date: ${new Date().toLocaleString()}`
        },
        reportFilename(): string {
            return `ledger_receipt_${this.headerData?.lastName ?? ""}_${this.headerData?.firstName ?? ""}_${new Date().toISOString()}`
        },
        reportLedgerLines(): ILedgerLine[] {
            var blank = {
            } as ILedgerLine
            var fees = {
                amount: this.totalFees,
                categoryDisplay: "Total Fees:",
            } as ILedgerLine;
            var payments = {
                amount: this.totalPayments,
                categoryDisplay: "Total Payments:",
            } as ILedgerLine;
            var balance = {
                amount: this.balance,
                categoryDisplay: "Net:",
            } as ILedgerLine;
            return this.ledgerLines.concat([blank]).concat([fees]).concat([payments]).concat([balance]).map(
                x => new LedgerLineForEdit(x)
            );
        }
	},
});
