export enum OutcomeReportType {
    Closing = 1,
    Quarterly = 2,
    Other = 99
}

export enum OutcomeEventType {
    Treatment = 1,
    Recidivism = 2,
    FosterCare = 3,
    Other = 99
}

export enum OutcomeRecidivismType {
    Arrest = 1,
    Filing = 2,
    Probation = 3,
    Jail = 4,
    Prison = 5,
    Other = 99
}

export enum OutcomeTreatmentType {
    EmergencyRoom = 1,
    Hospitalization = 2,
    Other = 99
}

export enum OutcomeJuvenileType {
    FosterCare = 1,
    Other = 99
}

export enum OutcomeJusticeType {
    ChargesExpunged = 1,
    Other = 99
}