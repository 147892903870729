
import type { ITaskListPreviewItem } from '@/Models/ITaskListBase';
import { BaseApiService } from './base-api-service';

class tasklistService extends BaseApiService{
    
    public constructor() {
        super('tasklist/');
    }

    public async getTaskListItemsByInternalUser(): Promise<Array<ITaskListPreviewItem>> {
        return this.httpClient.get<ITaskListPreviewItem[]>(`get-items-by-internal-user`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

	public async getTaskListItemByWorkflowInstanceStatusId(statusId: number): Promise<ITaskListPreviewItem> {
        return this.httpClient.get<ITaskListPreviewItem>(`get-by-workflow-instance-status-id/${statusId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
}

export const TaskListService : tasklistService = new tasklistService();