
	import { defineComponent } from 'vue';

	import ParticipantHeader from '@/ChildrenComponents/Participant/Common/ParticipantHeader.vue';
	import AlternateWorkflowSubmission from '@/ChildrenComponents/Phase/AlternateWorkflowSubmission.vue';
	import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';
	import WorkflowHistory from '@/ChildrenComponents/WorkflowHistory.vue';
	import DocumentList from '@/ChildrenComponents/DocumentList.vue';
	import AddAddressDialog from '@/ChildrenComponents/Participant/AddAddressDialog.vue';
	import AddEmailDialog from '@/ChildrenComponents/Participant/AddEmailDialog.vue';
	import AddPhoneDialog from '@/ChildrenComponents/Participant/AddPhoneDialog.vue';

	import { ComponentType } from '@/Enums/ComponentType'
	import { WorkflowStatus } from '@/Enums/WorkflowStatus';
	import { LookupKeys } from '@/Enums/LookupKeys';
	import { DocumentParentType } from '@/Enums/DocumentParentType';
	import { AlternateWorkflowType } from '@/Enums/AlternateWorkflowType';

	import HeaderData from '@/Models/HeaderData';
	import { IDataTableHeader } from '@/Models/IDataTableHeader';
	import { IInternalUser } from '@/Models/IInternalUser';
	import { IEligibility, IEligibilityForEdit } from '@/Models/IEligibility';
	import { IAddress, Address } from '@/Models/IAddress';
	import { IEmail } from '@/Models/IEmail';
	import { IPhone } from '@/Models/IPhone';
	import { IParticipantAssessment } from '@/Models/IParticipantAssessment';
	import { IParticipantRace } from '@/Models/IParticipantRace';
	import { IParticipantDemographic } from '@/Models/IParticipantDemographic';
	import { IParticipantDiagnosis } from '@/Models/IParticipantDiagnosis';
	import { IParticipantCriminalHistory } from '@/Models/IParticipantCriminalHistory';
	import { IParticipantCriminalStatus } from '@/Models/IParticipantCriminalStatus';
	import { IParticipantTreatment } from '@/Models/IParticipantTreatment';
	import { IParticipantContactInformation } from '@/Models/IParticipantContactInformation';
	import { IParticipantEmergencyContactInformation } from '@/Models/IParticipantEmergencyContactInformation';
	import { ITypedValidationResult } from '@/Models/IValidationResult';
	import { IBaseLookup } from '@/Models/Lookup/BaseLookup';
	import { MasterLookupWrapper } from '@/Models/Lookup/MasterLookupWrapper';

	import { doesUserRoleHaveComponentEditPermissions } from '@/Services/Helper/component-permissions-helper'
	import { EligibilityService } from '@/Services/eligibility-service';
	import { LookupService } from '@/Services/lookup-service';
	import FormatHelper from '@/Services/Helper/format-helper';
	import { DateHelper } from '@/Services/Helper/date-helper';

	import { useUserStore } from '@/Services/Store/user-store';

	export default defineComponent({
		name: 'participant-eligibility-data-entry',
		components: { ParticipantHeader, AlternateWorkflowSubmission, WorkflowHistory, ValidationAlert, DocumentList, AddAddressDialog, AddEmailDialog, AddPhoneDialog },
		setup(): { currentUser: IInternalUser | null } {
			const userStoreInstance = useUserStore();
			return { currentUser: userStoreInstance.$state.cstInternalUser };
		},
		created() {
			LookupService.getAllDataEntryLookups().then((response: MasterLookupWrapper) => {
				this.raceSelectItems = response.lookupLists[LookupKeys.Race];
				this.ethnicitySelectItems = response.lookupLists[LookupKeys.Ethnicity];
				this.incomeSelectItems = response.lookupLists[LookupKeys.AnnualIncome];
				this.employmentSelectItems = response.lookupLists[LookupKeys.DemographicEmploymentType];
				this.educationSelectItems = response.lookupLists[LookupKeys.Education];
				this.militaryStatusSelectItems = response.lookupLists[LookupKeys.MilitaryStatus];
				this.adaStatusSelectItems = response.lookupLists[LookupKeys.ADAStatus];
				this.chargeTypeSelectItems = response.lookupLists[LookupKeys.ChargeType];
				this.treatmentTypeSelectItems = response.lookupLists[LookupKeys.TreatmentType];
				this.assessmentTypeSelectItems = response.lookupLists[LookupKeys.AssessmentType];
				this.adjudicationTypeSelectItems = response.lookupLists[LookupKeys.AdjudicationType];
				this.drugOrAlcoholSelectItems = response.lookupLists[LookupKeys.DrugOrAlcoholRelated];
				this.riskLevelSelectItems = response.lookupLists[LookupKeys.RiskLevel];
				this.needLevelSelectItems = response.lookupLists[LookupKeys.NeedLevel];
				this.diagnosisSelectItems = response.lookupLists[LookupKeys.Diagnosis];
				this.housingStatusSelectItems = response.lookupLists[LookupKeys.HousingStatus];
				this.childCustodySelectItems = response.lookupLists[LookupKeys.ChildCustody];
				this.maritalStatusSelectItems = response.lookupLists[LookupKeys.MaritalStatus];
				this.contactTypeSelectItems = response.lookupLists[LookupKeys.ContactType];
                this.degreeSelectItems = response.lookupLists[LookupKeys.Degree]
				this.loadEligibility();
			});
		},
		data () {
			return {
				eligibilityId: this.$route.params.id == undefined ? null : parseInt(this.$route.params.id.toString()),
				headerData: null as HeaderData | null,
				assessmentOptionsHeaders: [
					{ title: "Assessment Type", key: "assessmentType", sortable: false, align: "start", width: "200px" },
					{ title: "Score", key: "score", sortable: false, align: "start", width: "150px"},
					{ title: "Date Taken", key: "dateTaken", sortable: false, align: "start", width: "200px" },
					{ title: "Risk Level", key: "riskLevel", sortable: false, align: "start", width: "200px" },
					{ title: "Need Level", key: "needLevel", sortable: false, align: "start", width: "200px" },
					{ title: "Assessment Actions", key: "actions", sortable: false, align: "end" }
				] as IDataTableHeader[],

				criminalHistoryOptionsHeaders: [
					{ title: "Case Status", key: "adjudicationType", sortable: false, align: "start", width: "200px"},
					{ title: "Case Number", key: "caseNumber", sortable: false, align: "start", width: '150px' },
					{ title: "Arrest/Citation Date", key: "arrestDate", sortable: false, align: "start", width: '200px' },
					{ title: "Charge Type", key: "chargeType", sortable: false, align: "start", width: "200px"},
					{ title: "Arrest Charge", key: "arrestCharge", sortable: false, align: "start", width: "200px" },
                    { title: "Citation", key: "citation", sortable: false, align: "start", width: "200px" },
					{ title: "Drug Or Alcohol Related", key: "drugOrAlcoholRelated", sortable: false, align: "start", width: '150px' },
					{ title: "Blood Alcohol Content", key: "bac", sortable: false, align: "start", width: '150px' },
                    { title: "Degree", key: "degree", sortable: false, align: "start", width: '150px' },
					{ title: "History Actions", key: "actions", sortable: false, align: "end" }
				] as IDataTableHeader[],

				diagnosisOptionsHeaders: [
					{ title: "Diagnosis Option", key: "diagnosis", sortable: false, align: "start" },
					{ title: "Diagnosis Actions", key: "actions", sortable: false, align: "end" }
				] as IDataTableHeader[],

				treatmentOptionsHeaders: [
					{ title: "Treatment Option", key: "treatmentType", sortable: false, align: "start" },
					{ title: "Treatment Actions", key: "actions", sortable: false, align: "end" }
				] as IDataTableHeader[],

				addressHeaders: [
					{ title: "Address 1", key: "address1", sortable: true, align: "start" },
					{ title: "Address 2", key: "address2", sortable: true, align: "start" },
					{ title: "City", key: "city", sortable: true, align: "start" },
					{ title: "State", key: "stateName", sortable: true, align: "start" },
					{ title: "Zip", key: "zip", sortable: true, align: "start" },
					{ title: "Country", key: "countryName", sortable: true, align: "start" },
					{ title: "Active?", key: "isActive", sortable: true, align: "start" },
					{ title: "Actions", key: "actions", sortable: false, align: "end" }
				] as IDataTableHeader[],
		
				emailHeaders: [
					{ title: "Email Address", key: "email", sortable: true, align: "start" },
					{ title: "Active?", key: "isActive", sortable: true, align: "start" },
					{ title: "Actions", key: "actions", sortable: false, align: "end" }
				] as IDataTableHeader[],

				phoneHeaders: [
					{ title: "Phone Number", key: "phoneNumber", sortable: true, align: "start" },
					{ title: "Active?", key: "isActive", sortable: true, align: "start" },
					{ title: "Actions", key: "actions", sortable: false, align: "end" }
				] as IDataTableHeader[],

				raceSelectItems: [] as IBaseLookup[],
				ethnicitySelectItems: [] as IBaseLookup[],
				incomeSelectItems: [] as IBaseLookup[],
				employmentSelectItems: [] as IBaseLookup[],
				educationSelectItems: [] as IBaseLookup[],
				militaryStatusSelectItems: [] as IBaseLookup[],
				adaStatusSelectItems: [] as IBaseLookup[],
				chargeTypeSelectItems: [] as IBaseLookup[],
				treatmentTypeSelectItems: [] as IBaseLookup[],
				riskLevelSelectItems: [] as IBaseLookup[],
				needLevelSelectItems: [] as IBaseLookup[],
				diagnosisSelectItems: [] as IBaseLookup[],
				assessmentTypeSelectItems: [] as IBaseLookup[],
				adjudicationTypeSelectItems: [] as IBaseLookup[],
				drugOrAlcoholSelectItems: [] as IBaseLookup[],
				housingStatusSelectItems: [] as IBaseLookup[],
				childCustodySelectItems: [] as IBaseLookup[],
				maritalStatusSelectItems: [] as IBaseLookup[],
				contactTypeSelectItems: [] as IBaseLookup[],
                degreeSelectItems: [] as IBaseLookup[],

				errorMessage: "",
				validationResults: [] as string[],
				raceSelections: [] as (number| null)[],
				eligibility: {} as IEligibilityForEdit,
				workflowType: AlternateWorkflowType.Eligibility as number,
				parentTypeId: DocumentParentType.Eligibility as number,

				selectedAddress: null as Address | null,
				selectedEmail: null as IEmail | null,
				selectedPhone: null as IPhone | null,
				selectedIndex: 0,

				isLoading: true,
				isSaving: false,
				canEnterData: true,
				isEditingEmergencyContact: false,
				showAddressDialog: false,
				showEmailDialog: false,
				showPhoneDialog: false,

				successNotification: false,
				panel: [0,1,2,3,4,5,6,7,8,9,10] as number[],
			}
		},
		methods: {
			loadEligibility() {
				this.isLoading = true;
				if (this.eligibilityId == null) {
					this.errorMessage = "Eligibility not found";
					this.isLoading = false;
				}
				else {
					EligibilityService.getWithChildren(this.eligibilityId).then(result => {
						this.eligibility = result as IEligibilityForEdit;
						this.headerData = new HeaderData(result.participant, result.program);
                        // var canEditStatuses = [
                        //     WorkflowStatus.PendingInitialEntry,
                        //     WorkflowStatus.TeamReview
                        // ]
                        //can edit in any statuses
						this.canEnterData = this.canEditRolePermission;

						//fill in missing elements
						if (this.eligibility.participantDemographic.participantDemographicId == undefined) {
							this.eligibility.participantDemographic = { participantDemographicId: 0, eligibilityId: this.eligibility.eligibilityId } as IParticipantDemographic;
						}
						if (this.eligibility.participantCriminalStatus.participantCriminalStatusId == undefined) {
							this.eligibility.participantCriminalStatus = { participantCriminalStatusId: 0, eligibilityId: this.eligibility.eligibilityId } as IParticipantCriminalStatus;
						}
						if (this.eligibility.participantAssessments.length == 0) {
							this.addAssessment();
						}
						if (this.eligibility.participantDiagnoses.length == 0) {
							this.addDiagnosis();
						}
						if (this.eligibility.participantCriminalHistories.length == 0) {
							this.addCriminalHistory();
						}
						if (this.eligibility.participantTreatments.length == 0) {
							this.addTreatment();
						}
						if (this.eligibility.participantContactInformations.length == 0) {
							this.addContactObject();
						}
						if (this.eligibility.participantEmergencyContactInformations.length == 0) {
							this.addEmergencyContactObject();
						}
						this.raceSelections = this.eligibility.participantRaces.map(e => e.raceId);

						//format date
						this.eligibility.participantCriminalHistories.forEach(e => {
							e.arrestDate = e.arrestDate ? DateHelper.formatForEdit(e.arrestDate) : null;
						});
						this.eligibility.participantAssessments.forEach(e => {
							e.dateTaken = e.dateTaken ? DateHelper.formatForEdit(e.dateTaken) : null;
						});

                        this.eligibility.targetEndDateForEdit = DateHelper.formatForEdit(this.eligibility.targetEndDate);
                        this.eligibility.startDateForEdit = DateHelper.formatForEdit(this.eligibility.startDate);
                        this.eligibility.endDateForEdit = DateHelper.formatForEdit(this.eligibility.endDate);
					}).then(() => this.isLoading = false);
				}
			},
			addCriminalHistory() {
				this.eligibility.participantCriminalHistories.push({ eligibilityId: this.eligibility.eligibilityId, adjudicationTypeId: this.eligibility.participantCriminalStatus.adjudicationTypeId || null, caseNumber: null, arrestDate: null, chargeTypeId: null, arrestHistoryId: null, drugOrAlcoholRelatedId: null, bac: null } as IParticipantCriminalHistory);
			},
			addAssessment() {
				this.eligibility.participantAssessments.push({ eligibilityId: this.eligibility.eligibilityId, assessmentTypeId: null, score: null, dateTaken: null, riskLevelId: 98, needLevelId: null } as IParticipantAssessment);
			},
			addDiagnosis() {
				this.eligibility.participantDiagnoses.push({ eligibilityId: this.eligibility.eligibilityId, diagnosisId: null } as IParticipantDiagnosis);
			},
			addTreatment() {
				this.eligibility.participantTreatments.push({ eligibilityId: this.eligibility.eligibilityId, treatmentTypeId: null } as IParticipantTreatment);
			},
			addContactObject() {
				this.eligibility.participantContactInformations.push({ eligibilityId: this.eligibility.eligibilityId } as IParticipantContactInformation);
			},
			addEmergencyContactObject() {
				this.eligibility.participantEmergencyContactInformations.push({ eligibilityId: this.eligibility.eligibilityId } as IParticipantEmergencyContactInformation);
			},
			addAddress(index: number, isEmergency: boolean) {
				const address = { id: 0, caseId: this.eligibility.caseId, isActive: true, countryId: 1} as IAddress;
				if (isEmergency) {
                    address.contactTypeId = this.eligibility.participantEmergencyContactInformations[index].contactTypeId;
					this.eligibility.participantEmergencyContactInformations[index].associatedAddress = address;
				}
				else {
                    address.contactTypeId = this.eligibility.participantContactInformations[index].contactTypeId;
					this.eligibility.participantContactInformations[index].associatedAddress = address;
				}
				this.editAddress(address, index, isEmergency);
			},
			addPhone(index: number, isEmergency: boolean) {
				const phone = { id: 0, caseId: this.eligibility.caseId, isActive: true } as IPhone;
				if (isEmergency) {
                    phone.contactTypeId = this.eligibility.participantEmergencyContactInformations[index].contactTypeId;
					this.eligibility.participantEmergencyContactInformations[index].associatedPhone = phone;
				}
				else {
                    phone.contactTypeId = this.eligibility.participantContactInformations[index].contactTypeId;
					this.eligibility.participantContactInformations[index].associatedPhone = phone;
				}
				this.editPhone(phone, index, isEmergency);
			},
			addEmail(index: number, isEmergency: boolean) {
				const email = { id: 0, caseId: this.eligibility.caseId, isActive: true } as IEmail;
				if (isEmergency) {
                    email.contactTypeId = this.eligibility.participantEmergencyContactInformations[index].contactTypeId;
					this.eligibility.participantEmergencyContactInformations[index].associatedEmail = email;
				}
				else {
                    email.contactTypeId = this.eligibility.participantContactInformations[index].contactTypeId;
					this.eligibility.participantContactInformations[index].associatedEmail = email;
				}
				this.editEmail(email, index, isEmergency);
			},
			editAddress(object: IAddress, index: number, isEmergency: boolean) {
				this.selectedAddress = new Address(object);
				this.selectedIndex = index;
				this.isEditingEmergencyContact = isEmergency;
				this.showAddressDialog = true;
			},
			editPhone(object: IPhone, index: number, isEmergency: boolean) {
				this.selectedPhone = object;
				this.selectedIndex = index;
				this.isEditingEmergencyContact = isEmergency;
				this.showPhoneDialog = true;
			},
			editEmail(object: IEmail, index: number, isEmergency: boolean) {
				this.selectedEmail = object;
				this.selectedIndex = index;
				this.isEditingEmergencyContact = isEmergency;
				this.showEmailDialog = true;
			},
			removeContactObject(index: number) {
				this.eligibility.participantContactInformations.splice(index, 1);
			},
			removeEmergencyContactObject(index: number) {
				this.eligibility.participantEmergencyContactInformations.splice(index, 1);
			},
			removeCriminalHistory(index: number) {
				this.eligibility.participantCriminalHistories.splice(index, 1);
			},
			removeAssessment(index: number) {
				this.eligibility.participantAssessments.splice(index, 1);
			},
			removeDiagnosis(index: number) {
				this.eligibility.participantDiagnoses.splice(index, 1);
			},
			removeTreatment(index: number) {
				this.eligibility.participantTreatments.splice(index, 1);
			},
			removeAddress(index: number, isEmergency: boolean) {
				if (isEmergency) {
					this.eligibility.participantEmergencyContactInformations[index].associatedAddress = null;
					this.eligibility.participantEmergencyContactInformations[index].addressId = null;
				}
				else {
					this.eligibility.participantContactInformations[index].associatedAddress = null;
					this.eligibility.participantContactInformations[index].addressId = null;
				}
			},
			removePhone(index: number, isEmergency: boolean) {
				if (isEmergency) {
					this.eligibility.participantEmergencyContactInformations[index].associatedPhone = null;
					this.eligibility.participantEmergencyContactInformations[index].phoneId = null;
				}
				else {
					this.eligibility.participantContactInformations[index].associatedPhone = null;
					this.eligibility.participantContactInformations[index].phoneId = null;
				}
			},
			removeEmail(index: number, isEmergency: boolean) {
				if (isEmergency) {
					this.eligibility.participantEmergencyContactInformations[index].associatedEmail = null;
					this.eligibility.participantEmergencyContactInformations[index].emailId = null;
				}
				else {
					this.eligibility.participantContactInformations[index].associatedEmail = null;
					this.eligibility.participantContactInformations[index].emailId = null;
				}
			},
			canAddAddress(index: number, isEmergency: boolean): boolean {
				const item = isEmergency ? this.eligibility.participantEmergencyContactInformations[index].associatedAddress : this.eligibility.participantContactInformations[index].associatedAddress;
				return this.canEnterData && item?.id == undefined;
			},
			canAddEmail(index: number, isEmergency: boolean): boolean {
				const item = isEmergency ? this.eligibility.participantEmergencyContactInformations[index].associatedEmail : this.eligibility.participantContactInformations[index].associatedEmail;
				return this.canEnterData && item?.id == undefined;
			},
			canAddPhone(index: number, isEmergency: boolean): boolean {
				const item = isEmergency ? this.eligibility.participantEmergencyContactInformations[index].associatedPhone : this.eligibility.participantContactInformations[index].associatedPhone;
				return this.canEnterData && item?.id == undefined;
			},
			returnToList(): void {
				this.$emit('return-to-list');
			},
			filterOnlyNumbers(event: KeyboardEvent) {
				FormatHelper.filterOnlyNumbers(event);
			},
			saveAddress(item: Address) {
				if (this.isEditingEmergencyContact) {
					this.eligibility.participantEmergencyContactInformations[this.selectedIndex].associatedAddress = item as IAddress;
				}
				else {
					this.eligibility.participantContactInformations[this.selectedIndex].associatedAddress = item as IAddress;
				}
				this.showAddressDialog = false;
			},
			saveEmail(item: IEmail) {
				if (this.isEditingEmergencyContact) {
					this.eligibility.participantEmergencyContactInformations[this.selectedIndex].associatedEmail = item;
				}
				else {
					this.eligibility.participantContactInformations[this.selectedIndex].associatedEmail = item;
				}
				this.showEmailDialog = false;
			},
			savePhone(item: IPhone) {
                item.phoneNumber = item.phoneNumber.replace(/\D+/g, "");
				if (this.isEditingEmergencyContact) {
					this.eligibility.participantEmergencyContactInformations[this.selectedIndex].associatedPhone = item;
				}
				else {
					this.eligibility.participantContactInformations[this.selectedIndex].associatedPhone = item;
				}
				this.showPhoneDialog = false;
			},
			assessmentIsValid(item: IParticipantAssessment): boolean {
				return !!(item.riskLevelId || item.needLevelId || item.dateTaken || item.score || item.assessmentTypeId); 
                //return (item.riskLevelId ?? 0) > 0 && (item.needLevelId || 0) > 0 && item.dateTaken != undefined && item.score != undefined && item.assessmentTypeId != undefined && item.assessmentTypeId > 0;
			},
			historyIsValid(item: IParticipantCriminalHistory): boolean {
                return !!(item.caseNumber || item.arrestDate || item.chargeTypeId || item.arrestHistoryId || item.drugOrAlcoholRelatedId || item.adjudicationTypeId);
				//return item.caseNumber != undefined && item.caseNumber !== "" && item.arrestDate != undefined && item.chargeTypeId != undefined && item.chargeTypeId > 0 && item.arrestHistoryId != undefined && item.arrestHistoryId > 0 && item.drugOrAlcoholRelatedId != undefined && item.drugOrAlcoholRelatedId > 0;
			},
			diagnosisIsValid(item: IParticipantDiagnosis): boolean {
				return (item.diagnosisId ?? 0) > 0;
			},
			treatmentIsValid(item: IParticipantTreatment): boolean {
				return (item.treatmentTypeId ?? 0) > 0;
			},
			contactInfoIsValid(item: IParticipantContactInformation): boolean {
				return (!!item.associatedAddress && Object.values(item.associatedAddress).length > 0) || (!!item.associatedEmail && Object.values(item.associatedEmail).length > 0) || (!!item.associatedPhone && Object.values(item.associatedPhone).length > 0);
			},
			emergencyContactIsValid(item: IParticipantEmergencyContactInformation): boolean {
				return (!!item.associatedAddress && Object.values(item.associatedAddress).length > 0) || (!!item.associatedEmail && Object.values(item.associatedEmail).length > 0) || (!!item.associatedPhone && Object.values(item.associatedPhone).length > 0);
			},
			prepareForSave(isIncompleteSubmission: boolean) {
				//check for valid items
				this.eligibility.participantAssessments = this.eligibility.participantAssessments.filter(p => this.assessmentIsValid(p));
				this.eligibility.participantCriminalHistories = this.eligibility.participantCriminalHistories.filter(p => this.historyIsValid(p));
				this.eligibility.participantDiagnoses = this.eligibility.participantDiagnoses.filter(p => this.diagnosisIsValid(p));
				this.eligibility.participantTreatments = this.eligibility.participantTreatments.filter(p => this.treatmentIsValid(p));
				this.eligibility.participantContactInformations = this.eligibility.participantContactInformations.filter(p => this.contactInfoIsValid(p));
				this.eligibility.participantEmergencyContactInformations = this.eligibility.participantEmergencyContactInformations.filter(p => this.emergencyContactIsValid(p));

				//prep races
				this.eligibility.participantRaces = [];
				this.raceSelections.forEach((l: number | null) => this.eligibility.participantRaces.push({ participantRaceId: 0, eligibilityId: this.eligibility.eligibilityId, raceId: l, isSubmissionIncomplete: isIncompleteSubmission } as IParticipantRace));

				//update incomplete submission status
				this.eligibility.participantDemographic.isSubmissionIncomplete = isIncompleteSubmission;
				this.eligibility.participantCriminalStatus.isSubmissionIncomplete = isIncompleteSubmission;
				this.eligibility.participantAssessments.forEach((e: IParticipantAssessment) => e.isSubmissionIncomplete = isIncompleteSubmission);
				this.eligibility.participantContactInformations.forEach((e: IParticipantContactInformation) => e.isSubmissionIncomplete = isIncompleteSubmission);
				this.eligibility.participantEmergencyContactInformations.forEach((e: IParticipantEmergencyContactInformation) => e.isSubmissionIncomplete = isIncompleteSubmission);
				this.eligibility.participantDiagnoses.forEach((e: IParticipantDiagnosis) => e.isSubmissionIncomplete = isIncompleteSubmission);
				this.eligibility.participantCriminalHistories.forEach((e: IParticipantCriminalHistory) => e.isSubmissionIncomplete = isIncompleteSubmission);
				this.eligibility.participantTreatments.forEach((e: IParticipantTreatment) => e.isSubmissionIncomplete = isIncompleteSubmission);

                //dates
                this.eligibility.targetEndDate = DateHelper.standardizeDate(this.eligibility.targetEndDateForEdit);
                this.eligibility.startDate = DateHelper.standardizeDate(this.eligibility.startDateForEdit);
                this.eligibility.endDate = DateHelper.standardizeDate(this.eligibility.endDateForEdit);
			},
			submitSave(isIncompleteSubmission: boolean): void {
				this.isSaving = true;
				this.prepareForSave(isIncompleteSubmission);
				EligibilityService.saveWithChildren(this.eligibility).then(result => {
					this.validationResults = result.messages;
					if (result.isValid && this.validationResults.length == 0) {
						this.successNotification = true;
						if (!isIncompleteSubmission) {
							setTimeout(() => this.$router.push({ path: "/" }), 1500);
						}
					}
					this.isSaving = false;
				});
			},
			saveWorkflow(result: ITypedValidationResult<IEligibility>): void {
				this.validationResults = result.messages;
				if (result.isValid && this.eligibility.currentWorkflowStatusId == WorkflowStatus.PendingInitialEntry) {
					this.submitSave(false);
				}
				else if (result.isValid) {
					this.successNotification = true;
					setTimeout(() => this.$router.push({ path: "/" }), 1500);
				}
			},
            getAbbreviatedText(text: string): string {
                if (text.includes("Citation:")) return text
                else return text?.split(':')[0] ?? ""
            }
		},
		computed: {
			componentType(): ComponentType {
				return ComponentType.ParticipantEligibility
			},
			canEditRolePermission() : boolean {
				return doesUserRoleHaveComponentEditPermissions(this.currentUser?.attachedRoles ?? [], this.componentType)
			},
			saveButtonText(): string {
				return this.isSaving ? "Saving..." : "Save";
			},
			canDelete(): boolean {
                return this.canEnterData;
				//return this.eligibility.participantWorkflowStatusHistory.find(e => e.workflowStatusId === WorkflowStatus.TeamReview) == undefined;
			},
			canAddCriminalHistory(): boolean {
				return this.canEnterData && this.eligibility.participantCriminalHistories.filter(x =>
					!this.historyIsValid(x)
				).length == 0;
			},
			canAddAssessment(): boolean {
				return this.canEnterData && this.eligibility.participantAssessments.filter(x =>
					!this.assessmentIsValid(x)
				).length == 0;
			},
			canAddDiagnosis(): boolean {
				return this.canEnterData && this.eligibility.participantDiagnoses.filter(x => !x.diagnosisId).length == 0;
			},
			canAddTreatment(): boolean {
				return this.canEnterData && this.eligibility.participantTreatments.filter(x => !x.treatmentTypeId).length == 0;
			},
			dialogText(): string {
				let result = this.isEditingEmergencyContact ? " Emergency " : " ";
				if (this.showAddressDialog) {
					result = (this.selectedAddress != null && this.selectedAddress.id > 0 ? "Edit" : "Add") + result + "Address";
				}
				if (this.showEmailDialog) {
					result = (this.selectedEmail != null && this.selectedEmail.id > 0 ? "Edit" : "Add") + result + "Email";
				}
				if (this.showPhoneDialog) {
					result = (this.selectedPhone != null && this.selectedPhone.id > 0 ? "Edit" : "Add") + result + "Phone";
				}
				return result + " Information";
			},
		},
	});
