import { IValidationResult, ITypedValidationResult } from '@/Models/IValidationResult';
import { BaseApiService } from './base-api-service';
import { IWorkflowStatus } from '@/Models/Workflow/IWorkflowStatus';
import { IWorkflowInstanceStatus } from '@/Models/Workflow/IWorkflowInstanceStatus';
import { IWorkflowSubmit } from '@/Models/IWorkflowSubmit';
import { AlternateWorkflowType } from '@/Enums/AlternateWorkflowType';

class workflowService extends BaseApiService{
    
    public constructor() {
        super('workflow/');
    }

    public async listNextWorkflowStatuses(workflowInstanceId: number): Promise<IWorkflowStatus[]> {
        return this.httpClient.get<IWorkflowStatus[]>(`list-next-workflow-statuses/${workflowInstanceId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

	public getStatusHistory(workflowInstanceId: number): Promise<IWorkflowInstanceStatus[]> {
		return this.httpClient.get<IWorkflowInstanceStatus[]>(`status-history/${workflowInstanceId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

	public getByCaseId(caseId: number): Promise<IWorkflowInstanceStatus[]> {
		return this.httpClient.get<IWorkflowInstanceStatus[]>(`case/${caseId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

	public async submit(workflowSubmit: IWorkflowSubmit): Promise<IValidationResult> {
        return this.httpClient.post<IValidationResult>(`submit`, workflowSubmit).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
	public async alternateSubmit<Type>(workflowSubmit: IWorkflowSubmit, type: AlternateWorkflowType, caseId: number, parentId: number): Promise<ITypedValidationResult<Type>> {
		return this.httpClient.post<ITypedValidationResult<Type>>(`alternate-submit/${type}/${caseId}/${parentId}`, workflowSubmit).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}
}

export const WorkflowService : workflowService = new workflowService();