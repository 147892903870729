
import { defineComponent } from 'vue';

export default defineComponent({
	name: "confirm-dialog",
	emits: ["confirm", "cancel"],
	props: {
		titleText: String,
		dialogText: String,
		dialogIsOpen: Boolean,
	},
	data() {
		return {
			isOpen: false,
		}
	},
	methods: {
		cancel(): void {
			this.$emit("cancel");
		},
		confirm(): void {
			this.$emit("confirm");
		},
	},
	watch: {
		dialogIsOpen(newVal: boolean) {
			this.isOpen = newVal;
		}
	}
});
