export interface IBaseDto {
    id: number;
	createUserId: number;
	createDate: Date;
    modifyUserId: number;
	modifyDate: Date;
	isNew: boolean;
}

export class BaseDto implements IBaseDto {
	id: number;
	createUserId: number;
	createDate: Date;
	modifyUserId: number;
	modifyDate: Date;
	isNew: boolean;

	constructor(src: IBaseDto) {
		this.id = src.id;
		this.createUserId = src.createUserId;
		this.createDate = src.createDate;
		this.modifyUserId = src.modifyUserId;
		this.modifyDate = src.modifyDate;
		this.isNew = src.isNew;
	}
}
