import { createApp } from 'vue';
//The http client we are using to make API calls, axios
import { provideAxios } from './Services/HttpClient/axios-plugin';
// Vuetify
import 'vuetify/styles';
import { ThemeDefinition, createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
//Still experimental, expect changes. Far superior in every way to v-table, which sucks.
import { VDataTable } from 'vuetify/labs/VDataTable';
import * as directives from 'vuetify/directives';
import {aliases, mdi} from 'vuetify/iconsets/mdi';
import "@mdi/font/css/materialdesignicons.css";
//Router and associated auth protection
import router from "./Router/router";
import Shell from "./Shell.vue";

//The supported state manager for this project
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

//Adds global functions
import CommonFunctions from '@/Services/Helper/common-functions';

const cstColorTheme: ThemeDefinition = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#0B0B45',
    secondary: '#93aabd',
    error: '#C51162',
    info: '#6200EE',
    success: '#09AF00',
    warning: '#EEFF41',
	loading: '#6200EE',
  }
}

const CommonFunctionOptions = {
	dateFormat: "MM/dd/yyyy",
	timestampFormat: "MM/dd/yyyy h:mm tt z",
};

const vuetify = createVuetify({
  components: {
    ...components,
    VDataTable
  },
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi
    }
   },
   theme: {
    defaultTheme: 'cstColorTheme',
    themes: {
      cstColorTheme
    }
   }
});

const app = createApp(Shell);
const piniaStateManager = createPinia();
piniaStateManager.use(piniaPluginPersistedstate);

app.use(vuetify).use(router).use(provideAxios()).use(piniaStateManager).use(CommonFunctions, CommonFunctionOptions).mount('#app');

