import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    "onClick:outside": _ctx.closeDialog,
    height: "auto",
    "max-width": "800",
    "scroll-strategy": "none"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { style: {"overflow":"hidden"} }, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, {
            color: "secondary",
            elevation: 8
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_toolbar_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.headerText), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                icon: "mdi-close-outline",
                density: "comfortable",
                variant: "plain",
                onClick: _ctx.closeDialog
              }, null, 8, ["onClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_card_text, { class: "mt-6" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_form, {
                ref: "form",
                "validate-on": "submit"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      (_ctx.displayContactType)
                        ? (_openBlock(), _createBlock(_component_v_col, { key: 0 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_select, {
                                items: _ctx.contactTypes,
                                "item-value": "id",
                                "item-title": "description",
                                label: "Contact Type",
                                modelValue: _ctx.phone.contactTypeId,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phone.contactTypeId) = $event))
                              }, null, 8, ["items", "modelValue"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_v_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_switch, {
                            modelValue: _ctx.phone.isActive,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.phone.isActive) = $event)),
                            label: "Active",
                            color: "secondary",
                            inset: ""
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            type: "phone",
                            modelValue: _ctx.phone.phoneNumber,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.phone.phoneNumber) = $event)),
                            label: "Phone Number",
                            required: "",
                            rules: _ctx.rules
                          }, null, 8, ["modelValue", "rules"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 512),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { align: "center" }, {
                    default: _withCtx(() => [
                      _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                        class: "mx-2 mb-4",
                        color: "success",
                        onClick: _ctx.save,
                        disabled: _ctx.isSaving
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick", "disabled"])), [
                        [_directive_ripple]
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onClick:outside"]))
}