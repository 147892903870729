
import { defineComponent, Ref, ref } from 'vue';

import { doesUserRoleHaveComponentEditPermissions, doesUserRoleHaveComponentViewPermissions } from '@/Services/Helper/component-permissions-helper'
import { DateHelper } from '@/Services/Helper/date-helper';
import { LookupService } from '@/Services/lookup-service';
import { OutcomeEventService, OutcomeService } from '@/Services/outcome-service';
import { loadParticipantDataRequireCase } from '@/Services/Helper/loader-helper';

import { ComponentType } from '@/Enums/ComponentType'
import { IInternalUser } from '@/Models/IInternalUser';
import { useUserStore } from '@/Services/Store/user-store';
import { IOutcome, IOutcomeEvent } from '@/Models/IOutcomes';
import { LookupKeys } from '@/Enums/LookupKeys';
import { MasterLookupWrapper } from '@/Models/Lookup/MasterLookupWrapper';
import HeaderData from '@/Models/HeaderData';

import LoadingSpinner from '@/ChildrenComponents/LoadingSpinner.vue';
import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';
import PageHeader from '@/ChildrenComponents/Participant/Common/PageHeader.vue';
import ParticipantHeader from '@/ChildrenComponents/Participant/Common/ParticipantHeader.vue';
import SingleErrorMessage from '@/ChildrenComponents/Participant/Common/SingleErrorMessage.vue';
import Outcome from '@/ChildrenComponents/Outcomes/Outcome.vue';
import OutcomeTable from '@/ChildrenComponents/Outcomes/OutcomeTable.vue';
import OutcomeEventTable from '@/ChildrenComponents/Outcomes/OutcomeEventTable.vue';
import IncorrectPermissions from '@/ChildrenComponents/IncorrectPermissions.vue';

export default defineComponent({
    name: "outcome-summary",
    components: { 
        ParticipantHeader, 
		LoadingSpinner, 
		ValidationAlert, 
		PageHeader, 
		SingleErrorMessage, 
        Outcome, 
		OutcomeTable, 
		OutcomeEventTable,
		IncorrectPermissions,
    },
    setup(): { form: Ref, currentUser: IInternalUser | null } {
        const form = ref(null);
        const userStoreInstance = useUserStore();
        return { form, currentUser: userStoreInstance.$state.cstInternalUser };
    },
    created() {
        this.loadData();
    },
    data() {
        return {
            isLoading: false,
            isSaving: false,
            routeId: parseInt(this.$route.params.id.toString()) as number,
            isCaseRoute: this.$route.path.includes("/case/") as boolean,

            headerData: null as HeaderData | null,
            lookups: {} as MasterLookupWrapper,
            outcomes: [] as IOutcome[],
			
			openPanels: [0, 2],
            editingOutcomeId: undefined as number | undefined,
            outcomeEvents: [] as IOutcomeEvent[],

			errorMessage: "",
            validationMessages: [] as string[],
        }
    },
    methods: {
        loadData() {
            this.isLoading = true;
            const lookupKeys = [
                LookupKeys.OutcomeEventType,
                LookupKeys.OutcomeType,
            ];

            this.lookups = {} as MasterLookupWrapper;
            this.outcomes = [] as IOutcome[];
            this.outcomeEvents = [] as IOutcomeEvent[];

            Promise.all([
                    LookupService.getLookupsByKey(lookupKeys), 
                    this.isCaseRoute ? OutcomeService.getOutcomesByCaseId(this.routeId) : OutcomeService.getOutcomesByPhaseId(this.routeId),
                    this.isCaseRoute ? OutcomeEventService.getAllEventsByCaseId(this.routeId) : OutcomeEventService.getAllEventsByPhaseId(this.routeId),
                ])
                .then(([lookups, outcomes, events]: [MasterLookupWrapper, IOutcome[], IOutcomeEvent[]]) => {
                    this.lookups = lookups;
                    this.outcomes = outcomes;
                    this.outcomeEvents = events;

                    loadParticipantDataRequireCase("case", this.routeId).then(data => {
                        this.headerData = new HeaderData(data.participant, data.case?.program ?? null);
                    });
                }).then(() => {
                    this.isLoading = false;
                })
        },
        formatDate(date: Date): string {
            return DateHelper.formatDateUtc(date, "MM/dd/yyyy");
        },
        editOutcome(oid: number) {
            const editingOutcome = this.outcomes.find((e) => e.outcomeId == oid);
            this.editingOutcomeId = editingOutcome?.outcomeId || undefined;

            if (this.openPanels.indexOf(1) == -1) {
                this.openPanels.push(1);
            }
        },
        afterOutcomeSave() {
            // firing the save to the service is handled by the Outcome component.
            // this method is responsible for updating the state of the summary component
            // after a save completes
            this.openPanels.splice(this.openPanels.indexOf(1), 1);
            this.editingOutcomeId = undefined;
            this.loadData();
        },
    },
    computed: {
        componentType(): ComponentType {
            return ComponentType.OutcomeSummary;
        },
        canEditRolePermission(): boolean {
            return doesUserRoleHaveComponentEditPermissions(this.currentUser?.attachedRoles ?? [], this.componentType)
        },
		canViewRolePermission(): boolean {
			return doesUserRoleHaveComponentViewPermissions(this.currentUser?.attachedRoles ?? [], this.componentType);
		},
        buttonText(): string {
            return this.isSaving ? "Saving..." : "Save";
        },
        outcomeEditPanelTitle(): string {
            return this.editingOutcomeId !== undefined ? "Editable Outcome" : "Select an Outcome in the Table Above to Edit"
        },
    },
});
