export enum PhaseSection {
	CaseManagement = 1,
	CourtAttendance = 2,
	DrugTest = 3,
	ElectronicMonitoring = 4,
	Employment = 5,
	Goal = 6,
	BehaviorResponse = 7,
	School = 8,
	Supervision = 9,
	Treatment = 10,
	CustodyResidential = 11,
    AncillaryMeeting = 12
}