
    // The document generator is a lightweight component which receives variable infill objects
    // and allows for the selection of headers and bodies before generating a preview and then
    // the document afterwards
    import {
        defineComponent,
        PropType
    } from 'vue';

    import { useUserStore } from '@/Services/Store/user-store';
    import { TemplateService } from '@/Services/Templating/template-service';
    import { TemplateEngine } from '@/Services/Templating/template-engine';

    import { IVariableInfill } from '@/Models/Templating/IVariableInfill';
    import { IInternalUser } from '@/Models/IInternalUser';
    import { ITemplate } from '@/Models/Templating/ITemplate';
    
    import SuccessAlert from '../SuccessAlert.vue';
    import SingleErrorMessage from '../Participant/Common/SingleErrorMessage.vue';
    import ModalEditor from './ModalEditor.vue';
    import LoadingSpinner from '../LoadingSpinner.vue';

    export default defineComponent({
        name: "document-generator",
        emits: [
            "newDocumentGenerated", // emmitted after a document successfully generates
            "didTemplateRefresh", // emitted after the loadData method finishes
        ],
        components: {
            SuccessAlert,
            SingleErrorMessage,
            ModalEditor,
            LoadingSpinner,
        },
        props: {
            // this is the object which is used to generate the document.
            infillProp: {
                type: Object as PropType<IVariableInfill>,
                required: true,
            },
            documentParentId: {
                type: Number,
                required: true,
            },
            documentParentTypeId: {
                type: Number,
                required: true,
            },
            treatmentProgramId: {
                type: Number,
                required: true,
            },
            // this is a watched prop which can be set to true to trigger the component's loadData method
            // it's used to synchronize data with the bulk editors as they change the contents of a template
            refreshProp: {
                type: Boolean,
                required: false,
                default: false,
                writable: true,
            },
            showGenerateButton: {
                type: Boolean,
                required: false,
                default: false,
            }
        },
        setup(): { currentUser: IInternalUser | null } {
            const userStoreInstance = useUserStore();
            const currentUser = userStoreInstance.$state.cstInternalUser;
            return { currentUser };
        },
        created() {
            this.loadData();
        },
        data() {
            return {
                // status
                errorMessage: "" as string,
                successMessages: [] as string[],

                // state
                isLoading: false as boolean,
                isShowingEditingModal: false as boolean,

                // data
                letterTemplate: {} as ITemplate | null,

                availableHeaderTemplates: [] as ITemplate[],
                availableBodyTemplates: [] as ITemplate[],
                selectedHeaderTemplate: {} as ITemplate,
                selectedBodyTemplate: {} as ITemplate,

                downloadedFilename: null as string | null,

                // perms
                canGenerate: false as boolean,
                isGenerating: false as boolean,
            }
        },
        methods: {
            changeTemplateSelection() {
                this.canGenerate = false;
            },
            doPreview() {
                const filledTemplate = TemplateEngine.expandHeaderBodyPair(
                    this.selectedHeaderTemplate,
                    this.selectedBodyTemplate,
                    this.infillProp,
                );

                this.letterTemplate = {
                    ...this.selectedBodyTemplate,
                    ...filledTemplate
                };

                this.isShowingEditingModal = true;
            },
            doGenerate() {
                this.isGenerating = true;
                TemplateService.generatePdf(this.letterTemplate ?? {} as ITemplate)
                    .then((res) => TemplateEngine.generatePdfResponseHandler(
                        this.documentParentId, this.documentParentTypeId, res, (this.downloadedFilename == "" || this.downloadedFilename == null) 
                                ? "Download.pdf" 
                                : this.downloadedFilename + ".pdf"
                    ))
                    .then(() => {
                        this.successMessages = ["Document successfully generated and attached to case."]
                        this.$emit("newDocumentGenerated");
                    })
                    .catch(async (err) => {
                        const errMessage = await err.response.data.text();
                        this.errorMessage = (errMessage && errMessage != "") ? errMessage : err.toString();
                    })
                    .finally(() => {
                        this.isGenerating = false
                    });
            },
            hideEditingModal() {
                this.letterTemplate = null;
                this.isShowingEditingModal = false;
            },
            loadData() {
                this.isLoading = true;
                this.resetDefaults();

                Promise.all([
                    TemplateService.getByProgramId(this.treatmentProgramId),
                ])
                .then((
                    [templates]: [ITemplate[]]
                ) => {
                    this.availableHeaderTemplates = templates.filter((t: ITemplate) => t.templateTypeId === 1);
                    this.availableBodyTemplates = templates.filter((t: ITemplate) => t.templateTypeId === 2);
                    
                    // handle states where one or both of the types of templates don't have any defined
                    // we need to still ensure the state reaches a generate-able state, since having one
                    // template type but not both should still allow for generation, but missing both
                    // types shouldn't allow for generation (there's nothing to generate)
                    if (this.availableHeaderTemplates.length < 1 && this.availableBodyTemplates.length < 1) {
                        this.errorMessage = "No templates were found for this program."
                    } else if (this.availableHeaderTemplates.length < 1) {
                        this.errorMessage = "No letterhead templates were found for this program."
                        this.selectedBodyTemplate = this.availableBodyTemplates[0];
                    } else if (this.availableBodyTemplates.length < 1) {
                        this.errorMessage = "No letter body templates were found for this program."
                        this.selectedHeaderTemplate = this.availableHeaderTemplates[0];
                    } else {
                        this.selectedHeaderTemplate = this.availableHeaderTemplates[0];
                        this.selectedBodyTemplate = this.availableBodyTemplates[0];
                    }
                })
                .catch(async (err) => {
                    const errMessage = await err.response.data.text();
                    this.errorMessage = (errMessage && errMessage != "") ? errMessage : err.toString();
                })
                .finally(() => {
                    this.$emit("didTemplateRefresh");
                    this.isLoading = false;
                });
            },
            onModalSave(template: ITemplate) {
                this.letterTemplate = template;
                this.canGenerate = true;
                this.isShowingEditingModal = false;
                this.doGenerate();
            },
            resetDefaults() {
                this.errorMessage = "",
                this.successMessages = [] as string[],
                this.letterTemplate = {} as ITemplate | null;
                this.availableHeaderTemplates = [] as ITemplate[];
                this.availableBodyTemplates = [] as ITemplate[];
                this.selectedHeaderTemplate = {} as ITemplate;
                this.selectedBodyTemplate = {} as ITemplate;
            }
        },
        computed: {
            downloadTooltip() {
                if (this.canGenerate) {
                    return "Generate a PDF with the last previewed content."
                } else {
                    return "You must preview the document before it can be generated and downloaded."
                }
            },
            downloadButtonText() {
                if (this.isGenerating) {
                    return "..."
                } else {
                    return "Download"
                }
            },
        },
        watch: {
            refreshProp(newVal, oldVal) {
                if (oldVal === false && newVal === true) {
                    this.loadData();
                }
            }
        }
    })
