
import type { ITreatmentProgram } from '@/Models/ITreatmentProgram';
import { BaseApiService } from './base-api-service';

class treatmentProgramService extends BaseApiService{
    
    public constructor() {
        super('treatmentprogram/');
    }

    public async getAllTreatmentPrograms(): Promise<Array<ITreatmentProgram>> {
        return this.httpClient.get<ITreatmentProgram[]>(`get-all-programs`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public async getTreatmentProgramsByCountyIdAndCourtId(countyId: number, courtId: number): Promise<Array<ITreatmentProgram>> {
        return this.httpClient.get<ITreatmentProgram[]>(`get-all-programs-by-county-and-court/${countyId}/${courtId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public async getTreatmentProgramsByCountyIdsAndCourtIds(countyIds: number[], courtIds: number[]): Promise<Array<ITreatmentProgram>> {
        return this.httpClient.get<ITreatmentProgram[]>(`get-all-programs-by-counties-and-courts/${countyIds.toString()}/${courtIds.toString()}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public async getTreatmentProgramsByTreatmentProgramIds(ids: number[]): Promise<Array<ITreatmentProgram>> {
        return this.httpClient.get<ITreatmentProgram[]>(`get-programs-by-ids/${ids.toString()}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
}

export const TreatmentProgramService : treatmentProgramService = new treatmentProgramService();