import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_ValidationAlert = _resolveComponent("ValidationAlert")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    "onClick:outside": _ctx.closeDialog,
    height: "auto",
    "max-width": "800",
    "scroll-strategy": "none",
    scrollable: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { style: {"overflow":"hidden"} }, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, {
            color: "secondary",
            elevation: 8
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_toolbar_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Electronic Monitoring")
                ]),
                _: 1
              }),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                icon: "mdi-close-outline",
                density: "comfortable",
                variant: "plain",
                onClick: _ctx.closeDialog
              }, null, 8, ["onClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_card_text, { class: "mt-6" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_form, { ref: "form" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { md: "6" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_select, {
                            items: _ctx.typeList,
                            "item-value": "id",
                            "item-title": "description",
                            label: "Select Type",
                            modelValue: _ctx.dto.monitoringTypeId,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dto.monitoringTypeId) = $event)),
                            required: "",
                            rules: _ctx.requiredFieldRules
                          }, null, 8, ["items", "modelValue", "rules"]),
                          _createVNode(_component_v_select, {
                            items: _ctx.statusList,
                            "item-value": "id",
                            "item-title": "description",
                            label: "Select Status",
                            modelValue: _ctx.dto.monitoringStatusId,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dto.monitoringStatusId) = $event)),
                            required: "",
                            rules: _ctx.requiredFieldRules
                          }, null, 8, ["items", "modelValue", "rules"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { md: "6" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            type: "date",
                            label: "Start Date",
                            modelValue: _ctx.dto.startDateForEdit,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dto.startDateForEdit) = $event)),
                            required: "",
                            rules: _ctx.requiredFieldRules
                          }, null, 8, ["modelValue", "rules"]),
                          _createVNode(_component_v_text_field, {
                            type: "date",
                            label: "End Date",
                            modelValue: _ctx.dto.endDateForEdit,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dto.endDateForEdit) = $event)),
                            rules: _ctx.endDateRules
                          }, null, 8, ["modelValue", "rules"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { md: "12" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_textarea, {
                            modelValue: _ctx.dto.notes,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dto.notes) = $event)),
                            label: "Notes"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 512),
              _createVNode(_component_ValidationAlert, { validationResults: _ctx.errors }, null, 8, ["validationResults"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { align: "center" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        class: "mx-2 mb-4",
                        color: "success",
                        variant: "elevated",
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.save(false))),
                        disabled: _ctx.isSaving
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.buttonText1), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled"]),
                      _createVNode(_component_v_btn, {
                        class: "mx-2 mb-4",
                        color: "success",
                        variant: "elevated",
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.save(true))),
                        disabled: _ctx.isSaving
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.buttonText2), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onClick:outside"]))
}