
	import { defineComponent, ref, Ref } from 'vue';

	import LoadingSpinner from '@/ChildrenComponents/LoadingSpinner.vue';
	import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';
	import WorkflowHistory from '@/ChildrenComponents/WorkflowHistory.vue';
	import WorkflowSubmission from '@/ChildrenComponents/WorkflowSubmission.vue';
	import PageHeader from '@/ChildrenComponents/Participant/Common/PageHeader.vue';
	import ParticipantHeader from '@/ChildrenComponents/Participant/Common/ParticipantHeader.vue';
	import SingleErrorMessage from '@/ChildrenComponents/Participant/Common/SingleErrorMessage.vue';

	import { ComponentType } from '@/Enums/ComponentType'
	import { LookupEnum } from '@/Enums/LookupEnum';
	import { LookupKeys } from "@/Enums/LookupKeys";
	import { MenuItemTypes } from "@/Enums/MenuItemTypes";

	import { IInternalUser } from '@/Models/IInternalUser';
	import { IDrugTest, IDrugTestForEdit } from '@/Models/IDrugTest';
	import { IDrugTestDrug } from '@/Models/IDrugTestDrug';
	import HeaderData from '@/Models/HeaderData';
	import { IBaseLookup } from '@/Models/Lookup/BaseLookup';
	import { IDrugTestType } from '@/Models/Lookup/IDrugTestType';
	import { IDataTableHeader } from '@/Models/IDataTableHeader';

	import { doesUserRoleHaveComponentEditPermissions } from '@/Services/Helper/component-permissions-helper'
	import { DrugTestService } from '@/Services/drug-test-service';
	import { DrugTestDrugService } from '@/Services/drug-test-drug-service';
	import { LookupService } from '@/Services/lookup-service';
	import { DateHelper } from '@/Services/Helper/date-helper';
	import { loadParticipantDataRequirePhase } from '@/Services/Helper/loader-helper';
	import { ValidationHelper } from '@/Services/Helper/validation-helper';
    import { UtilityHelper } from '@/Services/Helper/utility-helper';

	import { useUserStore } from '@/Services/Store/user-store';
    import { WorkflowStatus } from '@/Enums/WorkflowStatus';
    import { OtherSubstanceList } from '@/Enums/Substances';
import { IPhase } from '@/Models/IPhase';

	export default defineComponent({
		name: "drug-test",
		components: { LoadingSpinner, ParticipantHeader, ValidationAlert, PageHeader, SingleErrorMessage, WorkflowHistory, WorkflowSubmission },
		setup(): { form: Ref, currentUser: IInternalUser | null } {
			const form = ref(null);
			const userStoreInstance = useUserStore();
			return { form, currentUser: userStoreInstance.$state.cstInternalUser };
		},
		created() {
			if (this.drugTestId) {
				Promise.all([this.loadTest(), this.loadLookups()]).then(() => this.loadHeader())
					.catch(error => this.errorMessage = error).finally(() => this.isLoading = false);
			}
			else if (this.phaseId) {
				Promise.all([this.loadHeader(), this.loadLookups()]).then(() => this.setBlankTest())
					.catch(error => this.errorMessage = error).finally(() => this.isLoading = false);
			}
			else {
				this.errorMessage = "Unable to load test data.";
			}
		},
		data() {
			return {
				isLoading: true,
				isSaving: false,
				errorMessage: "",
				validationMessages: [] as string[],
				drugTestId: !this.$route.params.drugTestId ? null : parseInt(this.$route.params.drugTestId.toString()),
				phaseId: !this.$route.query.phaseId ? null : parseInt(this.$route.query.phaseId.toString()),
				headerData: null as HeaderData | null,
				requiredRules: ValidationHelper.requiredRules,

				sessionTypes: [] as IBaseLookup[],
				methods: [] as IBaseLookup[],
				types: [] as IBaseLookup[],
				locations: [] as IBaseLookup[],
				results: [] as IBaseLookup[],
				drugTypes: [] as IBaseLookup[],
				substances: [] as IBaseLookup[],

				openPanels: [0, 1, 2],
				drugTest: {} as IDrugTestForEdit,
				drugTestDrug: {} as IDrugTestDrug,
				drugTestDrugList: [] as IDrugTestDrug[],
				showSuccess: false,
				successMessageText: "",
				selectedTypeIds: [] as number[],

				drugTableHeaders: [
					{ title: "Drug/Substance Type", key: "drugTypeId", sortable: true, align: "start" },
					{ title: "Drug/Substance", key: "substanceId", sortable: true, align: "start" },
					{ title: "Actions", key: "actions", sortable: false },
				] as IDataTableHeader[],
                otherSubstanceList: OtherSubstanceList,
                workflowStatusEnum: WorkflowStatus,
                phase: {} as IPhase | null
			}
		},
		methods: {
			loadLookups(): Promise<void> {
				return new Promise((resolve, reject) => {
					const lookups = [
						LookupKeys.DrugTestMethod,
						LookupKeys.DrugTestLocation,
						LookupKeys.DrugTestSessionType,
						LookupKeys.DrugTestType,
						LookupKeys.DrugTestResult,
						LookupKeys.DrugType,
						LookupKeys.Substance
					];
					LookupService.getLookupsByKey(lookups).then(result => {
						this.sessionTypes = result.lookupLists[LookupKeys.DrugTestSessionType] ?? [];
						this.methods = result.lookupLists[LookupKeys.DrugTestMethod] ?? [];
						this.types = result.lookupLists[LookupKeys.DrugTestType] ?? [];
						this.locations = result.lookupLists[LookupKeys.DrugTestLocation] ?? [];
						this.results = result.lookupLists[LookupKeys.DrugTestResult] ?? [];
						this.drugTypes = result.lookupLists[LookupKeys.DrugType] ?? [];
						this.substances = result.lookupLists[LookupKeys.Substance] ?? [];

                        //sort lookups
                        UtilityHelper.arraySortByProperty(this.types, 'description');
                        
					}).then(() => resolve()).catch(error => reject(error));
				});
			},
			loadHeader(): Promise<void> {
				return new Promise((resolve, reject) => {
					loadParticipantDataRequirePhase(MenuItemTypes.Phase, this.phaseId)
						.then(data => {
                            this.phase = data.phase
                            this.headerData = new HeaderData(data.participant, data.case?.program ?? null)
                        })
						.then(() => resolve()).catch(error => reject(error));
				});
			},
			loadTest(): Promise<void> {
				return new Promise((resolve, reject) => {
					if (this.drugTestId == null) {
						reject("No drug test ID supplied.");
					}
					else {
						DrugTestService.getById(this.drugTestId).then(result => {
							if (result.drugTestId == undefined) {
								reject("Unable to load drug test.");
							}
							else {
								this.setTest(result);
								if (this.drugTestId)
								{
									DrugTestDrugService.getByTestId(this.phaseId ?? 0, this.drugTestId ?? 0).then(result2 => {
										this.drugTestDrugList = result2;
										resolve();
									})
								}
								resolve();
							}
						});
					}
				});
			},
			formatDate(date: Date): string {
				return DateHelper.formatDateUtc(date, "MM/dd/yyyy");
			},
			setBlankTest() {
				const blankTest = {
					drugTestId: 0,
					phaseId: this.phaseId,
					drugTestLocationId: null,
					drugTestLocationOther: "",
					datePerformed: null,
					performedBy: "",
					notes: "",
				} as IDrugTestForEdit;
				this.drugTest = blankTest;
			},
			setTest(test: IDrugTest) {
				this.drugTest = test as IDrugTestForEdit;
				this.drugTest.datePerformedForEdit = DateHelper.formatDateUtc(this.drugTest.datePerformed, "yyyy-MM-dd");
				this.selectedTypeIds = test.types.map(x => x.id);
				this.phaseId = test.phaseId;
			},
			prepareForSave() {
				this.drugTest.datePerformed = DateHelper.standardizeDate(this.drugTest.datePerformedForEdit);
				this.drugTest.types = this.types.filter(x => this.selectedTypeIds.includes(x.id)) as IDrugTestType[];
				this.drugTest.sessionTypeText = this.sessionTypes.find(x => x.id == this.drugTest.drugTestSessionTypeId)?.description ?? "";
				this.drugTest.methodText = this.methods.find(x => x.id == this.drugTest.drugTestMethodId)?.description ?? "";
				this.drugTest.resultText = this.types.find(x => x.id == this.drugTest.drugTestResultId)?.description ?? "";
				this.drugTest.locationText = this.drugTest.drugTestLocationId == LookupEnum.Other
					? this.drugTest.drugTestLocationOther
					: (this.locations.find(x => x.id == this.drugTest.drugTestLocationId)?.description ?? "");
			},
			async saveTest(redirect: boolean) {
				const { valid } = await this.form.validate();
				if (!valid)
					return;

				this.prepareForSave();
				this.isSaving = true;
				DrugTestService.save(this.drugTest).then(result => {
					this.validationMessages = result.messages;

					if (result.isValid && result.resultObject != null) {
						if (redirect) {
							setTimeout(() => this.returnToPhase(), 3000);
						}
						else if (!this.drugTest.drugTestId) {
							this.setTest(result.resultObject);
							this.successMessageText = "Drug test created successfully";
							this.showSuccess = true;

							// Handle drug list
							this.drugTestDrugList.forEach(o => {
								o.drugTestId = result.resultObject.drugTestId
								DrugTestDrugService.save(o, this.phaseId ?? 0)
									.then()
									.catch(error => this.errorMessage = error)
									.finally(() => this.isSaving = false)
							})

							this.$router.push(`/drug-test/${result.resultObject.drugTestId}`);
							this.openPanels = [0, 1, 2];
						}
						else {
							this.setTest(result.resultObject);
							this.successMessageText = "Drug test saved successfully";
							this.showSuccess = true;
							
						}
					}
				}).catch(error => this.errorMessage = error).finally(() => {
                    this.isSaving = false
                });
			},
			returnToPhase() {
				this.$router.push(`/${MenuItemTypes.Phase}/${this.phaseId}`);
			},
            returnToDrugTestDash() {
                this.$router.push(`/${MenuItemTypes.Phase}/${this.phaseId}/drug-test-dashboard`);
            },
			onWorkflowSubmit(selectedNextStatusId: number) {
				this.successMessageText = "Status updated successfully";
				this.showSuccess = true;
				if (selectedNextStatusId === this.workflowStatusEnum.PendingResults) {
					this.saveTest(false);
				} else {
					this.saveTest(true);
				}
			},
            copyAndSaveNewDrugTest() {
                var newDrugTest = JSON.parse(JSON.stringify(this.drugTest));
                newDrugTest.drugTestId = 0;
                newDrugTest.datePerformed = null;
                this.isSaving = true;
                DrugTestService.save(newDrugTest).then(result => {
					if (result) {
                        this.validationMessages = result.messages;
                        if (result.resultObject) {
                            this.$router.push(`/drug-test/${result.resultObject.drugTestId}`)
                        } else {
                            this.errorMessage = 'Cannot copy and create new drug test.'
                        }
                    } else {
                        this.errorMessage = 'Cannot copy and create new drug test.'
                    }
				}).catch(error => this.errorMessage = error).finally(() => {
                    this.isSaving = false
                });
            },
            onWorkflowSubmitCreateNew() {
				this.successMessageText = "Status updated successfully";
				this.showSuccess = true;
                this.saveTest(false);
                //create new drug test
                this.copyAndSaveNewDrugTest();
			},
			async addDrug() {
				// validate form
				const form = this.$refs.drugForm as HTMLFormElement;
				if (!form) return;
				const { valid } = await form.validate();
				if (!valid) return;

                this.isSaving = true;
				// add substance
				let temp = Object.assign({}, this.drugTestDrug);

				if (this.drugTestId) {
					temp.drugTestId = this.drugTestId;
					DrugTestDrugService.save(temp, this.phaseId ?? 0).then(result => {
						this.validationMessages = result.messages;

						if (result.isValid && result.resultObject != null) {
							temp.drugTestDrugId = result.resultObject.id;
						}
					}).catch(error => this.errorMessage = error).finally(() => this.isSaving = false);
				} else {
					if (this.drugTestDrugList.length > 0) {
						temp.drugTestDrugId = this.drugTestDrugList[this.drugTestDrugList.length - 1].drugTestDrugId + 1;
					} else {
						temp.drugTestDrugId = 1;
					}
                    this.isSaving = false;
				}
				this.drugTestDrugList.push(temp);
				this.drugTestDrug = {} as IDrugTestDrug;
			},
			deleteDrug(drugId: number) {
				this.drugTestDrugList = this.drugTestDrugList.filter(o => o.drugTestDrugId !== drugId);
				if (this.drugTestId) {
					DrugTestDrugService.delete(drugId, this.phaseId ?? 0).then(result => {
						this.validationMessages = result.messages;
					}).catch(error => this.errorMessage = error).finally(() => this.isSaving = false);
				}
			},
			getDrugTypeDescription(id: number) {
				return this.drugTypes.filter(x => x.id === id)[0]?.description ?? "";
			},
			getSubstanceDescription(id: number, other: string) {
				let substance =  this.substances.filter(x => x.id === id)[0]?.description ?? "";
                if (this.otherSubstanceList.includes(id) && other) {
                    substance += `    ${other}`
                }
                return substance
			}
		},
		computed: {
			componentType(): ComponentType {
				return ComponentType.DrugTest
			},
			canEditRolePermission() : boolean {
				return doesUserRoleHaveComponentEditPermissions(this.currentUser?.attachedRoles ?? [], this.componentType)
			},
			saveButtonText(): string {
				return this.drugTest.drugTestId == 0 ? "Create" : (this.isSaving ? "Saving..." : "Save");
			},
			showLocationOther(): boolean {
				return this.drugTest.drugTestLocationId == LookupEnum.Other;
			},
            canAddSubstance(): boolean {
                var positiveResults = [1,7];
                return positiveResults.includes(this.drugTest.drugTestResultId ?? 0)
            }
		},
	});
