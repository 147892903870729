import { DateHelper } from '@/Services/Helper/date-helper';
import FormatHelper from '@/Services/Helper/format-helper';

export default {
	install: (app: any, options: any) => {
		// Adds globally available functions
		app.config.globalProperties.formatDate = (date: Date | string | null): string => {
			const dateFormat = options.dateFormat ?? "MM/dd/yyyy";
			return DateHelper.formatDateUtc(date, dateFormat);
		};
		app.config.globalProperties.formatTimestamp = (timestamp: Date | string | null): string => {
			const timestampFormat = options.timestampFormat ?? "MM/dd/yyyy h:mm tt z";
			return DateHelper.formatDateLocal(timestamp, timestampFormat);
		};
		app.config.globalProperties.formatPhone = (phone: string): string => {
			return FormatHelper.formatPhone(phone);
		};
		app.config.globalProperties.getYesNoText = (bool: boolean | null): string => {
			return bool === null ? "" : (bool ? "Yes" : "No");
		};
        app.config.globalProperties.isPendingItemOverdue = (status: string, endDate: Date): boolean => {
            return status.includes("Pending") && endDate && new Date(endDate).getTime() < new Date().setHours(0, 0, 0, 0);
        };
	}
}