import { BaseApiService } from './base-api-service';
import { IBatchEntryWrapper, IBatchEntryValidationResult } from '@/Models/IBatchEntryWrapper';

class batchEntryService extends BaseApiService{

    public constructor() {
        super('BatchEntry/');
	}

	public submitBatchEntry(entry: IBatchEntryWrapper): Promise<IBatchEntryValidationResult> {
		return this.httpClient.post<IBatchEntryValidationResult>("SubmitBatchEntry", entry).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
}

export const BatchEntryService : batchEntryService = new batchEntryService();