import { MenuItemTypes } from "@/Enums/MenuItemTypes";

import ParticipantPageDto from '@/Models/ParticipantPageDto';

import { CaseService } from "@/Services/case-service";
import { ParticipantService } from "@/Services/participant-service";
import { PhaseService } from "@/Services/phase-service";
import { EligibilityService } from "../eligibility-service";

export const loadParticipantData = (pageType: string, id: number | null) => {
	return new Promise<ParticipantPageDto>((resolve, reject) => {
		if (pageType == undefined || pageType == "" || id == null || isNaN(id)) {
			reject(`Unable to find ${pageType} ID.`);
		}
		else {
			switch (pageType) {
				case MenuItemTypes.Participant:
					ParticipantService.getParticipant(id)
						.then(result => resolve(ParticipantPageDto.getFromParticipant(result)))
						.catch(error => reject(error));
					break;
				case MenuItemTypes.Case:
					CaseService.getCase(id)
						.then(result => resolve(ParticipantPageDto.getFromCase(result)))
						.catch(error => reject(error));
					break;
				case MenuItemTypes.Phase:
					PhaseService.getPhase(id)
						.then(result => resolve(ParticipantPageDto.getFromPhase(result)))
						.catch(error => reject(error));
					break;
                case MenuItemTypes.Eligibility:
                    EligibilityService.get(id)
                        .then(result => resolve(ParticipantPageDto.getFromEligibility(result)))
                        .catch(error => reject(error));
                    break;
				default:
					resolve(new ParticipantPageDto());
			}
		}
	});
}

export const loadParticipantDataRequireParticipant = (pageType: string, id: number | null) => {
	return new Promise<ParticipantPageDto>((resolve, reject) => {
		loadParticipantData(pageType, id).then(data => {
			if (data.participant?.participantId == undefined) {
				reject("Unable to load participant");
			}
			else {
				resolve(data);
			}
		}).catch(error => reject(error));
	});
}

export const loadParticipantDataRequireCase = (pageType: string, id: number | null) => {
	return new Promise<ParticipantPageDto>((resolve, reject) => {
		loadParticipantData(pageType, id).then(data => {
			if (data.case?.caseId == undefined) {
				reject("Unable to load case");
			}
			else if (data.participant?.participantId == undefined) {
				reject("Unable to load participant");
			}
			else {
				resolve(data);
			}
		}).catch(error => reject(error));
	});
}

export const loadParticipantDataRequirePhase = (pageType: string, id: number | null) => {
	return new Promise<ParticipantPageDto>((resolve, reject) => {
		loadParticipantData(pageType, id).then(data => {
			if (data.phase?.phaseId == undefined) {
				reject("Unable to load phase");
			}
			else if (data.case?.caseId == undefined) {
				reject("Unable to load case");
			}
			else if (data.participant?.participantId == undefined) {
				reject("Unable to load participant");
			}
			else {
				resolve(data);
			}
		}).catch(error => reject(error));
	});
}
