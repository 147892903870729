
import { defineComponent } from 'vue';

import { IOutcome } from '@/Models/IOutcomes';
import { DateHelper } from '@/Services/Helper/date-helper';

import LoadingSpinner from '../LoadingSpinner.vue';
import ValidationAlert from '../ValidationAlert.vue';
import SuccessAlert from '../SuccessAlert.vue';

import { LookupKeys } from '@/Enums/LookupKeys';
import { MasterLookupWrapper } from '@/Models/Lookup/MasterLookupWrapper';
import { LookupService } from '@/Services/lookup-service';
import { OutcomeService } from '@/Services/outcome-service';
import SingleErrorMessage from '../Participant/Common/SingleErrorMessage.vue';

export default defineComponent({
    name: 'outcome-table',
    components: { LoadingSpinner, ValidationAlert, SuccessAlert, SingleErrorMessage },
    emits: ['edit-outcome'],
    props: {
        routeId: {
            type: Number,
            required: true,
        },
        canEditPermission: {
            type: Boolean,
            required: true,
        },
    },
    created() {        
        this.loadData();
    },
    data() {
        return {
            isLoading: true,
            isSaving: false,
            isValidationError: false,
            isCaseRoute: this.$route.path.includes("/case/") || this.$route.path.includes("/case-closure/") as boolean,

            LookupKeys,
            lookupKeys: [
                LookupKeys.OutcomeType,
                LookupKeys.OutcomeEventType,
                LookupKeys.HousingStatus,
            ],

            headers: [
                { title: "Outcome Type", key: "reportType", align: "start" }, // quaerterly/closing
                { title: "Housing Status", key: "housingStatus", align: "start" },
                { title: "Income", key: "income", align: "start" },
                { title: "Received Wellfare", key: "wellfare", align: "start" },
                { title: "Received Foodstamps", key: "foodStamps", align: "start" },
                { title: "Paid Taxes", key: "paidTaxes", align: "start" },
                { title: "Created", key: "createDate", align: "start" },
                { title: "Last Modified", key: "modifyDate", align: "start" },
                { title: "Edit Event", key: "actions", sortable: false },
            ],

            lookups: {} as MasterLookupWrapper,
            outcomes: [] as IOutcome[],

            validationMessages: [] as string[],
            errorMessage: "" as string,
            successMessages: [] as string[],
        }
    },
    methods: {
        loadData() {
            Promise.all([
                    LookupService.getLookupsByKey(this.lookupKeys), 
                    this.isCaseRoute ? OutcomeService.getOutcomesByCaseId(this.routeId) : OutcomeService.getOutcomesByPhaseId(this.routeId),
                ])
                .then(([lookups, outcomes]: [MasterLookupWrapper, IOutcome[]]) => {
                    this.lookups = lookups
                    this.outcomes = outcomes;
                })
                .catch((err) => this.errorMessage = err.toString())
                .finally(() => this.isLoading = false);
        },
        formatDate(date: Date | string): string {
            return DateHelper.formatDateUtc(date, "MM/dd/yyyy");
        },
        editOutcome(eid: number) {
            this.$emit('edit-outcome', eid);
        },
    },
});
