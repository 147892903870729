
import { defineComponent } from 'vue';

import { IOutcomeEvent, IOutcomeEventEntry, IOutcomeJuvenileStatusType, IOutcomeRecidivismType, IOutcomeTreatmentType } from '@/Models/IOutcomes';
import { OutcomeEventService } from '@/Services/outcome-service';
import { DateHelper } from '@/Services/Helper/date-helper';

import LoadingSpinner from '../LoadingSpinner.vue';
import ValidationAlert from '../ValidationAlert.vue';

import OutcomeEventDialog from '@/ChildrenComponents/Outcomes/OutcomeEventDialog.vue';
import { ICounty } from '@/Models/Lookup/ICounty';
import { ICourt } from '@/Models/ICourt';
import { IChargeType } from '@/Models/Lookup/IChargeType';
import { InternalUserService } from '@/Services/internal-user-service';
import { LookupKeys } from '@/Enums/LookupKeys';
import { MasterLookupWrapper } from '@/Models/Lookup/MasterLookupWrapper';
import { LookupService } from '@/Services/lookup-service';
import { IOutcomeEventType } from '@/Models/Lookup/Outcomes/IOutcomeEventType';
import SingleErrorMessage from '../Participant/Common/SingleErrorMessage.vue';

export default defineComponent({
    name: 'outcome-event-table',
    components: { 
        LoadingSpinner, 
        ValidationAlert, 
        OutcomeEventDialog, 
        SingleErrorMessage, 
    },
    emits: [
        'updated-outcome-events'
    ],
    props: {
        routeId: {
            type: Number,
            required: true,
        },
        outcomeId: {
            type: Number,
            required: true,
        },
        // prop type functions similar to maySave and mayDelete as mayEdit, but is left
        // with the current name for consistency with the rest of the application
        canEditPermission: {
            type: Boolean,
            required: true,
        },
        maySave: {
            type: Boolean,
            required: false,
            default: false,
        },
        mayDelete: {
            type: Boolean,
            required: false,
            default: false,
        },
        // closed events are events where the end date < current date
        // we want them in the outcome summary but not in closure outcome
        includeClosedEvents: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    created() {        
        this.loadData();
    },
    data() {
        return {
            isLoading: true,
            isSaving: false,
            isValidationError: false,
            isCaseRoute: this.$route.path.includes("/case/") || this.$route.path.includes("/case-closure/") as boolean,

            sortBy: this.includeClosedEvents ? [{ key: 'endDate', order: 'desc' }] : [{ key: 'startDate', order: 'desc' }],
            headers: [
                { title: "Event Type", key: "eventType", align: "start", sortable: false },
                { title: "Event Description", key: "eventDescription", aling: "start", sortable: false},
                { title: "Start Date", key: "startDate", align: "start", sortable: true },
                { title: "End Date", key: "endDate", align: "start", sortable: true },
                { title: "Notes", key: "note", align: "start", sortable: false },
                { title: "Modify", key: "actions", sortable: false },
            ],

            eventPopupProps: {
                dtoPrefill: {
                    eventType: 1,
                    treatmentTypeId: 1,
                    recidivismTypeId: undefined,
                    fosterCareType: undefined,
                    startDate: "",
                    endDate: "",
                    note: "",
                } as IOutcomeEventEntry,
                showEventDialog: false,
            },
            

            eventTypes: [] as IOutcomeEventType[],
            outcomeEvents: [] as IOutcomeEvent[],

            treatmentTypeOptions: [] as IOutcomeTreatmentType[],
            recidivismTypeOptions: [] as IOutcomeRecidivismType[],
            juvenileStatusTypeOptions: [] as IOutcomeJuvenileStatusType[],

            validationMessages: [] as string[],
            errorMessage: "" as string,
            successMessages: [] as string[],
        }
    },
    methods: {
        loadData() {
            const lookupKeys = [
                LookupKeys.OutcomeEventType,
                LookupKeys.OutcomeTreatmentType,
                LookupKeys.OutcomeRecidivismType,
                LookupKeys.OutcomeJuvenileStatusType,
            ];

            Promise.all([
                    LookupService.getLookupsByKey(lookupKeys), 
                    this.isCaseRoute ? (this.includeClosedEvents ? OutcomeEventService.getAllEventsByCaseId(this.routeId) : OutcomeEventService.getOpenEventsByCaseId(this.routeId))
                                     : (this.includeClosedEvents ? OutcomeEventService.getAllEventsByPhaseId(this.routeId) : OutcomeEventService.getOpenEventsByPhaseId(this.routeId)),
                ])
                .then(([lookups, events]: [MasterLookupWrapper, IOutcomeEvent[]]) => {
                    // lookups
                    this.eventTypes = lookups.lookupLists[LookupKeys.OutcomeEventType] as IOutcomeEventType[] ?? [];
                    this.treatmentTypeOptions = lookups.lookupLists[LookupKeys.OutcomeTreatmentType] as IOutcomeTreatmentType[] ?? [];
                    this.recidivismTypeOptions = lookups.lookupLists[LookupKeys.OutcomeRecidivismType] as IOutcomeRecidivismType[] ?? [];
                    this.juvenileStatusTypeOptions = lookups.lookupLists[LookupKeys.OutcomeJuvenileStatusType] as IOutcomeJuvenileStatusType[] ?? [];

                    // events
                    this.outcomeEvents = events;
                })
                .then(() => {
                    this.outcomeEvents.forEach((e) => {
                        // ensure we map outcomeId to an id field on the FE so that
                        // vuetify tables finds the default id field
                        if (!e.id) {
                            e.id = e.outcomeId;
                        }
                    })
                })
                .catch((err) => this.errorMessage = err.toString())
                .finally(() => this.isLoading = false);
        },
        formatDate(date: Date | string): string {
            return DateHelper.formatDateUtc(date, "MM/dd/yyyy");
        },
        formatEventDescription(item: any): string {
            switch(item.outcomeEventTypeId) {
                case 1:
                    return this.treatmentTypeOptions.find((opt) => opt.id == item.treatmentTypeId)?.description || "";
                case 2:
                    return this.recidivismTypeOptions.find((opt) => opt.id == item.recidivismTypeId)?.description || "";
                case 3:
                    return this.juvenileStatusTypeOptions.find((opt) => opt.id == item.juvenileStatusId)?.description || "";
                case 99:
                    return "";
                default:
                    this.errorMessage = "An error occured while fetching the event table. Please try again. If the issue persists, contact an administrator.";
                    return "";
            }
        },
        addEvent() {
            this.eventPopupProps.dtoPrefill = {
                eventType: 1,
                treatmentTypeId: 1,
                recidivismTypeId: undefined,
                fosterCareType: undefined,
                startDate: "",
                endDate: "",
                note: "",
            } as IOutcomeEventEntry;
            this.eventPopupProps.showEventDialog = true;
        },
        editEvent(id: number) {
            const selectedEvent = this.outcomeEvents.find((e) => e.id === id);
            this.eventPopupProps.dtoPrefill = {
                eventId: selectedEvent?.outcomeEventId,
                eventType: selectedEvent?.outcomeEventTypeId,
                ...selectedEvent,
            } as IOutcomeEventEntry;
            this.eventPopupProps.showEventDialog = true;
        },
        deleteEvent(id: number) {
            if (confirm("Are you sure you want to delete this event? It cannot be recovered.")) {
                OutcomeEventService.delete(id).then(res => {
                    if (!res.isValid) {
                        this.validationMessages = res.messages;
                    } else {
                        this.$emit('updated-outcome-events');
                        this.successMessages = ["Successfully deleted the event."];
                    }
                });
                this.outcomeEvents = this.outcomeEvents.filter((e) => {
                    return e.outcomeEventId !== id
                });
            }
        },
        closeNewEventDialog() {
            this.eventPopupProps.showEventDialog = false;
        },
        submitEvent(dto: IOutcomeEventEntry) {
            InternalUserService.getCurrentCstUser().then((u) => {
                // convert an OutcomeEventEntry into an OutcomeEvent. Initial requirements for things
                // such as date range, valid selections, etc. is handled by validation on the creation
                // of the OutcomeEventEntry. This transmutation is only to take a mutable user facing
                // data entry blob and convert it to the structured form accepted by the DB
                const outcomeEvent = {
                    id: this.outcomeId,
                    outcomeId: this.outcomeId,
                    outcomeEventId: dto.eventId || -1,
                    outcomeEventTypeId: dto.eventType,
                    recidivismTypeId: dto.recidivismTypeId || null,
                    treatmentTypeId: dto.treatmentTypeId || null,
                    juvenileStatusId: dto.fosterCareType || null,
                    startDate: dto.startDate || "",
                    endDate: dto.endDate || null,
                    county: {} as ICounty,
                    location: {} as ICourt,
                    chargeId: {} as IChargeType,
                    conviction: "",
                    disposition: "",
                    note: dto.note || "",
                    createDate: new Date(),
                    createUserId: u.internalUserId,
                    modifyDate: new Date(),
                    modifyUserId: u.internalUserId,
                    isNew: true,
                } as IOutcomeEvent;

                if (outcomeEvent.outcomeEventId != -1) {
                    outcomeEvent.isNew = false;
                }

                // prefill based on the provided data because we'll assume that the DB write will succeed
                // it'll be removed when we reload the page if it failed for some reason when we get the
                // validation feedback. switch based on whether it's updating an existing event or creating
                // a new one. this prefilled value will be replaced as quickly as the db response arrives
                if (outcomeEvent.isNew) {
                    this.outcomeEvents.push(outcomeEvent);
                } else {
                    this.outcomeEvents.forEach((event) => {
                        if (event.outcomeEventId === dto.eventId) {
                            event = outcomeEvent;
                        }
                    })
                }

                this.closeNewEventDialog();
                OutcomeEventService.save(outcomeEvent)
                    .then((res) => { 
                        if (!res.isValid) {
                            this.validationMessages = res.messages;
                        } else {
                            this.successMessages = ["Successfully created the event."]
                        }
                    })
                    .catch((err) => this.errorMessage = err.toString())
                    .finally(() => {
                        this.$emit('updated-outcome-events');
                        this.loadData();
                    });
            });
            
        },
    },
    computed: {
        cardTitle(): string {
            return this.includeClosedEvents ? "All Events" : "Open Events"
        }
    }
});
