import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_ValidationAlert = _resolveComponent("ValidationAlert")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.visible,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.visible) = $event)),
    "onClick:outside": _ctx.closeDialog,
    transition: "dialog-top-transition",
    width: "auto",
    scrollable: "",
    "scroll-strategy": "none",
    "retain-focus": true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_toolbar_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Add a New Outcome Event")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_card_text, { class: "mt-6" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_form, { ref: "newEventForm" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ValidationAlert, { "validation-results": _ctx.validationResults }, null, 8, ["validation-results"]),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      (_ctx.isLoading)
                        ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                            key: 0,
                            indeterminate: ""
                          }))
                        : (_openBlock(), _createBlock(_component_v_select, {
                            key: 1,
                            modelValue: _ctx.dto.eventType,
                            "onUpdate:modelValue": [
                              _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dto.eventType) = $event)),
                              _ctx.updateEventType
                            ],
                            items: _ctx.eventTypeOptions,
                            "item-value": "id",
                            "item-title": "description"
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "items"]))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { md: "6" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            type: "date",
                            label: "Start Date",
                            modelValue: _ctx.dto.startDate,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dto.startDate) = $event)),
                            required: "",
                            rules: _ctx.requiredFieldRules
                          }, null, 8, ["modelValue", "rules"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { md: "6" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            type: "date",
                            label: "End Date",
                            modelValue: _ctx.dto.endDate,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dto.endDate) = $event)),
                            rules: _ctx.endDateRules
                          }, null, 8, ["modelValue", "rules"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { md: "12" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, null, {
                            default: _withCtx(() => [
                              (_ctx.visibleOpts.length > 0)
                                ? (_openBlock(), _createBlock(_component_v_select, {
                                    key: 0,
                                    modelValue: _ctx.selectedOpt,
                                    "onUpdate:modelValue": [
                                      _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedOpt) = $event)),
                                      _ctx.updateSubEventType
                                    ],
                                    items: _ctx.visibleOpts,
                                    "item-value": "id",
                                    "item-title": "description"
                                  }, null, 8, ["modelValue", "items", "onUpdate:modelValue"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_textarea, {
                                label: "Other Notes",
                                modelValue: _ctx.dto.note,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dto.note) = $event))
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 512)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                color: "success",
                onClick: _ctx.submitNewOutcomeEvent,
                disabled: !_ctx.maySave
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Save Event")
                ]),
                _: 1
              }, 8, ["onClick", "disabled"])), [
                [_directive_ripple]
              ]),
              _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                color: "secondary",
                onClick: _ctx.clearForm
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Clear Form")
                ]),
                _: 1
              }, 8, ["onClick"])), [
                [_directive_ripple]
              ]),
              _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                color: "error",
                onClick: _ctx.closeDialog
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Close Window")
                ]),
                _: 1
              }, 8, ["onClick"])), [
                [_directive_ripple]
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "onClick:outside"]))
}