import { BaseDto, IBaseDto } from "./IBaseDto";
import { DateHelper } from "@/Services/Helper/date-helper";
import FormatHelper from '@/Services/Helper/format-helper';

export interface ILedgerLine extends IBaseDto {
	caseLedgerId: number;
	caseId: number;
	ledgerTypeId: number;
	ledgerSubTypeId: number | null;
	amount: number,
	note: string;
	date: Date;
	ledgerTypeDisplay: string;
	ledgerSubTypeDisplay: string;
	metaData: string;
    categoryId: number | null;
    categoryDisplay: string;
}

export class LedgerLineForEdit extends BaseDto implements ILedgerLine {
	caseLedgerId: number;
	caseId: number;
	ledgerTypeId: number;
	ledgerSubTypeId: number | null;
	amount: number;
	note: string;
	date: Date;
	dateEditable: string;
	ledgerTypeDisplay: string;
	ledgerSubTypeDisplay: string;
	metaData: string;
    categoryId: number | null;
    categoryDisplay: string;
    amountFormattedToCurrency: string;
    
	constructor(src: ILedgerLine) {
		super(src);
		this.caseLedgerId = src.caseLedgerId;
		this.caseId = src.caseId;
		this.ledgerTypeId = src.ledgerTypeId;
		this.ledgerSubTypeId = src.ledgerSubTypeId;
		this.amount = src.amount;
		this.note = src.note;
		this.date = src.date;
		this.ledgerTypeDisplay = src.ledgerTypeDisplay;
		this.ledgerSubTypeDisplay = src.ledgerSubTypeDisplay;
		this.dateEditable = DateHelper.formatDateUtc(src.date, "yyyy-MM-dd" );
		this.metaData = src.metaData;
        this.categoryId = src.categoryId;
        this.categoryDisplay = src.categoryDisplay;
        this.amountFormattedToCurrency = FormatHelper.formatCurrency(src.amount);
	}
}

