
    // TemplateEditor is a component which will combine the layout and formatting of 
    // the HtmlEditor with the additional domain/business components necessary. 
    // This includes:
    //    - Variable Menu
    //    - Label editor
    import { 
        defineComponent, 
        PropType 
    } from 'vue';
    import { getTinymce } from '@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE'
    
    import { ITemplate } from '@/Models/Templating/ITemplate';

    import HtmlEditor from './HtmlEditor.vue';
    import VariableMenu from './VariableMenu.vue';

    export default defineComponent({
        name: 'template-editor',
        components: {
            HtmlEditor,
            VariableMenu,
        },
        emits: [
            "onChange", // emitted every time the template text is changed along with the updated template
        ],
        props: {
            // the template being edited. this is a watched variable
            activeTemplate: {
                type: Object as PropType<ITemplate>,
                required: true,
            },
            // a flag to enable or disable the variable menu (true == show the variable menu)
            includeVariableMenu: {
                type: Boolean,
                required: false,
                default: true,
            },
            // a flag to enable or disable the label text on the editor (true == show label)
            includeLabelEditor: {
                type: Boolean,
                required: false,
                default: true,
            }
        },
        created() {
            this.currentTemplate = this.activeTemplate
        },
        data() {
            return {
                currentTemplate: {} as ITemplate,
            }
        },
        methods: {
            insert(content: string) {
                // typescript lookup discussion: https://stackoverflow.com/questions/60819819/how-to-insert-content-inside-tinymce-editor-using-vue-js
                getTinymce().activeEditor.insertContent(content);
                this.$emit("onChange", this.currentTemplate);
            },
            onChange(text: string) {
                this.currentTemplate.contents = text;
                this.$emit("onChange", this.currentTemplate);
            }
        },
        watch: {
            activeTemplate() {
                this.currentTemplate = this.activeTemplate;
            },
        },
    })
