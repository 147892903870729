
    import { defineComponent } from 'vue';

    // A tooltray that contains all of the options for autofilling templates which can be uniquely
    // instanced or included, but is primarily used as the editor (a combo of this component and the
    // HtmlEditor component)
    export default defineComponent({
        name: 'variable-menu',
        components: {

        },
        emits: [
            "insertVariable",   // emitted whenever the 'insert' button is clicked for a variable. also emits the variable
            "copyVariable",     // emitted whenever the 'copy' button is clicked, also emits the copied value
        ],
        props: {},
        created() {

        },
        data() {
            return {
                // status

                // state

                // data
                headers: [
                    { title: "Values", key: "value", sortable: false, align: "start", width: "150px" },
                    { title: "Variables", key: "variable", sortable: false, align: "start", width: "100px" },
                    { title: "Actions", key: "actions", sortable: false, align: "start", width: "100px" },
                ],

                templateVariables: [
                    { value: "Today's Date", variable: "%today%" },
                    { value: "Participant Birthday", variable: "%dateOfBirth%" },
                    { value: "Case Number", variable: "%caseNumber%" },
                    { value: "Phase Number" , variable: "%phaseNumber%" },
                    { value: "Participant Full Name", variable: "%participantFirstName%" },
                    { value: "Participant First Name", variable: "%participantLastName%" },
                    { value: "Participant Last Name", variable: "%participantName%" },
                    { value: "SSN (e.g. *****6789)", variable: "%censoredSSN%" },
                    { value: "Court Name", variable: "%courtName%" },
                    { value: "Program Name", variable: "%programName%" },
                    { value: "Participant Status Text", variable: "%participantStatus%"}
                ] as { value: any; variable: string }[]

                // permissions
            }
        },
        methods: {
            doCopy(content: string) {
                navigator.clipboard.writeText(content);
                this.$emit("copyVariable", content);
            },
            doInsert(content: string) {
                this.$emit("insertVariable", content);
            },
        }
    })
