
    import App from "./App.vue";
    import { provideAuth0 } from './Services/Auth/auth0-plugin';

    export default {
        name: "shell-component",
        components: { App },

        
        setup(): void {
            provideAuth0();
        },
    };
