
    import { defineComponent, PropType } from 'vue';

    import { ChartType, IChartableSeries } from '@/Models/Charts/IChartableSeries';
    import IChartRenderOptions from '@/Models/Charts/IChartRenderOptions';
    import { ChartableCollection } from '@/Models/Charts/ChartableCollection';

    import BaseChart from './BaseChart.vue';

    export default defineComponent({
        name: "data-proportion-chart",
        components: {
            BaseChart,
        },
        props: {
            totalLineData: {
                type: Object as PropType<ChartableCollection>,
                required: true,
            },
            metricBarData: {
                type: Object as PropType<ChartableCollection>,
                required: true,
            },
            // complete list of options are here: https://apexcharts.com/docs/options/
			// also, a set of reusable defaults are exported in the visualization service
			chartOptions: {
				type: Object as PropType<IChartRenderOptions>,
				required: true,
			}
        },
        methods: {
            addChartType(series: ChartableCollection, ctype: ChartType) {
                return series.map((chart: IChartableSeries) => {
                    return {
                        ... chart,
                        type: ctype,
                    }
                })
            },
        },
        computed: {
            series() {
                return [
                    ...this.addChartType(this.totalLineData, 'line' as ChartType),
                    ...this.addChartType(this.metricBarData, 'column' as ChartType),
                ]
            },
        },
    })
