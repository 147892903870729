
	import { defineComponent, PropType, Ref, ref } from 'vue';

	import { CourtAttendanceService } from '@/Services/court-attendance-service';
	import { DateHelper } from '@/Services/Helper/date-helper';
	import { ValidationHelper } from '@/Services/Helper/validation-helper';

	import { LookupKeys } from '@/Enums/LookupKeys';

	import { ICourtAttendance, ICourtAttendanceForEdit } from '@/Models/ICourtAttendance';
	import { IBaseLookup } from '@/Models/Lookup/BaseLookup';
	import { MasterLookupWrapper } from '@/Models/Lookup/MasterLookupWrapper';

	import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';

	export default defineComponent({
		name: "court-attendance-dialog",
		components: { ValidationAlert },
		emits: ["close", "saved"],
		props: {
			lookups: {
				type: Object as PropType<MasterLookupWrapper>,
				required: true
			},
			source: {
				type: Object as PropType<ICourtAttendance>,
				required: true
			},
		},
		setup(): { form: Ref } {
			const form = ref(null);
			return { form };
		},
		created() {
			this.setDto();
			this.statusList = this.lookups.lookupLists[LookupKeys.CourtAttendanceStatus];
			this.typeList = this.lookups.lookupLists[LookupKeys.CourtAttendanceType];
		},
		data () {
			return {
				requiredFieldRules: ValidationHelper.requiredRules,
				dto: {} as ICourtAttendanceForEdit,
				statusList: [] as IBaseLookup[],
				typeList: [] as IBaseLookup[],
				errors: [] as string[],
				isSaving: false,
			}
		},
		methods: {
			setDto(): void {
				this.dto = JSON.parse(JSON.stringify(this.source)) as ICourtAttendanceForEdit;
				this.dto.startDateForEdit = DateHelper.formatDateUtc(this.dto.startDate, "yyyy-MM-dd");
				this.dto.endDateForEdit = DateHelper.formatDateUtc(this.dto.endDate, "yyyy-MM-dd");
				if (this.form != null) {
					//adding a brief delay to make sure the changes to startDateForEdit have fully registered before resetting validation
					setTimeout(() => { this.form.resetValidation(); }, 100);
				}
			},
			prepForSave(): void {
				this.dto.startDate = DateHelper.standardizeDate(this.dto.startDateForEdit);
				this.dto.endDate = DateHelper.standardizeDate(this.dto.endDateForEdit);
                if (!this.dto.endDate) this.dto.endDate = this.dto.startDate;
				this.dto.statusName = this.statusList.find(x => x.id == this.dto.courtAttendanceStatusId)?.description ?? "";
				this.dto.typeName = this.typeList.find(x => x.id == this.dto.courtAttendanceTypeId)?.description ?? "";
			},
			closeDialog(): void {
				this.$emit("close");
			},
			async save(startNew: boolean) {
                if (!this.dto.endDateForEdit) this.dto.endDateForEdit = this.dto.startDateForEdit;
				const { valid } = await this.form.validate();
				if (valid) {
					this.prepForSave();
					this.isSaving = true;
					CourtAttendanceService.save(this.dto)
						.then(result => {
							this.errors = result.messages;
							if (result.isValid && result.resultObject != null) {
								this.$emit("saved", result.resultObject, startNew);
							}
						})
						.catch(error => this.errors.push(error))
						.finally(() => this.isSaving = false);
				}
			},
		},
		computed: {
			buttonText1(): string {
				return this.isSaving ? "Saving..." : "Save and Close";
			},
			buttonText2(): string {
				return this.isSaving ? "Saving..." : "Save and Start New";
			},
			endDateRules() {
				return [
					(v : string) => {
						if (this.dto) {
							const startDate = new Date(this.dto.startDateForEdit);
							const endDate = new Date(v);
							return !startDate || endDate >= startDate || 'End Date cannot be before Start Date';
						}
						return true; // Return true if this.dto is null
					}
				];
			},
		},
		watch: {
			source() {
				this.setDto();
			},
			// 'dto.startDateForEdit': {
			// 	handler(value) {
			// 		if (this.dto && !this.dto?.endDateForEdit) {
			// 			this.dto.endDateForEdit = value;
			// 		}
			// 	},
			// 	deep: true
			// },
		},
	});
