
	import { defineComponent } from 'vue';

	import { IWorkflowInstanceStatus } from '@/Models/Workflow/IWorkflowInstanceStatus';
	import { IDataTableHeader } from '@/Models/IDataTableHeader';

	import { WorkflowService } from '@/Services/workflow-service';
	import { DateHelper } from '@/Services/Helper/date-helper';

	export default defineComponent({
		name: 'workflow-history',
		props: {
			workflowInstanceId: {
				type: Number,
				required: true
			},
		},
		created() {
			this.loadHistory();
		},
		data() {
			return {
				isLoading: true,
				statusList: [] as IWorkflowInstanceStatus[],
				headers: [
					{ title: "Status", key: "workflowStatusDescription", sortable: true, align: "start" },
					{ title: "User", key: "createdBy", sortable: true, align: "start" },
					{ title: "Date", key: "createDate", sortable: true, align: "start" },
					{ title: "Note", key: "note", sortable: true, align: "start" },
				] as IDataTableHeader[],
			}
		},
		methods: {
			loadHistory() {
				this.isLoading = true;
				WorkflowService.getStatusHistory(this.workflowInstanceId).then(response => this.statusList = response).then(() => this.isLoading = false);
			},
			formatDate(date: Date): string {
				return DateHelper.formatDateLocal(date, "MM/dd/yyyy h:mm tt z");
			},
		},
		watch: {
			workflowInstanceId() {
				this.loadHistory();
			},
		},
	}); 
