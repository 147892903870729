
	import { defineComponent, PropType, Ref, ref } from "vue";

	import { DateHelper } from "@/Services/Helper/date-helper";
	import { ValidationHelper } from "@/Services/Helper/validation-helper";

	import { IBaseLookup } from "@/Models/Lookup/BaseLookup";
	import { LedgerLineForEdit } from "@/Models/ILedgerLine";
	import { MasterLookupWrapper } from "@/Models/Lookup/MasterLookupWrapper";
	import { LookupKeys } from "@/Enums/LookupKeys";
	import { CaseLedgerType } from "@/Enums/CaseLedgerType";
	import { LedgerFeeType } from "@/Enums/LedgerSubType";

	export default defineComponent({
		name: "add-case-ledger-line-dialog",
		emits: ["close", "save"],
		props: {
			sourceLedgerLine: LedgerLineForEdit,
			headerText: String,
			lookups: {
				type: Object as PropType<MasterLookupWrapper>,
				required: true,
			},
			isSaveAllowed: {
				type: Boolean,
				required: true,
			},
			isDeleteAllowed: {
				type: Boolean,
				required: true,
			},
		},
		setup(): { lineForm: Ref } {
			const lineForm = ref(null);
			return { lineForm };
		},
		created() {
			if (
				this.sourceLedgerLine != undefined &&
				this.sourceLedgerLine != null
			) {
				this.ledgerLine = this.sourceLedgerLine;
			}
			this.ledgerTypeList = this.lookups.lookupLists[LookupKeys.CaseLedgerType];
			this.feeTypeList = this.lookups.lookupLists[LookupKeys.LedgerFeeType];
			this.paymentTypeList = this.lookups.lookupLists[LookupKeys.LedgerPaymentType];
            this.categoryList = this.lookups.lookupLists[LookupKeys.CaseLedgerCategory];
		},
		data() {
			return {
				requiredFieldRules: ValidationHelper.requiredRules,
				ledgerLine: {} as LedgerLineForEdit,
				buttonText: "Save Note",
				ledgerTypeList: [] as IBaseLookup[],
				feeTypeList: [] as IBaseLookup[],
				paymentTypeList: [] as IBaseLookup[],
                categoryList: [] as IBaseLookup[],
				isLoading: true,
				isSaving: false,
                sourceLineChanged: false
			};
		},
		methods: {
			closeDialog(): void {
				this.$emit("close");
			},
			async saveLine() {
				const { valid } = await this.lineForm.validate();
				if (valid) {
					if (this.ledgerLine != null) {
						const lineDate = DateHelper.standardizeDate(
							this.ledgerLine.dateEditable
						);
						if (lineDate != null) {
							this.ledgerLine.date = lineDate;
						}
					}

                    //force proper sign on amount (positive number on all EXCEPT: Fee reductions)
                    let negOrPos = 1;
                    let reductionTypes = [LedgerFeeType.IncentiveReduction, LedgerFeeType.FeeWaiver];
                    if (this.ledgerLine.ledgerTypeId === CaseLedgerType.Fee &&
                        reductionTypes.includes(this.ledgerLine.ledgerSubTypeId ?? 0)) {
                        negOrPos = -1;
                    }
                    this.ledgerLine.amount = Math.abs(this.ledgerLine.amount) * negOrPos;

					this.isSaving = true;
					this.buttonText = "Saving...";
					this.$emit("save", this.ledgerLine);
				}
			},
		},
		computed: {
			isLineTypeFee(): boolean {
				return this.ledgerLine.ledgerTypeId === CaseLedgerType.Fee;
			},
			isLineTypePayment(): boolean {
				return this.ledgerLine.ledgerTypeId === CaseLedgerType.Payment;
			},
            getFeePaymentTypeList(): IBaseLookup[] {
                switch (this.ledgerLine.ledgerTypeId) {
                    case CaseLedgerType.Fee:
                        return this.feeTypeList;
                    case CaseLedgerType.Payment:
                        return this.paymentTypeList;
                    default:
                        return [];
                }
            },
            getFeePaymentLabel(): string {
                switch (this.ledgerLine.ledgerTypeId) {
                    case CaseLedgerType.Fee:
                        return "Fee Type";
                    case CaseLedgerType.Payment:
                        return "Payment Type";
                    default:
                        return "";
                }
            }
		},
		watch: {
			sourceLedgerLine() {
                this.sourceLineChanged = true;
				this.ledgerLine =
					this.sourceLedgerLine?.caseLedgerId != undefined
						? this.sourceLedgerLine
						: ({} as LedgerLineForEdit);
				this.isSaving = false;
				this.buttonText = "Save Line";
			},
            'ledgerLine.ledgerTypeId'() {
                if (this.sourceLineChanged) {
                    this.sourceLineChanged = false;
                    return;
                }
				if (this.ledgerLine.ledgerSubTypeId) this.ledgerLine.ledgerSubTypeId = null;
			},
		},
	});
