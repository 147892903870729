import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ParticipantHeader = _resolveComponent("ParticipantHeader")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_SingleErrorMessage = _resolveComponent("SingleErrorMessage")!
  const _component_ValidationAlert = _resolveComponent("ValidationAlert")!
  const _component_ProgramSelectDialog = _resolveComponent("ProgramSelectDialog")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.headerData && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_ParticipantHeader, {
          key: 0,
          data: _ctx.headerData
        }, null, 8, ["data"]))
      : _createCommentVNode("", true),
    _createVNode(_component_PageHeader, { headerText: "Case List" }),
    (_ctx.isLoading && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_SingleErrorMessage, { errorMessage: _ctx.errorMessage }, null, 8, ["errorMessage"]),
    _createVNode(_component_ValidationAlert, { validationResults: _ctx.validationMessages }, null, 8, ["validationResults"]),
    _createVNode(_component_ProgramSelectDialog, {
      modelValue: _ctx.showProgramSelectDialog,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showProgramSelectDialog) = $event)),
      onSave: _ctx.newCaseCreate,
      onClose: _ctx.newCaseCancel
    }, null, 8, ["modelValue", "onSave", "onClose"]),
    (!_ctx.isLoading && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_v_card, { key: 2 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                (_ctx.allowNewCase)
                  ? (_openBlock(), _createBlock(_component_v_row, {
                      key: 0,
                      class: "mb-2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { align: "center" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_btn, {
                              onClick: _ctx.newCase,
                              color: "primary"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("Create New Case")
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_v_data_table, {
                  headers: _ctx.caseHeaders,
                  items: _ctx.caseList,
                  density: "compact",
                  class: "elevation-1"
                }, {
                  "item.createDate": _withCtx(({ item }) => [
                    _createTextVNode(_toDisplayString(_ctx.formatDate(item.raw.createDate)), 1)
                  ]),
                  "item.isActiveText": _withCtx(({ item }) => [
                    _createTextVNode(_toDisplayString(_ctx.getActiveText(item.raw.isActive)), 1)
                  ]),
                  "item.actions": _withCtx(({ item }) => [
                    _createVNode(_component_v_btn, {
                      onClick: ($event: any) => (_ctx.openCase(item.raw.caseId)),
                      density: "comfortable",
                      size: "small",
                      icon: "mdi-magnify-plus-outline",
                      color: "info"
                    }, null, 8, ["onClick"])
                  ]),
                  _: 1
                }, 8, ["headers", "items"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}