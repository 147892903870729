import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_ValidationAlert = _resolveComponent("ValidationAlert")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 0 }))
      : _createCommentVNode("", true),
    (!_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_v_data_table, {
          key: 1,
          headers: _ctx.headers,
          items: _ctx.items,
          density: "compact",
          class: "elevation-1 pre-wrap"
        }, {
          "item.currentStatusText": _withCtx(({ item }) => [
            (_ctx.isPendingItemOverdue(item.raw.currentStatusText, item.raw.datePerformed))
              ? (_openBlock(), _createBlock(_component_v_chip, {
                  key: 0,
                  label: "",
                  size: "large",
                  color: "orange"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.raw.currentStatusText), 1)
                  ]),
                  _: 2
                }, 1024))
              : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(item.raw.currentStatusText), 1))
          ]),
          "item.typeList": _withCtx(({ item }) => [
            _createVNode(_component_v_list, {
              density: "compact",
              items: item.raw.types,
              "item-title": "description"
            }, null, 8, ["items"])
          ]),
          "item.datePerformed": _withCtx(({ item }) => [
            _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(item.raw.datePerformed)), 1)
          ]),
          "item.actions": _withCtx(({ item }) => [
            (_ctx.isSaveAllowed)
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 0,
                  onClick: ($event: any) => (_ctx.editTest(item.raw.drugTestId)),
                  class: "ma-1",
                  density: "comfortable",
                  size: "small",
                  icon: "mdi-pencil-outline",
                  color: "info"
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.isDeleteAllowed)
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 1,
                  onClick: ($event: any) => (_ctx.deleteTest(item.raw.drugTestId)),
                  class: "ma-1",
                  density: "comfortable",
                  size: "small",
                  icon: "mdi-trash-can-outline",
                  color: "error"
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["headers", "items"]))
      : _createCommentVNode("", true),
    _createVNode(_component_ValidationAlert, { validationResults: _ctx.validationMessages }, null, 8, ["validationResults"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.addTestError,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.addTestError) = $event)),
      width: "auto"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createTextVNode(" An unexpected error occurred when adding a new drug test. Please try again. ")
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  color: "primary",
                  block: "",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addTestError = false))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Close")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_row, { class: "mt-2" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { align: "center" }, {
          default: _withCtx(() => [
            (_ctx.isSaveAllowed)
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 0,
                  onClick: _ctx.addTest,
                  color: "success"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Add")
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}