import { IParticipantAlias } from '@/Models/IParticipantAlias';
import { ITypedValidationResult } from '@/Models/IValidationResult';
import { BaseApiService } from './base-api-service';

class participantAliasService extends BaseApiService{
    
    public constructor() {
		super('ParticipantAlias/');
    }

	public async getByParticipantId(participantId: number): Promise<IParticipantAlias[]> {
		return this.httpClient.get<IParticipantAlias[]>(`GetByParticipantId/${participantId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
	public async save(alias: IParticipantAlias): Promise<ITypedValidationResult<IParticipantAlias>> {
		return this.httpClient.post<ITypedValidationResult<IParticipantAlias>>("save", alias).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
	public async delete(aliasId: number): Promise<void> {
		return this.httpClient.delete<void>(`delete/${aliasId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
}

export const ParticipantAliasService: participantAliasService = new participantAliasService();