import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!

  return (_openBlock(), _createBlock(_component_v_alert, {
    color: "#C51162",
    theme: "dark",
    icon: "mdi-emoticon-frown",
    prominent: "",
    title: "Access Denied",
    text: "Whoops! You do not have the correct permissions to view this page. If you believe this is a mistake, please contact your system administrator."
  }))
}