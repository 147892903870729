import { Workflow } from "@/Enums/Workflow";

export default class RouterHelper {
	public static getUrl(workflowId: number, parentId: number) {
		switch (workflowId) {
			case Workflow.Phase:
				return `/phase/${parentId}`;
			case Workflow.CaseClosure:
				return `/case-closure/${parentId}`;
			case Workflow.DrugTest:
				return `/drug-test/${parentId}`;
			case Workflow.ParticipantEligibility:
				return `/eligibility/${parentId}`;
		}
	}
}