
import { defineComponent, Ref, ref } from 'vue';

import { useRouter, Router } from 'vue-router';
import { useUserStore } from '@/Services/Store/user-store';
import { LookupService } from '@/Services/lookup-service';

import { IParticipant } from '@/Models/IParticipant';
import { IInternalUser } from '@/Models/IInternalUser';
import { IGender } from '../Models/Lookup/IGender';

import { LookupKeys } from "@/Enums/LookupKeys";
import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';

export default defineComponent({
  name: 'create-participant-dialog',
  components: { ValidationAlert },

  setup(): {currentUser: IInternalUser | null, router: Router, creationForm: Ref } {
    const userStoreInstance = useUserStore();
    const currentUser = userStoreInstance.$state.cstInternalUser;
	const router = useRouter();
	const creationForm = ref(null);
	
    return {
        currentUser,
		router,
		creationForm 
    };
  },
  beforeCreate() {
    
  },
  created() {
	LookupService.getLookupByKey(LookupKeys.Gender).then(result => this.genderSelectOptions = result as IGender[]);
  },
  data () {
    return {
        participantObject: {} as IParticipant,
		genderSelectOptions: [] as IGender[],
		requiredFieldRules: [
            (value: any) => !!value || 'This field is REQUIRED.',
        ],
        mutableDialogSwitch: undefined as boolean | undefined,
		isGettingRedirected: true as boolean,
		createButtonDisabled: false as boolean,
        showssn: false as boolean,
        validationResults: [] as string[]
    }
  },
  props: {
    dialog: {
        type: Boolean,
        required: true
    }
  },
  watch: {
    dialog(newValue: boolean): void {
        this.mutableDialogSwitch = newValue;
    }
  },
  methods: {
    closeDialog(): void {
        this.$emit('close-participant-dialog');
    },
    clearForm(): void {
        this.validationResults = [];
        this.participantObject = {} as IParticipant;
    },
    async submitParticipant(): Promise<void> {
		const {valid} = await this.creationForm.validate();
		if (valid) {
            this.validationResults = [];
			this.createButtonDisabled = true;
			if (this.currentUser?.attachedPrograms.length == 1) {
				this.participantObject.treatmentProgramId = this.currentUser.attachedPrograms[0].treatmentProgramId;
			}
			this.$emit('new-participant-submit', this.participantObject, this.isGettingRedirected);
			// this.$emit('close-participant-dialog');
			// if (this.isGettingRedirected) {
			// 	this.router.push({path: '/tasklist'});
			// }
		}
    }
  }
});
