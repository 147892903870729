import { IPhaseReportParamter } from '@/Models/Report/IPhaseReport';
import { defineStore } from 'pinia';

export const useMetricSearchStore = defineStore('metricSearch', {
    state: () => {
        return {
            metricSearch: {
                startDate: null,
                endDate: null,
                programIds: [],
                result: null,
                typeIds: [],
            } as IPhaseReportParamter,
        };
    },
})