import { AxiosError } from "axios";

export default abstract class ErrorHandlingHelper {
    // the genericErrorHandler method takes an error and attempts to decode it to a human
    // presentable string, defaulting to stringifying the error if things go poorly
    public static genericErrorHandler(updateErrorMessage: (message: string) => void) {
        return async (err: AxiosError): Promise<void> => {
            let errMessage: string;
            
            // we must ensure this method never fails, so if there are any problems
            // performing the decode, fallback to a safe handler even if the error
            // loses some specificity
            try {
                if (err && err.response && err.response.data) {
                    const data = err.response.data as Blob;
                    errMessage = await data.text();
                } else {
                    errMessage = err.toString();
                }
            } catch (error: any) {
                errMessage = `${error.toString()}`
            }
            updateErrorMessage(errMessage);
        };
    }

}