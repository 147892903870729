
	import { defineComponent, Ref, ref } from 'vue';

	import { ValidationHelper } from '@/Services/Helper/validation-helper';

	import { LookupService } from '@/Services/lookup-service';

	import { Address } from '@/Models/IAddress';
	import { IState } from '@/Models/Lookup/IState';
	import { ICountry } from '@/Models/Lookup/ICountry';
	import { LookupKeys } from '@/Enums/LookupKeys';

	export default defineComponent({
	name: "add-address-dialog",
	emits: ["close", "save"],
	props: {
		source: Address,
		headerText: String,
        contactTypes: Array,
        displayContactType: Boolean
	},
	setup(): { form: Ref } {
		const form = ref(null);
		return { form };
	},
	created() {
		this.address = this.source != undefined ? this.source : null;
		Promise.all([LookupService.getStateList(), LookupService.getLookupByKey(LookupKeys.Country)]).then(([stateList, countryList]) => {
			this.states = stateList;
			this.countries = countryList as ICountry[];
		}).then(() => this.isLoading = false);
	},
	data () {
		return {
			requiredRules: ValidationHelper.requiredRules,
			address: null as Address | null,
			buttonText: "Save Address",
			isSaving: false,
			isLoading: true,
			states: [] as IState[],
			countries: [] as ICountry[],
		}
	},
	methods: {
		closeDialog(): void {
			this.$emit("close");
		},
		async save() {
			const { valid } = await this.form.validate();
			if (valid) {
				this.isSaving = true;
				this.buttonText = "Saving..."
				this.$emit("save", this.address);
			}
		},
	},
	watch: {
		source(newAddress: Address) {
			this.address = newAddress;
			this.isSaving = false;
			this.buttonText = "Save Address";
		},
	},
});
