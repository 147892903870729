import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_ValidationAlert = _resolveComponent("ValidationAlert")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    "onClick:outside": _ctx.closeDialog,
    height: "auto",
    "max-width": "900",
    "scroll-strategy": "none"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { style: {"overflow":"hidden"} }, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, {
            color: "secondary",
            elevation: 8
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_toolbar_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.titleText), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                icon: "mdi-close-outline",
                density: "comfortable",
                variant: "plain",
                onClick: _ctx.closeDialog
              }, null, 8, ["onClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_card_text, { class: "mt-6" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_form, { ref: "form" }, {
                default: _withCtx(() => [
                  (_ctx.dto.custodyResidentialId == 0)
                    ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { md: "6" }, {
                            default: _withCtx(() => [
                              (_ctx.dto.custodyResidentialId == 0)
                                ? (_openBlock(), _createBlock(_component_v_select, {
                                    key: 0,
                                    items: _ctx.typeList,
                                    label: "Type",
                                    modelValue: _ctx.dto.custodyOrResidential,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dto.custodyOrResidential) = $event)),
                                    required: "",
                                    rules: _ctx.requiredFieldRules
                                  }, null, 8, ["items", "modelValue", "rules"]))
                                : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.titleText), 1))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { md: "3" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            type: "date",
                            label: "Start Date",
                            modelValue: _ctx.dto.startDateForEdit,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dto.startDateForEdit) = $event)),
                            required: "",
                            rules: _ctx.requiredFieldRules
                          }, null, 8, ["modelValue", "rules"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { md: "3" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            type: "date",
                            label: "End Date",
                            modelValue: _ctx.dto.endDateForEdit,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dto.endDateForEdit) = $event)),
                            rules: _ctx.endDateRules
                          }, null, 8, ["modelValue", "rules"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { md: "3" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            type: "date",
                            label: "Target End Date",
                            modelValue: _ctx.dto.targetEndDateForEdit,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dto.targetEndDateForEdit) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { md: "12" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_textarea, {
                            modelValue: _ctx.dto.location,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dto.location) = $event)),
                            label: "Location",
                            rows: "2"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { md: "12" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_textarea, {
                            modelValue: _ctx.dto.notes,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dto.notes) = $event)),
                            label: "Notes",
                            rows: "2"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 512),
              _createVNode(_component_ValidationAlert, { validationResults: _ctx.errors }, null, 8, ["validationResults"]),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { align: "center" }, {
                    default: _withCtx(() => [
                      _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                        class: "mx-2 mb-4",
                        color: "success",
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.save(false))),
                        disabled: _ctx.isSaving
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.buttonText1), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled"])), [
                        [_directive_ripple]
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onClick:outside"]))
}