
	import { defineComponent } from 'vue';

	import { ComponentType } from '@/Enums/ComponentType'
	import { IInternalUser } from '@/Models/IInternalUser';
	import { ITaskListPreviewItem } from '@/Models/ITaskListBase';
	import { IDataTableHeader } from '@/Models/IDataTableHeader';

	import { doesUserRoleHaveComponentEditPermissions, doesUserRoleHaveComponentViewPermissions } from '@/Services/Helper/component-permissions-helper'
	import { useUserStore } from '@/Services/Store/user-store';
	import { useTaskSearchStore } from '@/Services/Store/tasksearch-store';
	import { TaskListService } from '@/Services/tasklist-service';
	import RouterHelper from '@/Services/Helper/router-helper';
	import { DateHelper } from '@/Services/Helper/date-helper';
	import { ITaskSearch } from '@/Models/SearchParams/ITaskSearch';
	import { LookupKeys } from '@/Enums/LookupKeys';
	import { LookupService } from '@/Services/lookup-service';
	import { MasterLookupWrapper } from '@/Models/Lookup/MasterLookupWrapper';
	import { IBaseLookup } from '@/Models/Lookup/BaseLookup';
	import SingleErrorMessage from '@/ChildrenComponents/Participant/Common/SingleErrorMessage.vue';

	export default defineComponent({
		name: 'task-list',
		components: { SingleErrorMessage },
		setup(): {currentUser: IInternalUser | null, taskSearch: ITaskSearch | null } {
			const userStoreInstance = useUserStore();
			const taskSearchStoreInstance = useTaskSearchStore();
			return { 
				currentUser: userStoreInstance.$state.cstInternalUser, 
				taskSearch: taskSearchStoreInstance.$state.taskSearch
			};
		},
		created() {
			this.loadData();
		},
		data () {
			return {
				focusedTaskListItem: {} as ITaskListPreviewItem,

				searchOptions: [
					{ key: "participantFirstName", name: "First Name", type: "text" },
					{ key: "participantLastName", name: "Last Name", type: "text" },
					{ key: "workflowType", name: "Workflow Type", type: "select", lookupKey: LookupKeys.WorkflowType, object: 0 },
					{ key: "workflowTypeStatus", name: "Workflow Status", type: "select", lookupKey: LookupKeys.WorkflowTypeStatus, object: 0 },
				],

				taskListHeaders: [
					{ title: "First Name", key: "participantFirstName", sortable: true, align: "start" },
					{ title: "Last Name", key: "participantLastName", sortable: true, align: "start" },
					{ title: "Treatment Program", key: "treatmentProgramName", sortable: true, align: "start" },
					{ title: "Workflow Type", key: "workflowType", sortable: true, align: "start" },
					{ title: "Workflow Status", key: "workflowTypeStatus", sortable: true, align: "start" },
					{ title: "Assigned To", key: "assignedUserName", sortable: true, align: "start" },
					{ title: "Last Modified On", key: "modifyDate", sortable: true, align: "start" },
					{ title: "Actions", key: "actions", sortable: false }
				] as IDataTableHeader[],
				lookups: {} as MasterLookupWrapper,
				taskListItems: [] as ITaskListPreviewItem[],
				unfilteredTaskListItems: [] as ITaskListPreviewItem[],
				searchTaskListItems: "" as string,
				isLoading: true as boolean,
                sortBy: [{ key: 'participantLastName', order: 'asc' }],

				errorMessage: "" as string,
			}
		},
		methods: {
			loadData(): void {
				this.isLoading = true;

				const lookupKeys = [
					LookupKeys.WorkflowType,
					LookupKeys.WorkflowTypeStatus,
				];

				Promise.all([
					LookupService.getLookupsByKey(lookupKeys),
					TaskListService.getTaskListItemsByInternalUser()
				]).then(([lookups, taskList]: [MasterLookupWrapper, Array<ITaskListPreviewItem>]) => {
					this.lookups = lookups;
					this.taskListItems = taskList;
					this.unfilteredTaskListItems = taskList;
					this.filterData();
				}).catch((err) => { 
					this.errorMessage = err.toString();
				}).finally(() => {
					this.isLoading = false; 
				});
			},
			formatDateForDisplay(date: Date): string {
				return DateHelper.formatDateLocal(date);
			},
			showWorkflowSection(taskListItem: ITaskListPreviewItem) {
				this.focusedTaskListItem = taskListItem;
				const url = RouterHelper.getUrl(taskListItem.workflowId, taskListItem.parentId);
				if (url) {
					this.$router.push(url);
				}
			},
			// eslint-disable-next-line no-unused-vars
			filterTaskList(value: string, query: string, _item?: any): boolean {
				return value != null &&
						query != null &&
						typeof query === 'string' &&
						value.toString().indexOf(query) !== -1;
			},
			filterData() {
				this.taskListItems = this.unfilteredTaskListItems;

				// some of the options, despite having different IDs, have identical descriptions. to make this filtering
				// behave as an end user would likely expect, we would need two levels of filtering (workflow type and status)
				// thus we need each to be a fuzzy select based on the selection and similar descriptions. these provide descriptions
				// for the current selection, which are then compared to the item descriptions based on the hasSimilarDescription predicate
				const workflowTypeDescription: string = this.lookups.lookupLists[LookupKeys.WorkflowType].find((lup: IBaseLookup) => lup.id == this.taskSearch?.workflowType)?.description || "";
				const workflowStatusDescription: string = this.lookups.lookupLists[LookupKeys.WorkflowTypeStatus].find((lup: IBaseLookup) => lup.id == this.taskSearch?.workflowTypeStatus)?.description || "";
				const hasSimilarText = (itemText: string, filterText: string) => ((itemText.indexOf(filterText) != -1) || (filterText.indexOf(itemText) != -1))

				if (this.taskSearch) {
					this.taskListItems = this.taskListItems.filter((item) => {
						let shouldShowItem = true;

						const itemTypeDescription = this.lookups.lookupLists[LookupKeys.WorkflowType].find((lookup: IBaseLookup) => lookup.id == item.workflowId)?.description || "";
						const itemStatusDescription = this.lookups.lookupLists[LookupKeys.WorkflowTypeStatus].find((lup: IBaseLookup) => lup.id == item.workflowStatusId)?.description || "";

						// Rather than enumerate the objects attached to taskSearch with "Object.entries(...)", we check
						// each value one by one since an entry of "" (empty string) should not contribute at all to the 
						// filter. similar non-filtering behavior would be expected for the categorical selects as well
						if (this.taskSearch?.participantFirstName && this.taskSearch?.participantFirstName !== "") {
							shouldShowItem = shouldShowItem && item.participantFirstName.toLowerCase().indexOf(this.taskSearch?.participantFirstName.toLowerCase()) !== -1;
						}

						if (this.taskSearch?.participantLastName && this.taskSearch?.participantLastName !== "") {
							shouldShowItem = shouldShowItem && item.participantLastName.toLowerCase().indexOf(this.taskSearch?.participantLastName.toLowerCase()) !== -1;
						}

						if (this.taskSearch?.treatmentProgram) {
							shouldShowItem = shouldShowItem && item.treatmentProgramName == this.taskSearch!.treatmentProgram;
						}

						if (this.taskSearch?.workflowType !== null && this.taskSearch?.workflowType !== 0) {
							shouldShowItem = shouldShowItem && (item.workflowId === this.taskSearch?.workflowType || hasSimilarText(itemTypeDescription.toLowerCase(), workflowTypeDescription.toLowerCase()));
						}

						if (this.taskSearch?.workflowTypeStatus !== null && this.taskSearch?.workflowTypeStatus !== 0) {
							shouldShowItem = shouldShowItem && (item.workflowStatusId === this.taskSearch?.workflowTypeStatus || hasSimilarText(itemStatusDescription.toLowerCase(), workflowStatusDescription.toLowerCase()));
						}

						// support higher level filtering based on the previously existing search bar
						// this is field agnostic and includes the non-included fields as well as those above
						// such as update date, program name, and assigned username
						if (this.searchTaskListItems != "") {
							// Each option is converted to lowercase before running our comparison to do a case insensitive filtering
							// Restoring case-based functionality requires the removal of the lowercasing chained method calls
							shouldShowItem = shouldShowItem && (
								hasSimilarText(item.participantFirstName.toLowerCase(), this.searchTaskListItems.toLowerCase())
								|| hasSimilarText(item.participantLastName.toLowerCase(), this.searchTaskListItems.toLowerCase())
								|| hasSimilarText(itemTypeDescription.toLowerCase(), this.searchTaskListItems.toLowerCase())
								|| hasSimilarText(itemStatusDescription.toLowerCase(), this.searchTaskListItems.toLowerCase())
								|| hasSimilarText(item.treatmentProgramName.toLowerCase(), this.searchTaskListItems.toLowerCase())
								|| hasSimilarText(item.assignedUserName.toLowerCase(), this.searchTaskListItems.toLowerCase())
								|| hasSimilarText(this.formatDateForDisplay(item.createDate).toLowerCase(), this.searchTaskListItems.toLowerCase())
							)
						}

						return shouldShowItem
					});
				}
			},
		},
		computed: {
			componentType(): ComponentType {
				return ComponentType.TaskList
			},
			canEditRolePermission() : boolean {
				return doesUserRoleHaveComponentEditPermissions(this.currentUser?.attachedRoles ?? [], this.componentType)
			},
			canViewRolePermission() : boolean {
				return doesUserRoleHaveComponentViewPermissions(this.currentUser?.attachedRoles ?? [], this.componentType)
			},
		}
	});
