// The Visualization Service ensures that data is fetched in a format that is compatible with the
// BaseChart chart component to prevent needing to manually transmute the data on the front end
// before it is displayed

import { VisualizationWindowSize } from "@/Enums/VisualizationTypes";
import { BaseApiService } from "../base-api-service";
import { ChartableCollection } from "@/Models/Charts/ChartableCollection";
import { IChartingOptions } from "@/Models/Charts/IChartingOptions";
import IChartRenderOptions from "@/Models/Charts/IChartRenderOptions";
import { DateHelper } from "../Helper/date-helper";

class reportService extends BaseApiService {
    public constructor() {
        super("Report/visualization/")
    }

    public async getProgramVisualizationData(windowSize: VisualizationWindowSize, params: IChartingOptions)
        : Promise<ChartableCollection> {
            return this.httpClient.put<ChartableCollection>(`GetProgramVizData?window=${windowSize}`, params)
                .then(this.handleResponse.bind(this))
                .catch(this.handleError.bind(this));
        }
}

export const ReportVisualizer: reportService = new reportService();

export abstract class VisualizerOptions {
    private static base: IChartRenderOptions = {
        chart: {
            type: 'area',
        },
        stroke: {
            curve: 'smooth',
        },
    }

    public static getGenericOptions(): IChartRenderOptions {
        return this.base;
    }

    public static getGenericProportionOptions(xlab: number[]): IChartRenderOptions {
        return {
            ...this.base,
            chart: {
                stacked: false,
            },
            plotOptions: {
                bar: {
                    columnWidth: '75%'
                }
            },
            labels: xlab,
            xaxis: {
                type: 'datetime'
            },
        };
    }

    // xlab is a list of unix time numeric labels which are converted into dates for display
    // as categorical items attached to the IChartRenderOptions object. Additionally, the
    // itemType parameter is the 'thing' that is being displayed on the radial. I.e. for the drug
    // tests, the itemType given would be 'Test' which is used in formatting the labels for the chart
    // the itemType parameter should be the singular verion of the word in the label
    public static getRadialOptions(xlab: number[], itemType: string): IChartRenderOptions {
        return {
            labels: xlab.map((d: number) => DateHelper.formatDateLocal(new Date(d), "MM/dd/yyyy")),
            chart: {
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    dataLabels: {
                        value: {
                            formatter: (d: number) => `${d} ${itemType}${d != 1 ? 's' : ''}`,
                        },
                    },
                },
            },
            xaxis: {
                type: 'datetime'
            },
        };
    }

    // getHundredStackedBarOptions will take in an optional set of xlabel dates and formats
    // a set of options which support the hundred stacked bar display type. this is for visualizing
    // the proportion of a category such as the breakdown of drug test types. It also does an overload
    // on the ApexChart colors in order to more effectively visualize the ratios
    public static getHundredStackedBarOptions(xlab?: number[]): IChartRenderOptions {
        return {
            labels: (xlab ?? []).map((d: number) => DateHelper.formatDateLocal(new Date(d), "MM/dd/yyyy")),
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                stackType: '100%'
            },
            colors: ['#2f679b', '#e78610', '#27b059', '#6b439b', '#e45482', '#49c4cd'],
            plotOptions: {
                bar: {
                    horizontal: true,
                },
            },
        };
    }

    public static getOptionsWithXaxis(xlab: number[]): IChartRenderOptions {
        return {
            ...this.base,
            ...this.addXaxis(xlab),
        }
    }

    public static getLargeOptionsWithXaxis(xlab: number[]): IChartRenderOptions {
        return {
            ...this.base,
            ...this.addXaxis(xlab),
            chart: {
                type: 'line',
                height: '600px',
                maxHeight: '800px',
            },
            
        }
    }

    private static addXaxis(xlab: number[]): IChartRenderOptions {
        return { 
            xaxis: {
                categories: xlab,
                type: 'datetime'
            }
        }
    }
}