import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createSlots as _createSlots, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "mt-4 mb-2" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  style: {"display":"inline-flex"}
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 0,
  style: {"display":"inline-flex"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ParticipantHeader = _resolveComponent("ParticipantHeader")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_SingleErrorMessage = _resolveComponent("SingleErrorMessage")!
  const _component_ValidationAlert = _resolveComponent("ValidationAlert")!
  const _component_AddAddressDialog = _resolveComponent("AddAddressDialog")!
  const _component_AddEmailDialog = _resolveComponent("AddEmailDialog")!
  const _component_AddPhoneDialog = _resolveComponent("AddPhoneDialog")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.headerData && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_ParticipantHeader, {
          key: 0,
          data: _ctx.headerData
        }, null, 8, ["data"]))
      : _createCommentVNode("", true),
    _createVNode(_component_PageHeader, { headerText: "Contact Info" }),
    (_ctx.isLoading && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_SingleErrorMessage, { errorMessage: _ctx.errorMessage }, null, 8, ["errorMessage"]),
    _createVNode(_component_ValidationAlert, { validationResults: _ctx.validationMessages }, null, 8, ["validationResults"]),
    _createVNode(_component_AddAddressDialog, {
      modelValue: _ctx.showAddressDialog,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showAddressDialog) = $event)),
      source: _ctx.selectedAddress,
      headerText: _ctx.addressHeader,
      contactTypes: _ctx.contactTypes,
      displayContactType: !_ctx.isEmergencyEdit,
      onClose: _ctx.cancelAddressEdit,
      onSave: _ctx.saveAddress
    }, null, 8, ["modelValue", "source", "headerText", "contactTypes", "displayContactType", "onClose", "onSave"]),
    _createVNode(_component_AddEmailDialog, {
      modelValue: _ctx.showEmailDialog,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showEmailDialog) = $event)),
      source: _ctx.selectedEmail,
      headerText: _ctx.emailHeader,
      contactTypes: _ctx.contactTypes,
      displayContactType: !_ctx.isEmergencyEdit,
      onClose: _ctx.cancelEmailEdit,
      onSave: _ctx.saveEmail
    }, null, 8, ["modelValue", "source", "headerText", "contactTypes", "displayContactType", "onClose", "onSave"]),
    _createVNode(_component_AddPhoneDialog, {
      modelValue: _ctx.showPhoneDialog,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showPhoneDialog) = $event)),
      source: _ctx.selectedPhone,
      headerText: _ctx.phoneHeader,
      contactTypes: _ctx.contactTypes,
      displayContactType: !_ctx.isEmergencyEdit,
      onClose: _ctx.cancelPhoneEdit,
      onSave: _ctx.savePhone
    }, null, 8, ["modelValue", "source", "headerText", "contactTypes", "displayContactType", "onClose", "onSave"]),
    (!_ctx.isLoading && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_v_card, { key: 2 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_title, { class: "sectionHeader" }, {
                          default: _withCtx(() => [
                            _createTextVNode("Participant Addresses")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_data_table, {
                          headers: _ctx.addressHeaders,
                          items: _ctx.addresses,
                          density: "compact",
                          class: "elevation-1"
                        }, _createSlots({
                          "item.isActive": _withCtx(({ item }) => [
                            _createTextVNode(_toDisplayString(_ctx.getActiveText(item.raw.isActive)), 1)
                          ]),
                          "item.address": _withCtx(({ item }) => [
                            _createElementVNode("div", null, [
                              _createTextVNode(_toDisplayString(item.raw.address1), 1),
                              (item.raw.address2)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(", " + item.raw.address2), 1))
                                : _createCommentVNode("", true),
                              _createElementVNode("span", null, ", " + _toDisplayString(item.raw.city) + ", " + _toDisplayString(item.raw.stateAbbreviation) + " " + _toDisplayString(item.raw.zip), 1)
                            ])
                          ]),
                          _: 2
                        }, [
                          (_ctx.canEditRolePermission)
                            ? {
                                name: "item.actions",
                                fn: _withCtx(({ item }) => [
                                  _createVNode(_component_v_btn, {
                                    onClick: ($event: any) => (_ctx.editAddress(item.raw)),
                                    class: "ma-1",
                                    density: "comfortable",
                                    size: "small",
                                    icon: "mdi-pencil-outline",
                                    color: "info"
                                  }, null, 8, ["onClick"])
                                ]),
                                key: "0"
                              }
                            : undefined
                        ]), 1032, ["headers", "items"]),
                        _createVNode(_component_v_row, { class: "mt-2" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { align: "center" }, {
                              default: _withCtx(() => [
                                (_ctx.canEditRolePermission)
                                  ? (_openBlock(), _createBlock(_component_v_btn, {
                                      key: 0,
                                      onClick: _ctx.newAddress,
                                      color: "primary"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Add New Address")
                                      ]),
                                      _: 1
                                    }, 8, ["onClick"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_title, { class: "sectionHeader" }, {
                          default: _withCtx(() => [
                            _createTextVNode("Participant Emails")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_data_table, {
                          headers: _ctx.emailHeaders,
                          items: _ctx.emails,
                          density: "compact",
                          class: "elevation-1"
                        }, _createSlots({
                          "item.isActive": _withCtx(({ item }) => [
                            _createTextVNode(_toDisplayString(_ctx.getActiveText(item.raw.isActive)), 1)
                          ]),
                          _: 2
                        }, [
                          (_ctx.canEditRolePermission)
                            ? {
                                name: "item.actions",
                                fn: _withCtx(({ item }) => [
                                  _createVNode(_component_v_btn, {
                                    onClick: ($event: any) => (_ctx.editEmail(item.raw)),
                                    class: "ma-1",
                                    density: "comfortable",
                                    size: "small",
                                    icon: "mdi-pencil-outline",
                                    color: "info"
                                  }, null, 8, ["onClick"])
                                ]),
                                key: "0"
                              }
                            : undefined
                        ]), 1032, ["headers", "items"]),
                        _createVNode(_component_v_row, { class: "mt-2" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { align: "center" }, {
                              default: _withCtx(() => [
                                _withDirectives(_createVNode(_component_v_btn, {
                                  onClick: _ctx.newEmail,
                                  color: "primary"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Add New Email")
                                  ]),
                                  _: 1
                                }, 8, ["onClick"]), [
                                  [_vShow, _ctx.canEditRolePermission]
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_title, { class: "sectionHeader" }, {
                          default: _withCtx(() => [
                            _createTextVNode("Participant Phone Numbers")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_data_table, {
                          headers: _ctx.phoneHeaders,
                          items: _ctx.phones,
                          density: "compact",
                          class: "elevation-1"
                        }, _createSlots({
                          "item.isActive": _withCtx(({ item }) => [
                            _createTextVNode(_toDisplayString(_ctx.getActiveText(item.raw.isActive)), 1)
                          ]),
                          "item.phoneNumber": _withCtx(({ item }) => [
                            _createTextVNode(_toDisplayString(_ctx.formatPhone(item.raw.phoneNumber)), 1)
                          ]),
                          _: 2
                        }, [
                          (_ctx.canEditRolePermission)
                            ? {
                                name: "item.actions",
                                fn: _withCtx(({ item }) => [
                                  _createVNode(_component_v_btn, {
                                    onClick: ($event: any) => (_ctx.editPhone(item.raw)),
                                    class: "ma-1",
                                    density: "comfortable",
                                    size: "small",
                                    icon: "mdi-pencil-outline",
                                    color: "info"
                                  }, null, 8, ["onClick"])
                                ]),
                                key: "0"
                              }
                            : undefined
                        ]), 1032, ["headers", "items"]),
                        _createVNode(_component_v_row, { class: "mt-2" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { align: "center" }, {
                              default: _withCtx(() => [
                                _withDirectives(_createVNode(_component_v_btn, {
                                  onClick: _ctx.newPhone,
                                  color: "primary"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Add New Phone")
                                  ]),
                                  _: 1
                                }, 8, ["onClick"]), [
                                  [_vShow, _ctx.canEditRolePermission]
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_divider, {
                      thickness: 3,
                      class: "my-4"
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_v_card_title, { class: "sectionHeader" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Emergency Contacts")
                    ]),
                    _: 1
                  })
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.emergencyContacts, (emergencyContactInfo, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: emergencyContactInfo.participantEmergencyContactInformationId
                  }, [
                    _createVNode(_component_v_row, { justify: "space-between" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "4",
                          class: "pb-0"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_select, {
                              items: _ctx.contactTypes,
                              "item-value": "id",
                              "item-title": "description",
                              label: "Select Contact Type",
                              modelValue: emergencyContactInfo.contactTypeId,
                              "onUpdate:modelValue": ($event: any) => ((emergencyContactInfo.contactTypeId) = $event),
                              readonly: !_ctx.canEditRolePermission
                            }, null, 8, ["items", "modelValue", "onUpdate:modelValue", "readonly"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_v_col, {
                          cols: "4",
                          class: "pb-0"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              label: "Emergency Contact Name",
                              modelValue: emergencyContactInfo.contactName,
                              "onUpdate:modelValue": ($event: any) => ((emergencyContactInfo.contactName) = $event),
                              readonly: !_ctx.canEditRolePermission
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "readonly"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_v_col, {
                          cols: "4",
                          class: "pb-0"
                        }, {
                          default: _withCtx(() => [
                            (_ctx.canEditRolePermission)
                              ? (_openBlock(), _createBlock(_component_v_btn, {
                                  key: 0,
                                  class: "mr-2",
                                  onClick: ($event: any) => (_ctx.saveEmergencyContact(index)),
                                  density: "comfortable",
                                  icon: "",
                                  color: "info",
                                  disabled: _ctx.isEmergencyContactSaving
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_tooltip, {
                                      activator: "parent",
                                      location: "bottom"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(" Save this contact ")
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_icon, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode("mdi-content-save")
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 2
                                }, 1032, ["onClick", "disabled"]))
                              : _createCommentVNode("", true),
                            (_ctx.canEditRolePermission && !emergencyContactInfo.participantEmergencyContactInformationId)
                              ? (_openBlock(), _createBlock(_component_v_btn, {
                                  key: 1,
                                  onClick: ($event: any) => (_ctx.removeEmergencyContactObject(index)),
                                  density: "comfortable",
                                  icon: "",
                                  color: "info"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_tooltip, {
                                      activator: "parent",
                                      location: "bottom"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(" Delete this entire emergency contact ")
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_icon, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode("mdi-delete")
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_data_table, {
                              headers: _ctx.addressHeaders.filter(x => x.key !== 'contactType'),
                              items: [emergencyContactInfo.associatedAddress],
                              density: "comfortable",
                              class: "elevation-1",
                              "hide-default-footer": ""
                            }, _createSlots({
                              "item.isActive": _withCtx(({ item }) => [
                                _createTextVNode(_toDisplayString(_ctx.getActiveText(item.raw.isActive)), 1)
                              ]),
                              "item.address": _withCtx(({ item }) => [
                                _createElementVNode("div", null, [
                                  _createTextVNode(_toDisplayString(item.raw.address1), 1),
                                  (item.raw.address2)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(", " + item.raw.address2), 1))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("span", null, ", " + _toDisplayString(item.raw.city) + ", " + _toDisplayString(item.raw.stateAbbreviation) + " " + _toDisplayString(item.raw.zip), 1)
                                ])
                              ]),
                              bottom: _withCtx(() => []),
                              _: 2
                            }, [
                              (_ctx.canEditRolePermission)
                                ? {
                                    name: "item.actions",
                                    fn: _withCtx(({ item }) => [
                                      _createVNode(_component_v_btn, {
                                        onClick: ($event: any) => (_ctx.editAddress(item.raw, index)),
                                        class: "ma-1",
                                        density: "comfortable",
                                        size: "small",
                                        icon: "mdi-pencil-outline",
                                        color: "info"
                                      }, null, 8, ["onClick"])
                                    ]),
                                    key: "0"
                                  }
                                : undefined
                            ]), 1032, ["headers", "items"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          md: "6"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_data_table, {
                              headers: _ctx.emailHeaders.filter(x => x.key !== 'contactType'),
                              items: [emergencyContactInfo.associatedEmail],
                              density: "comfortable",
                              class: "elevation-1",
                              "hide-default-footer": ""
                            }, {
                              "item.isActive": _withCtx(({ item }) => [
                                (item.raw)
                                  ? (_openBlock(), _createElementBlock("tr", _hoisted_4, [
                                      _createElementVNode("td", null, _toDisplayString(item.raw.isActive ? 'Yes': 'No'), 1)
                                    ]))
                                  : _createCommentVNode("", true)
                              ]),
                              "item.actions": _withCtx(({ item }) => [
                                (item.raw)
                                  ? (_openBlock(), _createElementBlock("tr", _hoisted_5, [
                                      _createElementVNode("td", null, [
                                        (_ctx.canEditRolePermission)
                                          ? (_openBlock(), _createBlock(_component_v_btn, {
                                              key: 0,
                                              onClick: ($event: any) => (_ctx.editEmail(item.raw, index)),
                                              density: "comfortable",
                                              size: "small",
                                              icon: "",
                                              color: "info"
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_icon, null, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode("mdi-pencil")
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 2
                                            }, 1032, ["onClick"]))
                                          : _createCommentVNode("", true)
                                      ])
                                    ]))
                                  : _createCommentVNode("", true)
                              ]),
                              bottom: _withCtx(() => []),
                              _: 2
                            }, 1032, ["headers", "items"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          md: "6"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_data_table, {
                              headers: _ctx.phoneHeaders.filter(x => x.key !== 'contactType'),
                              items: [emergencyContactInfo.associatedPhone],
                              density: "comfortable",
                              class: "elevation-1",
                              "hide-default-footer": ""
                            }, {
                              "item.isActive": _withCtx(({ item }) => [
                                (item.raw)
                                  ? (_openBlock(), _createElementBlock("tr", _hoisted_6, [
                                      _createElementVNode("td", null, _toDisplayString(item.raw.isActive ? 'Yes': 'No'), 1)
                                    ]))
                                  : _createCommentVNode("", true)
                              ]),
                              "item.actions": _withCtx(({ item }) => [
                                (item.raw)
                                  ? (_openBlock(), _createElementBlock("tr", _hoisted_7, [
                                      _createElementVNode("td", null, [
                                        (_ctx.canEditRolePermission)
                                          ? (_openBlock(), _createBlock(_component_v_btn, {
                                              key: 0,
                                              onClick: ($event: any) => (_ctx.editPhone(item.raw, index)),
                                              density: "comfortable",
                                              size: "small",
                                              icon: "",
                                              color: "info"
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_icon, null, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode("mdi-pencil")
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 2
                                            }, 1032, ["onClick"]))
                                          : _createCommentVNode("", true)
                                      ])
                                    ]))
                                  : _createCommentVNode("", true)
                              ]),
                              bottom: _withCtx(() => []),
                              _: 2
                            }, 1032, ["headers", "items"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        (index === _ctx.emergencyContacts.length-1)
                          ? (_openBlock(), _createBlock(_component_v_col, {
                              key: 0,
                              class: "pb-6"
                            }, {
                              default: _withCtx(() => [
                                (_ctx.canEditRolePermission)
                                  ? (_openBlock(), _createBlock(_component_v_btn, {
                                      key: 0,
                                      onClick: _ctx.addEmergencyContactObject,
                                      color: "primary"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_tooltip, {
                                          activator: "parent",
                                          location: "bottom"
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(" Add a new emergency contact ")
                                          ]),
                                          _: 1
                                        }),
                                        _createTextVNode(" Add New Emergency Contact ")
                                      ]),
                                      _: 1
                                    }, 8, ["onClick"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }))
                          : (_openBlock(), _createBlock(_component_v_col, { key: 1 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_divider, {
                                  thickness: 3,
                                  class: "my-2"
                                })
                              ]),
                              _: 1
                            }))
                      ]),
                      _: 2
                    }, 1024)
                  ]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}