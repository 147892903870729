
	import { defineComponent } from 'vue';

	import { loadParticipantDataRequireCase } from '@/Services/Helper/loader-helper';
	import { useUserStore } from '@/Services/Store/user-store';
    import { TemplateEngine } from '@/Services/Templating/template-engine';
	import { doesUserRoleHaveComponentEditPermissions } from '@/Services/Helper/component-permissions-helper';

    import { DocumentParentType } from '@/Enums/DocumentParentType';
	import { Role } from '@/Enums/Role';
	import { ComponentType } from '@/Enums/ComponentType';

	import { IInternalUser } from '@/Models/IInternalUser';
    import { IPhase } from '@/Models/IPhase';
    import { ICase } from '@/Models/ICase';
    import { IParticipant } from '@/Models/IParticipant';
    import { IVariableInfill } from '@/Models/Templating/IVariableInfill';
    import HeaderData from '@/Models/HeaderData';

	import LoadingSpinner from '@/ChildrenComponents/LoadingSpinner.vue';
	import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';
	import DocumentList from '@/ChildrenComponents/DocumentList.vue';
	import PageHeader from '@/ChildrenComponents/Participant/Common/PageHeader.vue';
	import ParticipantHeader from '@/ChildrenComponents/Participant/Common/ParticipantHeader.vue';
	import SingleErrorMessage from '@/ChildrenComponents/Participant/Common/SingleErrorMessage.vue';
    import DocumentGenerator from '@/ChildrenComponents/Templating/DocumentGenerator.vue';

	export default defineComponent({
		name: "case-documents",
		components: { 
            ParticipantHeader, 
            LoadingSpinner, 
            ValidationAlert,
            PageHeader, 
            SingleErrorMessage, 
            DocumentList,
            DocumentGenerator,
        },
		setup(): { currentUser: IInternalUser | null } {
			const userStoreInstance = useUserStore();
			return { currentUser: userStoreInstance.$state.cstInternalUser };
		},
		created() {
			loadParticipantDataRequireCase(this.pageType, this.id)
                .then(data => {
                    this.caseId = data.case?.caseId ?? null;
                    this.headerData = new HeaderData(data.participant, data.case?.program ?? null);
                    this.phaseNumber = data.phase?.phaseNumber ?? null;

                    if (data.case) {
                        this.caseData = data.case;
                    }
                    if (data.phase) {
                        this.phaseData = data.phase;
                    }
                    if (data.participant) {
                        this.participantData = data.participant;
                    }
                })
				.catch(async (err) => {
                    const errMessage = await err.response.data.text();
                    this.errorMessage = (errMessage && errMessage != "") ? errMessage : err.toString();
                })
				.finally(() => {
					this.isLoading = false;
				});
		},
		data() {
			return {
				errorMessage: "",
				validationMessages: [] as string[],

				pageType: "case",
				id: this.$route.params.id == undefined ? null : parseInt(this.$route.params.id.toString()),
                caseId: null as number | null,
                phaseNumber: null as number | null,
				headerData: null as HeaderData | null,
                // parentType here refers to what type of owner there is for the document. i.e. is the document
                // owned by a case, owned by a phase, etc.
				parentTypeId: DocumentParentType.Case as number,
                caseData: {} as ICase,
                phaseData: {} as IPhase,
                participantData: {} as IParticipant,

                refreshKey: 0 as number,
                DocumentParentType,

                isLoading: true,
			}
		},
        methods: {
            refreshDocuments() {
                // this toggle will force a refresh in the document list object
                this.refreshKey += 1;
            },
        },
		computed: {
			canDelete(): boolean {
				const roles = this.currentUser?.attachedRoles ?? [];
				return roles.find(x => x.roleId == Role.StateAdministrator) != undefined;
			},
			canUpload(): boolean {
				return doesUserRoleHaveComponentEditPermissions(this.currentUser?.attachedRoles ?? [], ComponentType.CaseDocuments);
			},
            infillVars(): IVariableInfill {
                return TemplateEngine.fillVarWithObjects([
                    this.participantData,
                    this.caseData,
                    this.phaseData,
                ])
            },
		},
	});
