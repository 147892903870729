export type ValidationRuleType = (value: any) => boolean | string;

export abstract class ValidationHelper {
	private static emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;

	public static requiredRule = (value: any) => !!value || 'This field is REQUIRED.';
	public static atLeastOneRule = (value: any) => value.length > 0 || "At least ONE option must be selected.";
	
	public static emailRule = (value: any) => this.emailPattern.test(value) || 'Please enter a valid email address.';
	public static usPhoneRule = (value: any) => [7, 10].includes(value.replace(/\D+/g, "").length) || 'Please enter a valid phone number.';

	public static requiredRules = [this.requiredRule];
	public static atLeastOneRules = [this.atLeastOneRule];
	public static emailRules = [this.requiredRule, this.emailRule];
	public static phoneRules = [this.requiredRule, this.usPhoneRule];
}