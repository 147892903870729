
import { defineComponent, Ref, ref } from 'vue';

import { IInternalUser } from '@/Models/IInternalUser';
import { ITreatmentProgram } from '@/Models/ITreatmentProgram';

import { ValidationHelper } from '@/Services/Helper/validation-helper';
import { useUserStore } from '@/Services/Store/user-store';

export default defineComponent({
	name: "program-select-dialog",
	emits: ["close", "save"],
	setup(): { currentUser: IInternalUser | null, form: Ref } {
		const userStoreInstance = useUserStore();
		const currentUser = userStoreInstance.$state.cstInternalUser;
		const form = ref(null);
		return { currentUser, form };
	},
	created() {
		this.programs = this.currentUser?.attachedPrograms ?? [];
		if (this.programs.length == 0) {
			this.$emit("close");
		}
		else if (this.programs.length == 1) {
			this.selectedProgram = this.programs[0];
		}
	},
	data () {
		return {
			selectedProgram: null as ITreatmentProgram | null,
			programs: [] as ITreatmentProgram[],
			requiredFieldRules: ValidationHelper.requiredRules,
			buttonText: "Confirm and Create Case",
			isSaving: false,
		}
	},
	methods: {
		closeDialog(): void {
			this.$emit("close");
		},
		async save() {
			const { valid } = await this.form.validate();
			if (valid && this.selectedProgram) {
				this.isSaving = true;
				this.buttonText = "Creating..."
				this.$emit("save", this.selectedProgram.treatmentProgramId);
			}
		},
	},
});
