import { ICustodyResidential } from '@/Models/ICustodyResidential';
import { ITypedValidationResult } from '@/Models/IValidationResult';
import { BaseApiService } from './base-api-service';

class custodyResidentialService extends BaseApiService {
    public constructor() {
        super('custodyresidential/');
    }

    public async getByPhaseId(phaseId: number): Promise<ICustodyResidential[]> {
        return this.httpClient.get<ICustodyResidential[]>(`GetByPhaseId/${phaseId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
    public async save(dto: ICustodyResidential): Promise<ITypedValidationResult<ICustodyResidential>> {
        return this.httpClient.post<ITypedValidationResult<ICustodyResidential>>("save", dto).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}

	public async delete(id: number): Promise<void> {
		return this.httpClient.delete<void>(`delete/${id}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}
}

export const CustodyResidentialService: custodyResidentialService = new custodyResidentialService();