
import { defineComponent, PropType } from 'vue';
import { RoleService } from '@/Services/role-service';
import { TreatmentProgramService } from '@/Services/treatment-program-service';

import type { IInternalUser } from '@/Models/IInternalUser';
import { IInternalUserRole } from '@/Models/IInternalUserRole';
import { ITreatmentProgram } from '@/Models/ITreatmentProgram';


export default defineComponent({
  name: 'user-drilldown-dialog',
  components: { },

  setup(): { } {
    return {      
    };
  },
  beforeCreate() {
    
  },
  created() {
    RoleService.getAllRoles().then((response) => {
        this.selectableRoles = response;
    });
    TreatmentProgramService.getAllTreatmentPrograms().then((response) => {
        this.selectableTreatmentPrograms = response;
    });
  },
  data () {
    return {
        selectableTreatmentPrograms: [] as ITreatmentProgram[],
        selectableRoles: [] as IInternalUserRole[],

        selectedProgramIds: [] as number[],
        selectedRoleIds: [] as number[],
        
        mutableUserObject: {} as IInternalUser,

        mutableDialogSwitch: undefined as boolean | undefined,
        isFormValid: false as boolean,

        atLeastOneRules: [
            (value: any) => value.length > 0 || "At least ONE option must be selected."
        ],
        requiredFieldRules: [
            (value: any) => !!value || 'This field is REQUIRED.',
        ],
    }
  },
  props: {
    suppliedUser: {
        type: Object as PropType<IInternalUser>,
        required: true
    },
    dialog: {
        type: Boolean,
        required: true
    },
  },
  watch: {
    dialog(newValue: boolean): void {
        this.mutableDialogSwitch = newValue;
    },
    suppliedUser(newValue: IInternalUser): void {
        this.mutableUserObject = JSON.parse(JSON.stringify(newValue));
        this.selectedRoleIds = newValue.attachedRoles.map((role: IInternalUserRole) => role.roleId);
        this.selectedProgramIds = newValue.attachedPrograms.map((program: ITreatmentProgram) => program.treatmentProgramId);
    }
  },
  methods: {
    closeDialog(): void {
        this.$emit("close-user-dialog");
    },
    submitChanges(): void {
        if (this.isFormValid !== false) {
            this.mutableUserObject.attachedRoles = new Array<IInternalUserRole>();
            this.mutableUserObject.attachedPrograms = new Array<ITreatmentProgram>();
            
            this.selectedRoleIds.forEach((e: number) => {
                this.mutableUserObject.attachedRoles.push({roleId: e, description: ''});
            });
            this.selectedProgramIds.forEach((e: number) => {
                this.mutableUserObject.attachedPrograms.push({treatmentProgramId: e, description: '', countyId: 0, courtId: 0, phaseTypeId: 0});
            });
            this.$emit("refresh-users", this.mutableUserObject);
            this.$emit("close-user-dialog");
        }
        else {
            return;
        }

    }
  }
});
