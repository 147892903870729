import { BaseApiService } from './base-api-service';
import { IPhase } from '@/Models/IPhase';
import { ITypedValidationResult } from '@/Models/IValidationResult';
import { IPhaseSection } from '@/Models/IPhaseSection';

class phaseService extends BaseApiService {
    public constructor() {
        super('phase/');
    }

	public getPhase(id: number): Promise<IPhase> {
		return this.httpClient.get<IPhase>(id.toString()).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

	public startPhase(caseId: number, phaseNumber: number): Promise<ITypedValidationResult<IPhase>> {
		return this.httpClient.post<ITypedValidationResult<IPhase>>(`startPhase/${caseId}/${phaseNumber}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}

	public async save(dto: IPhase): Promise<ITypedValidationResult<IPhase>> {
		return this.httpClient.post<ITypedValidationResult<IPhase>>("save", dto).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}

	// used in the batch entry, phase sections are dynamically loaded from the backend based on the
	// authenticated users roles
	public async getPhaseSectionsByRole(): Promise<IPhaseSection[]> {
		return this.httpClient.get<IPhaseSection[]>("GetPhaseSectionForUserByRoles")
			.then(this.handleResponse.bind(this))
			.catch(this.handleError.bind(this));
	}
}

export const PhaseService : phaseService = new phaseService();