import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!

  return (_openBlock(), _createBlock(_component_v_list, {
    rounded: "lg",
    id: "navigationSidebar"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_list, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_divider, { class: "my-2" }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherLinks, (link) => {
            return (_openBlock(), _createBlock(_component_v_list_item, {
              key: link.endpoint
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, { color: "primary" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: link.to,
                      class: "text-white"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(link.label), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }),
      _createVNode(_component_v_divider, { class: "my-2" }),
      (_ctx.participantId || _ctx.caseId || _ctx.phaseId || _ctx.eligibilityId)
        ? (_openBlock(), _createBlock(_component_v_list, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_divider, { class: "my-2" }),
              _createVNode(_component_v_list_item, null, {
                default: _withCtx(() => [
                  _createTextVNode("Participant")
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.participantMenuLinks, (link) => {
                return (_openBlock(), _createBlock(_component_v_list_item, {
                  key: link.endpoint
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, { color: "primary" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_router_link, {
                          to: _ctx.getUrl(link.endpoint),
                          class: "text-white"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(link.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.caseId || _ctx.phaseId || _ctx.eligibilityId)
        ? (_openBlock(), _createBlock(_component_v_list, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_divider, { class: "my-2" }),
              _createVNode(_component_v_list_item, null, {
                default: _withCtx(() => [
                  _createTextVNode("Case")
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.caseMenuLinks, (link) => {
                return (_openBlock(), _createBlock(_component_v_list_item, {
                  key: link.endpoint
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, { color: "primary" }, {
                      default: _withCtx(() => [
                        (!link.base)
                          ? (_openBlock(), _createBlock(_component_router_link, {
                              key: 0,
                              to: _ctx.getUrl(link.endpoint),
                              class: "text-white"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(link.label), 1)
                              ]),
                              _: 2
                            }, 1032, ["to"]))
                          : (_openBlock(), _createBlock(_component_router_link, {
                              key: 1,
                              to: _ctx.getBasedUrl(link.base, link.endpoint),
                              class: "text-white"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(link.label), 1)
                              ]),
                              _: 2
                            }, 1032, ["to"]))
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.phaseId)
        ? (_openBlock(), _createBlock(_component_v_list, { key: 2 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_divider, { class: "my-2" }),
              _createVNode(_component_v_list_item, null, {
                default: _withCtx(() => [
                  _createTextVNode("Phase")
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.phaseMenuLinks, (link) => {
                return (_openBlock(), _createBlock(_component_v_list_item, {
                  key: link.endpoint
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, { color: "primary" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_router_link, {
                          to: _ctx.getUrl(link.endpoint),
                          class: "text-white"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(link.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}