
import type { ICounty } from '@/Models/Lookup/ICounty';
import { BaseApiService } from './base-api-service';

class countyService extends BaseApiService{
    
    public constructor() {
        super('county/');
    }

    public async getAllCounties(): Promise<Array<ICounty>> {
        return this.httpClient.get<ICounty[]>(`get-all-counties`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
}

export const CountyService : countyService = new countyService();