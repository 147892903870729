
import { BaseApiService } from './base-api-service';
import type { ICase } from '@/Models/ICase';
import { ITypedValidationResult } from '@/Models/IValidationResult';

class caseService extends BaseApiService {
    public constructor() {
        super('case/');
    }

	//Use when case is required. Requires non-null case id.
	public getCase(id: number): Promise<ICase> {
		return this.httpClient.get<ICase>(id.toString()).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

	//Use when case is optional. Doesn't throw an error if case id is null.
    public maybeGetCase(id: number | null): Promise<ICase | null> {
		if (id != null)
			return this.httpClient.get<ICase>(id.toString()).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
		else
			return new Promise((resolve) => resolve(null));
    }

	public getActiveCaseByParticipant(participantId: number): Promise<ICase> {
		return this.httpClient.get<ICase>(`active/${participantId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}

	public getCaseHistoryByParticipant(participantId: number): Promise<ICase[]> {
		return this.httpClient.get<ICase[]>(`history/${participantId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}

	public createCase(participantId: number, programId: number): Promise<ITypedValidationResult<ICase>> {
		return this.httpClient.post<ITypedValidationResult<ICase>>("create", null, { params: { participantId, programId }}).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}
}

export const CaseService : caseService = new caseService();