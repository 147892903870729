import { BaseApiService } from './base-api-service';

import { MasterLookupWrapper } from '@/Models/Lookup/MasterLookupWrapper';
import { BehaviorResponseLookupWrapper } from '@/Models/Lookup/BehaviorResponseLookupWrapper';
import { IBaseLookup } from '@/Models/Lookup/BaseLookup';
import { IDocumentType } from '@/Models/Lookup/IDocumentType';
import { IState } from '@/Models/Lookup/IState';
import { LookupKeys } from '@/Enums/LookupKeys';

class lookupService extends BaseApiService {
    public constructor() {
        super('Lookup/');
	}

	public getDocumentTypeList(): Promise<IDocumentType[]> {
		return this.httpClient.get<IDocumentType[]>("GetDocumentTypeList").then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}

	public getStateList(): Promise<IState[]> {
		return this.httpClient.get<IState[]>("GetStateList").then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}

	public getBehaviorResponseLookups(): Promise<BehaviorResponseLookupWrapper> {
		return this.httpClient.get<BehaviorResponseLookupWrapper>("GetBehaviorResponseLookups").then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}

	public getAllDataEntryLookups(): Promise<MasterLookupWrapper> {
		return this.httpClient.get<MasterLookupWrapper>("get-all-data-entry-lookups").then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}

	public getLookupsByKey(keys: LookupKeys[]): Promise<MasterLookupWrapper> {
		const encodedKeys = keys.map(x => `keys=${x}`).join("&");
		return this.httpClient.get<MasterLookupWrapper>(`GetLookupsByKey?${encodedKeys}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}

	public getLookupByKey(key: LookupKeys): Promise<IBaseLookup[]> {
		return this.httpClient.get<IBaseLookup[]>(`GetLookupByKey/${key}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}
}

export const LookupService : lookupService = new lookupService();