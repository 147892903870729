import { IProvider } from '@/Models/IProvider';
import { ITypedValidationResult } from '@/Models/IValidationResult';
import { BaseApiService } from './base-api-service';

class providerService extends BaseApiService{
    
    public constructor() {
		super('provider/');
    }

	public async getActiveList(): Promise<IProvider[]> {
		return this.httpClient.get<IProvider[]>("GetActiveList").then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
	public async save(provider: IProvider): Promise<ITypedValidationResult<IProvider>> {
		return this.httpClient.post<ITypedValidationResult<IProvider>>("save", provider).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}

	public async delete(providerId: number): Promise<void> {
		return this.httpClient.delete<void>(`provider/${providerId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}
}

export const ProviderService: providerService = new providerService();