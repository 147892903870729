
import { defineComponent } from 'vue';
import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';

import type { IAccessRequest } from '@/Models/IAccessRequest';
import type { IInternalUserRole } from '@/Models/IInternalUserRole';
import type { ICounty } from '@/Models/Lookup/ICounty';
import type { ICourt } from '@/Models/ICourt';
import type { ITreatmentProgram } from '@/Models/ITreatmentProgram';
import type { Auth0PluginModel } from '@/Models/Auth0/auth0-plugin-model';

import { AccessRequestService } from '@/Services/access-request-service';
import { RoleService } from '@/Services/role-service';
import { CountyService } from '@/Services/county-service';
import { CourtService } from '@/Services/court-service';
import { TreatmentProgramService } from '@/Services/treatment-program-service';

import { useAuth0 } from '@/Services/Auth/auth0-plugin';


export default defineComponent({
  name: 'access-request',
  components: { ValidationAlert },
  setup(): {auth0Instance: Auth0PluginModel | null } {
    const auth0Instance = useAuth0();
    return {
        auth0Instance
    };
  },
  created() {
    Promise.all([this.getRoles(), this.getCounties(), this.getExistingAccessRequest()]).then(() => {
        this.accessRequestObject.email = this.auth0Instance?.user.value?.email!;
        this.isLoading = false;
    });
  },
  data () {
    return {
        accessRequestObject: {} as IAccessRequest,
        selectableRoles: [] as IInternalUserRole[],
        selectableCounties: [] as ICounty[],
        selectableCourts: [] as ICourt[],
        selectableTreatmentPrograms: [] as ITreatmentProgram[],

        selectedRoleIds: [] as number[],
        selectedCountyIds: [] as number[],
        selectedCourtIds: [] as number[],
        selectedTreatmentProgramIds: [] as number[],
        
        accessRequestExists: false as boolean,
        programSelectDisabled: true as boolean,
        courtSelectDisabled: true as boolean,
        isLoading: true as boolean,
        isFormValid: false as boolean,

        atLeastOneRules: [
            (value: any) => value.length > 0 || "At least ONE option must be selected."
        ],
        requiredFieldRules: [
            (value: any) => !!value || 'This field is REQUIRED.',
        ],

        validationResults: [] as string[]
    }
  },
  watch: {
    selectedCountyIds(newIds: number[]): void {
        if (newIds.length > 0) {
            this.courtSelectDisabled = false;
            CourtService.getAllCourtsByCountyIds(newIds).then((response) => {
                this.selectableCourts = response;
                return;
            });
        }
        else {
            this.selectableCourts = new Array<ICourt>();
            this.selectedCourtIds = new Array<number>();
            this.courtSelectDisabled = true;
            return;
        }
    },
    selectedCourtIds(newIds: number[]): void {
        if (newIds.length > 0 && this.selectedCountyIds.length > 0) {
            this.programSelectDisabled = false;
            TreatmentProgramService.getTreatmentProgramsByCountyIdsAndCourtIds(this.selectedCountyIds, newIds).then((response) => {
                this.selectableTreatmentPrograms = response;
                return;
            });
        }
        else {
            this.selectableTreatmentPrograms = new Array<ITreatmentProgram>();
            this.selectedTreatmentProgramIds= new Array<number>();
            this.programSelectDisabled = true;
            return;
        }
    }
  },
  methods: {
    getRoles() : Promise<void> {
        return new Promise((resolve) => {
            RoleService.getAllRoles().then((response) => {
                this.selectableRoles = response;
                resolve();
            });
        });
    },
    getCounties() : Promise<void> {
        return new Promise((resolve) => {
            CountyService.getAllCounties().then((response) => {
                this.selectableCounties = response;
                resolve();
            });
        });
    },
    getExistingAccessRequest() : Promise<void> {
        return new Promise((resolve) => {
            AccessRequestService.getAccessRequestByAuth0GUID().then((response) => {
                if (response.accessRequestId > 0 && response.isRejected !== true) {
                    this.accessRequestExists = true;
                    resolve();
                }
                else {
                    resolve();
                }
            });
        });
    },
    submitAccessRequest(): void {
        if (!this.isFormValid) {
            return;
        }
        this.accessRequestObject.requestedRoleIds = this.selectedRoleIds.toString();
        this.accessRequestObject.requestedTreatmentProgramIds = this.selectedTreatmentProgramIds.toString();
        AccessRequestService.createNewAccessRequest(this.accessRequestObject).then((response) => {
            if (response.messages.length > 0) {
                this.validationResults = response.messages;
            }
            else {
                this.accessRequestExists = true;
            }
        })
    }
  }
});
