
import { defineComponent, PropType } from 'vue';


export default defineComponent({
    name: 'success-alert',
    components: { },

    setup(): { } {
        return {};
    },
    props: {
        // Note: this component will automatically clear this prop after a message has been displayed
        // for the timeout length. If the message needs to be saved, it must be done by the enclosing
        // component, not this one. Pass in a temporary reference if necessary.
        feedbackMessages: {
            type: Array as PropType<string[]>,
            required: true,
        },
        timeout: {
            type: Number,
            required: false,
            default: 5000, // 5 seconds
        }
    },
    data () {
        return {
            successMessages: [] as string[],
        }
    },
    watch: {
        feedbackMessages(updatedProp) { updatedProp.forEach((val: string) => {
            this.autoClearAfterDuration(val);
        }) }
    },
    methods: {
        autoClearAfterDuration(note: string) {
            this.successMessages.push(note);
            setTimeout(() => {
                const index = this.successMessages.indexOf(note);
                if (index > -1) {
                    this.successMessages.splice(index, 1);
                }
            }, this.timeout);
        }
    }
});
