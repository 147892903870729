import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IncorrectPermissions = _resolveComponent("IncorrectPermissions")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_ValidationAlert = _resolveComponent("ValidationAlert")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_item = _resolveComponent("v-card-item")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_ReportExporter = _resolveComponent("ReportExporter")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_UserDrilldownDialog = _resolveComponent("UserDrilldownDialog")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_ctx.isPermissionDenied)
    ? (_openBlock(), _createBlock(_component_v_container, {
        key: 0,
        fluid: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IncorrectPermissions)
        ]),
        _: 1
      }))
    : (!_ctx.isPermissionDenied)
      ? (_openBlock(), _createBlock(_component_v_container, {
          key: 1,
          fluid: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_sheet, {
              elevation: "8",
              height: "100%",
              width: "100%"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ValidationAlert, { "validation-results": _ctx.validationResults }, null, 8, ["validation-results"]),
                (_ctx.isLoading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createVNode(_component_v_progress_circular, {
                        size: 70,
                        width: 7,
                        color: "loading",
                        indeterminate: ""
                      })
                    ]))
                  : (!_ctx.isLoading)
                    ? (_openBlock(), _createBlock(_component_v_card, { key: 1 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_toolbar, {
                            dark: "",
                            flat: "",
                            prominent: "",
                            color: "secondary",
                            class: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_tabs, {
                                centered: "",
                                dark: "",
                                "slider-color": "deep-purple accent-4",
                                modelValue: _ctx.tabs,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tabs) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_tab, { value: "0" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_icon, { left: "" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode("mdi-account-check")
                                        ]),
                                        _: 1
                                      }),
                                      _createTextVNode(" Active Users ")
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }, 8, ["modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_window, {
                            modelValue: _ctx.tabs,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.tabs) = $event))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_window_item, { value: "0" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_card, { flat: "" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_card_item, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_card_title, { class: "black--text" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode("Active Users")
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_card_title, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_row, null, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_col, {
                                                    cols: "12",
                                                    md: "4",
                                                    lg: "3"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_v_text_field, {
                                                        modelValue: _ctx.searchOptions.firstName,
                                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchOptions.firstName) = $event)),
                                                        onInput: _ctx.filterUsers,
                                                        "single-line": "",
                                                        "hide-details": "",
                                                        label: "First Name",
                                                        color: "white",
                                                        class: "searchText"
                                                      }, null, 8, ["modelValue", "onInput"])
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _createVNode(_component_v_col, {
                                                    cols: "12",
                                                    md: "4",
                                                    lg: "3"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_v_text_field, {
                                                        modelValue: _ctx.searchOptions.lastName,
                                                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchOptions.lastName) = $event)),
                                                        onInput: _ctx.filterUsers,
                                                        "single-line": "",
                                                        "hide-details": "",
                                                        label: "Last Name",
                                                        color: "white",
                                                        class: "searchText"
                                                      }, null, 8, ["modelValue", "onInput"])
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _createVNode(_component_v_col, {
                                                    cols: "12",
                                                    md: "4",
                                                    lg: "3"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_v_text_field, {
                                                        modelValue: _ctx.searchOptions.email,
                                                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.searchOptions.email) = $event)),
                                                        onInput: _ctx.filterUsers,
                                                        "single-line": "",
                                                        "hide-details": "",
                                                        label: "Email Address",
                                                        color: "white",
                                                        class: "searchText"
                                                      }, null, 8, ["modelValue", "onInput"])
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _createVNode(_component_v_col, {
                                                    cols: "12",
                                                    md: "6",
                                                    lg: "4"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_v_select, {
                                                        modelValue: _ctx.searchOptions.treatmentProgram,
                                                        "onUpdate:modelValue": [
                                                          _cache[4] || (_cache[4] = ($event: any) => ((_ctx.searchOptions.treatmentProgram) = $event)),
                                                          _ctx.filterUsers
                                                        ],
                                                        clearable: "",
                                                        items: _ctx.treatmentPrograms,
                                                        "item-value": "id",
                                                        "item-title": "description",
                                                        label: "Treatment Program",
                                                        class: "searchText overflowSelect"
                                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "items"])
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _createVNode(_component_v_col, {
                                                    cols: "12",
                                                    md: "6",
                                                    lg: "4"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_v_select, {
                                                        modelValue: _ctx.searchOptions.role,
                                                        "onUpdate:modelValue": [
                                                          _cache[5] || (_cache[5] = ($event: any) => ((_ctx.searchOptions.role) = $event)),
                                                          _ctx.filterUsers
                                                        ],
                                                        clearable: "",
                                                        items: _ctx.availableRoles,
                                                        "item-value": "id",
                                                        "item-title": "description",
                                                        label: "User Roles",
                                                        class: "searchText overflowSelect"
                                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "items"])
                                                    ]),
                                                    _: 1
                                                  })
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_card_text, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_data_table, {
                                            density: "compact",
                                            headers: _ctx.activeUsersHeaders,
                                            items: _ctx.filteredUsers,
                                            class: "elevation-1"
                                          }, {
                                            [`item.attachedRoles`]: _withCtx(({ item }) => [
                                              _createElementVNode("span", null, _toDisplayString(_ctx.presentAttachedRoles(item.raw.attachedRoles)), 1)
                                            ]),
                                            [`item.attachedPrograms`]: _withCtx(({ item }) => [
                                              _createElementVNode("span", null, _toDisplayString(_ctx.presentAttachedPrograms(item.raw.attachedPrograms)), 1)
                                            ]),
                                            [`item.actions`]: _withCtx(({ item }) => [
                                              _createVNode(_component_v_btn, {
                                                onClick: ($event: any) => (_ctx.drilldownUser(item.raw)),
                                                density: "comfortable",
                                                size: "small",
                                                icon: "mdi-magnify-plus-outline",
                                                color: "info"
                                              }, null, 8, ["onClick"])
                                            ]),
                                            _: 2
                                          }, 1032, ["headers", "items"]),
                                          (!_ctx.isLoading && _ctx.filteredUsers.length > 0)
                                            ? (_openBlock(), _createBlock(_component_ReportExporter, {
                                                key: 0,
                                                items: _ctx.reportData,
                                                header: "System User Report",
                                                footer: _ctx.reportFooter,
                                                filename: "users-report",
                                                json_fields: _ctx.reportJsonFields,
                                                pdf_fields: _ctx.reportPdfFields
                                              }, null, 8, ["items", "footer", "json_fields", "pdf_fields"]))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_UserDrilldownDialog, {
                                        suppliedUser: _ctx.focusedUser,
                                        dialog: _ctx.isUserDialogActive,
                                        "retain-focus": false,
                                        onCloseUserDialog: _cache[6] || (_cache[6] = ($event: any) => {_ctx.isUserDialogActive=false;}),
                                        onRefreshUsers: _ctx.spliceInEditedUser
                                      }, null, 8, ["suppliedUser", "dialog", "onRefreshUsers"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
}