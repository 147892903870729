
	import { defineComponent, PropType } from 'vue';

	import LoadingSpinner from '@/ChildrenComponents/LoadingSpinner.vue';
	import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';

	import { IDocument, IDocumentUpload } from '@/Models/IDocument';
	import { IDataTableHeader } from '@/Models/IDataTableHeader';
	import { IDocumentType } from '@/Models/Lookup/IDocumentType';
	import { DocumentParentType } from '@/Enums/DocumentParentType';
	import { LookupKeys } from '@/Enums/LookupKeys';
	import { IDocumentParentType } from '@/Models/Lookup/IDocumentParentType';
	import { IBaseLookup } from '@/Models/Lookup/BaseLookup';

	import { DocumentService } from '@/Services/document-service';
	import { ValidationHelper } from '@/Services/Helper/validation-helper';
	import { LookupService } from '@/Services/lookup-service';
	
	export default defineComponent({
		name: "document-list",
		components: { LoadingSpinner, ValidationAlert },
		props: {
			parentId: {
				type: Number,
				required: true,
			},
			parentTypeId: {
				type: Number as PropType<DocumentParentType>,
				required: true,
			},
			canUpload: {
				type: Boolean,
				required: true,
			},
			canDelete: {
				type: Boolean,
				required: true,
			},
			showDocParentType: {
				type: Boolean,
				required: false,
				default: false,
			}
		},
		created() {
			Promise.all([
					LookupService.getDocumentTypeList(),
					LookupService.getLookupByKey(LookupKeys.DocumentParentType)
				])
				.then(([docs, docParentTypes]: [IDocumentType[], IBaseLookup[]]) => {
					this.documentTypes = docs;
					this.documentParentTypes = docParentTypes as IDocumentParentType[];
				})
				.catch((err) => this.errorMessage = err.toString())
				.finally(() => this.loadData());
		},
		data () {
			return {
				showSuccessMessage: false,
				validationMessages: [] as string[],
				errorMessage: "" as string,
				
				requiredRules: ValidationHelper.requiredRules,
				
				headers: [
					{ title: "Date", key: "createDate", sortable: true, align: "start" },
					{ title: "Parent Type", key: "documentParentTypeName", sortable: true, align: "start" },
					{ title: "Type", key: "documentTypeName", sortable: true, align: "start" },
					{ title: "Document", key: "filename", sortable: true, align: "start" },
					{ title: "", key: "actions", sortable: false },
				] as IDataTableHeader[],
				documents: [] as IDocument[],
				documentTypes: [] as IDocumentType[],
				documentParentTypes: [] as IDocumentParentType[],
				newDocumentTypeId: null as number | null,
				newFile: [] as File[],

				isLoading: true,
				isUploading: false,
				isCaseRoute: this.parentTypeId === DocumentParentType.Case,
				isPhaseRoute: this.parentTypeId === DocumentParentType.Phase,
				isEligibilityRoute: this.parentTypeId === DocumentParentType.Eligibility,
			}
		},
		methods: {
			loadData() {
				this.isLoading = true;
				this.clearUpload();

				const documentGetter =(this.isCaseRoute ? DocumentService.getAllCaseDocuments(this.parentId)
														: DocumentService.getByParent(this.parentTypeId, this.parentId))

				documentGetter
					.then(result => this.documents = result)
					.then(() => {
						this.documents.map((d) => d.documentParentTypeName = this.documentParentTypes.find(dpt => dpt.id === d.documentParentTypeId)?.description || "n/a")
					})
					.catch((err) => this.errorMessage = err.toString())
					.finally(() => this.isLoading = false);

				if (!this.showDocParentType) {
					this.headers = this.headers.filter((h) => h.key !== 'documentParentTypeName');
				}
			},
			clearUpload() {
				this.newDocumentTypeId = null;
				this.newFile = [];
			},
			deleteItem(id: number) {
				DocumentService.delete(id).then(() => this.documents = this.documents.filter(x => x.id != id));
			},
			download(dto: IDocument) {
				DocumentService.download(dto.id).then((response) => {
					const localBlob = new Blob([response]);
					const link = document.createElement('a');
					link.href = URL.createObjectURL(localBlob);
					link.download = dto.filename ?? "Download";
					link.click();
					URL.revokeObjectURL(link.href);
				});
			},
			upload() {
				const fileUpload = {
					parentId: this.parentId,
					documentParentTypeId: this.parentTypeId,
					documentTypeId: this.newDocumentTypeId,
					documentTypeName: this.documentTypes.find(x => x.id == this.newDocumentTypeId)?.description ?? "",
					file: this.newFile[0],
				} as IDocumentUpload;
				this.isUploading = true;
				DocumentService.upload(fileUpload).then(result => {
					this.validationMessages = result.messages;
					if (result.isValid) {
						this.clearUpload();
					}
					if (result.resultObject.id != undefined) {
						this.updateList(result.resultObject);
					}
				}).finally(() => this.isUploading = false);
			},
			updateList(item: IDocument) {
				const index = this.documents.findIndex(x => x.id == item.id);
				item.documentParentTypeName = this.documentParentTypes.find(dpt => dpt.id === item.documentParentTypeId)?.description || "n/a"
				
				if (index > -1) {
					this.documents[index] = item;
				}
				else {
					this.documents.push(item);
				}
			},
		},
		watch: {
			parentId() {
				this.loadData();
			},
		},
		computed: {
			filteredDocumentTypes(): IDocumentType[] {
				return this.documentTypes.filter(x => !x.documentParentTypeId || x.documentParentTypeId == this.parentTypeId);
			},
			buttonText(): string {
				return this.isUploading ? "Uploading..." : "Upload";
			},
			isUploadValid(): boolean {
				return this.newDocumentTypeId != null && this.newFile.length > 0;
			},
		},
	});
