import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IncorrectPermissions = _resolveComponent("IncorrectPermissions")!
  const _component_ParticipantHeader = _resolveComponent("ParticipantHeader")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_SingleErrorMessage = _resolveComponent("SingleErrorMessage")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_OutcomeTable = _resolveComponent("OutcomeTable")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_ValidationAlert = _resolveComponent("ValidationAlert")!
  const _component_Outcome = _resolveComponent("Outcome")!
  const _component_OutcomeEventTable = _resolveComponent("OutcomeEventTable")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!

  return (!_ctx.canViewRolePermission)
    ? (_openBlock(), _createBlock(_component_IncorrectPermissions, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.headerData && !_ctx.errorMessage)
          ? (_openBlock(), _createBlock(_component_ParticipantHeader, {
              key: 0,
              data: _ctx.headerData
            }, null, 8, ["data"]))
          : _createCommentVNode("", true),
        _createVNode(_component_PageHeader, { headerText: "Outcome Summary" }),
        (_ctx.isLoading && !_ctx.errorMessage)
          ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 1 }))
          : _createCommentVNode("", true),
        _createVNode(_component_SingleErrorMessage, { errorMessage: _ctx.errorMessage }, null, 8, ["errorMessage"]),
        (!_ctx.isLoading && !_ctx.errorMessage)
          ? (_openBlock(), _createBlock(_component_v_expansion_panels, {
              key: 2,
              multiple: "",
              modelValue: _ctx.openPanels,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.openPanels) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_expansion_panel_title, { class: "text-h5" }, {
                      default: _withCtx(() => [
                        _createTextVNode("Case Outcomes")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_expansion_panel_text, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_OutcomeTable, {
                          routeId: _ctx.routeId,
                          canEditPermission: _ctx.canEditRolePermission,
                          onEditOutcome: _ctx.editOutcome
                        }, null, 8, ["routeId", "canEditPermission", "onEditOutcome"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ValidationAlert, { validationResults: _ctx.validationMessages }, null, 8, ["validationResults"]),
                (_ctx.canEditRolePermission && _ctx.outcomes.length > 0)
                  ? (_openBlock(), _createBlock(_component_v_expansion_panel, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_expansion_panel_title, {
                          disabled: _ctx.editingOutcomeId === undefined,
                          class: "text-h5"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.outcomeEditPanelTitle), 1)
                          ]),
                          _: 1
                        }, 8, ["disabled"]),
                        _createVNode(_component_v_expansion_panel_text, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_Outcome, {
                              routeId: _ctx.routeId,
                              canEditPermission: _ctx.canEditRolePermission,
                              onSaveComplete: _ctx.afterOutcomeSave
                            }, null, 8, ["routeId", "canEditPermission", "onSaveComplete"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.canEditRolePermission && _ctx.outcomes.length > 0)
                  ? (_openBlock(), _createBlock(_component_v_expansion_panel, { key: 1 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_expansion_panel_title, { class: "text-h5" }, {
                          default: _withCtx(() => [
                            _createTextVNode("Event History")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_expansion_panel_text, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_OutcomeEventTable, {
                              routeId: _ctx.routeId,
                              outcomeId: _ctx.outcomes[0]?.outcomeId,
                              canEditPermission: _ctx.canEditRolePermission,
                              maySave: _ctx.canEditRolePermission,
                              mayDelete: _ctx.canEditRolePermission,
                              includeClosedEvents: true,
                              onUpdatedOutcomeEvents: _ctx.loadData
                            }, null, 8, ["routeId", "outcomeId", "canEditPermission", "maySave", "mayDelete", "onUpdatedOutcomeEvents"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["modelValue"]))
          : _createCommentVNode("", true)
      ]))
}