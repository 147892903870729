import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_data_table = _resolveComponent("v-data-table")!

  return (_openBlock(), _createBlock(_component_v_data_table, {
    headers: _ctx.headers,
    items: _ctx.statusList,
    density: "compact",
    class: "elevation-1 pre-wrap"
  }, {
    "item.createDate": _withCtx(({ item }) => [
      _createTextVNode(_toDisplayString(_ctx.formatDate(item.raw.createDate)), 1)
    ]),
    _: 1
  }, 8, ["headers", "items"]))
}