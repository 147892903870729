import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_SingleErrorMessage = _resolveComponent("SingleErrorMessage")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_SuccessAlert = _resolveComponent("SuccessAlert")!
  const _component_ValidationAlert = _resolveComponent("ValidationAlert")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_card, { title: "Outcomes" }, {
      default: _withCtx(() => [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.errorMessage != '')
          ? (_openBlock(), _createBlock(_component_SingleErrorMessage, {
              key: 1,
              errorMessage: _ctx.errorMessage
            }, null, 8, ["errorMessage"]))
          : _createCommentVNode("", true),
        (!_ctx.isLoading && _ctx.outcomes.length > 0)
          ? (_openBlock(), _createBlock(_component_v_data_table, {
              key: 2,
              headers: _ctx.headers,
              items: _ctx.outcomes,
              density: "compact",
              "item-value": "outcomeId",
              class: "elevation-1 pre-wrap"
            }, {
              [`item.reportType`]: _withCtx(({ item }) => [
                _createElementVNode("span", null, _toDisplayString(_ctx.lookups.lookupLists[_ctx.LookupKeys.OutcomeType].find((e) => e.id == item.raw.reportType)?.description || item.raw.reportType.toString()), 1)
              ]),
              [`item.housingStatus`]: _withCtx(({ item }) => [
                _createElementVNode("span", null, _toDisplayString(_ctx.lookups.lookupLists[_ctx.LookupKeys.HousingStatus].find((e) => e.id == item.raw.housingStatus)?.description || item.raw.housingStatus.toString()), 1)
              ]),
              [`item.wellfare`]: _withCtx(({ item }) => [
                _createElementVNode("span", null, _toDisplayString(item.raw.wellfare ? "Yes" : "No"), 1)
              ]),
              [`item.foodStamps`]: _withCtx(({ item }) => [
                _createElementVNode("span", null, _toDisplayString(item.raw.foodStamps ? "Yes" : "No"), 1)
              ]),
              [`item.paidTaxes`]: _withCtx(({ item }) => [
                _createElementVNode("span", null, _toDisplayString(item.raw.paidTaxes ? "Yes" : "No"), 1)
              ]),
              [`item.createDate`]: _withCtx(({ item }) => [
                _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(item.raw.createDate)), 1)
              ]),
              [`item.modifyDate`]: _withCtx(({ item }) => [
                _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(item.raw.modifyDate)), 1)
              ]),
              [`item.actions`]: _withCtx(({ item }) => [
                (_ctx.canEditPermission)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      onClick: ($event: any) => (_ctx.editOutcome(item.raw.outcomeId)),
                      class: "ma-1",
                      density: "comfortable",
                      size: "small",
                      icon: "mdi-pencil-outline",
                      color: "info"
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["headers", "items"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (!_ctx.isLoading && _ctx.outcomes.length === 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(" There are currently no outcomes associated with this case. ")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_row, { align: "center" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { cols: "1" }),
        _createVNode(_component_v_col, { cols: "10" }, {
          default: _withCtx(() => [
            _createVNode(_component_SuccessAlert, {
              feedbackMessages: _ctx.successMessages,
              timeout: 2500
            }, null, 8, ["feedbackMessages"]),
            _createVNode(_component_ValidationAlert, { validationResults: _ctx.validationMessages }, null, 8, ["validationResults"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}