
import { defineComponent } from 'vue';

import { DateHelper } from '@/Services/Helper/date-helper';
import HeaderData from '@/Models/HeaderData';

export default defineComponent({
	name: "participant-page",
	props: {
		data: HeaderData,
	},
	computed: {
		participantDOB(): string {
			if (this.data?.dateOfBirth == undefined) return "";
			return DateHelper.formatDateUtc(this.data?.dateOfBirth, "MM/dd/yyyy");
		},
	},
});
