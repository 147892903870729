import { IDrugTest } from '@/Models/IDrugTest';
import { ITypedValidationResult } from '@/Models/IValidationResult';
import { BaseApiService } from './base-api-service';

class drugTestService extends BaseApiService {
    public constructor() {
		super('DrugTest/');
    }

	public async getById(id: number): Promise<IDrugTest> {
		return this.httpClient.get<IDrugTest>(`GetById/${id}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
	public async getByPhaseId(phaseId: number): Promise<IDrugTest[]> {
		return this.httpClient.get<IDrugTest[]>(`GetByPhaseId/${phaseId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

	public async getByCaseId(caseId: number): Promise<IDrugTest[]> {
		return this.httpClient.get<IDrugTest[]>(`GetByCaseId/${caseId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
	public async save(dto: IDrugTest): Promise<ITypedValidationResult<IDrugTest>> {
		return this.httpClient.post<ITypedValidationResult<IDrugTest>>("save", dto).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}

	public async delete(id: number): Promise<ITypedValidationResult<IDrugTest>> {
		return this.httpClient.delete<ITypedValidationResult<IDrugTest>>(`delete/${id}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}
}

export const DrugTestService: drugTestService = new drugTestService();