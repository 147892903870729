import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_SingleErrorMessage = _resolveComponent("SingleErrorMessage")!
  const _component_ValidationAlert = _resolveComponent("ValidationAlert")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_TemplateEditor = _resolveComponent("TemplateEditor")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.isVisible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isVisible) = $event)),
    "onClick:outside": _ctx.closeDialog,
    transition: "dialog-top-transition",
    width: "auto",
    scrollable: "",
    scrollStrategy: "none",
    retainFocus: true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_toolbar_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Edit Templates")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_card_text, { class: "mt-6" }, {
            default: _withCtx(() => [
              (_ctx.errorMessage != '')
                ? (_openBlock(), _createBlock(_component_v_container, {
                    key: 0,
                    fluid: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_SingleErrorMessage, { errorMessage: _ctx.errorMessage }, null, 8, ["errorMessage"]),
                      _createVNode(_component_ValidationAlert, { validationResults: _ctx.validationResults }, null, 8, ["validationResults"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_TemplateEditor, {
                activeTemplate: _ctx.currentTemplate,
                includeVariableMenu: _ctx.editorOnly,
                includeLabelEditor: _ctx.editorOnly
              }, null, 8, ["activeTemplate", "includeVariableMenu", "includeLabelEditor"]),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  (_ctx.cancelButtonText != '')
                    ? (_openBlock(), _createBlock(_component_v_col, {
                        key: 0,
                        cols: "12",
                        md: "2"
                      }, {
                        default: _withCtx(() => [
                          _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                            class: "mx-2 mb-4",
                            color: "secondary",
                            onClick: _ctx.closeDialog
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.cancelButtonText), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"])), [
                            [_directive_ripple]
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "2"
                  }, {
                    default: _withCtx(() => [
                      _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                        class: "mx-2 mb-4",
                        color: "success",
                        onClick: _ctx.doSave
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.confirmButtonText), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])), [
                        [_directive_ripple]
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "onClick:outside"]))
}