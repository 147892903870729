
	import { defineComponent } from 'vue';

	import LoadingSpinner from '@/ChildrenComponents/LoadingSpinner.vue';
	import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';
	import AddAliasDialog from '@/ChildrenComponents/Participant/AddAliasDialog.vue';
	import PageHeader from '@/ChildrenComponents/Participant/Common/PageHeader.vue';
	import ParticipantHeader from '@/ChildrenComponents/Participant/Common/ParticipantHeader.vue';
	import SingleErrorMessage from '@/ChildrenComponents/Participant/Common/SingleErrorMessage.vue';

	import { LookupKeys } from "@/Enums/LookupKeys";

	import { ComponentType } from '@/Enums/ComponentType'
	import HeaderData from '@/Models/HeaderData';
	import { ICase } from '@/Models/ICase';
	import { IInternalUser } from '@/Models/IInternalUser';
	import { IParticipantAlias } from '@/Models/IParticipantAlias';
	import { IParticipantForEdit } from '@/Models/IParticipant';
	import { IGender } from '@/Models/Lookup/IGender';
	import { IDataTableHeader } from '@/Models/IDataTableHeader';

	import { doesUserRoleHaveComponentEditPermissions } from '@/Services/Helper/component-permissions-helper'
	import { LookupService } from '@/Services/lookup-service';
	import { ParticipantService } from '@/Services/participant-service';
	import { ParticipantAliasService } from '@/Services/participant-alias-service';
    import { CaseService } from '@/Services/case-service';
	import { DateHelper } from '@/Services/Helper/date-helper';
	import { loadParticipantDataRequireParticipant } from '@/Services/Helper/loader-helper';
	import { ValidationHelper } from '@/Services/Helper/validation-helper';

	import { useUserStore } from '@/Services/Store/user-store';

	export default defineComponent({
		name: "participant-info",
		components: { ValidationAlert, LoadingSpinner, ParticipantHeader, AddAliasDialog, PageHeader, SingleErrorMessage },
		setup(): { currentUser: IInternalUser | null } {
			const userStoreInstance = useUserStore();
			return { currentUser: userStoreInstance.$state.cstInternalUser };
		},
		created() {
			loadParticipantDataRequireParticipant(this.pageType, this.id).then((data) => {
				this.participant = data.participant as IParticipantForEdit;
				this.case = data.case;
				this.participantId = data.participant?.participantId ?? null;
				this.caseId = data.case?.caseId ?? null;
				this.participant.dateOfBirthEditable = DateHelper.formatDateUtc(this.participant.dateOfBirth, "yyyy-MM-dd");
				this.headerData = new HeaderData(data.participant, data.case?.program ?? null);
			})
				.then(() => {
					if (this.participantId != null) {
						Promise.all([
							LookupService.getLookupByKey(LookupKeys.Gender),
							ParticipantAliasService.getByParticipantId(this.participantId),
                            ParticipantService.getParticipant(this.participantId),
                            CaseService.getCaseHistoryByParticipant(this.participantId)
						]).then(([genderResult, aliasResult, participantResult, caseHistoryResult]) => {
							this.genderList = genderResult as IGender[];
							this.aliasList = aliasResult;
                            //get full SSN
                            this.participant!.ssn = participantResult.ssn;
                            this.caseHistory = caseHistoryResult;
						}).then(() => this.isLoading = false);
					}
				})
				.catch(error => this.errorMessage = error);
		},
		data() {
			return {
				isLoading: true,
				errorMessage: "",
				validationMessages: [] as string[],
				pageType: this.$route.params.type?.toString() ?? "",
				id: this.$route.params.id == undefined ? null : parseInt(this.$route.params.id.toString()),
				participant: null as IParticipantForEdit | null,
				case: null as ICase | null,
				participantId: null as number | null,
				caseId: null as number | null,
				headerData: null as HeaderData | null,
                caseHistory: [] as ICase[],
				isFormValid: false,
				isSaving: false,
				saveButtonText: "Save Participant",
				requiredFieldRules: ValidationHelper.requiredRules,
				genderList: [] as IGender[],
				aliasList: [] as IParticipantAlias[],
				selectedAlias: {} as IParticipantAlias,
				showAliasDialog: false,
				aliasHeader: "",
				aliasTableHeaders: [
					{ title: "Alias", key: "alias", sortable: true, align: "start" },
					{ title: "Edit", key: "actions", sortable: false },
				] as IDataTableHeader[],
			}
		},
		watch: {
		},
		methods: {
			setBlankAlias() {
				this.selectedAlias = {
					participantAliasId: 0,
					participantId: this.participantId,
					alias: "",
				} as IParticipantAlias;
			},
			newAlias() {
				this.setBlankAlias();
				this.aliasHeader = "Add Alias";
				this.showAliasDialog = true;
			},
			editAlias(alias: IParticipantAlias) {
				this.selectedAlias = alias;
				this.aliasHeader = "Edit Alias";
				this.showAliasDialog = true;
			},
			cancelAliasEdit() {
				this.setBlankAlias();
				this.showAliasDialog = false;
			},
			saveAlias(text: string) {
				this.selectedAlias.alias = text;
				ParticipantAliasService.save(this.selectedAlias).then(result => {
					this.validationMessages = result.messages;

					if (result.isValid && result.resultObject != null) {
						this.setBlankAlias();
						this.showAliasDialog = false;
						if (this.participantId != null) {
							ParticipantAliasService.getByParticipantId(this.participantId).then(aliases => this.aliasList = aliases);
						}
					}
				})
					.catch(error => this.errorMessage = error);
			},
			save() {
				if (this.participant == null)
					return;
				this.isSaving = true;
				this.saveButtonText = "Saving...";

				this.participant.dateOfBirth = DateHelper.standardizeDate(this.participant.dateOfBirthEditable);
				ParticipantService.saveParticipant(this.participant).then(validationResult => {
					this.validationMessages = validationResult.messages;
					if (validationResult.isValid) {
						this.saveButtonText = "Saved";
						this.headerData = new HeaderData(this.participant, this.case?.program ?? null);
					}
					else {
						this.saveButtonText = "Unable to save.";
						this.validationMessages = validationResult.messages;
					}
					setTimeout(() => {
						this.saveButtonText = "Save Participant";
						this.isSaving = false;
					}, 3000);
				});
			},
		},
		computed: {
			componentType(): ComponentType {
				return ComponentType.ParticipantInfo
			},
			canEditRolePermission() : boolean {
				return doesUserRoleHaveComponentEditPermissions(this.currentUser?.attachedRoles ?? [], this.componentType)
                    && this.caseHistory.length > 0;
			},
		}
	});
