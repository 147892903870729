
import { defineComponent, PropType, Ref, ref } from 'vue';

import { ValidationHelper } from '@/Services/Helper/validation-helper';
import { IEmail } from '@/Models/IEmail';

export default defineComponent({
	name: "add-email-dialog",
	emits: ["close", "save"],
	props: {
        source: Object as PropType<IEmail>,
        headerText: String,
        contactTypes: Array,
        displayContactType: Boolean
	},
	setup(): { form: Ref } {
		const form = ref(null);
		return { form };
	},
	created() {
		this.email = this.source != undefined ? this.source : null;
	},
	data () {
		return {
			rules: ValidationHelper.emailRules,
			email: null as IEmail | null,
			buttonText: "Save Email Address",
			isSaving: false
		}
	},
	methods: {
		closeDialog(): void {
			this.$emit("close");
		},
		async save() {
			const { valid } = await this.form.validate();
			if (valid) {
				this.isSaving = true;
				this.buttonText = "Saving..."
				this.$emit("save", this.email);
			}
		},
	},
	watch: {
        source(newEmail: IEmail) {
			this.email = newEmail;
			this.isSaving = false;
			this.buttonText = "Save Email Address";
		},
	},
});
