import { IInternalUserRole, mapUserRolesToEnums } from "@/Models/IInternalUserRole";
import { ComponentPermissionsRegistry } from "./component-permissions-registry";
import { ComponentType } from "@/Enums/ComponentType";
import { Role } from "@/Enums/Role";

export const doesUserRoleHaveComponentEditPermissions = (userRoles : IInternalUserRole[], componentType : ComponentType) => {
    const mappedUserRoles = mapUserRolesToEnums(userRoles)
    const permissions = ComponentPermissionsRegistry[componentType]?.CanEdit;
    // Check if any of the user's roles have edit permission
    const hasEditPermission = mappedUserRoles.some(userRole => permissions && permissions[userRole]);

    // Return true if there's edit permission, otherwise return false
    return hasEditPermission;
}

export const doesUserRoleHaveComponentViewPermissions = (userRoles : IInternalUserRole[], componentType : ComponentType) => {
    const mappedUserRoles = mapUserRolesToEnums(userRoles)
    const permissions = ComponentPermissionsRegistry[componentType]?.CanView;
    // Check if any of the user's roles have view permission
    const hasViewPermission = mappedUserRoles.some(userRole => permissions && permissions[userRole]);

    // Return true if there's view permission, otherwise return false
    return hasViewPermission;
}

export const doesUserRoleHaveComponentConditionalViewPermissions = (userRoles : IInternalUserRole[], componentType : ComponentType) => {
    const mappedUserRoles = mapUserRolesToEnums(userRoles)
    const permissions = ComponentPermissionsRegistry[componentType]?.CanViewConditional;
    // Check if any of the user's roles have conditional view permission
    const hasConditionalViewPermission = mappedUserRoles.some(userRole => permissions && !(permissions[userRole]));

    // Return true if there's conditional view permission, otherwise return false
    return !hasConditionalViewPermission;
}

export const isUserAdmin = (userRoles: IInternalUserRole[]): boolean => {
    return mapUserRolesToEnums(userRoles).includes(Role.StateAdministrator);
}

export const doesUserRoleHaveComponentDeletePermissions = (userRoles: IInternalUserRole[], componentType: ComponentType) => {
    const mappedUserRoles = mapUserRolesToEnums(userRoles)
    const permissions = ComponentPermissionsRegistry[componentType]?.CanDelete;
    // Check if any of the user's roles have edit permission
    const hasEditPermission = mappedUserRoles.some(userRole => permissions && permissions[userRole]);

    // Return true if there's edit permission, otherwise return false
    return hasEditPermission;
}