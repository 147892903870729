
	import { defineComponent, Ref, ref } from 'vue';

	import LoadingSpinner from '@/ChildrenComponents/LoadingSpinner.vue';
	import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';
	import WorkflowHistory from '@/ChildrenComponents/WorkflowHistory.vue';
	import PageHeader from '@/ChildrenComponents/Participant/Common/PageHeader.vue';
	import ParticipantHeader from '@/ChildrenComponents/Participant/Common/ParticipantHeader.vue';
	import SingleErrorMessage from '@/ChildrenComponents/Participant/Common/SingleErrorMessage.vue';
	import AlternateWorkflowSubmission from '@/ChildrenComponents/Phase/AlternateWorkflowSubmission.vue';
	import Outcome from '@/ChildrenComponents/Outcomes/Outcome.vue';

	import { AlternateWorkflowType } from '@/Enums/AlternateWorkflowType';
	import { CaseClosureReason } from '@/Enums/CaseClosureReason';
	import { LookupKeys } from "@/Enums/LookupKeys";
	import { ComponentType } from '@/Enums/ComponentType'
	import { Role } from '@/Enums/Role';
	
	import { IInternalUser } from '@/Models/IInternalUser';
	import { IBaseLookup } from '@/Models/Lookup/BaseLookup';
	import { ICaseClosure, ICaseClosureForEdit } from '@/Models/ICaseClosure';
	import { ITypedValidationResult } from '@/Models/IValidationResult';
	import HeaderData from '@/Models/HeaderData';
	import { mapUserRolesToEnums } from '@/Models/IInternalUserRole';

	import { doesUserRoleHaveComponentEditPermissions } from '@/Services/Helper/component-permissions-helper'
	import { CaseClosureService } from '@/Services/case-closure-service';
	import { LookupService } from '@/Services/lookup-service';
	import { loadParticipantDataRequireCase } from '@/Services/Helper/loader-helper';
	import { ValidationHelper } from '@/Services/Helper/validation-helper';
	import { DateHelper } from '@/Services/Helper/date-helper';
	import { useUserStore } from '@/Services/Store/user-store';

	export default defineComponent({
		name: "case-closure",
		components: { ParticipantHeader, LoadingSpinner, ValidationAlert, PageHeader, SingleErrorMessage, AlternateWorkflowSubmission, WorkflowHistory, Outcome },
		setup(): { form: Ref, currentUser: IInternalUser | null } {
			const form = ref(null);
			const userStoreInstance = useUserStore();
			return { form, currentUser: userStoreInstance.$state.cstInternalUser };
		},
		created() {
			if (this.caseClosureId != null) {
				Promise.all([
					CaseClosureService.get(this.caseClosureId),
					LookupService.getLookupByKey(LookupKeys.CaseClosureReason)
				]).then(([closure, reasons]) => {
                    loadParticipantDataRequireCase("case", closure.caseId).then(data => {
                        this.headerData = new HeaderData(data.participant, data.case?.program ?? null);
                    });
					this.$router.push(`/case-closure/${this.caseClosureId}/${closure.caseId}`);
					this.setDto(closure);
					this.reasons = reasons;
					if (this.closure?.caseClosureId == undefined) {
						this.errorMessage = "This case is not currently in the process of being closed.";
					}
				}).then(() => this.isLoading = false).catch(error => this.errorMessage = error);
			}
		},
		data() {
			return {
				isLoading: true,
				isSaving: false,
				errorMessage: "",
				validationMessages: [] as string[],
				caseId: this.$route.params.id == undefined ? null : parseInt(this.$route.params.id.toString()),
                caseClosureId: this.$route.params.caseclosureid == undefined ? null : parseInt(this.$route.params.caseclosureid.toString()),
				headerData: null as HeaderData | null,
				closure: null as ICaseClosureForEdit | null,
				workflowType: AlternateWorkflowType.CaseClosure as number,
				requiredRules: ValidationHelper.requiredRules,
				openPanels: [0, 1, 2, 3],
				Role,
				reasons: [] as IBaseLookup[],
			}
		},
		methods: {
			userRoles(): Role[] {
				if (!this.currentUser) {
					return [] as Role[];
				}

				return mapUserRolesToEnums(this.currentUser!.attachedRoles);
			},
			setDto(source: ICaseClosure): void {
				this.closure = source as ICaseClosureForEdit;
				if (this.closure != null) {
					this.closure.graduationDateForEdit = DateHelper.formatDateUtc(this.closure.graduationDate, "yyyy-MM-dd");
                    this.closure.startDateForEdit = DateHelper.formatDateUtc(this.closure.startDate, "yyyy-MM-dd");
                    this.closure.endDateForEdit = DateHelper.formatDateUtc(this.closure.endDate, "yyyy-MM-dd");
                    this.closure.targetEndDateForEdit = DateHelper.formatDateUtc(this.closure.targetEndDate, "yyyy-MM-dd");
				}
			},
			prepForSave(): void {
				if (this.closure != null) {
					this.closure.graduationDate = DateHelper.standardizeDate(this.closure.graduationDateForEdit);
                    this.closure.startDate = DateHelper.standardizeDate(this.closure.startDateForEdit);
                    this.closure.endDate = DateHelper.standardizeDate(this.closure.endDateForEdit);
                    this.closure.targetEndDate = DateHelper.standardizeDate(this.closure.targetEndDateForEdit);
				}
			},
			async save() {
				const { valid } = await this.form.validate();
				if (valid && this.closure != null) {
					this.isSaving = true;
					this.prepForSave();
					CaseClosureService.save(this.closure).then(result => {
						this.validationMessages = result.messages;
						this.isSaving = false;
					});
				}
			},
			workflowSubmitSuccess() {
				//on successful closure, go to dashboard
				this.$router.push("/");
			},
			workflowSubmitError(result: ITypedValidationResult<ICaseClosure>) {
				this.validationMessages = result.messages;
			},
			formatDate(date: Date): string {
				return DateHelper.formatDateUtc(date, "MM/dd/yyyy");
			},
		},
		computed: {
			componentType(): ComponentType {
				return ComponentType.CaseClosure
			},
			canEditRolePermission() : boolean {
				return doesUserRoleHaveComponentEditPermissions(this.currentUser?.attachedRoles ?? [], this.componentType)
			},
			buttonText(): string {
				return this.isSaving ? "Saving..." : "Save";
			},
			showReasonOther(): boolean {
				return this.closure?.caseClosureReasonId == CaseClosureReason.Other;
			},
			showGraduationDate(): boolean {
				return this.closure?.caseClosureReasonId == CaseClosureReason.Graduated;
			},
		},
	});
