export default class ParticipantSearch {
	participantNumber: string | null = "";
	court: number[] = [];
	county: number[] = [];
	firstName: string | null = "";
	lastName: string | null = "";
	ssn: string | null = "";
	birthDate: string | null = "";
	gender: number[] = [];
	activePhaseOnly: boolean = false;

	validate(): string[] {
		const results = [] as string[];
		//court and county don't count towards valid field count
		const enteredValues = [
			this.participantNumber,
			this.firstName,
			this.lastName,
			this.ssn,
			this.birthDate,
			this.gender.length == 0 ? null : "Y",
		] as string[];
		if (enteredValues.filter(x => x != null && x != "").length < 2) {
			results.push("At least two search terms must be entered, not including court or county.");
		}
		return results;
	}
}
