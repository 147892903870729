import type { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import axios, {AxiosRequestTransformer, InternalAxiosRequestConfig} from "axios";
import { getAccessTokenForService } from "./Auth/auth0-plugin";

export function isAxiosError(value: any): value is AxiosError {
    return typeof value?.response === 'object';
}

export abstract class BaseApiService {
    protected readonly httpClient: AxiosInstance;
	
    protected constructor (protected readonly path?: string, protected readonly useDateTransformer: boolean = true, protected readonly baseURL: string = '/api/') {
        const dateTransformer = (value: any): any => {
			if (value instanceof Date) {
				return value.toISOString();
			}
			if (Array.isArray(value)) {
				return value.map(dateTransformer);
			}
			if (typeof value === 'object' && value !== null) {
				return Object.fromEntries(Object.entries(value).map(([key, value]) => [key, dateTransformer(value)]));
			}
			return value;		
		}

		if (path) {
            baseURL += path;
        }
        this.httpClient = axios.create({
            baseURL,
			transformRequest: useDateTransformer ? [dateTransformer, ...(axios.defaults.transformRequest as AxiosRequestTransformer[])] : axios.defaults.transformRequest,
        });
        this.httpClient.interceptors.request.use(async (config) => {
            const token = await getAccessTokenForService();
            config.headers['Authorization'] = `Bearer ${token}`
            return config;
        });
    }

    protected handleResponse<T>(response: AxiosResponse<T>): T {
        return response.data;
    }

    protected handleError(error: unknown): never {
        if (error instanceof Error) {
            if (isAxiosError(error)) {
                if (error.response) {
                    //Uncomment any or all below for better debugging
                    //console.error(error.response.data);
                    //console.error(error.response.status);
                    //console.error(error.response.headers);
                    throw error;
                }
                else if (error.request) {
                    //console.error(error.request);
                    throw new Error(error as any);
                }
            }
            else {
                //console.error(error.message);
                throw new Error(error as any);
            }
        }
        throw new Error(error as any);
    }
}