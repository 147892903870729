import { App, inject } from "vue";
import axios, {AxiosInstance, AxiosRequestConfig, AxiosStatic } from "axios";
import { useAuth0 } from "../Auth/auth0-plugin";

export function provideAxios() {
    return {
        install(app: App) {
            app.config.globalProperties["axios"] = axios;
            app.provide("axios", axios);
        },
    };
}

export const useAxios = (config?: AxiosRequestConfig): AxiosInstance | undefined => {
    const axios = inject<AxiosStatic>('axios');
    const auth0Plugin = useAuth0();
    if (!config) {
        config = { baseURL: '/api/' };
    }
    const instance = axios?.create(config);

    instance && instance.interceptors.request.use(async (config) => {
        const token = await auth0Plugin?.getAccessToken();
        config.headers['Authorization'] = `Bearer ${token}`
        return config;
    });

    return instance;
}