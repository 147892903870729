import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    title: _ctx.title,
    variant: _ctx.variant 
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_form, { ref: "workflowForm" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    sm: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_select, {
                        clearable: "",
                        "no-data-text": "None Available",
                        items: _ctx.nextWorkflowStatusSelectItems,
                        "item-value": "workflowStatusId",
                        "item-title": "description",
                        label: "Select Next Applicable Status",
                        modelValue: _ctx.selectedNextStatusId,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedNextStatusId) = $event)),
                        required: "",
                        rules: _ctx.statusFieldRules
                      }, null, 8, ["items", "modelValue", "rules"])
                    ]),
                    _: 1
                  }),
                  _withDirectives(_createVNode(_component_v_col, {
                    cols: "12",
                    sm: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_textarea, {
                        label: "Workflow Notes",
                        modelValue: _ctx.notes,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.notes) = $event)),
                        required: "",
                        rules: _ctx.noteFieldRules
                      }, null, 8, ["modelValue", "rules"])
                    ]),
                    _: 1
                  }, 512), [
                    [_vShow, _ctx.selectedNextStatusId]
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 512)
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              (_ctx.showPartialSaveOptions)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    color: "success",
                    variant: "elevated",
                    class: "ma-2",
                    onClick: _ctx.savePartial,
                    disabled: _ctx.saveButtonsDisabled
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Save Progress")
                    ]),
                    _: 1
                  }, 8, ["onClick", "disabled"]))
                : _createCommentVNode("", true),
              (_ctx.nextWorkflowStatusSelectItems.length > 0)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 1,
                    color: "success",
                    variant: "elevated",
                    class: "ma-2",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.submitWorkflowStep(false))),
                    disabled: _ctx.saveButtonsDisabled
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Submit")
                    ]),
                    _: 1
                  }, 8, ["disabled"]))
                : _createCommentVNode("", true),
              (_ctx.showSubmitAndDoMore && _ctx.nextWorkflowStatusSelectItems.length > 0)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 2,
                    color: "success",
                    variant: "elevated",
                    class: "my-2 mx-10",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.submitWorkflowStep(true))),
                    disabled: _ctx.saveButtonsDisabled
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.submitAndDoMoreText), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title", "variant"]))
}