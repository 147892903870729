
	import { defineComponent } from 'vue';

	import LoadingSpinner from '@/ChildrenComponents/LoadingSpinner.vue';
	import ParticipantHeader from '@/ChildrenComponents/Participant/Common/ParticipantHeader.vue';
	import PageHeader from '@/ChildrenComponents/Participant/Common/PageHeader.vue';
	import SingleErrorMessage from '@/ChildrenComponents/Participant/Common/SingleErrorMessage.vue';

	import { MenuItemTypes } from "@/Enums/MenuItemTypes";

	import HeaderData from '@/Models/HeaderData';
	import { IDataTableHeader } from '@/Models/IDataTableHeader';
	import { IWorkflowInstanceStatus } from '@/Models/Workflow/IWorkflowInstanceStatus';

	import { WorkflowService } from '@/Services/workflow-service';
	import { DateHelper } from '@/Services/Helper/date-helper';
	import { loadParticipantDataRequireCase } from '@/Services/Helper/loader-helper';
	import RouterHelper from '@/Services/Helper/router-helper';

	export default defineComponent({
		name: "workflow-list",
		components: { LoadingSpinner, ParticipantHeader, PageHeader, SingleErrorMessage },
		created() {
			loadParticipantDataRequireCase(this.pageType, this.id).then((data) => {
				this.caseId = data.case?.caseId ?? null;
				this.headerData = new HeaderData(data.participant, data.case?.program ?? null);
			})
				.then(() => {
					if (this.caseId != null) {
						WorkflowService.getByCaseId(this.caseId)
							.then(result => this.workflowList = result)
							.then(() => this.isLoading = false);
					}
				})
				.catch(error => this.errorMessage = error);
		},
		data() {
			return {
				isLoading: true,
				errorMessage: "",
				pageType: this.$route.params.type?.toString() ?? MenuItemTypes.Case,
				id: this.$route.params.id == undefined ? null : parseInt(this.$route.params.id.toString()),
				caseId: null as number | null,
				headerData: null as HeaderData | null,

				workflowList: [] as IWorkflowInstanceStatus[],
				workflowHeaders: [
					{ title: "Type", key: "workflowDescription", sortable: true, align: "start" },
					{ title: "Current Status", key: "workflowStatusDescription", sortable: true, align: "start" },
					{ title: "Start Date", key: "startDate", sortable: true, align: "start" },
					{ title: "End Date", key: "endDate", sortable: true, align: "start" },
					{ title: "Workflow Status Date", key: "createDate", sortable: true, align: "start" },
					{ title: "View", key: "actions", sortable: false },
				] as IDataTableHeader[],
			}
		},
		methods: {
			openWorkflow(wis: IWorkflowInstanceStatus): void {
				const url = RouterHelper.getUrl(wis.workflowId, wis.parentId);
				if (url) {
					this.$router.push(url);
				}
			},
			formatDate(date: Date): string {
				return DateHelper.formatDateLocal(date, "MM/dd/yyyy");
			},
            formatDateUtc(date: Date): string {
				return DateHelper.formatDateUtc(date, "MM/dd/yyyy");
			},
		},
	});
