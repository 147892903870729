import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ParticipantHeader = _resolveComponent("ParticipantHeader")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_SingleErrorMessage = _resolveComponent("SingleErrorMessage")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_ValidationAlert = _resolveComponent("ValidationAlert")!
  const _component_Outcome = _resolveComponent("Outcome")!
  const _component_AlternateWorkflowSubmission = _resolveComponent("AlternateWorkflowSubmission")!
  const _component_WorkflowHistory = _resolveComponent("WorkflowHistory")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.headerData && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_ParticipantHeader, {
          key: 0,
          data: _ctx.headerData
        }, null, 8, ["data"]))
      : _createCommentVNode("", true),
    _createVNode(_component_PageHeader, { headerText: "Case Closure" }),
    (_ctx.isLoading && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_SingleErrorMessage, { errorMessage: _ctx.errorMessage }, null, 8, ["errorMessage"]),
    (!_ctx.isLoading && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_v_expansion_panels, {
          key: 2,
          multiple: "",
          modelValue: _ctx.openPanels,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.openPanels) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, { class: "text-h5" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Closure Information")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_form, { ref: "form" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  disabled: !_ctx.canEditRolePermission,
                                  type: "date",
                                  label: "Start Date",
                                  modelValue: _ctx.closure.startDateForEdit,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.closure.startDateForEdit) = $event))
                                }, null, 8, ["disabled", "modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  disabled: !_ctx.canEditRolePermission,
                                  type: "date",
                                  label: "End Date",
                                  modelValue: _ctx.closure.endDateForEdit,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.closure.endDateForEdit) = $event))
                                }, null, 8, ["disabled", "modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  disabled: !_ctx.canEditRolePermission,
                                  type: "date",
                                  label: "Target End Date",
                                  modelValue: _ctx.closure.targetEndDateForEdit,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.closure.targetEndDateForEdit) = $event))
                                }, null, 8, ["disabled", "modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_select, {
                                  disabled: !_ctx.canEditRolePermission,
                                  items: _ctx.reasons,
                                  "item-value": "id",
                                  "item-title": "description",
                                  label: "Closure Reason",
                                  modelValue: _ctx.closure.caseClosureReasonId,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.closure.caseClosureReasonId) = $event))
                                }, {
                                  selection: _withCtx(({ item }) => [
                                    _createElementVNode("span", null, _toDisplayString(item.title?.split(':')[0] ?? ""), 1)
                                  ]),
                                  _: 1
                                }, 8, ["disabled", "items", "modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { md: "4" }, {
                              default: _withCtx(() => [
                                (_ctx.showReasonOther)
                                  ? (_openBlock(), _createBlock(_component_v_text_field, {
                                      key: 0,
                                      disabled: !_ctx.canEditRolePermission,
                                      required: _ctx.showReasonOther,
                                      rules: _ctx.showReasonOther ? _ctx.requiredRules : [],
                                      modelValue: _ctx.closure.caseClosureReasonOther,
                                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.closure.caseClosureReasonOther) = $event)),
                                      label: "Specify Reason"
                                    }, null, 8, ["disabled", "required", "rules", "modelValue"]))
                                  : _createCommentVNode("", true),
                                (_ctx.showGraduationDate)
                                  ? (_openBlock(), _createBlock(_component_v_text_field, {
                                      key: 1,
                                      disabled: !_ctx.canEditRolePermission,
                                      type: "date",
                                      modelValue: _ctx.closure.graduationDateForEdit,
                                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.closure.graduationDateForEdit) = $event)),
                                      label: "Graduation Date"
                                    }, null, 8, ["disabled", "modelValue"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { md: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_textarea, {
                                  disabled: !_ctx.canEditRolePermission,
                                  modelValue: _ctx.closure.notes,
                                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.closure.notes) = $event)),
                                  label: "Note Text"
                                }, null, 8, ["disabled", "modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 512),
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { align: "center" }, {
                          default: _withCtx(() => [
                            _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                              class: "mx-2 mb-4",
                              color: "success",
                              onClick: _ctx.save,
                              disabled: _ctx.isSaving
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick", "disabled"])), [
                              [_vShow, _ctx.canEditRolePermission],
                              [_directive_ripple]
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ValidationAlert, { validationResults: _ctx.validationMessages }, null, 8, ["validationResults"]),
            (_ctx.userRoles().filter((r) => r == _ctx.Role.ProbationSupervisor || r == _ctx.Role.PeerSupportSpecialist).length == 0)
              ? (_openBlock(), _createBlock(_component_v_expansion_panel, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_expansion_panel_title, { class: "text-h5" }, {
                      default: _withCtx(() => [
                        _createTextVNode("Closure Outcomes")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_expansion_panel_text, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_Outcome, {
                          routeId: _ctx.closure?.caseId || _ctx.caseId || 1,
                          canEditPermission: _ctx.canEditRolePermission
                        }, null, 8, ["routeId", "canEditPermission"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.canEditRolePermission)
              ? (_openBlock(), _createBlock(_component_v_expansion_panel, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_expansion_panel_title, { class: "text-h5" }, {
                      default: _withCtx(() => [
                        _createTextVNode("Workflow Status")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_expansion_panel_text, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_AlternateWorkflowSubmission, {
                          caseId: _ctx.closure.caseId,
                          parentId: _ctx.closure.caseClosureId,
                          workflowInstanceId: _ctx.closure.workflowInstanceId,
                          type: _ctx.workflowType,
                          onSuccess: _ctx.workflowSubmitSuccess,
                          onError: _ctx.workflowSubmitError
                        }, null, 8, ["caseId", "parentId", "workflowInstanceId", "type", "onSuccess", "onError"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, { class: "text-h5" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Workflow History")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_WorkflowHistory, {
                      workflowInstanceId: _ctx.closure.workflowInstanceId
                    }, null, 8, ["workflowInstanceId"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}