import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    "onClick:outside": _ctx.closeDialog,
    height: "auto",
    "max-width": "800"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { style: {"overflow":"hidden"} }, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, {
            color: "secondary",
            elevation: 8
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_toolbar_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.headerText), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                icon: "mdi-close-outline",
                density: "comfortable",
                variant: "plain",
                onClick: _ctx.closeDialog
              }, null, 8, ["onClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_card_text, { class: "mt-6" }, {
            default: _withCtx(() => [
              (_ctx.caseNote)
                ? (_openBlock(), _createBlock(_component_v_form, {
                    key: 0,
                    ref: "noteForm"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                modelValue: _ctx.caseNote.noteDateEditable,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.caseNote.noteDateEditable) = $event)),
                                label: "Date",
                                type: "date",
                                required: "",
                                rules: _ctx.requiredFieldRules
                              }, null, 8, ["modelValue", "rules"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_select, {
                                items: _ctx.noteTypeList,
                                "item-title": "description",
                                "item-value": "id",
                                modelValue: _ctx.caseNote.noteTypeId,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.caseNote.noteTypeId) = $event)),
                                label: "Note Type",
                                required: "",
                                rules: _ctx.requiredFieldRules
                              }, null, 8, ["items", "modelValue", "rules"]),
                              (_ctx.showNoteTypeOther)
                                ? (_openBlock(), _createBlock(_component_v_text_field, {
                                    key: 0,
                                    required: _ctx.showNoteTypeOther,
                                    rules: _ctx.showNoteTypeOther ? _ctx.requiredFieldRules : [],
                                    modelValue: _ctx.caseNote.noteTypeOther,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.caseNote.noteTypeOther) = $event)),
                                    label: "Specify Note Type"
                                  }, null, 8, ["required", "rules", "modelValue"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_checkbox, {
                                modelValue: _ctx.caseNote.canProviderView,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.caseNote.canProviderView) = $event)),
                                label: "Viewable By Provider"
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_textarea, {
                                modelValue: _ctx.caseNote.note,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.caseNote.note) = $event)),
                                label: "Note Text",
                                required: "",
                                rules: _ctx.requiredFieldRules
                              }, null, 8, ["modelValue", "rules"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 512))
                : _createCommentVNode("", true),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { align: "center" }, {
                    default: _withCtx(() => [
                      _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                        class: "mx-2 mb-4",
                        color: "success",
                        onClick: _ctx.saveNote,
                        disabled: _ctx.isSaving
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick", "disabled"])), [
                        [_directive_ripple]
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onClick:outside"]))
}