import { IDocument, IDocumentUpload } from '@/Models/IDocument';
import { ITypedValidationResult } from '@/Models/IValidationResult';
import { BaseApiService } from './base-api-service';

class documentService extends BaseApiService {
    public constructor() {
		super('document/', false);
    }

	public getByParent(parentType: number, parentId: number): Promise<IDocument[]> {
		return this.httpClient.get<IDocument[]>(`GetByParent/${parentType}/${parentId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}

	public getAllCaseDocuments(caseId: number): Promise<IDocument[]> {
		return this.httpClient.get<IDocument[]>(`GetByCase/${caseId}`)
			.then(this.handleResponse.bind(this))
			.catch(this.handleError.bind(this));
	}

	public download(documentId: number): Promise<Blob> {
		return this.httpClient.get(`Download/${documentId}`,{ responseType: 'blob' }).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}

	public uploadMultiple(files: IDocumentUpload[]): Promise<ITypedValidationResult<IDocument>[]> {
		//Because this RESTful operation requires a file and it's related information to be included in the object, we must manually build a form request for each item in our document array.
		//Normally, there is no need for this, but I cannot find a way to manually map a list of objects to a form data object AND have the file object that is associated with the parent object
		//get accepted by the API controller. Everything else will map okay, but the file will not. So take care to always call this service promise separately (first or last) if it is ever chained with other service calls.
		return Promise.all(files.map(x => this.upload(x)));
	}

	public upload(file: IDocumentUpload): Promise<ITypedValidationResult<IDocument>> {
		if (!file.file) {
			return new Promise<ITypedValidationResult<IDocument>>(resolve => resolve({ messages: [] as string[], isValid: true } as ITypedValidationResult<IDocument>));
		}
		return this.httpClient.post<ITypedValidationResult<IDocument>>("Upload", file, { headers: { "Content-Type": "multipart/form-data" } }).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}

	public delete(documentId: number): Promise<ITypedValidationResult<IDocument>> {
		return this.httpClient.delete(`Delete/${documentId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}
}

export const DocumentService: documentService = new documentService();