
	import { defineComponent } from 'vue';
	import { useAuth0 } from './Services/Auth/auth0-plugin';
	import { onMounted } from 'vue';
	import { Auth0PluginModel } from './Models/Auth0/auth0-plugin-model';
	import NavigationSidebar from '@/ChildrenComponents/NavigationSidebar.vue';

	export default defineComponent({
		name: 'App',
		components: { NavigationSidebar },
		setup(): { auth0: Auth0PluginModel | null } {
			const auth0 = useAuth0();
			onMounted(async () => {
				if (!auth0) {
					return;
				}

				await auth0.createClient();
				await auth0.handleCallback();
			});
			return {
				auth0
			};
		},
		data() {
			return {
				masterToolbarOptions: [
					{ action: "Dashboard", route: "/", links: [] },
					{ action: "Participant Search", route: "/participant-search", links: [] },
					{ 
                        action: "Reports",
                        route: "",
                        links: [
                            { action: "Court Attendance Report", route: "/report/court-attendance-report" },
                            { action: "Case Management Report", route: "/report/case-management-report" },
                            { action: "Ancillary Meeting Report", route: "/report/ancillary-meeting-report" },
                            { action: "Supervision Report", route: "/report/supervision-report" },
                            { action: "Drug Test Report", route: "/report/drug-test-report" },
                            { action: "Treatment Report", route: "/report/treatment-report" },
							{ action: "Census Report", route: "/report/census-report" },
							{ action: "Metrics Report", route: "/report/metric-report"},
							// { action: "Metric Charts", route: "/report/charts" },
							{ action: "Metrics", route: "", sublinks: [
								{ action: "Court Attendance Metrics", route: "/report/metrics/court-appearances"},
								// { action: "Program Goal Metrics", route: "/report/metrics/goals"},
								{ action: "Behavior Event Metrics", route: "/report/metrics/behavior"},
								{ action: "Judicial History Metrics", route: "/report/metrics/judicial-history"},
								{ action: "Drug Test Metrics", route: "/report/metrics/drug-test"},
							]}
                        ] 
                    },
					{
						action: "Program Administrators",
						route: "",
						links: [
							{ action: "Template Editor", route: "/template-management" },
						]
					},
					{
						action: "Site Management",
						route: "",
						links: [
							{ action: "Manage Access Requests", route: "/access-request-management" },
							{ action: "Manage Active Users", route: "/user-management" },
						]
					},
				],
				isDrawerOpen: false,
				isDrawerTemporary: true,
			}
		},
		methods: {
			setTemporary(isTemporary: boolean) {
				this.isDrawerTemporary = isTemporary;
			},
		},
	});
