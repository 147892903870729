import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElectronicMonitoringDialog = _resolveComponent("ElectronicMonitoringDialog")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ElectronicMonitoringDialog, {
      modelValue: _ctx.showModal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showModal) = $event)),
      lookups: _ctx.lookups,
      source: _ctx.editingItem,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModal = false)),
      onSaved: _ctx.onSave
    }, null, 8, ["modelValue", "lookups", "source", "onSaved"]),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 0 }))
      : _createCommentVNode("", true),
    (!_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_v_data_table, {
          key: 1,
          headers: _ctx.headers,
          items: _ctx.items,
          density: "compact",
          class: "elevation-1 pre-wrap"
        }, {
          "item.statusName": _withCtx(({ item }) => [
            (_ctx.isPendingItemOverdue(item.raw.statusName, item.raw.endDate))
              ? (_openBlock(), _createBlock(_component_v_chip, {
                  key: 0,
                  label: "",
                  size: "large",
                  color: "orange"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.raw.statusName), 1)
                  ]),
                  _: 2
                }, 1024))
              : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(item.raw.statusName), 1))
          ]),
          "item.startDate": _withCtx(({ item }) => [
            _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(item.raw.startDate)), 1)
          ]),
          "item.endDate": _withCtx(({ item }) => [
            _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(item.raw.endDate)), 1)
          ]),
          "item.actions": _withCtx(({ item }) => [
            (_ctx.isSaveAllowed)
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 0,
                  onClick: ($event: any) => (_ctx.editRow(item.raw)),
                  class: "ma-1",
                  density: "comfortable",
                  size: "small",
                  icon: "mdi-pencil-outline",
                  color: "info"
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.isDeleteAllowed)
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 1,
                  onClick: ($event: any) => (_ctx.deleteRow(item.raw.monitoringId)),
                  class: "ma-1",
                  density: "comfortable",
                  size: "small",
                  icon: "mdi-trash-can-outline",
                  color: "error"
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["headers", "items"]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_row, { class: "mt-2" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { align: "center" }, {
          default: _withCtx(() => [
            (_ctx.isSaveAllowed)
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 0,
                  onClick: _ctx.addRow,
                  color: "success"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Add")
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}