import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IncorrectPermissions = _resolveComponent("IncorrectPermissions")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_ValidationAlert = _resolveComponent("ValidationAlert")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card_item = _resolveComponent("v-card-item")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_AccessRequestDrilldownDialog = _resolveComponent("AccessRequestDrilldownDialog")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_window = _resolveComponent("v-window")!

  return (_ctx.isPermissionDenied)
    ? (_openBlock(), _createBlock(_component_v_container, {
        key: 0,
        fluid: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IncorrectPermissions)
        ]),
        _: 1
      }))
    : (!_ctx.isPermissionDenied)
      ? (_openBlock(), _createBlock(_component_v_container, {
          key: 1,
          fluid: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ValidationAlert, { "validation-results": _ctx.validationResults }, null, 8, ["validation-results"]),
            (_ctx.isLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_v_progress_circular, {
                    size: 70,
                    width: 7,
                    color: "loading",
                    indeterminate: ""
                  })
                ]))
              : (!_ctx.isLoading)
                ? (_openBlock(), _createBlock(_component_v_card, { key: 1 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_toolbar, {
                        dark: "",
                        flat: "",
                        prominent: "",
                        color: "secondary",
                        class: "text-white"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_tabs, {
                            centered: "",
                            dark: "",
                            "slider-color": "deep-purple accent-4",
                            modelValue: _ctx.tabs,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tabs) = $event))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_tab, { value: "0" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_icon, { left: "" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode("mdi-shield-account")
                                    ]),
                                    _: 1
                                  }),
                                  _createTextVNode(" Pending Access Requests ")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_window, {
                        modelValue: _ctx.tabs,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.tabs) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_window_item, { value: "0" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_card, { flat: "" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_card_item, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_card_title, { class: "black--text" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode("Pending Access Requests")
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_card_title, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_text_field, {
                                            modelValue: _ctx.searchRequests,
                                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchRequests) = $event)),
                                            "append-icon": "mdi-magnify",
                                            label: "Search For Any Request In Any Row",
                                            "single-line": "",
                                            "hide-details": ""
                                          }, null, 8, ["modelValue"])
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_card_text, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_data_table, {
                                        density: "compact",
                                        headers: _ctx.accessRequestHeaders,
                                        items: _ctx.accessRequestList,
                                        search: _ctx.searchRequests,
                                        class: "elevation-1"
                                      }, {
                                        "item.actions": _withCtx(({ item }) => [
                                          _createVNode(_component_v_btn, {
                                            onClick: ($event: any) => (_ctx.drilldownAccessRequest(item.raw)),
                                            density: "comfortable",
                                            size: "small",
                                            icon: "mdi-magnify-plus-outline",
                                            color: "info"
                                          }, null, 8, ["onClick"])
                                        ]),
                                        _: 1
                                      }, 8, ["headers", "items", "search"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_AccessRequestDrilldownDialog, {
                                    suppliedAccessRequest: _ctx.focusedAccessRequest,
                                    dialog: _ctx.isAccessRequestDialogActive,
                                    "retain-focus": false,
                                    onCloseAccessRequestDialog: _cache[2] || (_cache[2] = ($event: any) => {_ctx.isAccessRequestDialogActive=false;}),
                                    onRefreshPendingRequests: _ctx.editAccessRequest
                                  }, null, 8, ["suppliedAccessRequest", "dialog", "onRefreshPendingRequests"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
}