
import type { IInternalUser } from '@/Models/IInternalUser';
import { BaseApiService } from './base-api-service';
import type { IValidationResult } from '@/Models/IValidationResult';

class internalUserService extends BaseApiService{
    
    public constructor() {
        super('internaluser/');
    }

    public async getCurrentCstUser(): Promise<IInternalUser> {
        return this.httpClient.get<IInternalUser>(`get-current-internal-user`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public async getAllActiveUsers(): Promise<Array<IInternalUser>> {
        return this.httpClient.get<IInternalUser[]>(`get-active-internal-users`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public async updateInternalUser(user: IInternalUser): Promise<IValidationResult> {
        return this.httpClient.put<IValidationResult>(`update-internal-user`, user).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
}

export const InternalUserService : internalUserService = new internalUserService();