import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ParticipantHeader = _resolveComponent("ParticipantHeader")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_SingleErrorMessage = _resolveComponent("SingleErrorMessage")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_ValidationAlert = _resolveComponent("ValidationAlert")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_AddAliasDialog = _resolveComponent("AddAliasDialog")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.headerData && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_ParticipantHeader, {
          key: 0,
          data: _ctx.headerData
        }, null, 8, ["data"]))
      : _createCommentVNode("", true),
    _createVNode(_component_PageHeader, { headerText: "Participant Info" }),
    (_ctx.isLoading && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_SingleErrorMessage, { errorMessage: _ctx.errorMessage }, null, 8, ["errorMessage"]),
    (!_ctx.isLoading && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_v_card, { key: 2 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_form, {
                  modelValue: _ctx.isFormValid,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isFormValid) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              modelValue: _ctx.participant.firstName,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.participant.firstName) = $event)),
                              label: "First Name",
                              rules: _ctx.requiredFieldRules,
                              disabled: !_ctx.canEditRolePermission
                            }, null, 8, ["modelValue", "rules", "disabled"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              modelValue: _ctx.participant.middleName,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.participant.middleName) = $event)),
                              label: "Middle Name",
                              disabled: !_ctx.canEditRolePermission
                            }, null, 8, ["modelValue", "disabled"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              modelValue: _ctx.participant.lastName,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.participant.lastName) = $event)),
                              label: "Last Name",
                              rules: _ctx.requiredFieldRules,
                              disabled: !_ctx.canEditRolePermission
                            }, null, 8, ["modelValue", "rules", "disabled"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              modelValue: _ctx.participant.ssn,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.participant.ssn) = $event)),
                              label: "Social Security Number",
                              maxlength: "9",
                              counter: "",
                              type: "password",
                              disabled: !_ctx.canEditRolePermission
                            }, null, 8, ["modelValue", "disabled"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_select, {
                              items: _ctx.genderList,
                              "item-title": "description",
                              "item-value": "id",
                              modelValue: _ctx.participant.genderId,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.participant.genderId) = $event)),
                              label: "Sex",
                              disabled: !_ctx.canEditRolePermission
                            }, null, 8, ["items", "modelValue", "disabled"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              modelValue: _ctx.participant.dateOfBirthEditable,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.participant.dateOfBirthEditable) = $event)),
                              label: "Date Of Birth",
                              type: "date",
                              rules: _ctx.requiredFieldRules,
                              disabled: !_ctx.canEditRolePermission
                            }, null, 8, ["modelValue", "rules", "disabled"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_ValidationAlert, { "validation-results": _ctx.validationMessages }, null, 8, ["validation-results"]),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { align: "center" }, {
                      default: _withCtx(() => [
                        _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                          class: "mx-2 mb-4",
                          color: "primary",
                          onClick: _ctx.save,
                          disabled: _ctx.isSaving
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.saveButtonText), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick", "disabled"])), [
                          [_vShow, _ctx.canEditRolePermission],
                          [_directive_ripple]
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_divider, {
                  class: "my-4 border-opacity-50",
                  thickness: "3"
                }),
                _createVNode(_component_v_card_title, { class: "sectionHeader" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Participant Aliases")
                  ]),
                  _: 1
                }),
                _createVNode(_component_AddAliasDialog, {
                  modelValue: _ctx.showAliasDialog,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.showAliasDialog) = $event)),
                  sourceText: _ctx.selectedAlias.alias,
                  headerText: _ctx.aliasHeader,
                  onClose: _ctx.cancelAliasEdit,
                  onSave: _ctx.saveAlias
                }, null, 8, ["modelValue", "sourceText", "headerText", "onClose", "onSave"]),
                _createVNode(_component_v_data_table, {
                  headers: _ctx.aliasTableHeaders,
                  items: _ctx.aliasList,
                  density: "compact",
                  class: "elevation-1"
                }, {
                  "item.actions": _withCtx(({ item }) => [
                    _createVNode(_component_v_btn, {
                      onClick: ($event: any) => (_ctx.editAlias(item.raw)),
                      class: "ma-1",
                      density: "comfortable",
                      size: "small",
                      icon: "mdi-pencil-outline",
                      color: "info",
                      disabled: !_ctx.canEditRolePermission
                    }, null, 8, ["onClick", "disabled"])
                  ]),
                  _: 1
                }, 8, ["headers", "items"]),
                _createVNode(_component_v_row, { class: "mt-2" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { align: "center" }, {
                      default: _withCtx(() => [
                        _withDirectives(_createVNode(_component_v_btn, {
                          onClick: _ctx.newAlias,
                          color: "primary"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Add New Alias")
                          ]),
                          _: 1
                        }, 8, ["onClick"]), [
                          [_vShow, _ctx.canEditRolePermission]
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}