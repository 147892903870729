
	import { defineComponent } from 'vue';

	import ConfirmDialog from '@/ChildrenComponents/ConfirmDialog.vue';
	import LoadingSpinner from '@/ChildrenComponents/LoadingSpinner.vue';
	import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';
	import AddCaseNoteDialog from '@/ChildrenComponents/Participant/AddCaseNoteDialog.vue';
	import PageHeader from '@/ChildrenComponents/Participant/Common/PageHeader.vue';
	import ParticipantHeader from '@/ChildrenComponents/Participant/Common/ParticipantHeader.vue';
	import SingleErrorMessage from '@/ChildrenComponents/Participant/Common/SingleErrorMessage.vue';
	import IncorrectPermissions from '@/ChildrenComponents/IncorrectPermissions.vue'

	import { ComponentType } from '@/Enums/ComponentType'
	import { IInternalUser } from '@/Models/IInternalUser';
	import { IDataTableHeader } from '@/Models/IDataTableHeader';
	import { CaseNoteForEdit, ICaseNote } from '@/Models/ICaseNote';
	import HeaderData from '@/Models/HeaderData';

	import { CaseNoteService } from '@/Services/case-note-service';
	import { DateHelper } from '@/Services/Helper/date-helper';
	import { loadParticipantDataRequireCase } from '@/Services/Helper/loader-helper';

	import { doesUserRoleHaveComponentConditionalViewPermissions, doesUserRoleHaveComponentEditPermissions, doesUserRoleHaveComponentViewPermissions } from '@/Services/Helper/component-permissions-helper'
	import { useUserStore } from '@/Services/Store/user-store';

	export default defineComponent({
		name: "case-notes",
		components: { AddCaseNoteDialog, LoadingSpinner, ParticipantHeader, ConfirmDialog, ValidationAlert, PageHeader, SingleErrorMessage, IncorrectPermissions },
		setup(): { currentUser: IInternalUser | null } {
			const userStoreInstance = useUserStore();
			return { currentUser: userStoreInstance.$state.cstInternalUser };
		},
		created() {
			loadParticipantDataRequireCase(this.pageType, this.id).then(data => {
				this.caseId = data.case?.caseId ?? null;
				this.headerData = new HeaderData(data.participant, data.case?.program ?? null);
				this.setBlankNote();
			})
				.then(() => {
					if (this.caseId != null) {
						CaseNoteService.getByCaseId(this.caseId).then(result => this.notes = result).then(() => {
							this.isLoading = false
							if (this.canViewConditionalRolePermission) {
								this.notes = this.notes.filter(note => note.canProviderView === true)
							}
						});
					}
				})
				.catch(error => this.errorMessage = error);
		},
		data() {
			return {
				isLoading: true,
				errorMessage: "",
				validationMessages: [] as string[],
				pageType: this.$route.params.type?.toString() ?? "",
				id: this.$route.params.id == undefined ? null : parseInt(this.$route.params.id.toString()),
				caseId: null as number | null,
				headerData: null as HeaderData | null,

				notes: [] as ICaseNote[],
				selectedNote: {} as CaseNoteForEdit | null,
				noteHeaders: [
					{ title: "User", key: "createdByName", sortable: true, align: "start" },
					{ title: "Type", key: "noteType", sortable: true, align: "start" },
					{ title: "Date", key: "noteDate", sortable: true, align: "start" },
					{ title: "Note", key: "note", sortable: true, align: "start" },
                    { title: "Create Date", key: "createDate", sortable: true, align: "start" },
					{ title: "Edit", key: "actions", sortable: false },
				] as IDataTableHeader[],

				showDeleteWarning: false,
				deleteWarningText: "Are you sure you want to delete this note? This cannot be undone!",
				idToDelete: null as number | null,

				showNoteDialog: false,
				noteHeader: "",
			}
		},
		methods: {
			formatDate(date: Date): string {
				return DateHelper.formatDateUtc(date, "MM/dd/yyyy");
			},
            formatDateTimeLocal(date: Date): string {
				return DateHelper.formatDateLocal(date);
			},
			setBlankNote() {
				const blankNote = {
					caseNoteId: 0,
					caseId: this.caseId,
					note: "",
				} as ICaseNote;
				this.selectedNote = new CaseNoteForEdit(blankNote);
			},
			newNote() {
				this.setBlankNote();
				this.noteHeader = "Add Note";
				this.showNoteDialog = true;
			},
			editNote(note: ICaseNote) {
				this.selectedNote = new CaseNoteForEdit(note);
				this.noteHeader = "Edit Note";
				this.showNoteDialog = true;
			},
			cancelNoteEdit() {
				this.setBlankNote();
				this.showNoteDialog = false;
			},
			saveNote(newNote: CaseNoteForEdit) {
				CaseNoteService.save(newNote).then(result => {
					this.validationMessages = result.messages;

					if (result.isValid && result.resultObject != null) {
						this.setBlankNote();
						this.showNoteDialog = false;
						if (this.caseId != null) {
							CaseNoteService.getByCaseId(this.caseId).then(notes => this.notes = notes);
						}
					}
				})
				.catch(error => this.errorMessage = error);
			},
			deleteNote(noteId: number) {
				this.idToDelete = noteId;
				this.showDeleteWarning = true;
			},
			cancelDelete() {
				this.showDeleteWarning = false;
				this.idToDelete = null;
			},
			confirmDelete() {
				this.showDeleteWarning = false;
				if (this.idToDelete != null) {
					CaseNoteService.delete(this.idToDelete).then(() => this.notes = this.notes.filter(x => x.caseNoteId != this.idToDelete));
				}
			},
		},
		computed: {
			componentType(): ComponentType {
				return ComponentType.CaseNotes
			},
			canEditRolePermission() : boolean {
				return doesUserRoleHaveComponentEditPermissions(this.currentUser?.attachedRoles ?? [], this.componentType)
			},
			canViewRolePermission() : boolean {
				return doesUserRoleHaveComponentViewPermissions(this.currentUser?.attachedRoles ?? [], this.componentType)
			},
			canViewConditionalRolePermission() : boolean {
				return doesUserRoleHaveComponentConditionalViewPermissions(this.currentUser?.attachedRoles ?? [], this.componentType)
			},
		},
	});
