
import { defineComponent, PropType } from 'vue';


export default defineComponent({
  name: 'validation-alert',
  components: { },

  setup(): { } {
    return {      
    };
  },
  beforeCreate() {
    
  },
  created() {
    
  },
  props: {
    validationResults: {
        type: Array as PropType<string[]>,
        required: true,
    },
  },
  data () {
    return {
        
    }
  },
  watch: {
    
  },
  methods: {
    
  }
});
