import { IParticipant } from './IParticipant';
import { ITreatmentProgram } from './ITreatmentProgram';

import { DateHelper } from '@/Services/Helper/date-helper';

export default class HeaderData {
	participantId?: number;
	firstName: string = "";
	middleName: string = "";
	lastName: string = "";
	ssn: string = "";
	dateOfBirth?: Date|null;
	programName: string = "";

	fullName: string = "";
	ssnLastFour: string = "";

	constructor(participant: IParticipant | null, program: ITreatmentProgram | null) {
		if (participant != null) {
			this.participantId = participant.participantId;
			this.firstName = participant.firstName;
			this.middleName = participant.middleName ?? "";
			this.lastName = participant.lastName;
			this.ssn = participant.ssn;
			this.dateOfBirth = DateHelper.standardizeDate(participant.dateOfBirth);

			const name = `${this.firstName} ${this.middleName} ${this.lastName}`;
			this.fullName = name.replace("  ", " ");
			this.ssnLastFour = (this.ssn?.length ?? 0) < 9 ? "" : "***-**-" + this.ssn.substring(5, 9);
		}
		if (program != null) {
			this.programName = program.description;
		}
	}
}