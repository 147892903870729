import { ISchool } from '@/Models/ISchool';
import { ITypedValidationResult } from '@/Models/IValidationResult';
import { BaseApiService } from './base-api-service';

class schoolService extends BaseApiService {
    public constructor() {
		super('school/');
    }

	public async getByPhaseId(phaseId: number): Promise<ISchool[]> {
		return this.httpClient.get<ISchool[]>(`GetByPhaseId/${phaseId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public async getByCaseId(caseId: number): Promise<ISchool[]> {
        return this.httpClient.get<ISchool[]>(`GetByCaseId/${caseId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
	public async save(dto: ISchool): Promise<ITypedValidationResult<ISchool>> {
		return this.httpClient.post<ITypedValidationResult<ISchool>>("save", dto).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}

	public async delete(id: number): Promise<void> {
		return this.httpClient.delete<void>(`delete/${id}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}
}

export const SchoolService: schoolService = new schoolService();