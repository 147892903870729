import { IBehaviorResponse } from '@/Models/IBehaviorResponse';
import { ITypedValidationResult } from '@/Models/IValidationResult';
import { BaseApiService } from './base-api-service';

class behaviorResponseService extends BaseApiService {
    public constructor() {
		super('behaviorResponse/');
    }

	public async getByPhaseId(phaseId: number): Promise<IBehaviorResponse[]> {
		return this.httpClient.get<IBehaviorResponse[]>(`GetByPhaseId/${phaseId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
	public async save(dto: IBehaviorResponse): Promise<ITypedValidationResult<IBehaviorResponse>> {
		return this.httpClient.post<ITypedValidationResult<IBehaviorResponse>>("save", dto).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}

	public async delete(id: number): Promise<void> {
		return this.httpClient.delete<void>(`delete/${id}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}
}

export const BehaviorResponseService: behaviorResponseService = new behaviorResponseService();