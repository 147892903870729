
	import { defineComponent } from "vue";

	import { MenuItemTypes } from "@/Enums/MenuItemTypes";
	import { Role } from "@/Enums/Role";
	
	import { IInternalUser } from "@/Models/IInternalUser";
	
	import { loadParticipantData } from "@/Services/Helper/loader-helper";
	import { useUserStore } from "@/Services/Store/user-store";
	
	export default defineComponent({
		name: "navigation-sidebar",
		emits: ["set-temporary"],
		created() {
			this.setIds();
			this.manageDynamicLinks();
		},
		data() {
			return {
				pageType: "",
				id: this.$route.params.id == undefined ? null : parseInt(this.$route.params.id.toString()),
				participantId: null as number | null,
				caseId: null as number | null,
				phaseId: null as number | null,
                eligibilityId: null as number | null,
				otherLinks: [
					{ to: "/batch-entry", label: "Batch Entry" },
				],
				participantMenuLinks: [
					{ endpoint: "cases", label: "Cases" },
					{ endpoint: "information", label: "Basic Information" },
					{ endpoint: "participant-notes", label: "Participant Notes" },
				],
				caseMenuLinks: [
					{ endpoint: "workflows", label: "Workflows" },
					{ endpoint: "contact", label: "Contact Info" },
					{ endpoint: "case-notes", label: "Case Notes" },
					{ endpoint: "drug-test-dashboard", label: "Drug Test" },
					{ endpoint: "case-ledger", label: "Fees" },
					{ endpoint: "judicial-history", label: "Judicial History" },
					{ endpoint: "documents", label: "Documents", base: "case" },
				],
				phaseMenuLinks: [
					{ endpoint: "dashboard", label: "Dashboard" },
					{ endpoint: "documents", label: "Documents", base: "phase" },
				],

				// the current user is used to determine conditional links depending on role
				// logic for switching is in the manageDynamicLinks method 
				currentUser: null as IInternalUser | null,
			}
		},
		methods: {
			getUrl(endpoint: string): string {
				if (this.phaseId != null) return `/phase/${this.phaseId}/${endpoint}`;
				if (this.caseId != null) return `/case/${this.caseId}/${endpoint}`;
				if (this.participantId != null) return `/participant/${this.participantId}/${endpoint}`;
                if (this.eligibilityId != null) return `/eligibility/${this.eligibilityId}/${endpoint}`;
				return "";
			},
			getBasedUrl(base: string, endpoint: string): string {
				switch (base) {
					case "phase":
						return `/phase/${this.phaseId}/${endpoint}`
					case "case":
						return `/case/${this.caseId}/${endpoint}`
					case "participant":
						return `/participant/${this.participantId}/${endpoint}`
					case "eligibility":
						return `/eligibility/${this.eligibilityId}/${endpoint}`
					default:
						return "";
				}
			},
			setIds() {
				this.participantId = null;
				this.caseId = null;
				this.phaseId = null;
                this.eligibilityId = null;
				this.id = this.$route.params.id == undefined ? null : parseInt(this.$route.params.id.toString());
				let pageType = "";

				if (this.$route.path.includes("/participant/")) {
					this.pageType = MenuItemTypes.Participant;
					this.participantId = this.id;
					pageType = "participant";
				}
				else if (this.$route.path.includes("/case/") || this.$route.path.includes("/case-closure/")) {
					this.pageType = MenuItemTypes.Case;
					this.caseId = this.id;
					pageType = "case";
				}
				else if (this.$route.path.includes("/phase/")) {
					this.pageType = MenuItemTypes.Phase;
					this.phaseId = this.id;
					pageType = "phase";
				}
                else if (this.$route.path.includes("/eligibility/")) {
					this.pageType = MenuItemTypes.Eligibility;
					this.eligibilityId = this.id;
					pageType = "eligibility";
				}

				loadParticipantData(pageType, this.id)
					.then((participant) => {
						this.caseId = participant.case?.caseId || this.caseId || null;
						this.phaseId = participant.phase?.phaseId || this.phaseId || null;
						this.participantId = participant.participant?.participantId || this.participantId || null;
						this.eligibilityId = participant.eligibility?.eligibilityId || this.eligibilityId || null;
					})
			},
			manageDynamicLinks() {
				const userStore = useUserStore();
				const currentUser = userStore.$state.cstInternalUser;
				const currentRoleIds = currentUser?.attachedRoles.map(ar => ar.roleId) ?? [];

				if (this.$route.path.includes("/participant/") || this.$route.path.includes("/eligibility/")) {
					if (this.caseMenuLinks.some(e => e.endpoint == "outcomes")) {
						this.caseMenuLinks = this.caseMenuLinks.filter(e => e.endpoint != "outcomes");
					}
				}
			
				// outcome is only rendered on these paths
				else if (this.$route.path.includes("/case/")
						|| this.$route.path.includes("/case-closure/") 
						|| this.$route.path.includes("/phase/")
				) {
					// outcome is only rendered if it doesn't exist and we aren't one of the users who can't
					// see outcomes information
					if (!this.caseMenuLinks.some(e => e.endpoint == "outcomes")
						&& !currentRoleIds.includes(Role.ProbationSupervisor)
						&& !currentRoleIds.includes(Role.PeerSupportSpecialist)
					) {
						this.caseMenuLinks.push({ endpoint: "outcomes", label: "Outcomes" });
					} else {
						this.caseMenuLinks = this.caseMenuLinks.filter(e => e.endpoint != "outcomes");
					}
				}
			},
		},
		watch: {
			'$route.path': function () {
				this.setIds();
				this.manageDynamicLinks();
				const isTemporary = this.participantId == null && this.caseId == null && this.phaseId == null && this.eligibilityId == null;
				this.$emit("set-temporary", isTemporary);
			},
		},
	});
