import { IEligibility } from '@/Models/IEligibility';
import { ITypedValidationResult } from '@/Models/IValidationResult';
import { BaseApiService } from './base-api-service';
import { IParticipantEmergencyContactInformation } from '@/Models/IParticipantEmergencyContactInformation';

class eligibilityService extends BaseApiService {
    public constructor() {
		super('eligibility/');
    }

	public async getWithChildren(id: number): Promise<IEligibility> {
		return this.httpClient.get<IEligibility>(`GetWithChildren/${id}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public async get(id: number): Promise<IEligibility> {
        return this.httpClient.get<IEligibility>(`Get/${id}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
	public async saveWithChildren(eligibility: IEligibility): Promise<ITypedValidationResult<IEligibility>> {
		return this.httpClient.post<ITypedValidationResult<IEligibility>>("SaveWithChildren", eligibility).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}

    public async getEmergencyContactsByCaseId(caseId: number): Promise<IParticipantEmergencyContactInformation[]> {
        return this.httpClient.get<IParticipantEmergencyContactInformation[]>(`GetEmergencyContactsByCaseId/${caseId}`)
            .then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
}

export const EligibilityService: eligibilityService = new eligibilityService();