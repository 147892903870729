import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_ApexCharts = _resolveComponent("ApexCharts")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { height: "20px" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { class: "d-flex justify-center mt-1" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_tooltip, { location: "bottom" }, {
            activator: _withCtx(({ props }) => [
              _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                (_ctx.data.length > _ctx.maxPeriods)
                  ? (_openBlock(), _createBlock(_component_v_chip, { key: 0 }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(`Data trimmed to ${_ctx.maxPeriods} most recent periods.`), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ], 16)
            ]),
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(`To ensure chart readability, only the ${_ctx.maxPeriods} most recent data points are shown. See another chart for a complete report of this metric.`), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, { class: "mt-1" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            md: "12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ApexCharts, {
                series: _ctx.data.slice(_ctx.startIdx, _ctx.lastIdx + 1),
                options: _ctx.options
              }, null, 8, ["series", "options"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}