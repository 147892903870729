import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createSlots as _createSlots, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ParticipantHeader = _resolveComponent("ParticipantHeader")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_SingleErrorMessage = _resolveComponent("SingleErrorMessage")!
  const _component_ValidationAlert = _resolveComponent("ValidationAlert")!
  const _component_IncorrectPermissions = _resolveComponent("IncorrectPermissions")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_AddCaseNoteDialog = _resolveComponent("AddCaseNoteDialog")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.headerData && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_ParticipantHeader, {
          key: 0,
          data: _ctx.headerData
        }, null, 8, ["data"]))
      : _createCommentVNode("", true),
    _createVNode(_component_PageHeader, { headerText: "Case Notes" }),
    (_ctx.isLoading && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_SingleErrorMessage, { errorMessage: _ctx.errorMessage }, null, 8, ["errorMessage"]),
    _createVNode(_component_ValidationAlert, { validationResults: _ctx.validationMessages }, null, 8, ["validationResults"]),
    (!_ctx.canViewRolePermission)
      ? (_openBlock(), _createBlock(_component_v_container, {
          key: 2,
          fluid: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_IncorrectPermissions)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    ((!_ctx.isLoading && !_ctx.errorMessage) && _ctx.canViewRolePermission)
      ? (_openBlock(), _createBlock(_component_v_card, { key: 3 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_AddCaseNoteDialog, {
                  modelValue: _ctx.showNoteDialog,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showNoteDialog) = $event)),
                  sourceCaseNote: _ctx.selectedNote,
                  headerText: _ctx.noteHeader,
                  onClose: _ctx.cancelNoteEdit,
                  onSave: _ctx.saveNote
                }, null, 8, ["modelValue", "sourceCaseNote", "headerText", "onClose", "onSave"]),
                _createVNode(_component_ConfirmDialog, {
                  dialogIsOpen: _ctx.showDeleteWarning,
                  onConfirm: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirmDelete())),
                  onCancel: _ctx.cancelDelete,
                  dialogText: _ctx.deleteWarningText
                }, null, 8, ["dialogIsOpen", "onCancel", "dialogText"]),
                _createVNode(_component_v_data_table, {
                  headers: _ctx.noteHeaders,
                  items: _ctx.notes,
                  density: "compact",
                  class: "elevation-1 pre-wrap"
                }, _createSlots({
                  "item.noteDate": _withCtx(({ item }) => [
                    _createTextVNode(_toDisplayString(_ctx.formatDate(item.raw.noteDate)), 1)
                  ]),
                  "item.createDate": _withCtx(({ item }) => [
                    _createTextVNode(_toDisplayString(_ctx.formatDateTimeLocal(item.raw.createDate)), 1)
                  ]),
                  _: 2
                }, [
                  (_ctx.canEditRolePermission)
                    ? {
                        name: "item.actions",
                        fn: _withCtx(({ item }) => [
                          _createVNode(_component_v_btn, {
                            onClick: ($event: any) => (_ctx.editNote(item.raw)),
                            class: "ma-1",
                            density: "comfortable",
                            size: "small",
                            icon: "mdi-pencil-outline",
                            color: "info"
                          }, null, 8, ["onClick"]),
                          _createVNode(_component_v_btn, {
                            onClick: ($event: any) => (_ctx.deleteNote(item.raw.caseNoteId)),
                            class: "ma-1",
                            density: "comfortable",
                            size: "small",
                            icon: "mdi-trash-can-outline",
                            color: "error"
                          }, null, 8, ["onClick"])
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1032, ["headers", "items"]),
                _createVNode(_component_v_row, { class: "mt-2" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { align: "center" }, {
                      default: _withCtx(() => [
                        _withDirectives(_createVNode(_component_v_btn, {
                          onClick: _ctx.newNote,
                          color: "primary"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Add New Note")
                          ]),
                          _: 1
                        }, 8, ["onClick"]), [
                          [_vShow, _ctx.canEditRolePermission]
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}