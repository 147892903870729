
	import { defineComponent } from 'vue';

	import LoadingSpinner from '@/ChildrenComponents/LoadingSpinner.vue';
	import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';

	import { IDrugTest } from '@/Models/IDrugTest';
	import { IDataTableHeader } from '@/Models/IDataTableHeader';

	import { DrugTestService } from '@/Services/drug-test-service';
	import { DateHelper } from '@/Services/Helper/date-helper';

	export default defineComponent({
		name: 'drug-test-list',
		components: { LoadingSpinner, ValidationAlert },
		props: {
			phaseId: {
				type: Number,
				required: true
			},
			isSaveAllowed: {
				type: Boolean,
				required: true
			},
			isDeleteAllowed: {
				type: Boolean,
				required: true
			},
		},
		created() {
			this.loadData();
		},
		data() {
			return {
				isLoading: true,
				items: [] as IDrugTest[],
				headers: [
					{ title: "Status", key: "currentStatusText", sortable: true, align: "start" },
					{ title: "Test Type", key: "typeList", sortable: true, align: "start" },
					{ title: "Test Date", key: "datePerformed", sortable: true, align: "start" },
					{ title: "Result", key: "resultText", sortable: true, align: "start" },
					{ title: "Notes", key: "notes", sortable: true, align: "start" },
					{ title: "Edit", key: "actions", sortable: false },
				] as IDataTableHeader[],
				validationMessages: [] as string[],
                addTestError: false
			}
		},
		methods: {
			loadData() {
				this.isLoading = true;
				DrugTestService.getByPhaseId(this.phaseId).then(items => this.items = items).then(() => this.isLoading = false);
			},
			formatDate(date: Date): string {
				return DateHelper.formatDateUtc(date, "MM/dd/yyyy");
			},
			addTest() {
                this.isLoading = true;
                DrugTestService.save({ phaseId: this.phaseId } as IDrugTest).then(result => {
                    if (result) {
                        this.validationMessages = result.messages;
                        if (result.resultObject) {
                            this.$router.push(`/drug-test/${result.resultObject.drugTestId}`);
                        }
                    } else {
                        this.addTestError = true;
                    }
                }).catch(() => this.addTestError = true).finally(() => this.isLoading = false)
			},
			editTest(id: number) {
				this.$router.push(`/drug-test/${id}`);
			},
			deleteTest(id: number) {
				if (confirm("Are you sure you want to delete?")) {
					DrugTestService.delete(id).then(result => {
						this.validationMessages = result.messages;
						if (result.isValid) {
							this.items = this.items.filter(x => x.drugTestId != id);
						}
					});
				}
			},
		},
		watch: {
			phaseId() {
				this.loadData();
			},
		},
	}); 
