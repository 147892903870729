
	import { defineComponent, PropType } from 'vue';

	import { TreatmentService } from '@/Services/treatment-service';
	import { ValidationHelper } from '@/Services/Helper/validation-helper';
	import { DateHelper } from '@/Services/Helper/date-helper';

	import { ITreatment } from '@/Models/ITreatment';
	import { IDataTableHeader } from '@/Models/IDataTableHeader';
	import { MasterLookupWrapper } from '@/Models/Lookup/MasterLookupWrapper';

	import LoadingSpinner from '@/ChildrenComponents/LoadingSpinner.vue';
	import TreatmentDialog from '@/ChildrenComponents/Phase/PhaseTreatmentDialog.vue';

	export default defineComponent({
		name: 'phase-treatment',
		components: { LoadingSpinner, TreatmentDialog },
		props: {
			lookups: {
				type: Object as PropType<MasterLookupWrapper>,
				required: true
			},
			phaseId: {
				type: Number,
				required: true
			},
			isSaveAllowed: {
				type: Boolean,
				required: true
			},
			isDeleteAllowed: {
				type: Boolean,
				required: true
			},
		},
		created() {
			this.loadData();
		},
		data() {
			return {
				isLoading: true,
				isSaving: false,
				showModal: false,
				items: [] as ITreatment[],
				editingItem: {} as ITreatment,
				requiredRules: ValidationHelper.requiredRules,
				headers: [
					{ title: "Type", key: "typeName", sortable: true, align: "start" },
					{ title: "Status", key: "statusName", sortable: true, align: "start" },
					{ title: "Start Date", key: "startDate", sortable: true, align: "start" },
					{ title: "End Date", key: "endDate", sortable: true, align: "start" },
					{ title: "Provider", key: "providerName", sortable: true, align: "start" },
                    { title: "Minutes", key: "minutes", sortable: true, align: "start" },
					{ title: "Notes", key: "notes", sortable: true, align: "start" },
					{ title: "Edit", key: "actions", sortable: false },
				] as IDataTableHeader[],
			}
		},
		methods: {
			setBlankItem() {
				const newItem = {
					treatmentId: 0,
					phaseId: this.phaseId,
				} as ITreatment;
				this.editingItem = newItem;
			},
			loadData() {
				this.isLoading = true;
				TreatmentService.getByPhaseId(this.phaseId).then(items => this.items = items).then(() => this.isLoading = false);
			},
			formatDate(date: Date): string {
				return DateHelper.formatDateUtc(date, "MM/dd/yyyy");
			},
			addRow() {
				this.setBlankItem();
				this.showModal = true;
			},
			editRow(item: ITreatment) {
				this.editingItem = item;
				this.showModal = true;
			},
			deleteRow(id: number) {
				if (confirm("Are you sure you want to delete?")) {
					TreatmentService.delete(id).then(() => this.items = this.items.filter(x => x.treatmentId != id));
				}
			},
			onSave(item: ITreatment, startNew: boolean) {
				let index = this.items.findIndex(x => x.treatmentId == item.treatmentId);
				if (index > -1) {
					this.items[index] = item;
				}
				else {
					this.items.push(item);
				}
				if (startNew) {
					this.setBlankItem();
				}
				else {
					this.showModal = false;
				}
			},
		},
		watch: {
			phaseId() {
				this.loadData();
			},
		},
	}); 
