import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_HtmlEditor = _resolveComponent("HtmlEditor")!
  const _component_VariableMenu = _resolveComponent("VariableMenu")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      (_ctx.includeLabelEditor)
        ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                md: "12"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, { style: {"margin-bottom":"20px"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.currentTemplate.label,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentTemplate.label) = $event)),
                            label: "Template Label",
                            "hide-details": "auto",
                            hint: "The template label will be used to identify this template throughout the application. You may update the name of the template later."
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.includeVariableMenu)
        ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                md: "7"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_HtmlEditor, {
                    source: _ctx.currentTemplate.contents,
                    onOnChange: _ctx.onChange
                  }, null, 8, ["source", "onOnChange"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "5"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_VariableMenu, { onInsertVariable: _ctx.insert }, null, 8, ["onInsertVariable"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_v_row, { key: 2 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                md: "12"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_HtmlEditor, {
                    source: _ctx.currentTemplate.contents,
                    onOnChange: _ctx.onChange
                  }, null, 8, ["source", "onOnChange"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
    ]),
    _: 1
  }))
}