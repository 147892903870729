
import { defineComponent, Ref, ref } from 'vue';

import { ValidationHelper } from '@/Services/Helper/validation-helper';

export default defineComponent({
	name: "add-alias-dialog",
	emits: ["close", "save"],
	props: {
		sourceText: String,
		headerText: String,
	},
	setup(): { form: Ref } {
		const form = ref(null);
		return { form };
	},
	created() {
		this.text = this.sourceText != undefined ? this.sourceText : "";
	},
	data () {
		return {
			requiredFieldRules: ValidationHelper.requiredRules,
			text: "",
			buttonText: "Save Alias",
			isSaving: false,
		}
	},
	methods: {
		closeDialog(): void {
			this.$emit("close");
		},
		async save() {
			const { valid } = await this.form.validate();
			if (valid) {
				this.isSaving = true;
				this.buttonText = "Saving..."
				this.$emit("save", this.text);
			}
		},
	},
	watch: {
		sourceText(newText: string) {
			this.text = newText;
			this.isSaving = false;
			this.buttonText = "Save Alias";
		},
	},
});
