import { 
    IDrugTestReport, 
    ICourtAttendanceReport , 
    IPhaseReportParamter, 
    ITreatmentReport, 
    ICaseManagementReport, 
    IAncillaryMeetingReport,
    ISupervisionReport, 
    ICensusReport, 
} from '@/Models/Report/IPhaseReport';
import { BaseApiService } from '../base-api-service';
import { 
    IBehaviorMetricReport, 
    ICourtAppearanceMetricReport, 
    IDrugTestMetricReport, 
    IJudicialHistoryMetricReport, 
    IMetricReport, 
    IProgramGoalMetricReport, 
} from '@/Models/Report/IMetricReport';

class reportService extends BaseApiService {
    public constructor() {
		super('Report/');
    }

    public async getDrugTestReportData(params: IPhaseReportParamter): Promise<IDrugTestReport[]> {
        return this.httpClient.post<IDrugTestReport[]>(`GetDrugTestReportData`, params)
            .then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public async getCourtAttendanceReportData(params: IPhaseReportParamter): Promise<ICourtAttendanceReport[]> {
        return this.httpClient.post<ICourtAttendanceReport[]>(`GetCourtAttendanceReportData`, params)
            .then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public async getCensusReportData(params: IPhaseReportParamter): Promise<ICensusReport[]> {
        return this.httpClient.post<ICensusReport[]>('GetCensusReportData', params)
                    .then(this.handleResponse.bind(this))
                    .catch(this.handleError.bind(this));
    }

    public async getMetricsReportData(params: IPhaseReportParamter): Promise<IMetricReport[]> {
        return this.httpClient.post<IMetricReport[]>('GetMetricReportData', params)
                    .then(this.handleResponse.bind(this))
                    .catch(this.handleError.bind(this));
    }

    public async getDrugTestMetricReportData(params: IPhaseReportParamter): Promise<IDrugTestMetricReport[]> {
        return this.httpClient.post<IDrugTestMetricReport[]>('GetDrugTestMetrics', params)
                    .then(this.handleResponse.bind(this))
                    .catch(this.handleError.bind(this));
    }

    public async getCourtAppearanceMetricReportData(params: IPhaseReportParamter): Promise<ICourtAppearanceMetricReport[]> {
        return this.httpClient.post<ICourtAppearanceMetricReport[]>('GetCourtAppearanceMetrics', params)
                    .then(this.handleResponse.bind(this))
                    .catch(this.handleError.bind(this));
    }

    public async getProgramGoalMetricReportData(params: IPhaseReportParamter): Promise<IProgramGoalMetricReport[]> {
        return this.httpClient.post<IProgramGoalMetricReport[]>('GetProgramGoalMetrics', params)
                    .then(this.handleResponse.bind(this))
                    .catch(this.handleError.bind(this));
    }

    public async getBehaviorMetricReportData(params: IPhaseReportParamter): Promise<IBehaviorMetricReport[]> {
        return this.httpClient.post<IBehaviorMetricReport[]>('GetBehaviorMetrics', params)
                    .then(this.handleResponse.bind(this))
                    .catch(this.handleError.bind(this));
    }

    public async getJudicialHistoryMetricReportData(params: IPhaseReportParamter): Promise<IJudicialHistoryMetricReport[]> {
        return this.httpClient.post<IJudicialHistoryMetricReport[]>('GetJudicialHistoryMetrics', params)
                    .then(this.handleResponse.bind(this))
                    .catch(this.handleError.bind(this));
    }

    public async getTreatmentReportData(params: IPhaseReportParamter): Promise<ITreatmentReport[]> {
        return this.httpClient.post<ITreatmentReport[]>(`GetTreatmentReportData`, params)
            .then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public async getCaseManagementReportData(params: IPhaseReportParamter): Promise<ICaseManagementReport[]> {
        return this.httpClient.post<ICaseManagementReport[]>(`GetCaseManagementReportData`, params)
            .then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public async getAncillaryMeetingReportData(params: IPhaseReportParamter): Promise<IAncillaryMeetingReport[]> {
        return this.httpClient.post<IAncillaryMeetingReport[]>(`GetAncillaryMeetingReportData`, params)
            .then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public async getSupervisionReportData(params: IPhaseReportParamter): Promise<ISupervisionReport[]> {
        return this.httpClient.post<ISupervisionReport[]>(`GetSupervisionReportData`, params)
            .then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
}

export const ReportService: reportService = new reportService();