import { ISupervision } from '@/Models/ISupervision';
import { ITypedValidationResult } from '@/Models/IValidationResult';
import { BaseApiService } from './base-api-service';

class supervisionService extends BaseApiService {
    public constructor() {
		super('supervision/');
    }

	public async getByPhaseId(phaseId: number): Promise<ISupervision[]> {
		return this.httpClient.get<ISupervision[]>(`GetByPhaseId/${phaseId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
	public async save(dto: ISupervision): Promise<ITypedValidationResult<ISupervision>> {
		return this.httpClient.post<ITypedValidationResult<ISupervision>>("save", dto).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}

	public async delete(id: number): Promise<void> {
		return this.httpClient.delete<void>(`delete/${id}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}
}

export const SupervisionService: supervisionService = new supervisionService();