
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'incorrect-permissions',
  components: { },

  setup(): { } {
    return {      
    };
  },
  beforeCreate() {
    
  },
  created() {
    
  },
  data () {
    return {
        
    }
  },
  watch: {
    
  },
  methods: {
    
  }
});
