import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_ValidationAlert = _resolveComponent("ValidationAlert")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_card_title, null, {
      default: _withCtx(() => [
        _createTextVNode("Documents")
      ]),
      _: 1
    }),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 0 }))
      : _createCommentVNode("", true),
    (!_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_v_data_table, {
          key: 1,
          headers: _ctx.headers,
          items: _ctx.documents,
          density: "compact",
          class: "elevation-1"
        }, {
          "item.createDate": _withCtx(({ item }) => [
            _createTextVNode(_toDisplayString(_ctx.formatTimestamp(item.raw.createDate)), 1)
          ]),
          "item.actions": _withCtx(({ item }) => [
            _createVNode(_component_v_btn, {
              onClick: ($event: any) => (_ctx.download(item.raw)),
              density: "comfortable",
              size: "small",
              icon: "mdi-download-box",
              color: "view"
            }, null, 8, ["onClick"]),
            (_ctx.canDelete)
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 0,
                  onClick: ($event: any) => (_ctx.deleteItem(item.raw.id)),
                  class: "ma-1",
                  density: "comfortable",
                  size: "small",
                  icon: "mdi-trash-can-outline",
                  color: "error"
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["headers", "items"]))
      : _createCommentVNode("", true),
    _createVNode(_component_ValidationAlert, { validationResults: _ctx.validationMessages }, null, 8, ["validationResults"]),
    (_ctx.canUpload)
      ? (_openBlock(), _createBlock(_component_v_row, {
          key: 2,
          class: "mt-2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_select, {
                  modelValue: _ctx.newDocumentTypeId,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newDocumentTypeId) = $event)),
                  label: "Type",
                  items: _ctx.filteredDocumentTypes,
                  "item-value": "id",
                  "item-title": "description",
                  rules: _ctx.requiredRules
                }, null, 8, ["modelValue", "items", "rules"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_file_input, {
                  counter: "",
                  label: "Select Document",
                  modelValue: _ctx.newFile,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newFile) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  onClick: _ctx.upload,
                  color: "primary",
                  disabled: _ctx.isUploading || !_ctx.isUploadValid
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
                  ]),
                  _: 1
                }, 8, ["onClick", "disabled"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}