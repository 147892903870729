import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"white-space":"pre-wrap"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ParticipantHeader = _resolveComponent("ParticipantHeader")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_SingleErrorMessage = _resolveComponent("SingleErrorMessage")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_ValidationAlert = _resolveComponent("ValidationAlert")!
  const _component_WorkflowSubmission = _resolveComponent("WorkflowSubmission")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_WorkflowHistory = _resolveComponent("WorkflowHistory")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.headerData && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_ParticipantHeader, {
          key: 0,
          data: _ctx.headerData
        }, null, 8, ["data"]))
      : _createCommentVNode("", true),
    _createVNode(_component_PageHeader, {
      headerText: _ctx.phase?.phaseNumber ? `(Phase ${_ctx.phase?.phaseNumber}) Drug Test` : 'Drug Test'
    }, null, 8, ["headerText"]),
    (_ctx.isLoading && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_SingleErrorMessage, { errorMessage: _ctx.errorMessage }, null, 8, ["errorMessage"]),
    (!_ctx.isLoading && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_v_expansion_panels, {
          key: 2,
          multiple: "",
          modelValue: _ctx.openPanels,
          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.openPanels) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, { class: "text-h5" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Drug Test Information")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { align: "center" }, {
                          default: _withCtx(() => [
                            _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                              class: "mx-2 mb-4",
                              color: "secondary",
                              onClick: _ctx.returnToPhase,
                              disabled: _ctx.isSaving
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("Return to Phase " + _toDisplayString(_ctx.phase?.phaseNumber + ' ' || '') + "Dashboard", 1)
                              ]),
                              _: 1
                            }, 8, ["onClick", "disabled"])), [
                              [_directive_ripple]
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { align: "center" }, {
                          default: _withCtx(() => [
                            _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                              class: "mx-2 mb-4",
                              color: "secondary",
                              onClick: _ctx.returnToDrugTestDash,
                              disabled: _ctx.isSaving
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("Return to Drug Test Dashboard")
                              ]),
                              _: 1
                            }, 8, ["onClick", "disabled"])), [
                              [_directive_ripple]
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_form, { ref: "form" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_autocomplete, {
                                  disabled: !_ctx.canEditRolePermission,
                                  multiple: "",
                                  clearable: "",
                                  items: _ctx.types,
                                  "item-value": "id",
                                  "item-title": "description",
                                  label: "Type",
                                  modelValue: _ctx.selectedTypeIds,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTypeIds) = $event))
                                }, null, 8, ["disabled", "items", "modelValue"]),
                                _createVNode(_component_v_select, {
                                  disabled: !_ctx.canEditRolePermission,
                                  items: _ctx.sessionTypes,
                                  "item-value": "id",
                                  "item-title": "description",
                                  label: "Session Type",
                                  modelValue: _ctx.drugTest.drugTestSessionTypeId,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.drugTest.drugTestSessionTypeId) = $event))
                                }, null, 8, ["disabled", "items", "modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              sm: "6",
                              md: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_select, {
                                  disabled: !_ctx.canEditRolePermission,
                                  items: _ctx.locations,
                                  "item-value": "id",
                                  "item-title": "description",
                                  label: "Location",
                                  modelValue: _ctx.drugTest.drugTestLocationId,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.drugTest.drugTestLocationId) = $event))
                                }, null, 8, ["disabled", "items", "modelValue"]),
                                (_ctx.showLocationOther)
                                  ? (_openBlock(), _createBlock(_component_v_text_field, {
                                      key: 0,
                                      disabled: !_ctx.canEditRolePermission,
                                      required: _ctx.showLocationOther,
                                      rules: _ctx.showLocationOther ? _ctx.requiredRules : [],
                                      modelValue: _ctx.drugTest.drugTestLocationOther,
                                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.drugTest.drugTestLocationOther) = $event)),
                                      label: "Specify Location"
                                    }, null, 8, ["disabled", "required", "rules", "modelValue"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              sm: "6",
                              md: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_select, {
                                  disabled: !_ctx.canEditRolePermission,
                                  items: _ctx.methods,
                                  "item-value": "id",
                                  "item-title": "description",
                                  label: "Method",
                                  modelValue: _ctx.drugTest.drugTestMethodId,
                                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.drugTest.drugTestMethodId) = $event))
                                }, null, 8, ["disabled", "items", "modelValue"]),
                                _createVNode(_component_v_select, {
                                  disabled: !_ctx.canEditRolePermission,
                                  items: _ctx.results,
                                  "item-value": "id",
                                  "item-title": "description",
                                  label: "Result",
                                  modelValue: _ctx.drugTest.drugTestResultId,
                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.drugTest.drugTestResultId) = $event))
                                }, null, 8, ["disabled", "items", "modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              cols: "12",
                              sm: "6",
                              md: "3"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  disabled: !_ctx.canEditRolePermission,
                                  type: "date",
                                  label: "Test Date",
                                  modelValue: _ctx.drugTest.datePerformedForEdit,
                                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.drugTest.datePerformedForEdit) = $event))
                                }, null, 8, ["disabled", "modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              sm: "6",
                              md: "6"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  disabled: !_ctx.canEditRolePermission,
                                  modelValue: _ctx.drugTest.performedBy,
                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.drugTest.performedBy) = $event)),
                                  label: "Test Performed By"
                                }, null, 8, ["disabled", "modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_spacer),
                        (_ctx.canAddSubstance)
                          ? (_openBlock(), _createBlock(_component_v_card, {
                              key: 0,
                              class: "mb-3"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card_text, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_form, { ref: "drugForm" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_row, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_col, {
                                              cols: "12",
                                              sm: "6",
                                              md: "4",
                                              class: "py-0"
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_autocomplete, {
                                                  disabled: !_ctx.canEditRolePermission,
                                                  items: _ctx.drugTypes,
                                                  "item-value": "id",
                                                  "item-title": "description",
                                                  label: "Drug/Substance Type",
                                                  modelValue: _ctx.drugTestDrug.drugTypeId,
                                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.drugTestDrug.drugTypeId) = $event)),
                                                  required: "",
                                                  rules: _ctx.requiredRules
                                                }, null, 8, ["disabled", "items", "modelValue", "rules"])
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_v_col, {
                                              cols: "12",
                                              sm: "6",
                                              md: "4",
                                              class: "py-0"
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_autocomplete, {
                                                  disabled: !_ctx.canEditRolePermission,
                                                  items: _ctx.substances,
                                                  "item-value": "id",
                                                  "item-title": "description",
                                                  label: "Drug/Substance",
                                                  modelValue: _ctx.drugTestDrug.substanceId,
                                                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.drugTestDrug.substanceId) = $event)),
                                                  required: "",
                                                  rules: _ctx.requiredRules
                                                }, null, 8, ["disabled", "items", "modelValue", "rules"])
                                              ]),
                                              _: 1
                                            }),
                                            (_ctx.otherSubstanceList.includes(_ctx.drugTestDrug.substanceId))
                                              ? (_openBlock(), _createBlock(_component_v_col, {
                                                  key: 0,
                                                  cols: "12",
                                                  sm: "6",
                                                  md: "4",
                                                  class: "py-0"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_text_field, {
                                                      disabled: !_ctx.canEditRolePermission,
                                                      modelValue: _ctx.drugTestDrug.substanceOther,
                                                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.drugTestDrug.substanceOther) = $event)),
                                                      label: "Substance Other"
                                                    }, null, 8, ["disabled", "modelValue"])
                                                  ]),
                                                  _: 1
                                                }))
                                              : _createCommentVNode("", true),
                                            _createVNode(_component_v_col, {
                                              cols: "12",
                                              sm: "6",
                                              md: "4",
                                              class: "py-0"
                                            }, {
                                              default: _withCtx(() => [
                                                _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                                                  class: "my-3",
                                                  color: "secondary",
                                                  onClick: _ctx.addDrug,
                                                  disabled: _ctx.isSaving
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode("Add Drug/Substance")
                                                  ]),
                                                  _: 1
                                                }, 8, ["onClick", "disabled"])), [
                                                  [_directive_ripple]
                                                ])
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }, 512)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(_component_v_spacer),
                        (_ctx.drugTestDrugList.length > 0)
                          ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_data_table, {
                                      headers: _ctx.drugTableHeaders,
                                      items: _ctx.drugTestDrugList,
                                      density: "compact",
                                      class: "elevation-1"
                                    }, {
                                      "item.drugTypeId": _withCtx(({ item }) => [
                                        _createTextVNode(_toDisplayString(_ctx.getDrugTypeDescription(item.raw.drugTypeId)), 1)
                                      ]),
                                      "item.substanceId": _withCtx(({ item }) => [
                                        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.getSubstanceDescription(item.raw.substanceId, item.raw.substanceOther)), 1)
                                      ]),
                                      "item.actions": _withCtx(({ item }) => [
                                        _createVNode(_component_v_btn, {
                                          onClick: ($event: any) => (_ctx.deleteDrug(item.raw.drugTestDrugId)),
                                          class: "ma-1",
                                          density: "comfortable",
                                          size: "small",
                                          icon: "mdi-trash-can-outline",
                                          color: "error"
                                        }, null, 8, ["onClick"])
                                      ]),
                                      _: 1
                                    }, 8, ["headers", "items"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_textarea, {
                                  disabled: !_ctx.canEditRolePermission,
                                  modelValue: _ctx.drugTest.notes,
                                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.drugTest.notes) = $event)),
                                  label: "Notes"
                                }, null, 8, ["disabled", "modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ValidationAlert, { validationResults: _ctx.validationMessages }, null, 8, ["validationResults"])
                      ]),
                      _: 1
                    }, 512),
                    (_ctx.drugTest.workflowInstanceId && _ctx.canEditRolePermission)
                      ? (_openBlock(), _createBlock(_component_WorkflowSubmission, {
                          key: 0,
                          suppliedWorkflowInstanceId: _ctx.drugTest.workflowInstanceId,
                          "show-partial-save-options": true,
                          onPartialSave: _cache[12] || (_cache[12] = ($event: any) => (_ctx.saveTest(false))),
                          onSubmitStep: _ctx.onWorkflowSubmit,
                          defaultNextWorkflowStatusId: _ctx.workflowStatusEnum.DrugTestComplete,
                          isParentLoading: _ctx.isSaving,
                          title: 'Workflow Status',
                          variant: 'flat',
                          showSubmitAndDoMore: true,
                          submitAndDoMoreText: 'Submit then Copy/Create New',
                          onSubmitDoMore: _ctx.onWorkflowSubmitCreateNew
                        }, null, 8, ["suppliedWorkflowInstanceId", "onSubmitStep", "defaultNextWorkflowStatusId", "isParentLoading", "onSubmitDoMore"]))
                      : (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { align: "center" }, {
                              default: _withCtx(() => [
                                (_ctx.canEditRolePermission)
                                  ? _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                                      key: 0,
                                      class: "mx-2 mb-4",
                                      color: "success",
                                      onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.saveTest(false))),
                                      disabled: _ctx.isSaving
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.saveButtonText), 1)
                                      ]),
                                      _: 1
                                    }, 8, ["disabled"])), [
                                      [_directive_ripple]
                                    ])
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_ctx.drugTest.workflowInstanceId)
              ? (_openBlock(), _createBlock(_component_v_expansion_panel, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_expansion_panel_title, { class: "text-h5" }, {
                      default: _withCtx(() => [
                        _createTextVNode("Workflow History")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_expansion_panel_text, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_WorkflowHistory, {
                          workflowInstanceId: _ctx.drugTest.workflowInstanceId
                        }, null, 8, ["workflowInstanceId"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_snackbar, {
      color: "success",
      modelValue: _ctx.showSuccess,
      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.showSuccess) = $event)),
      timeout: "2000"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.successMessageText), 1)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}