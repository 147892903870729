import { IParticipantCriminalHistory, ICriminalHistoryPayload } from '@/Models/IParticipantCriminalHistory';
import { ITypedValidationResult } from '@/Models/IValidationResult';
import { BaseApiService } from './base-api-service';

class criminalHistoryService extends BaseApiService{
    
    public constructor() {
        super('criminalHistory/');
    }

    public async getByCaseId(caseId: number): Promise<IParticipantCriminalHistory[]> {
        return this.httpClient.get<IParticipantCriminalHistory[]>(`GetByCaseId/${caseId}`)
            .then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
    public async save(criminalHistory: IParticipantCriminalHistory): Promise<ITypedValidationResult<IParticipantCriminalHistory>> {
        return this.httpClient.post<ITypedValidationResult<IParticipantCriminalHistory>>("save", criminalHistory)
            .then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public async saveList(payload: ICriminalHistoryPayload): Promise<ITypedValidationResult<IParticipantCriminalHistory[]>> {
        return this.httpClient.post<ITypedValidationResult<IParticipantCriminalHistory[]>>("saveList", payload)
            .then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
	public async delete(id: number): Promise<void> {
        return this.httpClient.delete<void>(`delete/${id}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
}

export const CriminalHistoryService: criminalHistoryService = new criminalHistoryService();