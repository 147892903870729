import type { IInternalUser } from '@/Models/IInternalUser';
import { defineStore } from 'pinia';
import { InternalUserService } from '../internal-user-service';


export const useUserStore = defineStore('internalUser', {
    state: () => {
        return {
            cstInternalUser: null as IInternalUser | null,
        }
    },
    //https://prazdevs.github.io/pinia-plugin-persistedstate/guide/config.html
    //Uncomment this if we decide we want user state to be kept across different root app reloads from routing actions, not just within specific components and their children.
    /*persist: {
        storage: sessionStorage,
    },*/
    actions: {
        async getInternalUser() {
            if (this.cstInternalUser === null) {
                this.cstInternalUser = await InternalUserService.getCurrentCstUser();
            }
        }
    }
})