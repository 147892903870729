
import { defineComponent, PropType, Ref, ref } from 'vue';

import { ValidationHelper } from '@/Services/Helper/validation-helper';
import { IPhone } from '@/Models/IPhone';

export default defineComponent({
	name: "add-phone-dialog",
	emits: ["close", "save"],
	props: {
		source: Object as PropType<IPhone>,
		headerText: String,
        contactTypes: Array,
        displayContactType: Boolean
	},
	setup(): { form: Ref } {
		const form = ref(null);
		return { form };
	},
	created() {
	},
	data () {
		return {
			rules: ValidationHelper.phoneRules,
			buttonText: "Save Phone Number",
			isSaving: false,
            phone: null as IPhone | null,
		}
	},
	methods: {
		closeDialog(): void {
			this.$emit("close");
		},
		async save() {
			const { valid } = await this.form.validate();
			if (valid) {
				this.isSaving = true;
				this.buttonText = "Saving..."
				this.$emit("save", this.phone);
			}
		},
	},
	watch: {
		source(newPhone: IPhone) {
			this.phone = newPhone;
			this.isSaving = false;
			this.buttonText = "Save Phone Number";
		},
	},
});
