
import { defineComponent } from "vue";

import ConfirmDialog from "@/ChildrenComponents/ConfirmDialog.vue";
import LoadingSpinner from "@/ChildrenComponents/LoadingSpinner.vue";
import ValidationAlert from "@/ChildrenComponents/ValidationAlert.vue";
import PageHeader from "@/ChildrenComponents/Participant/Common/PageHeader.vue";
import ParticipantHeader from "@/ChildrenComponents/Participant/Common/ParticipantHeader.vue";
import SingleErrorMessage from "@/ChildrenComponents/Participant/Common/SingleErrorMessage.vue";

import HeaderData from "@/Models/HeaderData";
import { ComponentType } from "@/Enums/ComponentType";
import { IInternalUser } from "@/Models/IInternalUser";
import { IDataTableHeader } from "@/Models/IDataTableHeader";
import { IDrugTest } from "@/Models/IDrugTest";
import { ICase } from "@/Models/ICase";

import { DrugTestService } from "@/Services/drug-test-service";
import { DateHelper } from "@/Services/Helper/date-helper";
import { loadParticipantDataRequireCase } from "@/Services/Helper/loader-helper";

import {
	doesUserRoleHaveComponentEditPermissions,
} from "@/Services/Helper/component-permissions-helper";
import { useUserStore } from "@/Services/Store/user-store";

export default defineComponent({
	name: "drug-test-dashboard",
	components: {
		LoadingSpinner,
		ParticipantHeader,
		ConfirmDialog,
		ValidationAlert,
		PageHeader,
		SingleErrorMessage,
	},
	setup(): { currentUser: IInternalUser | null } {
		const userStoreInstance = useUserStore();
		return { currentUser: userStoreInstance.$state.cstInternalUser };
	},
	created() {
		Promise.all([
			loadParticipantDataRequireCase(this.pageType, this.id),
		])
			.then(([data]) => {
				this.caseId = data.case?.caseId ?? null;
				this.caseData = data.case;
				this.headerData = new HeaderData(
					data.participant,
					data.case?.program ?? null
				);
			})
			.then(() => {
				if (this.caseId != null) {
					DrugTestService.getByCaseId(this.caseId)
						.then((result) => (this.items = result))
						.then(() => {
							this.isLoading = false;
						});
				}
			})
			.catch((error) => (this.errorMessage = error));
	},
	data() {
		return {
			isLoading: true,
			errorMessage: "",
			validationMessages: [] as string[],
			pageType: this.$route.params.type?.toString() ?? "",
			headerData: null as HeaderData | null,
			id:
				this.$route.params.id == undefined
					? null
					: parseInt(this.$route.params.id.toString()),
			caseId: null as number | null,

			items: [] as IDrugTest[],
			caseData: {} as ICase | null,
			drugTestHeaders: [
				{ title: "Status", key: "currentStatusText", sortable: true, align: "start" },
				{ title: "Type", key: "typeList", sortable: true, align: "start" },
				{ title: "Date", key: "datePerformed", sortable: true, align: "start" },
				{ title: "Result", key: "resultText", sortable: true, align: "start" },
				{ title: "Edit", key: "actions", sortable: false },
				{ title: "Phase", key: "phase", sortable: false }
			] as IDataTableHeader[],

			showDeleteWarning: false,
			deleteWarningText:
				"Are you sure you want to delete this Drug Test? This cannot be undone!",
			idToDelete: null as number | null,
			search: ''
		};
	},
	methods: {
		formatDate(date: Date): string {
			return DateHelper.formatDateUtc(date, "MM/dd/yyyy");
		},
		editTest(id: number) {
			this.$router.push(`/drug-test/${id}`);
		},
		navigateToPhase(phaseId: number) {
			this.$router.push(`/phase/${phaseId}`);
		},
		deleteTest(id: number) {
			this.idToDelete = id;
			this.showDeleteWarning = true;
		},
		cancelDelete() {
			this.showDeleteWarning = false;
			this.idToDelete = null;
		},
		confirmDelete() {
			this.showDeleteWarning = false;
			if (this.idToDelete != null) {
				DrugTestService.delete(this.idToDelete).then(
					() =>
						(this.items = this.items.filter(
							(x) => x.drugTestId != this.idToDelete
						))
				);
			}
		},
	},
	computed: {
		componentType(): ComponentType {
			return ComponentType.DrugTestDashboard;
		},
		canEditRolePermission(): boolean {
			return doesUserRoleHaveComponentEditPermissions(
				this.currentUser?.attachedRoles ?? [],
				this.componentType
			);
		},
		daysSober(): number {
			const sobrietyStartDate: Date = this.items.filter((item: IDrugTest) => {
				return item.resultText === 'Positive';
			})
			.reduce((acc: Date, test: IDrugTest) => {
				if (test && test.datePerformed === null) {
					return acc;
				}

				const testDate = new Date(test.datePerformed!);
				if (testDate < acc) {
					return acc;
				} else {
					return testDate;
				}
			}, this.caseData?.createDate || (new Date()));
			
			const now = (new Date()).getTime();
			return Math.round((now - (new Date(sobrietyStartDate)).getTime())  / (1000 * 3600 * 24))
		},
	},
});
