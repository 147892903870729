import { ILedgerLine } from '@/Models/ILedgerLine';
import { ITypedValidationResult } from '@/Models/IValidationResult';
import { BaseApiService } from './base-api-service';

class caseLedgerService extends BaseApiService{
    
    public constructor() {
		super('CaseLedger/');
    }

	public async getByCaseId(caseId: number): Promise<ILedgerLine[]> {
		return this.httpClient.get<ILedgerLine[]>(`GetByCaseId/${caseId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
	public async save(line: ILedgerLine): Promise<ITypedValidationResult<ILedgerLine>> {
		return this.httpClient.post<ITypedValidationResult<ILedgerLine>>("save", line).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
	public async delete(lineId: number): Promise<void> {
		return this.httpClient.delete<void>(`delete/${lineId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
}

export const CaseLedgerService: caseLedgerService = new caseLedgerService();