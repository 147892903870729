export default abstract class FormatHelper {
	public static formatPhone(phone: string): string {
		phone = phone.replace(/\D+/g, "");
		if (phone == null || phone == "") {
			return "";
		}
		if (phone.length == 7) {
			return phone.substring(0, 3) + "-" + phone.substring(3);
		}
		if (phone.length == 10) {
			return "(" + phone.substring(0, 3) + ") " + phone.substring(3, 6) + "-" + phone.substring(6);
		}
		return phone;
	}

    public static filterOnlyNumbers(evt: KeyboardEvent) {
        evt = (evt || window.event);
        const expect = (evt.target as HTMLInputElement)?.value?.toString() + evt.key.toString();
        if (!/^[0-9]*$/.test(expect)) {
            evt.preventDefault();
        } else {
            return true;
        }
    }

    public static formatCurrency(amount: number | null): string {
        if (amount === null || amount === undefined || Number.isNaN(amount)) return "";
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(amount);
    }
}