
import type { IAccessRequest } from '@/Models/IAccessRequest';
import type { IValidationResult } from '@/Models/IValidationResult';
import { BaseApiService } from './base-api-service';

class accessRequestService extends BaseApiService{
    
    public constructor() {
        super('accessrequest/');
    }

    public async getAccessRequestById(accessRequestId: number): Promise<IAccessRequest> {
        return this.httpClient.get<IAccessRequest>(`get-access-request-by-id/${accessRequestId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public async getAccessRequestByAuth0GUID(): Promise<IAccessRequest> {
        return this.httpClient.get<IAccessRequest>(`get-access-request-by-auth`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public async getAllPendingAccessRequests(): Promise<Array<IAccessRequest>> {
        return this.httpClient.get<IAccessRequest[]>(`get-all-pending`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
    public async createNewAccessRequest(accessrequest: IAccessRequest): Promise<IValidationResult> {
        return this.httpClient.post<IValidationResult>(`create-new-access-request`, accessrequest).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public async updateAccessRequest(accessrequest: IAccessRequest): Promise<IValidationResult> {
        return this.httpClient.put<IValidationResult>(`update-access-request`, accessrequest).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public async approveAccessRequest(accessrequest: IAccessRequest): Promise<IValidationResult> {
        return this.httpClient.put<IValidationResult>(`approve-access-request`, accessrequest).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public async rejectAccessRequest(accessrequest: IAccessRequest): Promise<IValidationResult> {
        return this.httpClient.put<IValidationResult>(`reject-access-request`, accessrequest).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
}

export const AccessRequestService : accessRequestService = new accessRequestService();