import { ITypedValidationResult } from '@/Models/IValidationResult';
import { BaseApiService } from './base-api-service';
import { IDrugTestDrug } from '@/Models/IDrugTestDrug';

class drugTestDrugService extends BaseApiService {
    public constructor() {
		super('DrugTestDrug/');
    }
    
	public async getByTestId(phaseId: number, testId: number): Promise<IDrugTestDrug[]> {
		return this.httpClient.get<IDrugTestDrug[]>(`GetByTestId/${phaseId}/${testId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

	public async delete(id: number, phaseId: number): Promise<ITypedValidationResult<IDrugTestDrug>> {
		return this.httpClient.delete<ITypedValidationResult<IDrugTestDrug>>(`Delete/${id}/${phaseId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}

	public async save(dto: IDrugTestDrug, phaseId: number): Promise<ITypedValidationResult<IDrugTestDrug>> {
		return this.httpClient.post<ITypedValidationResult<IDrugTestDrug>>(`Save/${phaseId}`, dto).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}
}

export const DrugTestDrugService: drugTestDrugService = new drugTestDrugService();