
import { defineComponent } from 'vue';
import  IncorrectPermissions from '@/ChildrenComponents/IncorrectPermissions.vue'
import AccessRequestDrilldownDialog from '@/ChildrenComponents/AccessRequestDrilldownDialog.vue';
import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';

import type { IAccessRequest } from '@/Models/IAccessRequest';
import type { IInternalUserRole } from '@/Models/IInternalUserRole';
import type { IInternalUser } from '@/Models/IInternalUser';
import type { IDataTableHeader} from "@/Models/IDataTableHeader";

import { AccessRequestService } from '@/Services/access-request-service';

import { useUserStore } from '@/Services/Store/user-store';

export default defineComponent({
  name: 'access-request-management',
  components: { IncorrectPermissions, AccessRequestDrilldownDialog, ValidationAlert },

  setup(): {currentUser: IInternalUser | null } {
    const userStoreInstance = useUserStore();
    const currentUser = userStoreInstance.$state.cstInternalUser;
    return {
        currentUser        
    };
  },
  beforeCreate() {
    
  },
  created() {
    
    if (!this.currentUser || !this.currentUser.attachedRoles.some((e: IInternalUserRole) => e.roleId === 1)) {
        this.isPermissionDenied = true;
        return;
    }
    else {
        Promise.all([this.getAllPendingAccessRequests()]).then(() => {
            this.isLoading = false;
        });   
    }
  },
  data () {
    return {
        accessRequestList: [] as IAccessRequest[],
        accessRequestHeaders: [{ title: "First Name", key: "firstName", sortable: true, align: "start" },
                               {title: "Last Name", key: "lastName", sortable: true, align: "start"},
                               {title: "Username", key: "userName", sortable: true, align: "start"},
                               {title:" Email", key: "email", sortable: true, align: "start"},
                               {title: "Phone", key: "phone", sortable: true, align: "start"},
                               {title: "Actions", key: "actions", sortable: false}]  as IDataTableHeader[],

        validationResults: [] as string[],
        
        focusedAccessRequest: {} as IAccessRequest,

        isPermissionDenied: false as boolean,
        isAccessRequestDialogActive: false as boolean,
        isLoading: true as boolean,

        tabs: 0 as number,
        searchRequests: "" as string,
    }
  },
  watch: {
    
  },
  methods: {
    getAllPendingAccessRequests(): Promise<void> {
        return new Promise((resolve) => {
            AccessRequestService.getAllPendingAccessRequests().then((response) => {
                this.accessRequestList = response;
                resolve();
            });
        });
    },
    drilldownAccessRequest(request: IAccessRequest): void {
        this.isAccessRequestDialogActive = true;
        this.focusedAccessRequest = request;      
    },
    
    editAccessRequest(editedRequest: IAccessRequest, approved: boolean): void {
        if (approved) {
            AccessRequestService.approveAccessRequest(editedRequest).then((response) => {
                if (response.messages.length > 0) {
                    this.validationResults = response.messages;
                    return;
                }
            });
        }
        else {
            AccessRequestService.rejectAccessRequest(editedRequest).then((response) => {
                if (response.messages.length > 0) {
                    this.validationResults = response.messages;
                    return;
                }
            });
        }
        this.accessRequestList.splice(this.accessRequestList.findIndex(x => x.accessRequestId === this.focusedAccessRequest.accessRequestId), 1);
    }
  }
});
