
import { BaseApiService } from './base-api-service';
import { IValidationResult } from '@/Models/IValidationResult';
import { IParticipant } from '@/Models/IParticipant';
import ParticipantSearch from '@/Models/ParticipantSearch';

class participantService extends BaseApiService{

    public constructor() {
        super('participant/');
    }

    public getParticipant(id: number): Promise<IParticipant> {
        return this.httpClient.get<IParticipant>(id.toString()).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

	public search(searchDto: ParticipantSearch): Promise<Array<IParticipant>> {
		return this.httpClient.post<IParticipant[]>("Search", searchDto).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public searchActives(searchDto: ParticipantSearch): Promise<Array<IParticipant>> {
        return this.httpClient.post<IParticipant[]>("SearchActives", searchDto).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public createBasicParticipant(participantInformation: IParticipant): Promise<IValidationResult> {
		return this.httpClient.post<IValidationResult>("CreateParticipant", participantInformation).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public saveParticipant(participant: IParticipant): Promise<IValidationResult> {
        return this.httpClient.post<IValidationResult>("Save", participant).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
}

export const ParticipantService : participantService = new participantService();