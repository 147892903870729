import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ApexCharts = _resolveComponent("ApexCharts")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, {
        cols: "12",
        md: "12"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ApexCharts, {
            series: _ctx.data,
            options: _ctx.chartOptions
          }, null, 8, ["series", "options"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}