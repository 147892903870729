import { ICourtAttendance } from '@/Models/ICourtAttendance';
import { ITypedValidationResult } from '@/Models/IValidationResult';
import { BaseApiService } from './base-api-service';

class courtAttendanceService extends BaseApiService {
    public constructor() {
		super('courtAttendance/');
    }

	public async getByPhaseId(phaseId: number): Promise<ICourtAttendance[]> {
		return this.httpClient.get<ICourtAttendance[]>(`GetByPhaseId/${phaseId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
	public async save(dto: ICourtAttendance): Promise<ITypedValidationResult<ICourtAttendance>> {
		return this.httpClient.post<ITypedValidationResult<ICourtAttendance>>("save", dto).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}

	public async delete(id: number): Promise<void> {
		return this.httpClient.delete<void>(`delete/${id}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
	}
}

export const CourtAttendanceService: courtAttendanceService = new courtAttendanceService();