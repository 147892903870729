import { IFilledTemplate } from "@/Models/Templating/IFilledTemplate";
import { ITemplate } from "@/Models/Templating/ITemplate";
import { IVariableInfill } from "@/Models/Templating/IVariableInfill";
import { RenderableTemplate } from "@/Models/Templating/TemplatingEngine";
import { DocumentService } from "../document-service";
import { IDocumentUpload } from "@/Models/IDocument";

// TemplateEngine provides a lot of the front-end based template management oeprations.
// it is distinct from the template service in the fact that the service is for managing
// template lifetimes between the FE and BE while the template engine is for synchronously
// updating and managing specific templates and instances of templates (aka documents)
class templateEngine {

    public emptyInfill(): IVariableInfill {
        return {
            today: (new Date(Date.now())).toLocaleDateString(),
            phaseNumber: "",
            caseNumber: "",
            participantName: "",
            participantFirstName: "",
            participantLastName: "",
            dateOfBirth:  "",
            censoredSSN: "",
            courtName: "",
            programName: "",
            participantStatus: "",
        }
    }

    // fillVarWithObjects will take all of the objects and map them into a single variableInfill
    // object. Precedence is given based on the order objects appear in the array with earier
    // items taking precedence. fillVarWithObject (singular) is used for infill calculations
    public fillVarWithObjects(objects: any[]): IVariableInfill {
        return objects.reduce((acc, obj) => {
            const newObjInfill = this.fillVarWithObject(obj);
            return this.joinVariableInfills(acc, newObjInfill);
        }, this.emptyInfill());
    }

    // fillVarWithObject will take an object and attempt to extract appropriate values for defined template
    // variables from the parameter object. It chooses sensible defaults ("") when the values don't exist
    public fillVarWithObject(object: any): IVariableInfill {
        const aggDateOfBirth = (object.dateOfBirth ?? object.DoB ?? object.dob) || ""
        return {
            // TODO: ensure that whatever versions of each of these variables we might use are
            // properly enumerated and deserialized from the object
            today: (new Date(Date.now())).toLocaleDateString(),
            phaseNumber: object.phaseNumber?.toString() ?? "",
            caseNumber: object.caseId?.toString() ?? "",
            participantName: `${object.participantFirstName ?? object.firstName ?? ""}${!!(object.participantFirstName ?? object.firstName) && !!(object.participantLastName ?? object.lastName) ? " " : ""}${object.participantLastName ?? object.lastName ?? ""}`,
            participantFirstName: object.participantFirstName ?? object.firstName ?? "",
            participantLastName: object.participantLastName ?? object.lastName ?? "",
            dateOfBirth: aggDateOfBirth !== "" ? (new Date(aggDateOfBirth)).toLocaleDateString() : "",
            censoredSSN: object.ssn ?? object.ssnLastFour ?? "",
            courtName: object.court?.description ?? "",
            programName: object.program?.description ?? "",
            participantStatus: object.statusText ?? "",
        }
    }

    public joinVariableInfills(in1: IVariableInfill, in2: IVariableInfill): IVariableInfill {
        const jointInfill = {} as IVariableInfill

        for (const [key, value] of Object.entries(in1)) {
            if (!value || value == "") {
                jointInfill[key as keyof IVariableInfill] = in2[key as keyof IVariableInfill];
            } else {
                jointInfill[key as keyof IVariableInfill] = in1[key as keyof IVariableInfill];
            }
        }
        return jointInfill;
    }

    public expandTemplate(templ: RenderableTemplate, infill: IVariableInfill): IFilledTemplate {
        const varRegex = /%(\w+)%/g;
        let match;
        const infillKeys = [];
        while ((match = varRegex.exec(templ.contents)) !== null) {
            // first element is the entire match
            // second element is just the capture group, which is only the text without the wrapper %%
            // end is the index of the last character
            // length is the length of the key including the wrapper (i.e. the number of characters that need to
            //      be removed)
            infillKeys.push({
                key: match[1],
                index: match.index,
                end: match[0].length + match.index,
                length: match[0].length,
            });
        }

        // if the keys aren't processes R->L, then the indices will be stale after we replace earlier strings
        infillKeys.reverse().forEach((infillPosition) => {
            // slice out the variable from the template
            templ.contents = templ.contents.slice(0, infillPosition.index)
                              + templ.contents.slice(infillPosition.end);
            // slice the value from the infill object into the rendered template
            templ.contents = templ.contents.slice(0, infillPosition.index)
                              + (infill[infillPosition.key as keyof IVariableInfill] || "")
                              + templ.contents.slice(infillPosition.index);
        });

        return {
            contents: templ.contents,
            style: templ.style
        } as IFilledTemplate;
    }

    public expandHeaderBodyPair(headerTempl: RenderableTemplate, bodyTempl: RenderableTemplate, infill: IVariableInfill): IFilledTemplate {
        // if either of the objects is null, we would want to be able to still generate the document successfully
        // so we'll ensure that reasonable defaults are filled in
        if (!headerTempl) {
            headerTempl = {
                contents: "",
                style: "",
            } as ITemplate
        }
        if (!bodyTempl) {
            bodyTempl = {
                contents: "",
                style: "",
            } as ITemplate
        }
        
        const expandedHeader = this.expandTemplate(headerTempl, infill);
        const expandedBody = this.expandTemplate(bodyTempl, infill);

        return {
            contents: expandedHeader.contents + expandedBody.contents,
            style: expandedHeader.style + expandedHeader.style,
        } as IFilledTemplate
    }

    // this response handler will be responsible for uploading a generated file given a data blob and a filename
    // it is best used in a call like so:
    // TemplateService.generatePdf(...).then((res) => TemplateEngine.generatedPdfResponseHandler(res, fname))
    // This allows for the server to handle generation and any necessary server side rendering before pushing the
    // generated file out to the client.
    public generatePdfResponseHandler(parentId: number, parentType: number, data: Blob, fname?: string) {
        const up = {
            parentId: parentId,
            documentTypeId: 98, // generated document type
            documentParentTypeId: parentType,
            file: new File([data], fname || ''),
            filename: fname || `generated_${new Date().getTime()}.pdf`
        } as IDocumentUpload;
        DocumentService.upload(up);
    }

}

export const TemplateEngine : templateEngine = new templateEngine();