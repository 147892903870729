

import { defineComponent, PropType, Ref, ref } from 'vue';

import { 
    IOutcomeEventEntry, 
    IOutcomeJuvenileStatusType,
    IOutcomeRecidivismType, 
    IOutcomeTreatmentType 
} from '@/Models/IOutcomes';
import { IOutcomeEventType } from '@/Models/Lookup/Outcomes/IOutcomeEventType';
import { OutcomeEventType } from '@/Enums/Outcomes';
import { MasterLookupWrapper } from '@/Models/Lookup/MasterLookupWrapper';
import { IBaseLookup } from '@/Models/Lookup/BaseLookup';
import { LookupKeys } from '@/Enums/LookupKeys';

import { DateHelper } from '@/Services/Helper/date-helper';
import { ValidationHelper } from '@/Services/Helper/validation-helper';
import { LookupService } from '@/Services/lookup-service';

import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';

export default defineComponent({
    name: 'outcome-event-dialog',
    components: { ValidationAlert },
    emits: ['close-outcome-event-dialog', 'outcome-event-submit'],
    props: {
        showEventDialog: {
            type: Boolean,
            required: true,
        },
        dtoPrefill: {
            type: Object as PropType<IOutcomeEventEntry>,
            required: true,
        }
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        showEventDialog: function(newVal) { 
            this.visible = newVal;
        }, // second arg is oldValue, but we don't need it to watch for updates here
        dtoPrefill: function(newVal) {
            this.isLoading = true;
            this.dto = newVal;
            this.dto.startDate = DateHelper.formatDateUtc(this.dto.startDate, "yyyy-MM-dd");
            this.dto.endDate = DateHelper.formatDateUtc(this.dto.endDate, "yyyy-MM-dd");
            // handles updating the visible dropdown based on the selected eventtype
            this.updateEventType(this.dto.eventType);
            // DB constraints require one of these three values to be non-null, but default to 1 just in case
            this.updateSubEventType(this.dto.treatmentTypeId || this.dto.recidivismTypeId || this.dto.fosterCareType || 1);
            
            this.isLoading = false;
        }
    },
    setup(): { newEventForm: Ref } {
        const newEventForm = ref(null);
        return {
            newEventForm
        }
    },
    created() {
        const lookupKeys = [
            LookupKeys.OutcomeEventType,
            LookupKeys.OutcomeTreatmentType,
            LookupKeys.OutcomeRecidivismType,
            LookupKeys.OutcomeJuvenileStatusType,
        ];
        
        Promise.all([
            LookupService.getLookupsByKey(lookupKeys), 
        ])
        .then(([lookups]: [MasterLookupWrapper]) => {
            // lookups
            this.eventTypeOptions = lookups.lookupLists[LookupKeys.OutcomeEventType] as IOutcomeEventType[] ?? [];
            this.treatmentTypeOptions = lookups.lookupLists[LookupKeys.OutcomeTreatmentType] as IOutcomeTreatmentType[] ?? [];
            this.recidivismTypeOptions = lookups.lookupLists[LookupKeys.OutcomeRecidivismType] as IOutcomeRecidivismType[] ?? [];
            this.juvenileStatusTypeOptions = lookups.lookupLists[LookupKeys.OutcomeJuvenileStatusType] as IOutcomeJuvenileStatusType[] ?? [];
        }).then(() => {
            this.dto = this.dtoPrefill;
            // handles updating the visible dropdown based on the selected eventtype
            this.updateEventType(this.dto.eventType);
            // DB constraints require one of these three values to be non-null, but default to 1 just in case
            this.updateSubEventType(this.dto.treatmentTypeId || this.dto.recidivismTypeId || this.dto.fosterCareType || 1);
            this.isLoading = false;
        });
    },
    data() {
        return {
            isLoading: true,
            maySave: true,

            requiredFieldRules: ValidationHelper.requiredRules,

            eventTypeOptions: [] as IOutcomeEventType[],
            treatmentTypeOptions: [] as IOutcomeTreatmentType[],
            recidivismTypeOptions: [] as IOutcomeRecidivismType[],
            juvenileStatusTypeOptions: [] as IOutcomeJuvenileStatusType[],

            visibleOpts: [] as IBaseLookup[],
            selectedOpt: null as number | null,
            dto: {
                eventId: -1,
                eventType: 1,
                treatmentTypeId: undefined,
                recidivismTypeId: undefined,
                fosterCareType: undefined,
                startDate: "",
                endDate: "",
                note: "",
            } as IOutcomeEventEntry,
            validationResults: [] as string[],
            visible: this.showEventDialog,
        }
    },
    methods: {
        closeDialog(): void {
            this.$emit('close-outcome-event-dialog');
        },
        async submitNewOutcomeEvent(): Promise<void> {
            this.maySave = false;
            this.dto.eventTypeDescription = this.visibleOpts.find(
                (e) => {
                    return e.id == this.dto.eventType;
                }, this)?.description || "";
            this.$emit('outcome-event-submit', this.dto);
            this.maySave = true;
        },
        setDto(): void {
            this.dto.startDate = DateHelper.formatDateUtc(this.dto.startDate, "yyyy-MM-dd");
            this.dto.endDate = DateHelper.formatDateUtc(this.dto.endDate, "yyyy-MM-dd");
            this.dto = {
                eventId: -1,
                eventType: 1,
                treatmentTypeId: undefined,
                recidivismTypeId: undefined,
                fosterCareType: undefined,
                startDate: "",
                endDate: "",
                note: "",
            } as IOutcomeEventEntry;
        },
        updateEventType(eid: number) {
            this.dto.treatmentTypeId = undefined;
            this.dto.recidivismTypeId = undefined;
            this.dto.fosterCareType = undefined;
            this.selectedOpt = 1;
            switch (eid) {
                case OutcomeEventType.Treatment:
                    this.dto.treatmentTypeId = 1;
                    this.visibleOpts = this.treatmentTypeOptions;
                    break;
                case OutcomeEventType.Recidivism:
                    this.dto.recidivismTypeId = 1;
                    this.visibleOpts = this.recidivismTypeOptions;
                    break;
                case OutcomeEventType.FosterCare:
                    this.dto.fosterCareType = 1;
                    this.visibleOpts = this.juvenileStatusTypeOptions;
                    break;
                case OutcomeEventType.Other:
                    this.visibleOpts = [];
                    break;
                default:
                    this.closeDialog();
                    break;
            }
        },
        updateSubEventType(seid: number) {
            if (this.dto.treatmentTypeId) {
                this.dto.treatmentTypeId = seid;
            } else if (this.dto.recidivismTypeId) {
                this.dto.recidivismTypeId = seid;
            } else if (this.dto.fosterCareType) {
                this.dto.fosterCareType = seid;
            }
        },
        clearForm() {
            this.setDto();
        }
    },
    computed: {
        endDateRules() {
            return [
                (v : string) => {
                    if (this.dto.startDate == "" && this.dto.endDate == "") {
                        // case where both have been reset and we want to clear errors
                        return true;
                    }

                    if (this.dto) {
                        const startDate = new Date(this.dto.startDate);
                        const endDate = new Date(v);
                        return !startDate || endDate >= startDate || 'End Date cannot be before Start Date';
                    }

                    return true;
                }
            ];
        },
    },
});

