
	import { defineComponent } from 'vue';

	import ConfirmDialog from '@/ChildrenComponents/ConfirmDialog.vue';
	import LoadingSpinner from '@/ChildrenComponents/LoadingSpinner.vue';
	import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';
	import AddParticipantNoteDialog from '@/ChildrenComponents/Participant/AddParticipantNoteDialog.vue';
	import PageHeader from '@/ChildrenComponents/Participant/Common/PageHeader.vue';
	import ParticipantHeader from '@/ChildrenComponents/Participant/Common/ParticipantHeader.vue';
	import SingleErrorMessage from '@/ChildrenComponents/Participant/Common/SingleErrorMessage.vue';

	import { ComponentType } from '@/Enums/ComponentType'
	import { IInternalUser } from '@/Models/IInternalUser';
	import { IDataTableHeader } from '@/Models/IDataTableHeader';
	import { IParticipantNote } from '@/Models/IParticipantNote';
	import HeaderData from '@/Models/HeaderData';

	import { doesUserRoleHaveComponentEditPermissions } from '@/Services/Helper/component-permissions-helper'
	import { ParticipantNoteService } from '@/Services/participant-note-service';
	import { DateHelper } from '@/Services/Helper/date-helper';
	import { loadParticipantDataRequireParticipant } from '@/Services/Helper/loader-helper';

	import { useUserStore } from '@/Services/Store/user-store';
    import { CaseService } from '@/Services/case-service';
    import { ICase } from '@/Models/ICase';

	export default defineComponent({
		name: "participant-notes",
		components: { AddParticipantNoteDialog, LoadingSpinner, ParticipantHeader, ConfirmDialog, ValidationAlert, PageHeader, SingleErrorMessage },
		setup(): { currentUser: IInternalUser | null } {
			const userStoreInstance = useUserStore();
			return { currentUser: userStoreInstance.$state.cstInternalUser };
		},
		created() {
			loadParticipantDataRequireParticipant(this.pageType, this.id).then((data) => {
				this.participantId = data.participant?.participantId ?? null;
				this.headerData = new HeaderData(data.participant, data.case?.program ?? null);
				this.setBlankNote();
			})
				.then(() => {
					if (this.participantId != null) {
                        Promise.all([
							ParticipantNoteService.getByParticipantId(this.participantId),
                            CaseService.getCaseHistoryByParticipant(this.participantId)
						]).then(([notesResult, caseHistoryResult]) => {
							this.notes = notesResult;
                            this.caseHistory = caseHistoryResult;
						}).then(() => this.isLoading = false);
					}
				})
				.catch(error => this.errorMessage = error);
		},
		data() {
			return {
				isLoading: true,
				errorMessage: "",
				validationMessages: [] as string[],
				pageType: this.$route.params.type?.toString() ?? "",
				id: this.$route.params.id == undefined ? null : parseInt(this.$route.params.id.toString()),
				participantId: null as number | null,
				headerData: null as HeaderData | null,
                caseHistory: [] as ICase[],
				notes: [] as IParticipantNote[],
				selectedNote: {} as IParticipantNote,
				noteHeaders: [
					{ title: "User", key: "createdByName", sortable: true, align: "start" },
					{ title: "Create Date", key: "createDate", sortable: true, align: "start" },
					{ title: "Note", key: "note", sortable: true, align: "start" },
					{ title: "Actions", key: "actions", sortable: false },
				] as IDataTableHeader[],

				showDeleteWarning: false,
				deleteWarningText: "Are you sure you want to delete this note? This cannot be undone!",
				idToDelete: null as number | null,

				showNoteDialog: false,
				noteHeader: "",
			}
		},
		methods: {
			formatDate(date: Date): string {
				return DateHelper.formatDateLocal(date);
			},
			setBlankNote() {
				this.selectedNote = {
					participantNoteId: 0,
					participantId: this.participantId,
					note: "",
				} as IParticipantNote;
			},
			newNote() {
				this.noteHeader = "Add Note";
				this.showNoteDialog = true;
			},
			editNote(note: IParticipantNote) {
				this.selectedNote = note;
				this.noteHeader = "Edit Note";
				this.showNoteDialog = true;
			},
			cancelNoteEdit() {
				this.setBlankNote();
				this.showNoteDialog = false;
			},
			saveNote(text: string) {
				this.selectedNote.note = text;
				ParticipantNoteService.save(this.selectedNote).then(result => {
					this.validationMessages = result.messages;

					if (result.isValid && result.resultObject != null) {
						this.setBlankNote();
						this.showNoteDialog = false;
						if (this.participantId != null) {
							ParticipantNoteService.getByParticipantId(this.participantId).then(notes => this.notes = notes);
						}
					}
				})
				.catch(error => this.errorMessage = error);
			},
			deleteNote(noteId: number) {
				this.idToDelete = noteId;
				this.showDeleteWarning = true;
			},
			cancelDelete() {
				this.showDeleteWarning = false;
				this.idToDelete = null;
			},
			confirmDelete() {
				this.showDeleteWarning = false;
				if (this.idToDelete != null) {
					ParticipantNoteService.delete(this.idToDelete)
						.then(() => this.notes = this.notes.filter(x => x.participantNoteId != this.idToDelete));
				}
			},
		},
		computed: {
			componentType(): ComponentType {
				return ComponentType.ParticipantNotes
			},
			canEditRolePermission() : boolean {
				return doesUserRoleHaveComponentEditPermissions(this.currentUser?.attachedRoles ?? [], this.componentType)
                    && this.caseHistory.length > 0
			},
		},
	});
