
	import { defineComponent, Ref, ref } from 'vue';

	import { AlternateWorkflowType } from '@/Enums/AlternateWorkflowType';

	import { WorkflowService } from '@/Services/workflow-service';
	import { ValidationHelper } from '@/Services/Helper/validation-helper';
	import { DateHelper } from '@/Services/Helper/date-helper';

	import { IWorkflowStatus } from '@/Models/Workflow/IWorkflowStatus';
	import { IWorkflowSubmit } from '@/Models/IWorkflowSubmit';

	export default defineComponent({
		name: 'alternate-workflow-submission',
		emits: ["success", "error"],
		setup(): { workflowForm: Ref } {
			const workflowForm = ref(null);
			return {
				workflowForm
			};
		},
		props: {
			caseId: {
				type: Number,
				required: true
			},
			workflowInstanceId: {
				type: Number,
				required: true
			},
			parentId: {
				type: Number,
				required: true
			},
			type: {
				type: Number,
				required: true
			},
		},
		created() {
			this.loadData();
		},
		data() {
			return {
				newStatus: {} as IWorkflowSubmit,
				statusList: [] as IWorkflowStatus[],
				dropdownRules: ValidationHelper.requiredRules,
				noteRules: ValidationHelper.requiredRules,
				selectedNextStatusId: null as number | null,
				notes: "",
				isSaving: false,
				showCompleteDate: false,
			}
		},
		methods: {
			loadData() {
				WorkflowService.listNextWorkflowStatuses(this.workflowInstanceId).then((response) => this.statusList = response);
				this.newStatus = {
					workflowInstanceId: this.workflowInstanceId,
					note: "",
					completeDateForEdit: "",
					assignedUserId: null,
				} as IWorkflowSubmit;
			},
			async submit(): Promise<void> {
                this.isSaving = true;
				const { valid } = await this.workflowForm.validate();
				if (valid) {
					this.newStatus.completeDate = DateHelper.standardizeDate(this.newStatus.completeDateForEdit);
					WorkflowService.alternateSubmit(this.newStatus, this.type, this.caseId, this.parentId).then(response => {
						this.isSaving = false;
						this.$emit(response.isValid ? "success" : "error", response);
					});
				} else {
                    this.isSaving = false;
                }
			}
		},
		watch: {
			workflowInstanceId() {
				this.loadData();
			},
			'newStatus.newWorkflowStatusId'(statusId: number) {
				const selectedStatus = this.statusList.find(x => x.workflowStatusId == statusId);
				if (selectedStatus) {
					this.noteRules = selectedStatus.isNoteRequired ? ValidationHelper.requiredRules : [];
					this.showCompleteDate = selectedStatus.showCompleteDate;
					this.newStatus.completeDateForEdit = selectedStatus.showCompleteDate ? DateHelper.formatDateLocal(new Date(), "yyyy-MM-dd") : "";
				}
			},
		},
		computed: {
			buttonText(): string {
				return this.isSaving ? "Submitting..." : "Submit";
			},
			completeDateRules(): any {
				return this.showCompleteDate ? ValidationHelper.requiredRules : [];
			},
			completeDateText(): string {
				return AlternateWorkflowType[this.type] + " Completion Date";
			},
		},
	}); 
