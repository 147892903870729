
import type { ICourt } from '@/Models/ICourt';
import { BaseApiService } from './base-api-service';

class courtService extends BaseApiService{
    
    public constructor() {
        super('court/');
    }

    public async getAllCourtsByCountyId(countyId: number): Promise<Array<ICourt>> {
        return this.httpClient.get<ICourt[]>(`get-all-courts-by-county/${countyId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public async getAllCourtsByCountyIds(countyIds: number[]): Promise<Array<ICourt>> {

        return this.httpClient.get<ICourt[]>(`get-all-courts-by-counties/${countyIds.toString()}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
}

export const CourtService : courtService = new courtService();