import { ICaseClosure } from '@/Models/ICaseClosure';
import { ITypedValidationResult } from '@/Models/IValidationResult';
import { BaseApiService } from './base-api-service';

class caseClosureService extends BaseApiService{
    
    public constructor() {
		super('CaseClosure/');
    }

	public async getActiveByCaseId(caseId: number): Promise<ICaseClosure> {
		return this.httpClient.get<ICaseClosure>(`GetActiveByCaseId/${caseId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
	public async save(dto: ICaseClosure): Promise<ITypedValidationResult<ICaseClosure>> {
		return this.httpClient.post<ITypedValidationResult<ICaseClosure>>("save", dto).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }

    public async get(caseClosureId: number): Promise<ICaseClosure> {
        return this.httpClient.get<ICaseClosure>(`Get/${caseClosureId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
}

export const CaseClosureService: caseClosureService = new caseClosureService();