import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    "onClick:outside": _ctx.cancel,
    modelValue: _ctx.isOpen,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isOpen) = $event)),
    width: "auto"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { style: {"overflow":"hidden"} }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.titleText), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.dialogText), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, { class: "mb-3" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { align: "center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    class: "mx-2",
                    color: "error",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.confirm()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Confirm")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, {
                    class: "mx-2",
                    color: "secondary",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.cancel()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Cancel")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onClick:outside", "modelValue"]))
}