
    import { defineComponent, PropType } from 'vue';
    import VueApexCharts from 'vue3-apexcharts';

    import IChartRenderOptions from '@/Models/Charts/IChartRenderOptions';

    export default defineComponent({
        name: "radial-bar-chart",
        components: {
            ApexCharts: VueApexCharts
        },
        props: {
            labels: {
                type: Array as PropType<(string | number)[]>,
                required: true,
            },
            data: {
                type: Array as PropType<number[]>,
                required: true,
            },
            chartOptions: {
                type: Object as PropType<IChartRenderOptions>,
                required: true,
            },
            maxPeriods: {
                // ApexCharts won't display a radial bar chart with more than 24 items (on my screen size), so this component
                // supports automatically trimming from the end of the provided dataset. the number of
                // items to display is configurable with this prop. we default to 8 rather than 24 becuase
                // 24 is nearly unreadable even though it is technically possible, and doesn't render on columns
                // that aren't full width. in other words, if the chart can't be drawn in the available space while
                // preserving center space, it won't sale down below a certain size. i gave a quick glance at the
                // ApexCharts source to see if it was an easily identifiable or overrideable size, but didn't see
                // anything jump out immediately. thus, set this reasonably for the expected screen size and potentially
                // provide a way to modify it at a user level later
                type: Number,
                required: false,
                default: 8,
            },
        },
        computed: {
            lastIdx(): number {
                return this.data.length - 1;
            },
            startIdx(): number {
                let startIdx = this.data.length - this.maxPeriods;
                if (startIdx < 0) {
                    startIdx = 0;
                }
                return startIdx;
            },
            options() {
                return {
                    ...this.chartOptions,
                    labels: this.chartOptions.labels?.slice(this.startIdx, this.lastIdx + 1) 
                                ?? this.labels.slice(this.startIdx, this.lastIdx + 1),
                }
            }
        } 
    })
