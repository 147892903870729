import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ParticipantHeader = _resolveComponent("ParticipantHeader")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_SingleErrorMessage = _resolveComponent("SingleErrorMessage")!
  const _component_ValidationAlert = _resolveComponent("ValidationAlert")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_text = _resolveComponent("v-card-text")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.headerData && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_ParticipantHeader, {
          key: 0,
          data: _ctx.headerData
        }, null, 8, ["data"]))
      : _createCommentVNode("", true),
    _createVNode(_component_PageHeader, { headerText: "Drug Test Dashboard" }),
    (_ctx.isLoading && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_SingleErrorMessage, { errorMessage: _ctx.errorMessage }, null, 8, ["errorMessage"]),
    _createVNode(_component_ValidationAlert, { validationResults: _ctx.validationMessages }, null, 8, ["validationResults"]),
    (!_ctx.isLoading && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_v_card, { key: 2 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { class: "sectionHeader" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_chip, {
                  variant: "elevated",
                  size: "large"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, "Days of Sobriety: " + _toDisplayString(_ctx.daysSober), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!_ctx.isLoading && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_v_card, { key: 3 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ConfirmDialog, {
                      dialogIsOpen: _ctx.showDeleteWarning,
                      onConfirm: _cache[0] || (_cache[0] = ($event: any) => (_ctx.confirmDelete())),
                      onCancel: _ctx.cancelDelete,
                      dialogText: _ctx.deleteWarningText
                    }, null, 8, ["dialogIsOpen", "onCancel", "dialogText"]),
                    _createVNode(_component_v_text_field, {
                      modelValue: _ctx.search,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event)),
                      "append-icon": "mdi-magnify",
                      label: "Search",
                      "single-line": "",
                      "hide-details": ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_data_table, {
                  headers: _ctx.drugTestHeaders,
                  items: _ctx.items,
                  search: _ctx.search,
                  density: "compact",
                  class: "elevation-1"
                }, _createSlots({
                  [`item.typeList`]: _withCtx(({ item }) => [
                    _createVNode(_component_v_list, {
                      density: "compact",
                      items: item.raw.types,
                      "item-title": "description"
                    }, null, 8, ["items"])
                  ]),
                  [`item.datePerformed`]: _withCtx(({ item }) => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(item.raw.datePerformed)), 1)
                  ]),
                  [`item.phase`]: _withCtx(({ item }) => [
                    _createVNode(_component_v_btn, {
                      onClick: ($event: any) => (_ctx.navigateToPhase(item.raw.phaseId)),
                      class: "ma-1",
                      density: "comfortable",
                      size: "small",
                      icon: "mdi-navigation-variant",
                      color: "primary"
                    }, null, 8, ["onClick"])
                  ]),
                  _: 2
                }, [
                  (_ctx.canEditRolePermission)
                    ? {
                        name: `item.actions`,
                        fn: _withCtx(({ item }) => [
                          _createVNode(_component_v_btn, {
                            onClick: ($event: any) => (_ctx.editTest(item.raw.drugTestId)),
                            class: "ma-1",
                            density: "comfortable",
                            size: "small",
                            icon: "mdi-pencil-outline",
                            color: "info"
                          }, null, 8, ["onClick"]),
                          _createVNode(_component_v_btn, {
                            onClick: ($event: any) => (_ctx.deleteTest(item.raw.drugTestId)),
                            class: "ma-1",
                            density: "comfortable",
                            size: "small",
                            icon: "mdi-trash-can-outline",
                            color: "error"
                          }, null, 8, ["onClick"])
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1032, ["headers", "items", "search"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}