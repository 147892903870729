
    // The modal editor encapsulates the editor component but wraps it in a modal component.
    // Emits the edited template to be consumed by whichever component is embedding the modal
    import { 
        defineComponent, 
        PropType 
    } from 'vue';
    
    import { ITemplate } from '@/Models/Templating/ITemplate';

    import SingleErrorMessage from '../Participant/Common/SingleErrorMessage.vue';
    import ValidationAlert from '../ValidationAlert.vue';
    import TemplateEditor from './Common/TemplateEditor.vue';

    export default defineComponent({
        name: 'modal-template-editor',
        components: {
            ValidationAlert,
            SingleErrorMessage,
            TemplateEditor,
        },
        emits: [
            "onTemplateSave",   // emitted when the template is saved
            "closeModalEditor", // emitted when the user indicates the modal should be closed
        ],
        props: {
            // the activeTemplate will be the template which is loaded into the editor
            activeTemplate: {
                type: Object as PropType<ITemplate>,
                required: true,
            },
            // the state of whether the modal is visible or not
            visible: {
                type: Boolean,
                required: false,
                default: false,
            },
            // editorOnly allows for enabling or disabling the variable menu. if editorOnly
            // is true, then it will not show the infill variable menu
            editorOnly: {
                type: Boolean,
                required: false,
                default: true,
            },
            confirmButtonText: {
                type: String,
                required: false,
                default: "Save",
            },
            // bind the button to '' (empty string) to disable
            cancelButtonText: {
                type: String,
                required: false,
                default: "Cancel",
            }
        },
        created() {
            this.currentTemplate = this.activeTemplate;
            this.isVisible = this.visible;
        },
        data() {
            return {
                // status
                validationResults: [] as string[],
                errorMessage: "" as string,

                // state
                isVisible: false as boolean,

                //dto
                currentTemplate: {} as ITemplate,
            
            }
        },
        methods: {
            closeDialog(): void {
                this.$emit("closeModalEditor");
                this.isVisible = false;
            },
            doSave() {
                this.$emit("onTemplateSave", this.currentTemplate);
                this.isVisible = false;
            },
        },
        watch: {
            activeTemplate() {
                this.currentTemplate = this.activeTemplate;
            },
            visible() {
                this.isVisible = this.visible;
            },
        }
    })

