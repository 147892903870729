
	// adapted from the HTMLEditor component of NESC and the VSS Help page editor
	import { defineComponent } from 'vue';
	import Editor from "@tinymce/tinymce-vue";

	export default defineComponent({
		name: "html-editor",
		components: { Editor },
		emits: ["onChange"],
		props: {
			source: { type: String, required: true },
			isPdf: { type: Boolean, required: false, default: false },
			plugins: { type: String, required: false },
			height: { type: Number, required: false },
			width: { type: Number, required: false },
			toolbar: { type: String, required: false },
			disabled: { type: Boolean, required: false, default: false },
		},
		created() {
			this.text = this.source;
		},
		data() {
			return {
				apiKey: "0clsw80lz5huk31vc4bju13sd7542dhy0qp5nw0f6tfh3hqc",
				text: "",
				label: "",
			}
		},
		methods: {
			onChange() {
				this.$emit("onChange", this.text);
			},
		},
		computed: {
			fonts(): string {
				// jsPDF is limited to these three:
				return this.isPdf ? "Courier=courier; Helvetica=helvetica; Times New Roman=times new roman,times"
					: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva";
			},
			options(): object {
				// see available options: https://www.tiny.cloud/docs/configure/editor-appearance/
				return {
					height: this.height ?? 500,
					width: this.width ?? "100%",
					menubar: 'file edit view insert format tools table help',
					toolbar: this.toolbar ?? [
						"undo redo | blocks | fontfamily fontsize | forecolor backcolor",
						"bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | lineheight | numlist bullist | image link table | removeformat",
					],
					plugins: this.plugins ?? "lists advlist link table image", // available plugins: https://www.tiny.cloud/docs/plugins/opensource/
					content_style: "body { font-family: Helvetica; font-size: 12pt } p, h1, h2, h3, h4, h5, h6 { margin: 0 }", //set to match jsPDF as closely as possible
					font_family_formats: this.fonts,
					/* NOTE: on change, update App.vue. Any classes in use in a template must be added to that template's CSS as well. */
					table_cell_class_list: [
						{ title: "None", value: "" },
						{ title: "No Borders", value: "no-borders" },
						{ title: "No Left Border", value: "no-left-border" },
						{ title: "No Right Border", value: "no-right-border" },
						{ title: "No Top Border", value: "no-top-border" },
						{ title: "No Bottom Border", value: "no-bottom-border" },
						{ title: "No Side Borders", value: "no-x-borders" },
						{ title: "No Top/Bottom Border", value: "no-y-borders" },
					],
					// eslint-disable-next-line no-unused-vars
					file_picker_callback: (cb: any, _value: any, _meta: any) => {
						var input = document.createElement('input')
						input.setAttribute('type', 'file')
						input.onchange = () => {
							if (!input || !input.files || input.files.length < 1) {
								return;
							}

							const file = input.files[0]
							const reader = new FileReader()
							var formData = new FormData()
							formData.append('file', file)
							reader.onload = () => {
								const imageUrl = reader.result
								const blobInfo = {
									blobUri: imageUrl
								}
								cb(imageUrl, blobInfo)
							}
							reader.readAsDataURL(file)
						}
						input.click()
					},
					// in addition to the file picker callback, we could also use an image upload
					// callback here to place things into Azure; https://www.tiny.cloud/docs/tinymce/latest/image/#images_upload_handler
					// however, with adding the 'image' plugin to the TinyMCE editor, we don't 
					// REQUIRE doing it this way.
				};
			},
		},
		watch: {
			source() {
				this.text = this.source;
			},
		},
	});
