import { IParticipant } from './IParticipant';
import { ICase } from '@/Models/ICase';
import { IPhase } from '@/Models/IPhase';
import { IEligibility } from './IEligibility';

export default class ParticipantPageDto {
	participant = null as IParticipant | null;
	case = null as ICase | null;
	phase = null as IPhase | null;
    eligibility = null as IEligibility | null;

	public static getFromParticipant(src: IParticipant | null) : ParticipantPageDto {
		return {
			participant: src?.participantId != undefined ? src : null,
			case: null,
            eligibility: null,
			phase: null,
		} as ParticipantPageDto;
	}

	public static getFromCase(src: ICase | null) : ParticipantPageDto {
		return {
			participant: src?.participant != undefined ? src.participant : null,
			case: src?.caseId != undefined ? src : null,
            eligibility: null,
			phase: null,
		} as ParticipantPageDto;
	}

    public static getFromEligibility(src: IEligibility | null): ParticipantPageDto {
        return {
            participant: src?.participant ? src.participant : null,
            case: src?.case ? src.case : null,
            eligibility: src?.eligibilityId ? src : null,
            phase: null,
        } as ParticipantPageDto;
    }

	public static getFromPhase(src: IPhase | null) : ParticipantPageDto {
		return {
			participant: src?.participant != undefined ? src.participant : null,
			case: src?.case != undefined ? src.case : null,
            eligibility: null,
			phase: src?.phaseId != undefined ? src : null,
		} as ParticipantPageDto;
	}
}