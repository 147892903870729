import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createSlots as _createSlots, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ParticipantHeader = _resolveComponent("ParticipantHeader")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_SingleErrorMessage = _resolveComponent("SingleErrorMessage")!
  const _component_ValidationAlert = _resolveComponent("ValidationAlert")!
  const _component_IncorrectPermissions = _resolveComponent("IncorrectPermissions")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_AddCaseLedgerLineDialog = _resolveComponent("AddCaseLedgerLineDialog")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_report_exporter = _resolveComponent("report-exporter")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.headerData && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_ParticipantHeader, {
          key: 0,
          data: _ctx.headerData
        }, null, 8, ["data"]))
      : _createCommentVNode("", true),
    _createVNode(_component_PageHeader, { headerText: "Case Ledger" }),
    (_ctx.isLoading && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_SingleErrorMessage, { errorMessage: _ctx.errorMessage }, null, 8, ["errorMessage"]),
    _createVNode(_component_ValidationAlert, { validationResults: _ctx.validationMessages }, null, 8, ["validationResults"]),
    (!_ctx.canViewRolePermission)
      ? (_openBlock(), _createBlock(_component_v_container, {
          key: 2,
          fluid: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_IncorrectPermissions)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!_ctx.isLoading && !_ctx.errorMessage && _ctx.canViewRolePermission)
      ? (_openBlock(), _createBlock(_component_v_card, { key: 3 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_AddCaseLedgerLineDialog, {
                  lookups: _ctx.lookups,
                  isSaveAllowed: _ctx.canEditRolePermission,
                  isDeleteAllowed: _ctx.canEditRolePermission,
                  modelValue: _ctx.showLedgerLineDialog,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showLedgerLineDialog) = $event)),
                  sourceLedgerLine: _ctx.selectedLedgerLine,
                  headerText: _ctx.ledgerLineHeader,
                  onClose: _ctx.cancelLineEdit,
                  onSave: _ctx.saveLine
                }, null, 8, ["lookups", "isSaveAllowed", "isDeleteAllowed", "modelValue", "sourceLedgerLine", "headerText", "onClose", "onSave"]),
                _createVNode(_component_ConfirmDialog, {
                  dialogIsOpen: _ctx.showDeleteWarning,
                  onConfirm: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirmDelete())),
                  onCancel: _ctx.cancelDelete,
                  dialogText: _ctx.deleteWarningText
                }, null, 8, ["dialogIsOpen", "onCancel", "dialogText"]),
                _createVNode(_component_v_data_table, {
                  headers: _ctx.ledgerLineHeaders,
                  items: _ctx.ledgerLines,
                  density: "compact",
                  class: "elevation-1"
                }, _createSlots({
                  "item.ledgerTypeDisplay": _withCtx(({ item }) => [
                    _createVNode(_component_v_chip, {
                      label: "",
                      color: _ctx.getTextColor(item.raw.ledgerTypeId)
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.raw.ledgerTypeDisplay), 1)
                      ]),
                      _: 2
                    }, 1032, ["color"])
                  ]),
                  "item.ledgerSubTypeDisplay": _withCtx(({ item }) => [
                    _createVNode(_component_v_chip, {
                      label: "",
                      color: _ctx.getTextColor(item.raw.ledgerTypeId)
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.raw.ledgerSubTypeDisplay), 1)
                      ]),
                      _: 2
                    }, 1032, ["color"])
                  ]),
                  "item.categoryDisplay": _withCtx(({ item }) => [
                    _createVNode(_component_v_chip, {
                      label: "",
                      color: _ctx.getTextColor(item.raw.ledgerTypeId)
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.raw.categoryDisplay), 1)
                      ]),
                      _: 2
                    }, 1032, ["color"])
                  ]),
                  "item.amount": _withCtx(({ item }) => [
                    _createVNode(_component_v_chip, {
                      label: "",
                      color: _ctx.getTextColor(item.raw.ledgerTypeId)
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formatCurrency(_ctx.amount = item.raw.amount)), 1)
                      ]),
                      _: 2
                    }, 1032, ["color"])
                  ]),
                  "item.date": _withCtx(({ item }) => [
                    _createElementVNode("span", {
                      style: _normalizeStyle({ color: _ctx.getTextColor(item.ledgerTypeId) })
                    }, _toDisplayString(_ctx.formatDate(item.raw.date)), 5)
                  ]),
                  _: 2
                }, [
                  (_ctx.canEditRolePermission)
                    ? {
                        name: "item.actions",
                        fn: _withCtx(({ item }) => [
                          _createVNode(_component_v_btn, {
                            onClick: ($event: any) => (_ctx.editLine(item.raw)),
                            class: "ma-1",
                            density: "comfortable",
                            size: "small",
                            icon: "mdi-pencil-outline",
                            color: "info"
                          }, null, 8, ["onClick"]),
                          _createVNode(_component_v_btn, {
                            onClick: ($event: any) => (_ctx.deleteLine(item.raw.caseLedgerId)),
                            class: "ma-1",
                            density: "comfortable",
                            size: "small",
                            icon: "mdi-trash-can-outline",
                            color: "error"
                          }, null, 8, ["onClick"])
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1032, ["headers", "items"]),
                _createVNode(_component_v_row, { class: "mt-2" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { align: "center" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_chip, { color: "red" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Fees: " + _toDisplayString(_ctx.formatCurrency(_ctx.amount = _ctx.totalFees)), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, { align: "center" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_chip, { color: "green" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Payments: " + _toDisplayString(_ctx.formatCurrency(_ctx.amount = _ctx.totalPayments)), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, { align: "center" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_chip, {
                          color: _ctx.balance >= 0 ? 'green' : 'red'
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Net: " + _toDisplayString(_ctx.formatCurrency(_ctx.amount = _ctx.balance)), 1)
                          ]),
                          _: 1
                        }, 8, ["color"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, { class: "mt-2" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { align: "center" }, {
                      default: _withCtx(() => [
                        _withDirectives(_createVNode(_component_v_btn, {
                          onClick: _ctx.newLedgerLine,
                          color: "primary"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Add New Line")
                          ]),
                          _: 1
                        }, 8, ["onClick"]), [
                          [_vShow, _ctx.canEditRolePermission]
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (!_ctx.isLoading && _ctx.ledgerLines.length > 0)
                  ? (_openBlock(), _createBlock(_component_report_exporter, {
                      key: 0,
                      items: _ctx.reportLedgerLines,
                      header: _ctx.reportHeader,
                      footer: _ctx.reportFooter,
                      filename: _ctx.reportFilename,
                      json_fields: _ctx.reportJsonfields,
                      pdf_fields: _ctx.reportPdfFields
                    }, null, 8, ["items", "header", "footer", "filename", "json_fields", "pdf_fields"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}