
	import { defineComponent } from 'vue';

	import { loadParticipantDataRequirePhase } from '@/Services/Helper/loader-helper';
	import { useUserStore } from '@/Services/Store/user-store';
	import { TemplateEngine } from '@/Services/Templating/template-engine';

	import { DocumentParentType } from '@/Enums/DocumentParentType';
	import { Role } from '@/Enums/Role';
	import HeaderData from '@/Models/HeaderData';
	import { IInternalUser } from '@/Models/IInternalUser';
	import { ICase } from '@/Models/ICase';
	import { IPhase } from '@/Models/IPhase';
	import { IParticipant } from '@/Models/IParticipant';
	import { IVariableInfill } from '@/Models/Templating/IVariableInfill';

	import LoadingSpinner from '@/ChildrenComponents/LoadingSpinner.vue';
	import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';
	import DocumentList from '@/ChildrenComponents/DocumentList.vue';
	import PageHeader from '@/ChildrenComponents/Participant/Common/PageHeader.vue';
	import ParticipantHeader from '@/ChildrenComponents/Participant/Common/ParticipantHeader.vue';
	import SingleErrorMessage from '@/ChildrenComponents/Participant/Common/SingleErrorMessage.vue';
	import DocumentGenerator from '@/ChildrenComponents/Templating/DocumentGenerator.vue';


	export default defineComponent({
		name: "phase-documents",
		components: { 
			ParticipantHeader, 
			LoadingSpinner, 
			ValidationAlert, 
			PageHeader, 
			SingleErrorMessage, 
			DocumentList,
			DocumentGenerator,
		},
		setup(): { currentUser: IInternalUser | null } {
			const userStoreInstance = useUserStore();
			return { currentUser: userStoreInstance.$state.cstInternalUser };
		},
		created() {
			loadParticipantDataRequirePhase(this.pageType, this.id)
				.then(data => {
					this.phaseId = data.phase?.phaseId ?? null;
					this.headerData = new HeaderData(data.participant, data.case?.program ?? null);
					this.phaseNumber = data.phase?.phaseNumber ?? null;

					if (data.case) {
                        this.caseData = data.case;
                    }
                    if (data.phase) {
                        this.phaseData = data.phase;
                    }
                    if (data.participant) {
                        this.participantData = data.participant;
                    }
				})
				.catch(async (err) => {
                    const errMessage = await err.response.data.text();
                    this.errorMessage = (errMessage && errMessage != "") ? errMessage : err.toString();
                })
				.finally(() => this.isLoading = false);
		},
		data() {
			return {
				errorMessage: "",
				validationMessages: [] as string[],

				headerData: null as HeaderData | null,
				pageType: "phase",
				id: this.$route.params.id == undefined ? null : parseInt(this.$route.params.id.toString()),
				phaseId: null as number | null,
				parentTypeId: DocumentParentType.Phase as number,
                phaseNumber: null as number | null,
				caseData: {} as ICase,
                phaseData: {} as IPhase,
                participantData: {} as IParticipant,

				refreshKey: 0 as number,
				DocumentParentType,

				isLoading: true,
				canUpload: true,
			}
		},
		methods: {
			refreshDocuments() {
                // this toggle will force a refresh in the document list object
                this.refreshKey += 1;
            },
		},
		computed: {
			canDelete(): boolean {
				const roles = this.currentUser?.attachedRoles ?? [];
				return roles.find(x => x.roleId == Role.StateAdministrator) != undefined;
			},
			infillVars(): IVariableInfill {
                return TemplateEngine.fillVarWithObjects([
                    this.participantData,
                    this.caseData,
                    this.phaseData,
                ]);
            },
		},
	});
