import { IParticipantNote } from '@/Models/IParticipantNote';
import { ITypedValidationResult } from '@/Models/IValidationResult';
import { BaseApiService } from './base-api-service';

class participantNoteService extends BaseApiService {
    public constructor() {
		super('ParticipantNote/');
    }

	public async getByParticipantId(participantId: number): Promise<IParticipantNote[]> {
		return this.httpClient.get<IParticipantNote[]>(`GetByParticipantId/${participantId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
	public async save(note: IParticipantNote): Promise<ITypedValidationResult<IParticipantNote>> {
		return this.httpClient.post<ITypedValidationResult<IParticipantNote>>("save", note).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
    
	public async delete(noteId: number): Promise<void> {
		return this.httpClient.delete<void>(`delete/${noteId}`).then(this.handleResponse.bind(this)).catch(this.handleError.bind(this));
    }
}

export const ParticipantNoteService: participantNoteService = new participantNoteService();