
	import { defineComponent, Ref, ref } from 'vue';

	import { DateHelper } from '@/Services/Helper/date-helper';
	import { ValidationHelper } from '@/Services/Helper/validation-helper';
	import { LookupService } from '@/Services/lookup-service';

	import { CaseNoteForEdit } from '@/Models/ICaseNote';
	import { INoteType } from '@/Models/Lookup/INoteType';

	import { NoteType } from '@/Enums/NoteType';
	import { LookupKeys } from "@/Enums/LookupKeys";

	export default defineComponent({
		name: "add-case-note-dialog",
		emits: ["close", "save"],
		props: {
			sourceCaseNote: CaseNoteForEdit,
			headerText: String,
		},
		setup(): { noteForm: Ref } {
			const noteForm = ref(null);
			return { noteForm };
		},
		created() {
			if (this.sourceCaseNote != undefined && this.sourceCaseNote != null) {
				this.caseNote = this.sourceCaseNote;
				this.showNoteTypeOther = this.caseNote.noteTypeId == NoteType.Other;
			}
			LookupService.getLookupByKey(LookupKeys.NoteType).then(result => this.noteTypeList = result as INoteType[]).then(() => this.isLoading = false);
		},
		data () {
			return {
				requiredFieldRules: ValidationHelper.requiredRules,
				caseNote: {} as CaseNoteForEdit | null,
				buttonText: "Save Note",
				noteTypeList: [] as INoteType[],
				isLoading: true,
				isSaving: false,
				showNoteTypeOther: false,
			}
		},
		methods: {
			closeDialog(): void {
				this.$emit("close");
			},
			async saveNote() {
				const { valid } = await this.noteForm.validate();
				if (valid) {
					if (this.caseNote != null) {
						const noteDate = DateHelper.standardizeDate(this.caseNote.noteDateEditable);
						if (noteDate != null) {
							this.caseNote.noteDate = noteDate;
						}
					}

					this.isSaving = true;
					this.buttonText = "Saving..."
					this.$emit("save", this.caseNote);
				}
			},
		},
		watch: {
			sourceCaseNote() {
				this.caseNote = this.sourceCaseNote?.caseNoteId != undefined ? this.sourceCaseNote : null;
				this.showNoteTypeOther = this.caseNote?.noteTypeId != undefined && this.caseNote?.noteTypeId == NoteType.Other;
				this.isSaving = false;
				this.buttonText = "Save Note";
			},
			'caseNote.noteTypeId'(newTypeId: number) {
				this.showNoteTypeOther = newTypeId == NoteType.Other;
			},
		},
	});
