import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustodyResidentialDialog = _resolveComponent("CustodyResidentialDialog")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_CustodyResidentialDialog, {
      modelValue: _ctx.showModal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showModal) = $event)),
      lookups: _ctx.lookups,
      source: _ctx.editingItem,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModal = false)),
      onSaved: _ctx.onSave
    }, null, 8, ["modelValue", "lookups", "source", "onSaved"]),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 0 }))
      : _createCommentVNode("", true),
    (!_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_v_data_table, {
          key: 1,
          headers: _ctx.headers,
          items: _ctx.items,
          density: "compact",
          class: "elevation-1 pre-wrap",
          "no-data-text": "No records"
        }, {
          "item.startDate": _withCtx(({ item }) => [
            _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(item.raw.startDate)), 1)
          ]),
          "item.endDate": _withCtx(({ item }) => [
            _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(item.raw.endDate)), 1)
          ]),
          "item.targetEndDate": _withCtx(({ item }) => [
            _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(item.raw.targetEndDate)), 1)
          ]),
          "item.custodyOrResidential": _withCtx(({ item }) => [
            _createElementVNode("span", null, _toDisplayString(_ctx.formatType(item.raw.custodyOrResidential)), 1)
          ]),
          "item.actions": _withCtx(({ item }) => [
            (_ctx.isSaveAllowed)
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 0,
                  onClick: ($event: any) => (_ctx.editRow(item.raw)),
                  class: "ma-1",
                  density: "comfortable",
                  size: "small",
                  icon: "mdi-pencil-outline",
                  color: "info"
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.isDeleteAllowed && false)
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 1,
                  onClick: ($event: any) => (_ctx.deleteRow(item.raw.goalId)),
                  class: "ma-1",
                  density: "comfortable",
                  size: "small",
                  icon: "mdi-trash-can-outline",
                  color: "error"
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["headers", "items"]))
      : _createCommentVNode("", true),
    false
      ? (_openBlock(), _createBlock(_component_v_row, {
          key: 2,
          class: "mt-2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { align: "center" }, {
              default: _withCtx(() => [
                (_ctx.isSaveAllowed)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      onClick: _ctx.addRow,
                      color: "success"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Add")
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}