
	import { defineComponent } from 'vue';
	import  CreateParticipantDialog  from '@/ChildrenComponents/CreateParticipantDialog.vue';
	import LoadingSpinner from '@/ChildrenComponents/LoadingSpinner.vue';

	import { LookupKeys } from '@/Enums/LookupKeys';

	import { ComponentType } from '@/Enums/ComponentType'
	import { IInternalUser } from '@/Models/IInternalUser';
	import { IParticipant } from '@/Models/IParticipant';
	import { IDataTableHeader } from '@/Models/IDataTableHeader';
	import ParticipantSearch from '@/Models/ParticipantSearch';
	import { IBaseLookup } from '@/Models/Lookup/BaseLookup';
	import { MasterLookupWrapper } from '@/Models/Lookup/MasterLookupWrapper';

	import { doesUserRoleHaveComponentEditPermissions } from '@/Services/Helper/component-permissions-helper'
	import { LookupService } from '@/Services/lookup-service';
	import { ParticipantService } from '@/Services/participant-service';

	import { useUserStore } from '@/Services/Store/user-store';

	export default defineComponent({
		name: 'participant-search',
		components: { LoadingSpinner, CreateParticipantDialog },
		setup(): { currentUser: IInternalUser | null } {
			const userStoreInstance = useUserStore();
			return { currentUser: userStoreInstance.$state.cstInternalUser };
		},
		created() {
			LookupService.getLookupsByKey([LookupKeys.Court, LookupKeys.County, LookupKeys.Gender])
				.then(wrapper => this.lookups = wrapper).finally(() => this.isLoading = false);
		},
		data () {
			return {
				lookups: {} as MasterLookupWrapper,
				searchResults: [] as IParticipant[],
				participantHeaders: [
					{ title: "Participant Number", key: "participantId", sortable: true, align: "start" },
					{ title: "First Name", key: "firstName", sortable: true, align: "start" },
					{ title: "Last Name", key: "lastName", sortable: true, align: "start" },
					{ title: "Current Status", key: "statusText", sortable: true, align: "start" },
					{ title: "Actions", key: "actions", sortable: false }
				] as IDataTableHeader[],

				searchOptions: [
					{ key: "firstName", name: "First Name", type: "text" },
					{ key: "lastName", name: "Last Name", type: "text" },
					{ key: "birthDate", name: "Date of Birth", type: "date" },
					{ key: "ssn", name: "SSN", type: "text" },
					{ key: "participantNumber", name: "Participant Number", type: "text" },
					{ key: "court", name: "Court", type: "select", lookupKey: LookupKeys.Court },
					{ key: "county", name: "County", type: "select", lookupKey: LookupKeys.County },
					{ key: "gender", name: "Sex", type: "select", lookupKey: LookupKeys.Gender },
				],
				searchDto: new ParticipantSearch(),
				searchValidationResults: [] as string[],
				isLoading: true,
				isLoadingResults: false,
				isCreateParticipantDialogActive: false,
			}
		},
		methods: {
			getLookupList(key: LookupKeys): IBaseLookup[] {
				return this.lookups.lookupLists[key];
			},
			getParticipantResults() {
				this.searchValidationResults = this.searchDto.validate();
				if (this.searchValidationResults.length == 0) {
					this.isLoadingResults = true;
					ParticipantService.search(this.searchDto).then(response => this.searchResults = response).then(() => this.isLoadingResults = false);
				}
			},
			openParticipantDetail(participant: IParticipant): void {
				this.$router.push({ path: `/participant/${participant.participantId}` });
			},
			newParticipantSubmit(newParticipant: IParticipant, redirect: boolean): void {
				ParticipantService.createBasicParticipant(newParticipant).then((response) => {
                    if (response.isValid) {
                        this.togglePartCreateDialog();
                        if (redirect) {
                            this.$router.push({path: `/participant/${response.id}`});
                        }
                    }
                    let createPartDialog = this.$refs.createPartDialog as any;
                    createPartDialog.validationResults = response.messages;
                    createPartDialog.createButtonDisabled = false;
                });
			},
            togglePartCreateDialog () {
                this.isCreateParticipantDialogActive = !this.isCreateParticipantDialogActive;
            }
		},
		computed: {
			componentType(): ComponentType {
				return ComponentType.ParticipantSearch
			},
			canEditRolePermission() : boolean {
				return doesUserRoleHaveComponentEditPermissions(this.currentUser?.attachedRoles ?? [], this.componentType)
			},
		},
	});
