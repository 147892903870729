
    import { defineComponent } from 'vue';

    import { useUserStore } from '@/Services/Store/user-store';
    import { LookupService } from '@/Services/lookup-service';
    import { TemplateService } from '@/Services/Templating/template-service';
    import { TemplateEngine } from '@/Services/Templating/template-engine';

    import { DocumentParentType } from '@/Enums/DocumentParentType';
    import { TemplateType } from '@/Enums/TemplateType';
    import { LookupKeys } from '@/Enums/LookupKeys';
    import { IInternalUser } from '@/Models/IInternalUser';
    import { IInternalUserRole } from '@/Models/IInternalUserRole';
    import { MasterLookupWrapper } from '@/Models/Lookup/MasterLookupWrapper';
    import { ITreatmentProgram } from '@/Models/ITreatmentProgram';
    import { ITemplate } from '@/Models/Templating/ITemplate';

    import IncorrectPermissions from '@/ChildrenComponents/IncorrectPermissions.vue';
    import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';
    import SingleErrorMessage from '@/ChildrenComponents/Participant/Common/SingleErrorMessage.vue';
    import BulkEditor from '@/ChildrenComponents/Templating/BulkEditor.vue';
    import DocumentGenerator from '@/ChildrenComponents/Templating/DocumentGenerator.vue';

    export default defineComponent({
        name: "template-management",
        components: {
            IncorrectPermissions,
            ValidationAlert,
            SingleErrorMessage,
            BulkEditor,
            DocumentGenerator,  
        },
        setup(): { currentUser: IInternalUser | null } {
            const userStoreInstance = useUserStore();
            const currentUser = userStoreInstance.$state.cstInternalUser;
            return {
                currentUser
            };
        },
        created() {
            if (!this.currentUser || !this.currentUser.attachedRoles.some((e: IInternalUserRole) => e.roleId === 1)) {
                this.isPermissionDenied = true;
                return;
            } else {
                this.loadData();
            }
        },
        data() {
            return {
                // status
                validationResults: [] as string[],
                errorMessage: "" as string,

                // state
                openPanels: [] as number[],
                lookups: {} as MasterLookupWrapper,
                templateRefreshProp: false as boolean,

                // dto
                availablePrograms: [] as ITreatmentProgram[],
                selectedProgram: null as ITreatmentProgram | null,
                programTemplates: [] as ITemplate[],
                infillVariables: TemplateEngine.emptyInfill(),

                TemplateType,
                DocumentParentType,
                allHeaderTemplates: [] as ITemplate[],
                allLetterTemplates: [] as ITemplate[],
                headerTemplates: [] as ITemplate[],
                letterTemplates: [] as ITemplate[],

                // perms
                isPermissionDenied: false as boolean,
                isLoading: true as boolean,
            }
        },
        methods: {
            // this callback should be triggered after any of the bulk editors update the template repository
            // to trigger a refresh for any consuming components like the document generator
            doTemplateRefresh() {
                this.templateRefreshProp = true;
            },
            didTemplateDelete() {
                this.doTemplateRefresh();
                this.loadData();
            },
            // this callback should be triggered after the document generator performs the update
            // based on modified templates
            didTemplateRefresh() {
                this.templateRefreshProp = false;
            },
            loadData() {
                this.isLoading = true;
                const lookups = [
                    LookupKeys.Court
                ]

                Promise.all([
                    LookupService.getLookupsByKey(lookups),
                    TemplateService.getByProgramIds((this.currentUser?.attachedPrograms ?? []).map(p => p.treatmentProgramId)),
                ])
                .then(([lookups, templates]: [MasterLookupWrapper, ITemplate[]]) => {
                    this.lookups = lookups;
                    this.programTemplates = templates;
                    this.headerTemplates = this.programTemplates.filter((t: ITemplate) => t.templateTypeId == TemplateType.Header);
                    this.letterTemplates = this.programTemplates.filter((t: ITemplate) => t.templateTypeId == TemplateType.Letter);
                    
                    // these 'all XXX template' objects are used to store the non-filtered by program templates
                    // to allow us to support multiple program selections on this page
                    this.allHeaderTemplates = this.headerTemplates;
                    this.allLetterTemplates = this.letterTemplates;

                    if (this.selectedProgram !== null) {
                        this.headerTemplates = this.headerTemplates.filter((t: ITemplate) => t.treatmentProgramId == this.selectedProgram!.treatmentProgramId)
                        this.letterTemplates = this.letterTemplates.filter((t: ITemplate) => t.treatmentProgramId == this.selectedProgram!.treatmentProgramId)
                    }
                    
                    if (!!this.currentUser && !!this.currentUser.attachedPrograms) {
                        this.availablePrograms = this.currentUser.attachedPrograms;
                    }

                })
                .catch(async (err) => {
                    const errMessage = await err.response.data.text();
                    this.errorMessage = (errMessage && errMessage != "") ? errMessage : err.toString();
                })
                .finally(() => {
                    this.isLoading = false;
                })
            },
            updateSelectedProgram(prog: ITreatmentProgram) {
                this.selectedProgram = prog;
                this.headerTemplates = this.headerTemplates.filter((t: ITemplate) => t.treatmentProgramId == prog.treatmentProgramId)
                this.letterTemplates = this.letterTemplates.filter((t: ITemplate) => t.treatmentProgramId == prog.treatmentProgramId)

                if (prog == null) {
                    this.openPanels = [];
                } else {
                    this.loadData();
                }
            }
        }
    });
