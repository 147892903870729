export abstract class DateHelper {
	/*
    IMPORTANT NOTE: DateTime stored in DB has no time zone, all DateTime returned from server (or DB) will not have time zone
    When using DateHelper, these DateTimes will be assumed to be UTC-0
    
	DATE FORMATTING PARAMETERS - based on C#
	d		Represents the day of the month as a number from 1 through 31
	dd		Represents the day of the month as a number from 01 through 31
	ddd		Represents the abbreviated name of the day (Mon, Tue, Wed, etc)
	dddd	Represents the full name of the day (Monday, Tuesday, etc)
	h		12-hour clock hour (e.g. 4)
	hh		12-hour clock, with a leading 0 (e.g. 06)
	H		24-hour clock hour (e.g. 15)
	HH		24-hour clock hour, with a leading 0 (e.g. 22)
	mm		Minutes with a leading zero
	M		Month number (e.g. 3)
	MM		Month number with leading zero (e.g. 04)
	MMM		Abbreviated Month Name (e.g. Dec)
	MMMM	Full month name (e.g. December)
	s		Seconds
	ss		Seconds with leading zero
	tt		AM / PM
	y		Year, no leading zero (e.g. 2015 would be 15)
	yyyy	Year, (e.g. 2015)
	z		UTC offset (e.g. "-6")
	*/

	/**
	 * Standardizes dates that may vary depending on how they come in from the API.
	 * @param date An object that's supposed to be a date but may come in string format.
	 * @returns Date|null Returns a date if the input is a date or a parseable string, null otherwise.
	 */
	public static standardizeDate(input: Date | string | null): Date | null {
		let result = null;

		if (input == null)
			return null;

		if (typeof input == "string") {
			try {
				//if string does not contain time zone, assume it's UTC
				const utcModifier = input.includes(":") ? "+00:00" : " 00:00:00+00:00";
				const utcInput = input.includes("+") || input.toLowerCase().includes("z") ? input : input + utcModifier;
				result = new Date(utcInput);
			}
			catch (e: any) {
				result = null;
			}

			if (result?.getTime() == undefined || isNaN(result?.getTime())) {
				try {
					//if adding time zone fails, try without the time zone
					result = new Date(input);
				}
				catch (e: any) {
					result = null;
				}
			}

			if (result?.getTime() == undefined || isNaN(result?.getTime()))
				result = null;
		}
		else {
			result = input;
		}

		return result;
	}

	/**
	 * Formats a date according to the provided format string, because Javascript has not seen fit to provide a native method for this elementary task.
	 * @param date A Date object or string.
	 * @param format A string representing the desired format. Uses C# formatting parameters. See this class for full list.
	 * @returns empty string if date is null or unparseable, otherwise the formatted date string for UTC time.
	 */
	public static formatDateUtc(input: Date | string | null, format: string): string {
		const date = this.standardizeDate(input);
		if (date == null) return "";

		const parsed = {
			seconds: date.getUTCSeconds(),
			minutes: date.getUTCMinutes(),
			hours: date.getUTCHours(),
			hours12: date.getUTCHours() == 0 ? 12 : (date.getUTCHours() > 12 ? date.getUTCHours() - 12 : date.getUTCHours()),
			weekday: date.getUTCDay(),
			day: date.getUTCDate(),
			month: date.getUTCMonth() + 1,
			year: date.getUTCFullYear(),
			utcText: "UTC",
		} as IParsedDate;

		return this.formatDate(parsed, format);
	}

	/**
	 * Formats a date according to the provided format string, because Javascript has not seen fit to provide a native method for this elementary task.
	 * @param date A Date object or string.
	 * @param format A string representing the desired format. Uses C# formatting parameters. See this class for full list.
	 * @returns empty string if date is null or unparseable, otherwise the formatted date string for local time.
	 */
    public static formatDateLocal(input: Date | string | null, format: string = "M/dd/yyyy h:mm tt z"): string {
		const date = this.standardizeDate(input);
		if (date == null) return "";

		const parsed = {
			seconds: date.getSeconds(),
			minutes: date.getMinutes(),
			hours: date.getHours(),
			hours12: date.getHours() == 0 ? 12 : (date.getHours() > 12 ? date.getHours() - 12 : date.getHours()),
			weekday: date.getDay(),
			day: date.getDate(),
			month: date.getMonth() + 1,
			year: date.getFullYear(),
			utcText: "UTC-" + (date.getTimezoneOffset() / 60),
		} as IParsedDate;

		return this.formatDate(parsed, format);
	}

	/**
	 * Formats a date to be used in a date entry field.
	 * @param input The date to be edited
	 */
	public static formatForEdit(input: Date | string | null): string {
		return this.formatDateUtc(input, "yyyy-MM-dd");
	}

	/**
	 * Takes a date from a date entry field and parses it as local.
	 * @param input The date to be parsed
	 */
	public static formatFromEdit(input: string): Date | null {
		if (input == "" || input == null || input == undefined) {
			return null;
		}
		try {
			const dateSplit = input.split(/\D/);
			return new Date(Number(dateSplit[0]), Number(dateSplit[1]) - 1, Number(dateSplit[2]));
		}
		catch (e: any) {
			return null;
		}
	}

	private static formatDate(parsed: IParsedDate, format: string): string {
		try {
			let result = format
				.replace("yyyy", parsed.year.toString())
				.replace("y", (parsed.year % 100).toString())
				.replace("tt", "%%")
				.replace("ss", parsed.seconds.toString().padStart(2, "0"))
				.replace("s", parsed.seconds.toString())
				.replace("MMMM", "~~~~")
				.replace("MMM", "~~~")
				.replace("MM", parsed.month.toString().padStart(2, "0"))
				.replace("M", parsed.month.toString())
				.replace("mm", parsed.minutes.toString().padStart(2, "0"))
				.replace("HH", parsed.hours.toString().padStart(2, "0"))
				.replace("H", parsed.hours.toString())
				.replace("hh", parsed.hours12.toString().padStart(2, "0"))
				.replace("h", parsed.hours12.toString())
				.replace("dddd", "^^^^")
				.replace("ddd", "^^^")
				.replace("dd", parsed.day.toString().padStart(2, "0"))
				.replace("d", parsed.day.toString())
				.replace("z", parsed.utcText)
				;
			//names/abbreviations are causing issues
			result = result
				.replace("%%", parsed.hours < 12 ? "AM" : "PM")
				.replace("~~~~", this.getMonthName(parsed.month))
				.replace("~~~", this.getMonthAbbreviation(parsed.month))
				.replace("^^^^", this.getDayName(parsed.weekday))
				.replace("^^^", this.getDayAbbreviation(parsed.weekday));
			return result;
		}
		catch (e: any) {
			return "";
		}
	}

	private static getMonthName(monthNum: number): string {
		switch (monthNum) {
			case 1: return "January";
			case 2: return "February";
			case 3: return "March";
			case 4: return "April";
			case 5: return "May";
			case 6: return "June";
			case 7: return "July";
			case 8: return "August";
			case 9: return "September";
			case 10: return "October";
			case 11: return "November";
			case 12: return "December";
			default: throw new Error("Invalid month number");
		}
	}

	private static getMonthAbbreviation(monthNum: number): string {
		switch (monthNum) {
			case 1: return "Jan";
			case 2: return "Feb";
			case 3: return "Mar";
			case 4: return "Apr";
			case 5: return "May";
			case 6: return "Jun";
			case 7: return "Jul";
			case 8: return "Aug";
			case 9: return "Sep";
			case 10: return "Oct";
			case 11: return "Nov";
			case 12: return "Dec";
			default: throw new Error("Invalid month number");
		}
	}

	private static getDayName(dayNum: number): string {
		switch (dayNum) {
			case 0: return "Sunday";
			case 1: return "Monday";
			case 2: return "Tuesday";
			case 3: return "Wednesday";
			case 4: return "Thursday";
			case 5: return "Friday";
			case 6: return "Saturday";
			default: throw new Error("Invalid day number");
		}
	}

	private static getDayAbbreviation(dayNum: number): string {
		switch (dayNum) {
			case 0: return "Sun";
			case 1: return "Mon";
			case 2: return "Tue";
			case 3: return "Wed";
			case 4: return "Thu";
			case 5: return "Fri";
			case 6: return "Sat";
			default: throw new Error("Invalid day number");
		}
	}
}


interface IParsedDate {
	seconds: number,
	minutes: number,
	hours: number,
	hours12: number,
	weekday: number,
	day: number,
	month: number,
	year: number,
	utcText: string,
}
