export enum CaseClosureReason {
	OptedOut = 1,
	DroppedOut = 2,
	OutOfState = 3,
	Death = 4,
	Incarceration = 5,
	Terminated = 6,
	Discharged = 7,
	Graduated = 8,
	TimedOut = 9,
	Medical = 10,
	Other = 99
}