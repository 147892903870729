import { BaseDto, IBaseDto } from "./IBaseDto";

export interface IAddress extends IBaseDto {
    addressId: number;
	caseId: number;
	isActive: boolean;
    address1: string;
	address2: string;
	address3: string;
	city: string;
	stateId: number;
	stateOther: string;
	zip: string;
	countryId: number;
    contactTypeId: number | null;

	stateAbbreviation: string;
	stateName: string;
	countryName: string;
    contactType: string;
}

export class Address extends BaseDto implements IAddress {
	addressId: number;
	caseId: number;
	isActive: boolean;
	address1: string;
	address2: string;
	address3: string;
	city: string;
	stateId: number;
	stateOther: string;
	zip: string;
	countryId: number;
    contactTypeId: number | null;

	stateAbbreviation: string;
	stateName: string;
	countryName: string;
    contactType: string;

	constructor(src: IAddress) {
		super(src);
		this.addressId = src.addressId;
		this.caseId = src.caseId;
		this.isActive = src.isActive;
		this.address1 = src.address1;
		this.address2 = src.address2;
		this.address3 = src.address3;
		this.city = src.city;
		this.stateId = src.stateId;
		this.stateOther = src.stateOther;
		this.zip = src.zip;
		this.countryId = src.countryId;
        this.contactTypeId = src.contactTypeId;

		this.stateAbbreviation = src.stateAbbreviation;
		this.stateName = src.stateName;
		this.countryName = src.countryName;
        this.contactType = src.contactType;
	}
}
